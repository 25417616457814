// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_URL = 'https://dev.ecsy.fr/api/';
const FRONTEND_URL = 'https://dev.ecsy.fr/';

export const environment = {
  production: false,
  postLogoutRedirectUri: 'https://dev.ecsy.fr/',
  apiUrl: API_URL,
  frontendUrl: FRONTEND_URL,
  envName: 'dev',
  authDebugging: false,
  scope: 'openid profile ecsy.back policy.back broadcast.service ticket.service geometry.service offline_access',
  allowedUrls: [
    API_URL
  ],
};
