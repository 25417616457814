import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './components/layout/layout.component';
import { RoleGuard } from './guards/role.guard';
import { ClientsComponent } from './components/clients/clients.component';
import { NgrxRouterStoreModule } from '../store/router-store/ngrx-router.module';
import { CoreStatesGuard } from './guards/core-states.guard';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    // canActivateChild: [AuthGuard],
    children: [
      {
        path: 'clients',
        canActivate: [AuthGuard],
        component: ClientsComponent
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent
      },
      {
        path: 'accounts',
        loadChildren: () => import('../features/account/account.module').then(m => m.AccountModule),
      },
      {
        path: '',
        canActivate: [AuthGuard, RoleGuard, CoreStatesGuard],
        //canActivateChild: [TicketGuard], // RoleGuard TicketGuard
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: 'real-estate/map', redirectTo: '/pages/real-estate/map', pathMatch: 'full' },
          // { path: 'manage/manage-floor-models', redirectTo: '/pages/manage/manage-floor-models', pathMatch: 'full' },
          // { path: 'manage/floor-catalog', redirectTo: '/pages/manage/floor-catalog', pathMatch: 'full' },
          // { path: 'manage/admin-import', redirectTo: '/pages/manage/admin-import', pathMatch: 'full' },
          {
            path: 'dashboard',
            loadChildren: () => import('../features/dashboard/dashboard.module').then(m => m.DashboardModule),
          },
          {
            path: 'pages',
            loadChildren: () => import('../pages/pages.module').then(m => m.PagesModule),
          },
          {
            path: 'user',
            loadChildren: () => import('../features/user/user.module').then(m => m.UserModule)
          },
          {
            path: 'simulations',
            loadChildren: () => import('../features/simulation/simulation.module').then(m => m.SimulationModule)
          },
          {
            path: 'floor',
            loadChildren: () => import('../features/floor/floor.module').then(m => m.FloorModule),
          },
          {
            path: 'building',
            loadChildren: () => import('../features/building/building.module').then(m => m.BuildingModule)
          },
          {
            path: 'project-management',
            loadChildren: () => import('../features/project-management/project-management.module').then(m => m.ProjectManagementModule)
          },
          {
            path: 'data-tables',
            loadChildren: () => import('../features/data-tables/data-tables.module').then(m => m.DataTablesModule)
          },
          {
            path: 'upload',
            loadChildren: () => import('../features/upload/upload.module').then(m => m.UploadModule)
          },
        ]
      },
      {
        path: '**', component: NotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, enableTracing: false}), NgrxRouterStoreModule], //relativeLinkResolution: 'legacy'
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
