import { createAction, props } from '@ngrx/store';
import { RoomAttributionType } from '../../../domain-models/business/room-attribution-type.model';

export const beginGetAllRoomAttributionTypes = createAction('[Shared/API] - Begin Get All RoomAttributionTypes');

export const successGetAllRoomAttributionTypes = createAction(
  '[API] - Success Get All RoomAttributionTypes',
  props<{ roomAttributionTypes: RoomAttributionType[] }>()
);

export const errorGetAllRoomAttributionTypes = createAction('[Shared/API] - Error Get All RoomAttributionTypes', props<Error>());

export const addRoomAttributionType = createAction('[Shared/API] Add RoomAttributionType', props<RoomAttributionType>());
