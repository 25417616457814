import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TaskFloorModel } from '../../../domain-models/business-extended/task-floor-model.model';

export const addTaskFloorModel = createAction(
  '[Floor/API] - Add TaskFloorModel',
  props<{ taskFloorModel: TaskFloorModel }>()
);

export const addTaskFloorModels = createAction(
  '[Floor/API] - Add TaskFloorModels',
  props<{ taskFloorModels: TaskFloorModel[] }>()
);

export const removeTaskFloorModelItems = createAction(
  '[Floor/API] - Remove TaskFloorModels',
  props<{ ids: string[] }>()
);

export let updateTaskFloorModel = createAction(
  '[Floor] - Update TaskFloorModel',
  props<{ update: Update<TaskFloorModel> }>()
);

export let updateTaskFloorModelItems = createAction(
  '[Floor] - Update TaskFloorModelItems',
  props<{ updates: Update<TaskFloorModel>[] }>()
);

export const beginGetAllTaskFloorModels = createAction('[Floor/API] - Begin Get All TaskFloorModels');
export const successGetAllTaskFloorModels = createAction(
  '[Floor/API] - Success Get All TaskFloorModels',
  props<{ taskFloorModels: TaskFloorModel[] }>()
);

export const removeTaskFloorModelItemsByTaskIds = createAction(
  '[Floor/StudyMode] - Remove TaskFloorModel by taskId',
  props<{ taskIds: number[] }>()
);

export const errorGetAllTaskFloorModels = createAction('[API] - Error Get All TaskFloorModels', props<Error>());
export const clearTaskFloorModels = createAction('[Floor] Clear TaskFloorModel');

export const showFloorPlanning = createAction('[Floor] Show Partitioning');
export const hideFloorPlanning = createAction('[Floor] Hide Partitioning');
