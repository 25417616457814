import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiResponseCodeEnum } from '../../domain-models/models/api-response-code.model';
import { ApiData } from '../models/api-data.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    return next.handle(request).pipe(
      tap((r) => {
        if (r instanceof HttpResponse) {
          if ((<ApiData>r.body).statusCode === ApiResponseCodeEnum.databaseUnavailable) {
            this.router.navigate(['/maintenance']);
          }
        }
      }),
    /** Error are handled in ApiService **/
    //   catchError(err => {
    //   if (err instanceof HttpErrorResponse) {
    //     if (err.status === 400) {
    //       // Handle unauthorized error
    //       // console.log("Unauthorized");
    //       // logout() user
    //     } else if (err.status === 500) {
    //       // Handler internal server error
    //       // console.log("Server is not responding.")
    //       // alert("Try after some time.")
    //     }
    //     // ......
    //   }
    //   //console.log(err);
    //   return new Observable<HttpEvent<any>>();
    // })
    );
  }
}
