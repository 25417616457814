import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { SvgViewerComponent } from './components/svg-viewer/svg-viewer.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { ImageTooltipComponent } from './components/image-tooltip/image-tooltip.component';
import { ImageTooltipDirective } from './directives/image-tooltip.directive';
import { HoverClassDirective } from './directives/hover-class.directive';
import { LanguagePipe } from './pipes/language.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { FindPipe } from './pipes/find.pipe';
import { SumPipe } from './pipes/sum.pipe';
import { FloorLegendComponent } from './components/floor-legend/floor-legend.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RoomAreaPipe } from './pipes/room-area.pipe';
import { DirectoryNamePipe } from './pipes/directory-name.pipe';
import { IsNaNPipe } from './pipes/is-na-n.pipe';
import { BusinessUnitPipe } from './pipes/business-unit.pipe';
import { PinchGestureDirective } from './directives/pinch-gesture.directive';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import { BarcodeScannerDialogComponent } from './components/barcode-scanner-dialog/barcode-scanner-dialog.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FileNameFromPathPipe } from './pipes/file-name-from-path.pipe';
import { FilePreviewDialogComponent } from './components/file-preview-dialog/file-preview-dialog.component';
import { SecuredImageComponent } from './components/secured-image/secured-image.component';
import { DocumentVersionsTableComponent } from './components/document-versions-table/document-versions-table.component';
import { ColorFormFieldComponent } from './components/color-form-field/color-form-field.component';
import { WorkplaceTypeCountPipe } from './pipes/workplace-type-count.pipe';
import { MatInputTreeComponent } from './components/mat-input-tree/mat-input-tree.component';
import { MatInputTreeDirective } from './directives/mat-input-tree.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    FloorLegendComponent,
    SvgViewerComponent,
    OrderByPipe,
    ReversePipe,
    CustomTooltipDirective,
    CustomTooltipComponent,
    ImageTooltipComponent,
    ImageTooltipDirective,
    HoverClassDirective,
    LanguagePipe,
    FilterPipe,
    FindPipe,
    SumPipe,
    RoomAreaPipe,
    DirectoryNamePipe,
    IsNaNPipe,
    BusinessUnitPipe,
    PinchGestureDirective,
    BarcodeScannerComponent,
    BarcodeScannerDialogComponent,
    DateAgoPipe,
    FileNameFromPathPipe,
    FilePreviewDialogComponent,
    SecuredImageComponent,
    DocumentVersionsTableComponent,
    ColorFormFieldComponent,
    WorkplaceTypeCountPipe,
    MatInputTreeComponent,
    MatInputTreeDirective,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ZXingScannerModule,
  ],
  exports: [
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SvgViewerComponent,
    OrderByPipe,
    ReversePipe,
    CustomTooltipDirective,
    ImageTooltipDirective,
    HoverClassDirective,
    LanguagePipe,
    FilterPipe,
    FindPipe,
    SumPipe,
    FloorLegendComponent,
    RoomAreaPipe,
    DirectoryNamePipe,
    IsNaNPipe,
    BusinessUnitPipe,
    PinchGestureDirective,
    DateAgoPipe,
    FileNameFromPathPipe,
    SecuredImageComponent,
    DocumentVersionsTableComponent,
    MatInputTreeComponent
  ]
  // entryComponents: [
  //   CustomTooltipComponent,
  //   ImageTooltipComponent
  // ]
})
export class SharedModule {
}
