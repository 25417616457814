import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { asyncScheduler, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';
import { DisplayThemeItemActions } from '../actions';
import { ROOM_LAYOUT_TYPE, RoomLayoutType } from '../../../domain-models/business/room-layout-type.model';
import { SharedService } from '../../../shared/services/shared.service';
import { ROOM_ACTIVITY_STATUS_TYPE, RoomActivityStatusTypeEnum } from '../../../domain-models/business/room-activity-status-type.model';
import { BusinessUnit } from '../../../domain-models/business/business-unit.model';
import { DisplayThemeMockup } from '../../../features/floor/models/display-theme-mockup.model';
import { DISPLAY_THEMABLE, DisplayThemableEnum } from '../../../domain-models/business/display-themable.model';
import { DisplayTheme, DisplayThemeEnum } from '../../../domain-models/business/display-theme.model';
import { ThemeItemMockup } from '../../../features/floor/models/theme-item-mockup.model';
import { ROOM_ATTRIBUTION_TYPE, RoomAttributionTypeEnum } from '../../../domain-models/business/room-attribution-type.model';
import { ROOM_ALLOCATION } from '../../../domain-models/business/room-allocation.model';
import { Store } from '@ngrx/store';
import { DisplayThemeService } from '../../../shared/services/display-theme.service';
import { LanguageService } from '../../../shared/services/language.service';
import { BusinessUnitSelectors, DisplayThemeItemSelectors, RoomLayoutTypeSelectors } from '../selectors';
import { DisplayThemeItemView } from '../../../domain-models/views/display-theme-item-view.model';
import { DisplayThemeCategory } from '../../../domain-models/business/display-theme-category.model';
import { WORKPLACE_TYPE } from '../../../domain-models/business/workplace-type.model';
import { FLOOR_DATA_STATE } from '../../../domain-models/business/floor-data-state.model';
import { RendererActions } from '../../svg-store/actions';
import { Update } from '@ngrx/entity/src/models';
import { FLOOR_DATA_IMPORT } from '../../../domain-models/business/floor-data-import.model';
import { State } from '../../../reducers';
import { EQUIPMENT_PLANNING_STATE } from '../../../domain-models/business/equipment-planning-state.model';

@Injectable()
export class DisplayThemeItemEffects {

  allRoomLayoutTypes$: Observable<RoomLayoutType[]>;
  allBusinessUnits$: Observable<BusinessUnit[]>;

  themeSource$ = combineLatest([
    this.displayThemeService.getDisplayTheme(),
    this.displayThemeService.getDisplayThemeCategories(),
    this.displayThemeService.getDisplayThemeItemView(),
    this.store.select(RoomLayoutTypeSelectors.selectAllRoomLayoutTypes),
    this.store.select(BusinessUnitSelectors.selectAllBusinessUnits)]);

  applyThemeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayThemeItemActions.applyThemeItem),
      // Clear FloorData selection prior to apply any theme item
      switchMap(action => of(RendererActions.resetSelection()))
    )
  );

  isolateThemeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayThemeItemActions.isolateThemeItem),
      switchMap(action => this.store.select(DisplayThemeItemSelectors.selectAllCurrentDisplayThemeItems).pipe(
          first(),
          switchMap(themeItems => {
            const updates = themeItems.map(t => {
              const update = {
                id: t.resId
              } as Update<ThemeItemMockup>;
              if (t.resId === action.id) {
                update.changes = {
                  active: true
                };
              } else {
                update.changes = {
                  active: false
                };
              }
              return update;
              // return {
              //   id: t.resId,
              //   changes: {
              //     active: false
              //   }
              // } as Update<ThemeItemMockup>;
            }) as Update<ThemeItemMockup>[];
            //console.log(updates);
            return [DisplayThemeItemActions.updateDisplayThemeItems({
              displayThemeItems: updates
            })];
          })
        )
      )
    )
  );

  showAllThemeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayThemeItemActions.showAllThemeItems),
      switchMap(action => this.store.select(DisplayThemeItemSelectors.selectAllCurrentDisplayThemeItems).pipe(
          first(),
          switchMap(themeItems => {
            const updates = themeItems.map(t => {
              return {
                id: t.resId,
                changes: {
                  active: true
                }
              } as Update<ThemeItemMockup>;
            }) as Update<ThemeItemMockup>[];
            //console.log(updates);
            return [DisplayThemeItemActions.updateDisplayThemeItems({
              displayThemeItems: updates
            })];
          })
        )
      )
    )
  );

  buildDisplayThemes$ = createEffect(
    () => ({debounce = 0, scheduler = asyncScheduler} = {}) =>
      this.actions$.pipe(
        ofType(DisplayThemeItemActions.buildDisplayThemes),
        debounceTime(debounce, scheduler),
        switchMap(action =>
          this.themeSource$.pipe(
            map(([displayThemes,
                   displayThemeCategories,
                   displayThemeItemView,
                   layoutTypes,
                   businessUnits
                 ]) => {
              const themes = this.buildThemes(displayThemes, displayThemeCategories);
              this.store.dispatch(DisplayThemeItemActions.addDisplayThemes({themes: themes}));
              this.store.dispatch(DisplayThemeItemActions.addDisplayThemeCategories({themeCategories: displayThemeCategories}));
              this.mapDisplayThemeItems(themes, displayThemeItemView, layoutTypes, businessUnits);
            }),
            switchMap(_ => of(DisplayThemeItemActions.buildDisplayThemesSuccess()))
          )
        ))
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private displayThemeService: DisplayThemeService,
    private languageService: LanguageService,
    private store: Store<State>
  ) {
    this.allRoomLayoutTypes$ = this.store.select(RoomLayoutTypeSelectors.selectAllRoomLayoutTypes);
    this.allBusinessUnits$ = this.store.select(BusinessUnitSelectors.selectAllBusinessUnits);
  }

  buildThemes(displayThemes: DisplayTheme[], displayThemeCategories: DisplayThemeCategory[]): DisplayThemeMockup[] {
    const themes = [] as DisplayThemeMockup[];

    displayThemes.forEach(e => {
      const theme = {
        id: e.id,
        categoryId: e.categoryId,
        name: this.languageService.searchDisplayName(e.displayNameId),
      } as DisplayThemeMockup;
      themes.push(theme);
    });
    //console.log(themes);
    return themes;
  }

  mapDisplayThemeItems(themes: DisplayThemeMockup[], displayThemeItemView: DisplayThemeItemView[], layoutTypes: RoomLayoutType[], businessUnits: BusinessUnit[]) {
    const layoutTypesThemeItems: ThemeItemMockup[] = [];
    const businessUnitsThemeItems: ThemeItemMockup[] = [];

    layoutTypes.forEach(type => {
      const item = {
        displayThemeIds: [DisplayThemeEnum.LayoutType],
        //displayThemeIds: [],
        resId: ROOM_LAYOUT_TYPE.databaseTableName + '-' + type.id,
        name: type.name,
        color: type.color,
        //svgPatternId: undefined,
        active: true,
      } as ThemeItemMockup;
      layoutTypesThemeItems.push(item);
    });

    businessUnits.forEach(bu => {
      const item = {
        displayThemeIds: [DisplayThemeEnum.Allocations, DisplayThemeEnum.Mixed],
        //displayThemeIds: [],
        resId: ROOM_ALLOCATION.databaseTableName + '-' + bu.id,
        name: bu.name,
        color: bu.color,
        //svgPatternId: undefined,
        active: true,
      } as ThemeItemMockup;
      businessUnitsThemeItems.push(item);
    });

    /** Dictionary of DiTh ids mapped to DiTh ids**/
    /*const displayThemableIdsDict = displayThemeItemView.reduce((acc, displayThemeItemViewItem) => {
      const {displayThemableId, displayThemeId, themableTableName} = displayThemeItemViewItem;
      return {...acc, [displayThemableId]: [...(acc[displayThemableId] || []), displayThemeId]};
    }, {});*/

    /** Dictionary of DiTh
     * [themableTableName]: displayThemableId[displayThemeId]
     * **/
    let displayThemableIdsDict = displayThemeItemView.reduce((acc, displayThemeItemViewItem) => {
      const {displayThemableId, displayThemeId, themableTableName} = displayThemeItemViewItem;
      return {
        ...acc,
        [themableTableName]: [...(acc[themableTableName] || []), displayThemeItemViewItem]
      };
    }, {});

    for (const [themableTableName, displayThemeItemViewItems] of Object.entries(displayThemableIdsDict)) {
      displayThemableIdsDict[themableTableName] = (<DisplayThemeItemView[]>displayThemeItemViewItems).reduce((acc, displayThemeItemViewItem) => {
        const {displayThemableId, displayThemeId} = displayThemeItemViewItem;
        return {
          ...acc,
          [displayThemableId]: [...(acc[displayThemableId] || []), displayThemeId]
        };
      }, {});
    }

    let themeItems: ThemeItemMockup[] = [];

    for (const [displayThemableName, displayThemableDict] of Object.entries(displayThemableIdsDict)) {
      for (const [displayThemableId, displayThemeIds] of Object.entries(displayThemableDict)) {
        const item = displayThemeItemView.find(i => i.themableTableName === displayThemableName && i.displayThemableId === Number(displayThemableId));
        let displayThemeItem = this.createThemeItem(item);
        displayThemeItem.displayThemeIds = displayThemeIds;
        themeItems.push(displayThemeItem);
      }
    }

    themeItems = themeItems.concat(layoutTypesThemeItems);
    themeItems = themeItems.concat(businessUnitsThemeItems);
    this.store.dispatch(DisplayThemeItemActions.addDisplayThemeItems({displayThemeItems: themeItems}));
  }

  createThemeItem(displayThemeItem: DisplayThemeItemView): ThemeItemMockup {
    let item = {
      displayThemeIds: [displayThemeItem.displayThemeId],
      name: this.languageService.searchDisplayName(displayThemeItem.displayThemableDisplayNameId),
      //name: displayThemeItem.displayThemableName,
      color: displayThemeItem.displayThemableColor,
      svgPatternId: displayThemeItem.displayThemableSvgPatternId,
      active: true,
    } as ThemeItemMockup;


    item = {...this.displayThemeIdToResId(displayThemeItem, item)};
    return item;
  }

  displayThemeIdToResId(item: DisplayThemeItemView, themeItem: ThemeItemMockup): ThemeItemMockup {
    switch (item.themableTableName) {
      default:
        // console.log(item);
        // console.log(themeItem);
        themeItem.resId = `NA-${themeItem.name}`;
        return themeItem;
      case DISPLAY_THEMABLE.databaseTableName: {
        switch (item.displayThemableId) {
          case DisplayThemableEnum.LayoutType:
            themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-LayoutType';
            return themeItem;
          case DisplayThemableEnum.None:
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.None;
            return themeItem;
          case DisplayThemableEnum.Empty:
            themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.Empty;
            return themeItem;
          case DisplayThemableEnum.MultiAllocated:
            themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-MultiAllocated';
            return themeItem;
          case DisplayThemableEnum.NotAllocated:
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-NotAllocated';
            return themeItem;
          case DisplayThemableEnum.Used:
            //themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.Usable;
            return themeItem;
          case DisplayThemableEnum.UnderConstruction:
            themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.UnderConstruction;
            return themeItem;
          case DisplayThemableEnum.Shared:
            themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Sharing;
            return themeItem;
          case DisplayThemableEnum.Allocated:
            //  themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Allocation;
            return themeItem;
          case DisplayThemableEnum.Exploitation:
            themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
            themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Exploitation;
            return themeItem;
          case DisplayThemableEnum.StandingBy:
            themeItem.resId = DISPLAY_THEMABLE.databaseTableName + '-' + DisplayThemableEnum.StandingBy;
            return themeItem;
          case DisplayThemableEnum.Identified:
            themeItem.resId = DISPLAY_THEMABLE.databaseTableName + '-' + DisplayThemableEnum.Identified;
            return themeItem;
          case DisplayThemableEnum.Unchanged:
            themeItem.resId = FLOOR_DATA_IMPORT.databaseTableName + '-' + DisplayThemableEnum.Unchanged;
            return themeItem;
          case DisplayThemableEnum.ProposedAdded:
            themeItem.resId = FLOOR_DATA_IMPORT.databaseTableName + '-' + DisplayThemableEnum.ProposedAdded;
            return themeItem;
          case DisplayThemableEnum.ProposedDeleted:
            themeItem.resId = FLOOR_DATA_IMPORT.databaseTableName + '-' + DisplayThemableEnum.ProposedDeleted;
            return themeItem;
          // default:
          //   console.log(themeItem);
          //   break;
        }
        break;
      }
      case WORKPLACE_TYPE.databaseTableName:
        themeItem.resId = WORKPLACE_TYPE.databaseTableName + '-' + item.displayThemableId;
        return themeItem;
      case FLOOR_DATA_STATE.databaseTableName:
        themeItem.resId = FLOOR_DATA_STATE.databaseTableName + '-' + item.displayThemableId;
        return themeItem;
      case EQUIPMENT_PLANNING_STATE.databaseTableName: {
        themeItem.resId = EQUIPMENT_PLANNING_STATE.databaseTableName + '-' + item.displayThemableId;
        return themeItem;
      }
    }
  }
}
