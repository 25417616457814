import { createSelector } from '@ngrx/store';
import * as fromWorkplace from '../reducers/workplace.reducer';
import { State } from '../reducers/workplace.reducer';
import { selectFloorsState } from '../reducers';
import { selectFloorDataIdsByBuildingId, selectFloorDataIdsByTaskId } from '../../svg-store/selectors/floor-data.selectors';

/** Select store slice **/

export const selectWorkplaces = createSelector(
  selectFloorsState,
  state => state[fromWorkplace.workplaceFeatureKey]
);

/** Basics selectors **/

export const selectWorkplaceIds = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceIds // shorthand for workplacesState => fromWorkplace.selectWorkplaceIds(workplacesState)
);
export const selectWorkplaceEntities = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceEntities
);
export const selectAllWorkplaces = createSelector(
  selectWorkplaces,
  fromWorkplace.selectAllWorkplaces
);
export const selectWorkplaceTotal = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceTotal
);
// export const selectCurrentWorkplaceId = createSelector(
//   selectWorkplaces,
//   fromWorkplace.getSelectedWorkplaceId
// );

// export const selectCurrentWorkplace = createSelector(
//   selectWorkplaceEntities,
//   selectCurrentWorkplaceId,
//   (workplaceEntities, workplaceId) => workplaceEntities[workplaceId]
// );

/** Advanced selectors **/

export const selectWorkplacesByFloorDataId = (id: number) => createSelector(
  selectAllWorkplaces,
  (workplaces) => workplaces.filter(w => w.floorDataId === id)
);

export const selectWorkplaceByFloorDataId = (id: number) => createSelector(
  selectAllWorkplaces,
  (workplaces) => workplaces.find(w => w.floorDataId === id)
);

export const selectAllWorkplaceByTaskId = (taskId: number) => createSelector(
  selectAllWorkplaces,
  selectFloorDataIdsByTaskId(taskId),
  (workplaces, floorDataIds) => workplaces.filter(w => floorDataIds.find(id => id === w.floorDataId))
);

export const selectAllWorkplaceByBuildingId = (buildingId: number) => createSelector(
  selectAllWorkplaces,
  selectFloorDataIdsByBuildingId(buildingId),
  (workplaces, floorDataIds) => workplaces.filter(w => floorDataIds.find(id => id === w.floorDataId))
);

export const getSelectedWorkplaceIds = createSelector(
  selectWorkplaces,
  (state: State) => state.selectedIds
);

export const getSelectedWorkplaces = createSelector(
  selectAllWorkplaces,
  getSelectedWorkplaceIds,
  (workplaces, ids) =>
    workplaces.filter(w => w.floorDataId === ids.find(e => e === w.floorDataId))
);

/** Advanced selectors **/

export const selectBuildingWorkplacesByWorkplaceTypeId = (workplaceTypeId: number, buildingId: number) => createSelector(
  selectAllWorkplaceByBuildingId(buildingId),
  selectFloorDataIdsByBuildingId(buildingId),
  (workplaces, buildingFloorDataIds) =>
    workplaces.filter(a => buildingFloorDataIds.find(id => id === a.floorDataId))
      .filter(a => a.workplaceType === workplaceTypeId)
);

export const selectBuildingWorkplaceCountByWorkplaceTypeId = (workplaceTypeId: number, buildingId: number) => createSelector(
  selectAllWorkplaceByBuildingId(buildingId),
  // selectBuildingRoomAllocationById(workplaceTypeId, buildingId),
  (workplaces) => {
    let total = 0;
    workplaces.forEach(r => total += 1);
    return total;
  }
);

export const selectFloorWorkplaceByWorkplaceTypeId = (workplaceTypeId: number, taskId: number) => createSelector(
  selectAllWorkplaceByTaskId(taskId),
  selectFloorDataIdsByTaskId(taskId),
  (workplaces, taskFloorDataIds) =>
    workplaces.filter(a => taskFloorDataIds.find(id => id === a.floorDataId))
      .filter(a => a.workplaceType === workplaceTypeId)
);

export const selectFloorWorkplaceCountByWorkplaceTypeId = (workplaceTypeId: number, taskId: number) => createSelector(
  selectFloorWorkplaceByWorkplaceTypeId(workplaceTypeId, taskId),
  (workplaces) => {
    let total = 0;
    workplaces.forEach(r => total += 1);
    return total;
  }
);
