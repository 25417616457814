import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { map } from 'rxjs/operators';
import { LoggerService } from '../../../core/services/logger.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { LoaderService } from '../../../core/services/loader.service';
import { UsageContextIdEnum } from '../../../shared/models/usage-context-id.enum';

@Injectable({
  providedIn: 'root'
})
export class TreeService extends ApiService {

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  getTreeData(tableName: string, withGrants?: boolean, parentsItems?: boolean): Observable<Array<any>> {
    let httpParams = new HttpParams();
    if (withGrants === false) {
      httpParams = httpParams.set('granted', false);
    }
    return this.get(`${API_ENDPOINTS.dynH}${tableName}/${parentsItems ? 'ParentsItems' : 'all'}`, UsageContextIdEnum.none, httpParams).pipe(
      map(data => data.payload)
    );
  }

  getFlatTreeData(tableName: string, withGrants?: boolean): Observable<Array<any>> {
    let httpParams = new HttpParams();
    if (withGrants === false) {
      httpParams = httpParams.set('granted', false);
    }
    return this.get(`${API_ENDPOINTS.dynT}${tableName}`, UsageContextIdEnum.none, httpParams).pipe(
      map(data => data.payload)
    );
  }

  isLastItem(tableName: string, id: number, withGrants?: boolean): Observable<boolean> {
    let httpParams = new HttpParams();
    if (withGrants === false) {
      httpParams = httpParams.set('granted', false);
    }
    return this.get(`${API_ENDPOINTS.dynH}${tableName}/IsLastItem/${id}`, UsageContextIdEnum.none, httpParams).pipe(
      map(data => data.payload)
    );
  }
}
