import { createAction, props } from '@ngrx/store';
import { SvgPoint } from '../../../features/svg-factory/models/svg-point.model';
import { ApiFloorDataEquipmentRotate } from '../../../domain-models/endpoint-specific/floordata.equipment.rotate.model';

export const translateEquipment = createAction(
  '[Floor/API] Translate equipment (SvgUse)',
  props<{ id:  number, translationVector: SvgPoint }>()
);

export const translateEquipments = createAction(
  '[Floor/API] Translate equipments (SvgUse[])',
  props<{ ids:  number[], translationVector: SvgPoint }>()
);

export const rotateEquipment = createAction(
  '[Floor/API] Rotate equipment (SvgUse)',
  props<{ rotation: ApiFloorDataEquipmentRotate }>()
);

export const rotateEquipments = createAction(
  '[Floor/API] Rotate equipments (SvgUse[])',
  props<{ rotations: ApiFloorDataEquipmentRotate[] }>()
);

export const equipmentTransformUpdateError = createAction(
  '[Floor/API] Error on Equipment(s) Translate or Rotate'
);

export const deleteEquipment = createAction(
  '[Floor/UseOverlay] - Delete equipment',
  props<{ id: number }>(),
);

export const deleteEquipments = createAction(
  '[Floor/UseOverlay] - Delete equipments',
  props<{ ids: number[] }>(),
);
export const setTransformType = createAction(
  '[Floor/UseOverlay] Set TransformType',
  props<{id: number}>()
);
export const nextGripDetectionMode = createAction('[Floor/UseOverlay] Cycle over GripAlignmentTypes');
