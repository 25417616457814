import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DynamicFormsService } from '../../../features/dynamic-forms/services/dynamic-forms.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';

@Component({
  selector: 'echo-barcode-scanner-dialog',
  templateUrl: './barcode-scanner-dialog.component.html',
  styleUrls: ['./barcode-scanner-dialog.component.scss']
})
export class BarcodeScannerDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BarcodeScannerDialogComponent>,
              private dynamicFormsService: DynamicFormsService,
              private snackbarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: { tableName: string, columnName: string }) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  scanSuccessHandler(result: any) {
    this.dynamicFormsService.checkIfValueExists(this.data.tableName, this.data.columnName, result).pipe(
      first(),
    ).subscribe(alreadyExists => {
      if (!alreadyExists) {
        this.dialogRef.close(result);
      } else {
        this.snackbarService.displayError(`Cet identifiant existe déjà, veuillez en choisir un autre.`);
      }
    });
  }

}
