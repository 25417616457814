import { AppMenu } from '../../domain-models/core/z-app-menu.model';

export class MenuItem extends AppMenu {
  public isOpened = false;
  public items?: MenuItem[];
  public label: string;
  public icon?: string;
  public faIcon?: string;
  public link?: string;
  public externalRedirect?: boolean;
  public hidden?: boolean;
  public display_title?: string;
  public selected?: string;
  constructor(appMenu: AppMenu) {
    super(appMenu.databaseTableName,
      appMenu.id,
      appMenu.displayNameId,
      appMenu.parentId,
      appMenu.grantId,
      appMenu.scopeId,
      appMenu.orderIndex,
      appMenu.depth,
      appMenu.isCustomizable,
      appMenu.apiEndPoint,
      appMenu.componentPath,
      appMenu.dataContext,
      appMenu.usageContextId);
  }
}
