import { Color, ColorUtils } from '../../core/utils/color';
import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum TaskTypeEnum {
  Project = 1,
  Task = 2,
  Milestone = 3,
}

export const TASK_TYPE = {
  databaseTableName: 'task_type',

  id: 'TaTy_Id',
  name: 'TaTy_Name',
  displayNameId: 'TaTy_DisplayNameId',
  color: 'TaTy_Color'
};

export class TaskType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskTypeAdapter implements Adapter<TaskType> {
  adapt(item: any): TaskType {
    return new TaskType(
      TASK_TYPE.databaseTableName,
      item[TASK_TYPE.id],
      item[TASK_TYPE.name],
      item[TASK_TYPE.displayNameId],
      ColorUtils.colorFromHexARGB(item[TASK_TYPE.color]),
    );
  }

  encode(item: TaskType): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_TYPE.id]: item.id,
      [TASK_TYPE.name]: item.name,
      [TASK_TYPE.displayNameId]: item.displayNameId,
      [TASK_TYPE.color]: item.color.toHexArgb(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
