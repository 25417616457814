import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum DisplayThemeCategoryEnum {
  None = 1,
  Areas,
  Workplaces,
  EquipmentUpdates,
  EquipmentMoveReconcile,
  EquipmentScanReconcile,
  ImportReconcile,
}

export const DISPLAY_THEME_CATEGORY = {
  databaseTableName: 'DisplayThemeCategory',
  id: 'DiThCa_Id',
  name: 'DiThCa_Name',
  displayNameId: 'DiThCa_DisplayNameId',
  displayOrder: 'DiThCa_DisplayOrder',
};

export class DisplayThemeCategory {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public displayOrder: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DisplayThemeCategoryAdapter implements Adapter<DisplayThemeCategory> {
  adapt(item: any): DisplayThemeCategory {
    return new DisplayThemeCategory(
      DISPLAY_THEME_CATEGORY.databaseTableName,
      item[DISPLAY_THEME_CATEGORY.id],
      item[DISPLAY_THEME_CATEGORY.name],
      item[DISPLAY_THEME_CATEGORY.displayNameId],
      item[DISPLAY_THEME_CATEGORY.displayOrder]
    );
  }
}
