import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum TaskStatusEnum {
  Canceled = 1,
  Ended,
  Pending,
  Started,
  Idling,
  Closed,
  Validating = 7
}

export const TASK_STATUS = {
  databaseTableName: 'task_status',
  id: 'TaSt_Id',
  code: 'TaSt_Code',
  name: 'TaSt_Name',
  displayNameId: 'TaSt_DisplayNameId',
  color: 'TaSt_Color'
};

export class TaskStatus {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public name: string,
    public displayNameId: number,
    public color: Color,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskStatusAdapter implements Adapter<TaskStatus> {
  adapt(item: any): TaskStatus {
    return new TaskStatus(
      TASK_STATUS.databaseTableName,
      item[TASK_STATUS.id],
      item[TASK_STATUS.code],
      item[TASK_STATUS.name],
      item[TASK_STATUS.displayNameId],
      ColorUtils.colorFromHexARGB(item[TASK_STATUS.color]),
    );
  }

  encode(item: TaskStatus): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_STATUS.id]: item.id,
      [TASK_STATUS.code]: item.code,
      [TASK_STATUS.name]: item.name,
      [TASK_STATUS.displayNameId]: item.displayNameId,
      [TASK_STATUS.color]: item.color.toHexArgb(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
