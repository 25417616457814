<svg [attr.viewBox]="viewBox.x + ' ' + viewBox.y + ' ' + viewBox.width + ' ' + viewBox.height"
     [ngClass]="{'full-width': sizeRatio, 'full-height': !sizeRatio}"
     version="1.1"
     xmlns="http://www.w3.org/2000/svg"
>
<!--  <g id="floor">-->
<!--    <path [attr.d]="floorDetail[FLOOR.geometry]" class="svgSlab" fill="white"/>-->
<!--  </g>-->
</svg>
<svg [attr.viewBox]="viewBox.x + ' ' + viewBox.y + ' ' + viewBox.width + ' ' + viewBox.height"
     [ngClass]="{'fadeIn': roomOpacity, 'fadeOut': !roomOpacity, 'full-width': sizeRatio, 'full-height': !sizeRatio}"
     version="1.1"
     xmlns="http://www.w3.org/2000/svg">
  <!-- [attr.viewBox]="floorDetail.SvgViewBox" -->
  <defs>
    <pattern height="1" id="rubalise" patternTransform="rotate(-45)" patternUnits="userSpaceOnUse" width="1">
      <line [attr.stroke]="gradientColor" stroke-width="1" x1="0" x2="0" y="0" y2="1"/>
    </pattern>
  </defs>
  <g *ngFor="let space of floorDetail.room_view" [ngSwitch]="colorMode" id="spaces">
    <ng-container *ngSwitchCase="1">
      <ng-container
        *ngIf="displayedUsages.includes(space[ROOM_USE.id]); then spaceRoUs; else noSpaceRoUs"></ng-container>
      <ng-template #spaceRoUs>
        <path [attr.d]="space[ROOM.geometry]" [attr.fill]="space.UsageHtmlColor" [attr.id]="space[ROOM.id]"
              class="svgSpace"/>
      </ng-template>
      <ng-template #noSpaceRoUs>
        <path [attr.d]="space[ROOM.geometry]" [attr.id]="space[ROOM.id]" class="svgSpace" fill="#ccc"/>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-container *ngFor="let item of space.room_allocation_view">
        <ng-container
          *ngIf="displayedUsages.includes(item[BUSINESS_UNIT.id]); then spaceBun; else noSpaceBun"></ng-container>
        <ng-template #spaceBun>
          <ng-container
            *ngIf="item[BUSINESS_UNIT.id] === inWorkRoomAllocationBuId; else defaultColor">
            <path [attr.d]="space[ROOM.geometry]"
                  [attr.id]="space[ROOM.id]"
                  class="svgSpace"
                  fill="url(#rubalise)"/>
          </ng-container>
          <ng-template #defaultColor>
            <path [attr.d]="space[ROOM.geometry]" [attr.fill]="space.AllocationHtmlColor" [attr.id]="space[ROOM.id]"
                  class="svgSpace"/>
          </ng-template>
        </ng-template>
        <ng-template #noSpaceBun>
          <path [attr.d]="space[ROOM.geometry]" [attr.id]="space[ROOM.id]" class="svgSpace" fill="#ccc"/>
        </ng-template>
      </ng-container>
      <!--<path [attr.id]="space.Ro_Id" class="svgSpace" [attr.d]="space.Ro_Geometry"
            [attr.fill]="space.AllocationHtmlColor"/>-->
    </ng-container>
  </g>
</svg>
