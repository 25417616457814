import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
  /**
   * https://medium.com/@thunderroid/angular-date-ago-pipe-minutes-hours-days-months-years-ago-c4b5efae5fe5
   */

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'À l\'instant';
      }
      const intervals = {
        // 'année': 31536000,
        'mois': 2592000,
        'semaine': 604800,
        'jour': 86400,
        'heure': 3600,
        'minute': 60,
        'seconde': 1
      };
      let counter;
      for (const i of Object.keys(intervals)) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (i === 'mois') {
            return `il y a ${counter} ${i}`;
          } else {
            if (counter === 1) {
              return `il y a ${counter} ${i}`; // singular (1 day ago)
            } else {
              return `il y a ${counter} ${i}s`; // plural (2 days ago)
            }
          }

        }
      }
    }
    return value;
  }

}
