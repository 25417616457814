import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private httpLoading$ = new ReplaySubject<boolean>(1);

  constructor(router: Router) {
  }

  httpProgress(): Observable<boolean> {
    return this.httpLoading$.asObservable();
  }

  setHttpProgressStatus(inProgress: boolean) {
    this.httpLoading$.next(inProgress);
  }
}
