import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../../../../core/services/layout.service';
import { ClientService } from '../../../../core/services/client.service';
import { EcSyFile } from '../../../../domain-models/models/EcSyFile.model';
import { UserService } from '../../../../core/services/user.service';
import { AuthService } from '../../../../core/services/auth.service';
import { Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { CoreSelectors } from '../../../../store/core-store/selectors';
import { UserIdentity } from '../../../../domain-models/business-extended/user.model';
import { filter, map } from 'rxjs/operators';
import { RoleEnum } from '../../../../core/models/role.enum';
import { API_ENDPOINTS } from '../../../../shared/api-endpoints';

@Component({
  selector: 'echo-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPanelComponent implements OnInit {

  client$: Observable<EcSyFile>;
  user$: Observable<UserIdentity>;
  userIsContractorAdmin$: Observable<boolean>;
  companyName$: Observable<string>;

  constructor(private store: Store<State>,
              private router: Router,
              private authService: AuthService,
              private clientService: ClientService,
              private userService: UserService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.user$ = this.store.select(CoreSelectors.selectUser);
    this.client$ = this.store.select(CoreSelectors.selectClient);
    this.userIsContractorAdmin$ = this.user$.pipe(
      filter(user => user !== null),
      map(user => {
        if (Array.isArray(user.role)) {
          return !!user.role.find(r => r === RoleEnum.ContractorAdmin);
        } else {
          return user.role === RoleEnum.ContractorAdmin;
        }

      })
    );

    this.companyName$ = this.user$.pipe(
      switchMap(user => this.userIsContractorAdmin$.pipe(
        filter(isContractorAdmin => isContractorAdmin === true),
        switchMap(() => this.clientService.get(`${API_ENDPOINTS.contractorsCompany(user.employer)}`).pipe(
          map(data => data.payload['Name'])
        ))
      )),
    );
  }

  logout() {
    this.authService.logout();
    this.closeDrawer();
  }

  navigate() {
    this.router.navigateByUrl('app/(debug//sidenav:debug)');
  }

  closeDrawer() {
    this.layoutService.rightDrawer$.next(false);
  }


}
