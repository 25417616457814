import { createSelector } from '@ngrx/store';
import * as fromFloorDoorStyle from '../reducers/floor-door-style.reducer';
import { selectSharedState } from '../reducers';

export const selectFloorDoorStyles = createSelector(
  selectSharedState,
  state => state[fromFloorDoorStyle.floorDoorStylesFeatureKey]
);

export const selectFloorDoorStylesIds = createSelector(
  selectFloorDoorStyles,
  fromFloorDoorStyle.selectFloorDoorStylesIds
);
export const selectFloorDoorStylesEntities = createSelector(
  selectFloorDoorStyles,
  fromFloorDoorStyle.selectFloorDoorStylesEntities
);
export const selectAllFloorDoorStyles = createSelector(
  selectFloorDoorStyles,
  fromFloorDoorStyle.selectAllFloorDoorStyles
);
export const selectFloorDoorStylesTotal = createSelector(
  selectFloorDoorStyles,
  fromFloorDoorStyle.selectFloorDoorStylesTotal
);

export const allFloorDoorStylesLoaded = createSelector(
  selectFloorDoorStyles,
  state => state.isLoaded
);

export const selectFloorDoorStyleById = (id: number) => createSelector(
  selectFloorDoorStylesEntities,
  entities => entities[id]
);
