import { createAction, props } from '@ngrx/store';
import { Task } from '../../../domain-models/business/task.model';
import { EntityMap, Predicate, Update } from '@ngrx/entity';

/**
 * CRUD Actions
 */

export const loadTasks = createAction('[Task/API] Load Tasks', props<{ tasks: Task[] }>());
export const addTask = createAction('[Task/API] Add Task', props<{ task: Task }>());
export const setTask = createAction('[Task/API] Set Task', props<{ task: Task }>());
export const upsertTask = createAction('[Task/API] Upsert Task', props<{ task: Task }>());
export const addTasks = createAction('[Task/API] Add Tasks', props<{ tasks: Task[] }>());
export const upsertTasks = createAction('[Task/API] Upsert Tasks', props<{ tasks: Task[] }>());
export const updateTask = createAction('[Task/API] Update Task', props<{ update: Update<Task>, ignoreApiCall?: boolean }>());
export const updateTasks = createAction('[Task/API] Update Tasks', props<{ updates: Update<Task>[] }>());
export const mapTasks = createAction('[Task/API] Map Tasks', props<{ entityMap: EntityMap<Task> }>());
export const deleteTask = createAction('[Task/API] Delete Task', props<{ id: number }>());
export const deleteTasks = createAction('[Task/API] Delete Tasks', props<{ ids: number[] }>());
export const deleteTasksByPredicate = createAction('[Task/API] Delete Tasks By Predicate', props<{ predicate: Predicate<Task> }>());
export const clearTasks = createAction('[Task/API] Clear Tasks');

/** END of CRUD **/

export const beginGetTaskById = createAction('[Task/API] - Get Task by Id', props<{id: number}>());
export const beginGetReconcileTasksById = createAction('[Task/API] - Get Reconcile Tasks by Id', props<{id: number}>());
export const beginGetAllTasks = createAction('[Task/API] - Begin Get All Tasks');
export const errorGetAllTasks = createAction('[API] - Error Get All Tasks', props<Error>());

export const updateSelectedTaskId = createAction(
  '[Task/API] - Save Selected Task Id',
  props<{ taskId: number }>()
);

export const beginGetCurrentTaskPerimeterIds = createAction('[API] - Get Current Task Perimeter Ids');
export const beginGetCurrentTaskLayoutTypeIds = createAction('[API] - Get Current Task Layout Type Ids');
export const beginGetCurrentTaskActivityStatusTypeIds = createAction('[API] - Get Current Task Activity Status Type Ids');
export const beginGetCurrentTaskAttributionTypeIds = createAction('[API] - Get Current Task Attribution Type Ids');
export const beginGetCurrentTaskBusinessUnitIds = createAction('[API] - Get Current Task Business Units Ids');

export const successGetTaskPerimeterIds = createAction(
  '[API] - Success Get Task Perimeter Ids',
  props<{ taskId: number; taskPerimeterIds: number[] }>()
);

export const setSelectedTaskReconcileTaskIds = createAction(
  '[Tasks/API] - Set Selected Task Reconcile Task Ids Get',
  props<{ reconcileTaskIds: number[] }>()
);

export const addTaskRoomLayoutTypeId = createAction('[SvgGroup] - Add Task present Layout Type Id', props<{taskId: number; layoutTypeId: number}>());

export const beginUpdateTask = createAction(
  '[API/Tasks] - Update Task API Call',
  props<{ update: Update<Task> }>(),
);
