import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const FACILITY_JOB_TASK = {
  databaseTableName: 'facility_job_task',
  id: 'FaJoTa_Id',
  name: 'FaJoTa_Name',
  isActive: 'FaJoTa_IsActive',
  facilityJob: 'FaJoTa_FacilityJob',
  unitOfWork: 'FaJoTa_UnitOfWork',
  unitPrice: 'FaJoTa_UnitPrice',
};

export class FacilityJobTask {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public isActive: boolean,
    public facilityJob: number,
    public unitOfWork: number,
    public unitPrice: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class FacilityJobTaskAdapter implements Adapter<FacilityJobTask> {
  adapt(item: any): FacilityJobTask {
    return new FacilityJobTask (
      FACILITY_JOB_TASK.databaseTableName,
      item[FACILITY_JOB_TASK.id],
      item[FACILITY_JOB_TASK.name],
      item[FACILITY_JOB_TASK.isActive],
      item[FACILITY_JOB_TASK.facilityJob],
      item[FACILITY_JOB_TASK.unitOfWork],
      item[FACILITY_JOB_TASK.unitPrice],
    );
  }
  encode(item: FacilityJobTask): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FACILITY_JOB_TASK.id]: item.id,
      [FACILITY_JOB_TASK.name]: item.name,
      [FACILITY_JOB_TASK.isActive]: item.isActive,
      [FACILITY_JOB_TASK.facilityJob]: item.facilityJob,
      [FACILITY_JOB_TASK.unitOfWork]: item.unitOfWork,
      [FACILITY_JOB_TASK.unitPrice]: item.unitPrice,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}

