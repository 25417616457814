import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum TaskBusinessTypeEnum {
  PreventiveMaintenance = 1,
  Works = 2,
  Move = 3,
  Request = 4,
  MacroSimulation = 5,
  CurativeMaintenance,
  Quote,
  Command,
  ReconcileEquipmentPrepare,
  AcceptanceOfTheWork,
  SpacePlanning,
  BlueprintImport,
  ReconcileEquipmentScan,
  ReconcileBlueprint,
  Reservation,
  Intervention,
  SpacePlanningImport,
  CommandReceipt,
}

export const TASK_BUSINESS = {
  databaseTableName: 'task_business',
  id: 'TaBu_Id',
  taskTableName: 'TaBu_TaskTableName',
  displayNameId: 'TaBu_DisplayNameId',
  scopeId: 'TaBu_ScopeId',
  itemsTableName: 'TaBu_ItemsTableName',
  isActive: 'TaBu_IsActive',
};

export class TaskBusiness {
  constructor(
    public databaseTableName: string,
    public id: number,
    public taskTableName: string,
    public displayNameId: number,
    public scopeId: number,
    public itemsTableName: string,
    public isActive: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskBusinessAdapter implements Adapter<TaskBusiness> {
  adapt(item: any): TaskBusiness {
    return new TaskBusiness(
      TASK_BUSINESS.databaseTableName,
      item[TASK_BUSINESS.id],
      item[TASK_BUSINESS.taskTableName],
      item[TASK_BUSINESS.displayNameId],
      item[TASK_BUSINESS.scopeId],
      item[TASK_BUSINESS.itemsTableName],
      item[TASK_BUSINESS.isActive],
    );
  }

  encode(item: TaskBusiness): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_BUSINESS.id]: item.id,
      [TASK_BUSINESS.taskTableName]: item.taskTableName,
      [TASK_BUSINESS.displayNameId]: item.displayNameId,
      [TASK_BUSINESS.scopeId]: item.scopeId,
      [TASK_BUSINESS.itemsTableName]: item.itemsTableName,
      [TASK_BUSINESS.isActive]: item.isActive,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
