import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FLOOR_DATA } from '../business/floor-data.model';
import { BUILDING } from '../business/building.model';
import { FLOOR } from '../business/floor.model';
import { ROOM_ACTIVITY_STATUS_TYPE } from '../business/room-activity-status-type.model';
import { ROOM } from '../business/room.model';
import { ROOM_ACTIVITY_STATUS_TYPE_VIEW } from '../business/Room_Activity_Status_Type_View';
import { Color, ColorUtils } from '../../core/utils/color';

export const FLOOR_ACTIVITY_STATUS_VIEW = {
  databaseTableName: 'FloorActivityStatusView',
  siteId: BUILDING.siteId,
  buildingId: FLOOR.buildingId,
  floorId: FLOOR.id,
  taskId: FLOOR_DATA.taskId,
  roomActivityStatusTypeId: ROOM.activityStatusTypeId,
  roomActivityStatusTypeName: ROOM_ACTIVITY_STATUS_TYPE.name,
  roomActivityStatusTypeDisplayNameId: ROOM_ACTIVITY_STATUS_TYPE.displayNameId,
  roomActivityStatusTypeDisplayName: ROOM_ACTIVITY_STATUS_TYPE_VIEW.displayName,
  roomActivityStatusTypeColor: ROOM_ACTIVITY_STATUS_TYPE.color,
  area: 'FlAcStVi_Area',
  roundedArea: 'FlAcStVi_RoundedArea',
};

export class FloorActivityStatusView {
  constructor(
    public databaseTableName: string,
    public siteId: number,
    public buildingId: number,
    public floorId: number,
    public taskId: number,
    public roomActivityStatusTypeId: number,
    public roomActivityStatusTypeName: string,
    public roomActivityStatusTypeDisplayNameId: number,
    public roomActivityStatusTypeDisplayName: string,
    public roomActivityStatusTypeColor: Color,
    public area: number,
    public roundedArea: number) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class FloorActivityStatusViewAdapter implements Adapter<FloorActivityStatusView> {
  adapt(item: any): FloorActivityStatusView {
    return new FloorActivityStatusView(
      FLOOR_ACTIVITY_STATUS_VIEW.databaseTableName,
      item[FLOOR_ACTIVITY_STATUS_VIEW.siteId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.buildingId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.floorId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.taskId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.roomActivityStatusTypeId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.roomActivityStatusTypeName],
      item[FLOOR_ACTIVITY_STATUS_VIEW.roomActivityStatusTypeDisplayNameId],
      item[FLOOR_ACTIVITY_STATUS_VIEW.roomActivityStatusTypeDisplayName],
      ColorUtils.colorFromHexARGB(item[FLOOR_ACTIVITY_STATUS_VIEW.roomActivityStatusTypeColor]),
      item[FLOOR_ACTIVITY_STATUS_VIEW.area],
      item[FLOOR_ACTIVITY_STATUS_VIEW.roundedArea],
    );
  }
}
