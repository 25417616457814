import { createAction, props } from '@ngrx/store';
import { FloorModel } from '../../../domain-models/business/floor-model.model';

export const beginGetAllFloorModels = createAction('[Shared/API] - Begin Get All FloorModels');

export const addFloorModels = createAction(
  '[API] - Add FloorModels',
  props<{ floorModels: FloorModel[] }>()
);

export const successGetAllFloorModels = createAction('[API] - Success Get All FloorModels');
export const errorGetAllFloorModels = createAction('[Shared/API] - Error Get All FloorModels', props<Error>());
