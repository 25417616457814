import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

@Pipe({
  name: 'directoryName'
})
export class DirectoryNamePipe implements PipeTransform {

  constructor(private sharedService: SharedService) {
  }

  transform(directoryId: number, /**directory: Directory[]**/): Observable<string> {
    try {
      return this.sharedService.getDirectory().pipe(
        map(directory => {
          const directoryItem = directory.find(item => item.id === directoryId);
          return `${directoryItem.firstName} ${directoryItem.lastName}`;
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

}
