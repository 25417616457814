import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GanttTaskActions } from '../actions';
import { GanttTask } from '../../../features/project-management/models/gantt-task.model';

export const ganttTaskFeatureKey = 'ganttTasks';

export interface State extends EntityState<GanttTask> {
  selectedGanttTaskId: number | null;
}

export const adapter: EntityAdapter<GanttTask> = createEntityAdapter<GanttTask>();

export const initialState: State = adapter.getInitialState({
  selectedGanttTaskId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(GanttTaskActions.addGanttTask, (state, { ganttTask }) => {
    return adapter.addOne(ganttTask, state);
  }),
  on(GanttTaskActions.setGanttTask, (state, { ganttTask }) => {
    return adapter.setOne(ganttTask, state);
  }),
  on(GanttTaskActions.upsertGanttTask, (state, { ganttTask }) => {
    return adapter.upsertOne(ganttTask, state);
  }),
  on(GanttTaskActions.addGanttTasks, (state, { ganttTasks }) => {
    return adapter.addMany(ganttTasks, state);
  }),
  on(GanttTaskActions.upsertGanttTasks, (state, { ganttTasks }) => {
    return adapter.upsertMany(ganttTasks, state);
  }),
  on(GanttTaskActions.updateGanttTask, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(GanttTaskActions.updateGanttTasks, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(GanttTaskActions.mapGanttTasks, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(GanttTaskActions.deleteGanttTask, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(GanttTaskActions.deleteGanttTasks, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(GanttTaskActions.deleteGanttTasksByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(GanttTaskActions.loadGanttTasks, (state, { ganttTasks }) => {
    return adapter.setAll(ganttTasks, state);
  }),
  on(GanttTaskActions.clearGanttTasks, state => {
    return adapter.removeAll({ ...state, selectedGanttTaskId: null });
  }),
  /** END OF CRUD **/
  on(GanttTaskActions.updateSelectedGanttTaskId, (state, {ganttTaskId}) => {
    return ({...state, selectedGanttTaskId: ganttTaskId});
  }),
);

export const getSelectedGanttTaskId = (state: State) => state.selectedGanttTaskId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of ganttTask ids
export const selectGanttTaskIds = selectIds;

// select the dictionary of ganttTask entities
export const selectGanttTaskEntities = selectEntities;

// select the array of ganttTasks
export const selectAllGanttTasks = selectAll;

// select the total ganttTask count
export const selectGanttTaskTotal = selectTotal;
