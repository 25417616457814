import { ROOM_ACTIVITY_STATUS_TYPE, RoomActivityStatusTypeEnum } from './room-activity-status-type.model';

export const ROOM_ACTIVITY_STATUS_TYPE_VIEW = {
  id: ROOM_ACTIVITY_STATUS_TYPE.id,
  activityStatusTypeId: RoomActivityStatusTypeEnum,
  name: ROOM_ACTIVITY_STATUS_TYPE.name,
  displayNameId: ROOM_ACTIVITY_STATUS_TYPE.displayNameId,
  color: ROOM_ACTIVITY_STATUS_TYPE.color,
  displayName: 'RoAcTyVi_DisplayName'
};
