import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as DbColumnActions from '../actions/db-column.actions';
import { DbColumn } from '../../../domain-models/core/z-db-column.model';

export const dbColumnFeatureKey = 'dbColumns';

export interface State extends EntityState<DbColumn> {
  isLoaded: boolean;
}

export function generateId(a: DbColumn): string {
  return a.tableName + '-' + a.columnDisplayNameId;
}

export const adapter: EntityAdapter<DbColumn> = createEntityAdapter<DbColumn>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  isLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(DbColumnActions.addDbColumns, (state, {dbColumns}) => {
    return adapter.addMany(dbColumns, state);
  }),
  on(DbColumnActions.addDbColumn, (state, {dbColumn}) => {
    return adapter.addOne(dbColumn, state);
  }),
  on(DbColumnActions.successGetAllDbColumns, state => ({...state, isLoaded: true}))
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectDbColumnIds = selectIds;
export const selectDbColumnEntities = selectEntities;
export const selectAllDbColumns = selectAll;
export const selectDbColumnTotal = selectTotal;
