import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { WorkplaceSelectors } from '../../store/floor-store/selectors';
import { EMPTY, Observable } from 'rxjs';

@Pipe({
  name: 'workplaceTypeCount'
})
export class WorkplaceTypeCountPipe implements PipeTransform {

  constructor(private store: Store<State>) {
  }

  transform(resId: string, taskId?: number, buildingId?: number): Observable<number> {
    try {
      /** Split ThemeItem resId in {tableName-id} **/
      const strArr = resId.split('-');
      const tableName = strArr[0];
      const id = strArr[1];

      if (buildingId) {
        return this.store.select(WorkplaceSelectors.selectBuildingWorkplaceCountByWorkplaceTypeId(Number(id), buildingId));
      } else if (taskId) {
        return this.store.select(WorkplaceSelectors.selectFloorWorkplaceCountByWorkplaceTypeId(Number(id), taskId));
      } else {
        return EMPTY;
      }
    } catch (e) {
      console.log(e);
    }  }

}
