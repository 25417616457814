import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum ZScopeTypeEnum {
  Application = 1,
  User,
  Template
}

export const Z_SCOPE = {
  databaseTableName:  'z_scope',
  id: 'Sc_Id',
  name: 'Sc_Name',
};

export class ZScope {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})


export class ZScopeAdapter implements Adapter<ZScope> {
  adapt(item: any): ZScope {
    return new ZScope(
      Z_SCOPE.databaseTableName,
      item[Z_SCOPE.id],
      item[Z_SCOPE.name]
    );
  }
}
