import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const Z_HIERARCHIC_TABLE = {
  databaseTableName: 'z_hierarchic_table',
  tableName: 'HiTa_TableName',
  depthColumnName: 'HiTa_DepthColumnName',
  parentColumnName: 'HiTa_ParentColumnName',
  idColumnName: 'HiTa_IdColumnName',
  displayColumnName: 'HiTa_DisplayColumnName'
};

export class HierarchicTable {
  constructor(
    public databaseTableName: string,
    public tableName: string,
    public depthColumnName: string,
    public parentColumnName: string,
    public idColumnName: string,
    public displayColumnName: string
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class HierarchicTableAdapter implements Adapter<HierarchicTable> {

  adapt(item: any): HierarchicTable {
    return new HierarchicTable(
      Z_HIERARCHIC_TABLE.databaseTableName,
      item[Z_HIERARCHIC_TABLE.tableName],
      item[Z_HIERARCHIC_TABLE.depthColumnName],
      item[Z_HIERARCHIC_TABLE.parentColumnName],
      item[Z_HIERARCHIC_TABLE.idColumnName],
      item[Z_HIERARCHIC_TABLE.displayColumnName]
    );
  }

  encode(item: HierarchicTable): ISingleBackendObj {
    const encoded = {
      TableName: Z_HIERARCHIC_TABLE.databaseTableName,
      [Z_HIERARCHIC_TABLE.tableName]: item.tableName,
      [Z_HIERARCHIC_TABLE.depthColumnName]: item.depthColumnName,
      [Z_HIERARCHIC_TABLE.parentColumnName]: item.parentColumnName,
      [Z_HIERARCHIC_TABLE.idColumnName]: item.idColumnName,
      [Z_HIERARCHIC_TABLE.displayColumnName]: item.displayColumnName
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
