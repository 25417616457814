import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const UNIT_OF_WORK = {
  databaseTableName: 'unit_of_work',
  id: 'UnOfWo_Id',
  code: 'UnOfWo_Code',
  name: 'UnOfWo_Name',
  isActive: 'UnOfWo_IsActive'
};

export class UnitOfWork {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public name: string,
    public isActive: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class UnitOfWorkAdapter implements Adapter<UnitOfWork> {
  adapt(item: any): UnitOfWork {
    return new UnitOfWork(
      UNIT_OF_WORK.databaseTableName,
      item[UNIT_OF_WORK.id],
      item[UNIT_OF_WORK.code],
      item[UNIT_OF_WORK.name],
      item[UNIT_OF_WORK.isActive],
    );
  }

  encode(item: UnitOfWork): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [UNIT_OF_WORK.id]: item.id,
      [UNIT_OF_WORK.code]: item.code,
      [UNIT_OF_WORK.name]: item.name,
      [UNIT_OF_WORK.isActive]: item.isActive,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
