import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
//https://stackoverflow.com/questions/42164896/angular-2-pipe-calculating-summary-of-array-of-objects
export class SumPipe implements PipeTransform {

  transform<T>(items: T[], key: string): unknown {
    // console.log(items);
    // console.log(key);
    // console.log(items.reduce((a, b) => a + b[key], 0));
    return items.reduce((a, b) => a + b[key], 0);
  }

}
