import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const EQUIPMENT_PURCHASE = {
  databaseTableName: 'EquipmentPurchase',
  id: 'EqPu_Id',
  purchaseDate: 'EqPu_PurchaseDate',
  purchaseRef: 'EqPu_PurchaseRef',
  equipmentCatalogId: 'EqPu_EquipmentCatalogId',
  quantity: 'EqPu_Quantity',
  targetDate: 'EqPu_TargetDate',
  deliveryDate: 'EqPu_DeliveryDate'
};

export class EquipmentPurchase {
  constructor(
    public databaseTableName: string,
    public id: number,
    public purchaseDate: Date,
    public purchaseRef: number,
    public equipmentCatalogId: number,
    public quantity: number,
    public targetDate: Date,
    public deliveryDate: Date
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class EquipmentPurchaseAdapter implements Adapter<EquipmentPurchase> {
  adapt(item: any): EquipmentPurchase {
    return new EquipmentPurchase(
      EQUIPMENT_PURCHASE.databaseTableName,
      item[EQUIPMENT_PURCHASE.id],
      new Date(item[EQUIPMENT_PURCHASE.purchaseDate]),
      item[EQUIPMENT_PURCHASE.purchaseRef],
      item[EQUIPMENT_PURCHASE.equipmentCatalogId],
      item[EQUIPMENT_PURCHASE.quantity],
      new Date(item[EQUIPMENT_PURCHASE.targetDate]),
      new Date(item[EQUIPMENT_PURCHASE.deliveryDate])
    );
  }

  encode(item: EquipmentPurchase | Partial<EquipmentPurchase>): ISingleBackendObj {
    const encoded = {
      TableName: EQUIPMENT_PURCHASE.databaseTableName,
      [EQUIPMENT_PURCHASE.id]: item.id,
      [EQUIPMENT_PURCHASE.purchaseDate]: item.purchaseDate.toISOString(),
      [EQUIPMENT_PURCHASE.purchaseRef]: item.purchaseRef,
      [EQUIPMENT_PURCHASE.equipmentCatalogId]: item.equipmentCatalogId,
      [EQUIPMENT_PURCHASE.quantity]: item.quantity,
      [EQUIPMENT_PURCHASE.targetDate]: item.targetDate.toISOString(),
      [EQUIPMENT_PURCHASE.deliveryDate]: item.deliveryDate.toISOString()
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
