import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { TaskContributorDirectory } from '../../../domain-models/business/task-contributor-directory.model';

/**
 * CRUD Actions
 */

export const loadTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Load TaskContributorDirectoryItems', props<{ taskContributorDirectoryItems: TaskContributorDirectory[] }>());
export const addTaskContributorDirectory = createAction('[TaskContributorDirectory/API] Add TaskContributorDirectory', props<{ taskContributorDirectory: TaskContributorDirectory }>());
export const setTaskContributorDirectory = createAction('[TaskContributorDirectory/API] Set TaskContributorDirectory', props<{ taskContributorDirectory: TaskContributorDirectory }>());
export const upsertTaskContributorDirectory = createAction('[TaskContributorDirectory/API] Upsert TaskContributorDirectory', props<{ taskContributorDirectory: TaskContributorDirectory }>());
export const addTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Add TaskContributorDirectoryItems', props<{ taskContributorDirectoryItems: TaskContributorDirectory[] }>());
export const upsertTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Upsert TaskContributorDirectoryItems', props<{ taskContributorDirectoryItems: TaskContributorDirectory[] }>());
export const updateTaskContributorDirectory = createAction('[TaskContributorDirectory/API] Update TaskContributorDirectory', props<{ update: Update<TaskContributorDirectory> }>());
export const updateTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Update TaskContributorDirectoryItems', props<{ updates: Update<TaskContributorDirectory>[] }>());
export const mapTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Map TaskContributorDirectoryItems', props<{ entityMap: EntityMap<TaskContributorDirectory> }>());
export const deleteTaskContributorDirectory = createAction('[TaskContributorDirectory/API] Delete TaskContributorDirectory', props<{ id: string }>());
export const deleteTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Delete TaskContributorDirectoryItems', props<{ ids: string[] }>());
export const deleteTaskContributorDirectoryItemsByPredicate = createAction('[TaskContributorDirectory/API] Delete TaskContributorDirectoryItems By Predicate', props<{ predicate: Predicate<TaskContributorDirectory> }>());
export const clearTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] Clear TaskContributorDirectoryItems');

/** END of CRUD **/

export const beginGetAllTaskContributorDirectoryItems = createAction('[TaskContributorDirectory/API] - Begin Get All TaskContributorDirectoryItems');
export const errorGetAllTaskContributorDirectoryItems = createAction('[API] - Error Get All TaskContributorDirectoryItems', props<Error>());

export const updateSelectedTaskContributorDirectoryId = createAction(
  '[TaskContributorDirectory/API] - Save Selected TaskContributorDirectory Id',
  props<{ taskContributorDirectoryId: number }>()
);
