import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FLOOR_DATA } from '../business/floor-data.model';
import { BUILDING } from '../business/building.model';
import { FLOOR } from '../business/floor.model';
import { BUSINESS_UNIT } from '../business/business-unit.model';
import { ROOM_ALLOCATION } from '../business/room-allocation.model';
import { Color, ColorUtils } from '../../core/utils/color';

export const FLOOR_ALLOCATION_VIEW = {
  databaseTableName: 'FloorAllocationView',
  siteId: BUILDING.siteId,
  buildingId: FLOOR.buildingId,
  floorId: FLOOR.id,
  taskId: FLOOR_DATA.taskId,
  businessUnitName: BUSINESS_UNIT.name,
  businessUnitId: ROOM_ALLOCATION.businessUnitId,
  businessUnitCode: BUSINESS_UNIT.code,
  businessUnitColor: BUSINESS_UNIT.color,
  area: 'FlAlVi_Area',
  roundedArea: 'FlAlVi_RoundedArea',
};

export class FloorAllocationView {
  constructor(
    public databaseTableName: string,
    public siteId: number,
    public buildingId: number,
    public floorId: number,
    public taskId: number,
    public businessUnitName: string,
    public businessUnitId: number,
    public businessUnitCode: string,
    public businessUnitColor: Color,
    public area: number,
    public roundedArea: number) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class FloorAllocationViewAdapter implements Adapter<FloorAllocationView> {
  adapt(item: any): FloorAllocationView {
    return new FloorAllocationView(
      FLOOR_ALLOCATION_VIEW.databaseTableName,
      item[FLOOR_ALLOCATION_VIEW.siteId],
      item[FLOOR_ALLOCATION_VIEW.buildingId],
      item[FLOOR_ALLOCATION_VIEW.floorId],
      item[FLOOR_ALLOCATION_VIEW.taskId],
      item[FLOOR_ALLOCATION_VIEW.businessUnitName],
      item[FLOOR_ALLOCATION_VIEW.businessUnitId],
      item[FLOOR_ALLOCATION_VIEW.businessUnitCode],
      ColorUtils.colorFromHexARGB(item[FLOOR_ALLOCATION_VIEW.businessUnitColor]),
      item[FLOOR_ALLOCATION_VIEW.area],
      item[FLOOR_ALLOCATION_VIEW.roundedArea],
    );
  }
}
