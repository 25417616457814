import { createSelector } from '@ngrx/store';
import * as fromTaskStatus from '../reducers/task-status.reducer';
import { selectProjectManagementState } from '../reducers';

/** Select store slice **/

export const selectTaskStatusStatus = createSelector(
  selectProjectManagementState,
  state => state[fromTaskStatus.taskStatusFeatureKey]
);

/** Basics selectors **/

export const selectTaskStatusIds = createSelector(
  selectTaskStatusStatus,
  fromTaskStatus.selectTaskStatusIds
);
export const selectTaskStatusEntities = createSelector(
  selectTaskStatusStatus,
  fromTaskStatus.selectTaskStatusEntities
);
export const selectAllTaskStatusItems = createSelector(
  selectTaskStatusStatus,
  fromTaskStatus.selectAllTaskStatusItems
);
export const selectTaskStatusTotal = createSelector(
  selectTaskStatusStatus,
  fromTaskStatus.selectTaskStatusTotal
);
export const selectCurrentTaskStatusId = createSelector(
  selectTaskStatusStatus,
  fromTaskStatus.getSelectedTaskStatusId
);

export const selectCurrentTaskStatus = createSelector(
  selectTaskStatusEntities,
  selectCurrentTaskStatusId,
  (taskStatusEntities, taskStatusId) => taskStatusEntities[taskStatusId]
);

/** Advanced selectors **/

export const selectTaskStatusById = (id: number) => createSelector(
  selectTaskStatusEntities,
  (entities) => entities[id]
);
