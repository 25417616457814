import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { DOCUMENT } from '../business/document.model';
import { DOCUMENT_VERSION } from '../business/document-version.model';
import { DOCUMENT_DATA_MODEL } from '../business/document-data-model.model';

export const DOCUMENT_VIEW = {
  databaseTableName: 'DocumentView',
  filePath: 'FilePath',
  documentTypeId: DOCUMENT.typeId,
  documentMemo: DOCUMENT.memo,
  documentId: DOCUMENT.id,
  documentFileName: DOCUMENT.filename,
  documentDataModelItemId: DOCUMENT.dataModelItemId,
  documentDataModelId: DOCUMENT.dataModelId,
  documentVersionMemo: DOCUMENT_VERSION.memo,
  documentVersionIndexCode: DOCUMENT_VERSION.indexCode,
  documentVersionIndex: DOCUMENT_VERSION.index,
  documentVersionId: DOCUMENT_VERSION.id,
  documentVersionDocumentId: DOCUMENT_VERSION.documentId,
  documentVersionCreationDate: DOCUMENT_VERSION.creationDate,
  documentDataModelTableName: DOCUMENT_DATA_MODEL.databaseTableName
};

export class DocumentView {
  constructor(
    public databaseTableName: string,
    public filePath: string,
    public documentTypeId: number,
    public documentMemo: string,
    public documentId: number,
    public documentFileName: string,
    public documentDataModelItemId: number,
    public documentDataModelId: number,
    public documentVersionMemo: string,
    public documentVersionIndexCode: number,
    public documentVersionIndex: number,
    public documentVersionId: number,
    public documentVersionDocumentId: number,
    public documentVersionCreationDate: Date,
    public documentDataModelTableName: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class DocumentViewAdapter implements Adapter<DocumentView> {
  adapt(item: any): DocumentView {
    return new DocumentView(
      DOCUMENT_VIEW.databaseTableName,
      item[DOCUMENT_VIEW.filePath],
      item[DOCUMENT_VIEW.documentTypeId],
      item[DOCUMENT_VIEW.documentMemo],
      item[DOCUMENT_VIEW.documentId],
      item[DOCUMENT_VIEW.documentFileName],
      item[DOCUMENT_VIEW.documentDataModelItemId],
      item[DOCUMENT_VIEW.documentDataModelId],
      item[DOCUMENT_VIEW.documentVersionMemo],
      item[DOCUMENT_VIEW.documentVersionIndexCode],
      item[DOCUMENT_VIEW.documentVersionIndex],
      item[DOCUMENT_VIEW.documentVersionId],
      item[DOCUMENT_VIEW.documentVersionDocumentId],
      new Date(item[DOCUMENT_VIEW.documentVersionCreationDate]),
      item[DOCUMENT_VIEW.documentDataModelTableName],
    );
  }
}
