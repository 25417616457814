import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum EquipmentCategoryRootItemsEnum {
  Furniture = 1,
  Hardware,
  FireSafety,
  SanitaryWare,
  Decoration,
  Medical,
  Vegetation,
  Fitness,
  Leisure,
  Catering,
  Accessibility
}

export const EQUIPMENT_CATEGORY = {
  databaseTableName: 'EquipmentCategory',
  id: 'EqCa_Id',
  name: 'EqCa_Name',
  displayNameId: 'EqCa_DisplayNameId',
  depth: 'EqCa_Depth',
  parentId: 'EqCa_ParentId',
  isActive: 'EqCa_IsActive',
  canHaveChildren: 'EqCa_CanHaveChildren',
  scope: 'EqCa_Scope'
};

export class EquipmentCategory {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public depth: number,
    public parentId: number,
    public isActive: boolean,
    public canHaveChildren: boolean,
    public scope: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class EquipmentCategoryAdapter implements Adapter<EquipmentCategory> {
  adapt(item: any): EquipmentCategory {
    return new EquipmentCategory(
      EQUIPMENT_CATEGORY.databaseTableName,
      item[EQUIPMENT_CATEGORY.id],
      item[EQUIPMENT_CATEGORY.name],
      item[EQUIPMENT_CATEGORY.displayNameId],
      item[EQUIPMENT_CATEGORY.depth],
      item[EQUIPMENT_CATEGORY.parentId],
      item[EQUIPMENT_CATEGORY.isActive],
      item[EQUIPMENT_CATEGORY.canHaveChildren],
      item[EQUIPMENT_CATEGORY.scope],
    );
  }

  encode(item: EquipmentCategory | Partial<EquipmentCategory>): ISingleBackendObj {
    const encoded = {
      TableName: EQUIPMENT_CATEGORY.databaseTableName,
      [EQUIPMENT_CATEGORY.id]: item.id,
      [EQUIPMENT_CATEGORY.name]: item.name,
      [EQUIPMENT_CATEGORY.displayNameId]: item.displayNameId,
      [EQUIPMENT_CATEGORY.depth]: item.depth,
      [EQUIPMENT_CATEGORY.parentId]: item.parentId,
      [EQUIPMENT_CATEGORY.isActive]: item.isActive,
      [EQUIPMENT_CATEGORY.canHaveChildren]: item.canHaveChildren,
      [EQUIPMENT_CATEGORY.scope]: item.scope,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
