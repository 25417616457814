import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum FloorModelEnum {
  Background = 1,
  Planning = 2,
  Equipment = 3,
  TechnicalData = 4,
  Walls = 5,
  Doors = 6,
  Furniture = 7,
  Hardware = 8,
  Framing = 9,
  FloorInsideContours = 10,
  FloorCoreContours = 11,
  BlueprintExtents = 12,
  FloorOutsideContours = 13,
  VerticalTrafficContours = 14,
  VerticalShaftContours = 15,
  Areas = 16,
  FrontFrame = 17,
  FrameLabels = 18,
  FireSafety = 19,
  SanitaryWare = 20,
  PlanningMisc = 21,
  Compartments = 23,
  PartitioningFrame = 24,
  Rooms = 29,
  Workplaces = 30,
  RoomLabels = 31,
  WorkplaceLabels = 32,
  PeopleLabels = 33,
  Partitioning,
  Decoration = 35,
  Medical = 36,
  Vegetation = 37,
  Fitness = 38,
  Leisure = 39,
  Catering = 40,
  Accessibility = 41,
  Annotations = 42,
  Notes = 43,
  Measurement = 44,
  SketchNotes = 45,
  SketchMeasurement = 46
}

export const FLOOR_MODEL = {
  databaseTableName: 'FloorModel',
  id: 'FlMo_Id',
  parentId: 'FlMo_ParentId',
  depth: 'FlMo_Depth',
  name: 'FlMo_Name',
  displayNameId: 'FlMo_DisplayNameId',
  blueprintAttributes: 'FlMo_BlueprintAttributes',
  displayOrder: 'FlMo_DisplayOrder',
  scope: 'FlMo_Scope',
  isActive: 'FlMo_IsActive',
  tableName: 'FlMo_TableName',
  contentTypeId: 'FlMo_ContentTypeId',
  // canHaveChildren: 'FlMo_CanHaveChildren',
  readGrantId: 'FlMo_ReadGrantId',
  updateGrantId: 'FlMo_UpdateGrantId'
};

export class FloorModel {
  constructor(
    public databaseTableName: string,
    public id: number,
    public parentId: number,
    public depth: number,
    public name: string,
    public displayNameId: number,
    public blueprintAttributes: string, /** if null FlMo is a wrapper of other FlMo's **/
    public displayOrder: number,
    public scope: number,
    public isActive: boolean,
    public tableName: string,
    public contentTypeId: number,
    // public canHaveChildren: boolean,
    public readGrantId: number,
    public updateGrantId: number
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorModelAdapter implements Adapter<FloorModel> {
  adapt(item: any): FloorModel {
    return new FloorModel(
      FLOOR_MODEL.databaseTableName,
      item[FLOOR_MODEL.id],
      item[FLOOR_MODEL.parentId],
      item[FLOOR_MODEL.depth],
      item[FLOOR_MODEL.name],
      item[FLOOR_MODEL.displayNameId],
      item[FLOOR_MODEL.blueprintAttributes],
      item[FLOOR_MODEL.displayOrder],
      item[FLOOR_MODEL.scope],
      item[FLOOR_MODEL.isActive],
      item[FLOOR_MODEL.tableName],
      item[FLOOR_MODEL.contentTypeId],
      // item[FLOOR_MODEL.canHaveChildren],
      item[FLOOR_MODEL.readGrantId],
      item[FLOOR_MODEL.updateGrantId],
    );
  }

  encode(item: FloorModel): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_MODEL.databaseTableName,
      [FLOOR_MODEL.id]: item.id,
      [FLOOR_MODEL.parentId]: item.parentId,
      [FLOOR_MODEL.depth]: item.depth,
      [FLOOR_MODEL.name]: item.name,
      [FLOOR_MODEL.displayNameId]: item.displayNameId,
      [FLOOR_MODEL.blueprintAttributes]: item.blueprintAttributes,
      [FLOOR_MODEL.displayOrder]: item.displayOrder,
      [FLOOR_MODEL.scope]: item.scope,
      [FLOOR_MODEL.isActive]: item.isActive,
      [FLOOR_MODEL.tableName]: item.tableName,
      [FLOOR_MODEL.contentTypeId]: item.contentTypeId,
      // [FLOOR_MODEL.canHaveChildren]: item.canHaveChildren,
      [FLOOR_MODEL.readGrantId]: item.readGrantId,
      [FLOOR_MODEL.updateGrantId]: item.updateGrantId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
