import { createSelector } from '@ngrx/store';
import { selectFloorsState } from '../reducers';
import * as fromUiContext from '../reducers/ui-context.reducer';

export const selectUiContext = createSelector(
  selectFloorsState,
  state => state[fromUiContext.uiContextFeatureKey] as fromUiContext.State
);

export const selectIsCreatingRoom = createSelector(
  selectUiContext,
  (state) => state.isCreatingRoom
);

export const selectIsRecalculatingRoomArea = createSelector(
  selectUiContext,
  (state) => state.isRecalculatingRoomArea
);

export const selectIsCreatingWall = createSelector(
  selectUiContext,
  (state) => state.isCreatingWall
);

export const selectLoadFloorComponentState = createSelector(
  selectUiContext,
  (state) => state.loadFloorComponentState
);

export const selectIsCreatingDoor = createSelector(
  selectUiContext,
  (state) => state.isCreatingDoor
);

export const selectIsUsingSelectionTool = createSelector(
  selectUiContext,
  (state) => state.isUsingSelectionTool
);


export const selectCreateDoorProps = createSelector(
  selectUiContext,
  (state) => state.createDoorProps
);

export const selectCreateWallProps = createSelector(
  selectUiContext,
  (state) => state.createWallProps
);

export const selectCreateEquipmentProps = createSelector(
  selectUiContext,
  (state) => state.createEquipmentProps
);

export const selectRoomEditMode = createSelector(
  selectUiContext,
  (state) => state.roomEditionMode
);

export const selectEquipmentCreationMode = createSelector(
  selectUiContext,
  (state) => state.equipmentCreationMode
);

export const selectIsCreatingEquipment = createSelector(
  selectUiContext,
  (state) => state.isCreatingEquipment
);

export const selectSelectedFloorCatalogId = createSelector(
  selectUiContext,
  (state) => state.selectedFloorCatalogId
);

export const selectStudyMode = createSelector(
  selectUiContext,
  (state) => state.studyMode
);

export const selectAddFloorDataToTaskMode = createSelector(
  selectUiContext,
  (state) => state.isAddingFloorDataToTask
);

export const selectRoomFormProps = createSelector(
  selectUiContext,
  (state) => state.roomFormProps
);

export const isSelectingContour = createSelector(
  selectUiContext,
  (state) => state.isSelectingContour
);

export const selectFloorToolbarTitle = createSelector(
  selectUiContext,
  (state) => state.toolbarTitle
);

export const selectLeftSidenavMainTabLabel = createSelector(
  selectUiContext,
  (state) => state.leftSidenavMainTabLabel
);

export const selectTable = createSelector(
  selectUiContext,
  (state) => state.table
);

export const selectFloorDisplayContextId = createSelector(
  selectUiContext,
  (state) => state.displayContext
);

export const selectFloorPerimeterIds = createSelector(
  selectUiContext,
  (state) => state.floorPerimeterIds
);
export const selectCurrentTaskRoomLayoutTypeIds = createSelector(
  selectUiContext,
  (state) => state.currentTaskRoomLayoutTypeIds
);
export const selectCurrentTaskActivityStatusTypeIds = createSelector(
  selectUiContext,
  (state) => state.currentTaskActivityStatusTypeIds
);
export const selectCurrentTaskAttributionTypeIds = createSelector(
  selectUiContext,
  (state) => state.currentTaskAttributionTypeIds
);
export const selectCurrentTaskBusinessUnitIds = createSelector(
  selectUiContext,
  (state) => state.currentTaskBusinessUnitIds
);
export const selectDisplayedTasksIds = createSelector(
  selectUiContext,
  (state) => state.displayedTasksIds
);
export const selectDisplayedTaskVisibility = createSelector(
  selectUiContext,
  (state) => state.displayedTasksVisibility
);

export const selectCurrentlyEditedFloorModelId = createSelector(
  selectUiContext,
  (state) => state.currentFloorModelEditing
);

export const selectIsVerifyingSpacePlanningImport = createSelector(
  selectUiContext,
  (state) => state.isVerifyingSpacePlanningImport
);

export const selectIsBindingEquipmentImportedItem = createSelector(
  selectUiContext,
  (state) => state.isBindingEquipmentImportedItem
);
