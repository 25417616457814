import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../floor-store/reducers';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { TaskLinkActions } from '../actions';
import { concatMap, switchMap } from 'rxjs/operators';
import { TaskLinkAdapter } from '../../../domain-models/business/task-link.model';
import { of } from 'rxjs';


@Injectable()
export class TaskLinkEffects {

  beginGetTaskLinkByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(TaskLinkActions.beginGetTaskLinkByTaskId),
    concatMap((action) => this.projectManagementService.getTaskLinksByTaskId(action.id).pipe(
        switchMap(taskLinks => of(TaskLinkActions.upsertTaskLinks({taskLinks: taskLinks})))
      )
    )),
  );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private taskLinkAdapter: TaskLinkAdapter,
    private store: Store<State>
  ) {
  }
}
