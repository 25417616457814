import { Injectable } from '@angular/core';
import { GanttTask, GanttTaskAdapter } from '../models/gantt-task.model';
import { ApiService } from '../../../core/services/api.service';
import { concatMap, filter, first, map, tap } from 'rxjs/operators';
import { GanttService } from './gantt.service';
import { API_ENDPOINTS, API_URL } from '../../../shared/api-endpoints';
import { Task, TASK, TaskAdapter } from '../../../domain-models/business/task.model';
import { Observable, of } from 'rxjs';
import { TaskBusinessTypeAdapter, TaskBusinessTypeStandardRootItemsEnum } from '../../../domain-models/business/task-business-type.model';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { GanttTaskSelectors, ProjectManagementUiSelectors, TaskSelectors } from '../../../store/project-management/selectors';
import { TaskActions, TaskContributorActions, TaskFloorActions } from '../../../store/project-management/actions';
import { Update } from '@ngrx/entity';
import { deepCopy, Utils } from '../../../shared/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiTasksPost, ApiTasksPostAdapter } from '../../../domain-models/endpoint-specific/tasks.post.model';
import { Actions } from '@ngrx/effects';
import { ProjectManagementService } from './project-management.service';
import { LoggerService } from '../../../core/services/logger.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { LoaderService } from '../../../core/services/loader.service';
import { TaskBusinessTypeEnum } from '../../../domain-models/business/task-business.model';
import { UsageContextIdEnum } from '../../../shared/models/usage-context-id.enum';
import { TASK_FACILITY, TaskFacility, TaskFacilityAdapter } from '../../../domain-models/business/task-facility.model';
import {
  FACILITY_COMPANY_TASK_VIEW,
  FacilityCompanyTaskView,
  FacilityCompanyTaskViewAdapter
} from '../../../domain-models/views/facility-company-task-view.model';
import { FACILITY_COMPANY_TASK } from '../../../domain-models/business/facility-company-task.model';
import { TaskDisplayFilterOptionEnum } from '../models/task-display-filter-option.model';
import { ApiData } from '../../../core/models/api-data.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends ApiService {

  // public tasks: GanttTask[] = [];
  ganttTasks$: Observable<GanttTask[]>;
  tasks$: Observable<Task[]>;
  public ganttTasks: GanttTask[];
  tasks: Task[];

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private store: Store<State>,
              private actions: Actions,
              private apiTasksPostAdapter: ApiTasksPostAdapter,
              private taskAdapter: TaskAdapter,
              private ganttTaskAdapter: GanttTaskAdapter,
              private taskFacilityAdapter: TaskFacilityAdapter,
              private facilityCompanyTaskViewAdapter: FacilityCompanyTaskViewAdapter,
              private projectManagementService: ProjectManagementService,
              private taskBusinessTypeAdapter: TaskBusinessTypeAdapter,
              private ganttService: GanttService) {
    super(http, loggerService, snackBarService, httpLoaderService);
    /**
     * Select all GantTasks from the store and return them as deep copied array which is needed
     * for data manipulations by DHTMLX plugin
     */
    this.ganttTasks$ = this.store.select(GanttTaskSelectors.selectAllGanttTasks).pipe(
      map(ganttTasks => {
        // console.log(ganttTasks);
        this.ganttTasks = ganttTasks;
        return deepCopy(ganttTasks) as GanttTask[];
      })
    );

    /**
     * Select all Tasks from the store
     */
    this.tasks$ = this.store.select(TaskSelectors.selectAllTasks);
    this.store.select(TaskSelectors.selectAllTasks).pipe(
      // filter(x => x.length > 0),
      map(tasks => this.tasks = tasks)
    ).subscribe();
  }

  findTaskById(id: number): Task {
    return this.tasks.find(t => t.id === id);
  }

  getTaskById(id: number): Observable<Task> {
    return this.store.select(TaskSelectors.selectTaskById(id));
  }

  insert(task: GanttTask): Observable<number> {
    return this.store.select(ProjectManagementUiSelectors.selectTaskCreationDto).pipe(
      concatMap(taskCreationDto => this.createTask(taskCreationDto).pipe(
        map(apiTasksPost => {
          this.store.dispatch(TaskActions.addTask({task: apiTasksPost.task}));
          this.store.dispatch(TaskContributorActions.addTaskContributors({taskContributors: apiTasksPost.taskContributors}));
          /** Guards against undefined TaFl in case of Ta_BusinessTypeId is blueprint **/
          if (apiTasksPost.taskFloor) {
            this.store.dispatch(TaskFloorActions.addTaskFloor({taskFloor: apiTasksPost.taskFloor}));
          }
          return apiTasksPost.task.id;
        }),
      )),
    );
  }

  createTask(taskCreationDto: any): Observable<ApiTasksPost> {
    // console.log(this.projectManagementService.taskDisplayFilterValue);
    if (this.projectManagementService.taskDisplayFilterValue
      && this.projectManagementService.taskDisplayFilterValue.value === TaskDisplayFilterOptionEnum.Templates) {
      taskCreationDto = {
        ...taskCreationDto,
        [TASK.isTemplate]: true,
      };
    }

    return this.post(`${API_URL}tasks`, taskCreationDto).pipe(
      map(data => this.apiTasksPostAdapter.adapt(data.payload))
    );
  }

  createTaskFromPojectTemplate(taskTemplateId: number, taskName: string, taskStartDate: Date): Observable<ApiData> {
    const dto = {
      [TASK.name]: taskName,
      [TASK.startDate]: Utils.dateToBackendString(taskStartDate),
    };
    return this.post(`${API_ENDPOINTS.createFromProjectTemplate}${taskTemplateId}`, dto).pipe(
      // map(data => this.apiTasksPostAdapter.adapt(data.payload))
    );
  }

  updateTaskStore(updatedGanttTask: GanttTask, fields?: any): Observable<Task> {

    /**
     * Always update Task, GanttTask is a slave / GanttTask SHOULD extends Task in the future
     */
    const updatedTask = this.ganttTaskAdapter.adaptToTask(updatedGanttTask);

    /**
     * Check for Task changes from Gantt Lightbox and add them to GanttTask
     */
    if (fields) {
      console.log(fields);
      if (fields[TASK.statusId]) {
        updatedTask.statusId = fields[TASK.statusId];
      }
      if (fields[TASK_FACILITY.companyTaskId] || fields[TASK_FACILITY.unitOfWorkAmount]) {
        const taskFacility = this.taskFacilityAdapter.adapt(fields);
        taskFacility.taskId = updatedGanttTask.id;
        const dto = this.taskFacilityAdapter.encode(taskFacility);
        this.patch(API_ENDPOINTS.dynT, dto).pipe().subscribe();
      }
    }

    /** Select original Task from the store **/
    console.log('store call');
    return this.store.select(TaskSelectors.selectTaskById(updatedGanttTask.id)).pipe(
      first(),
      tap(originalTask => {
        console.log(updatedTask);
        this.store.dispatch(TaskActions.updateTask({
          update: {
            id: updatedTask.id,
            changes: updatedTask
          } as Update<Task>,
          //ignoreApiCall: true,
        }));
        //return true;
      })
    );
  }

  // update(updatedGanttTask: GanttTask, fields?: any): Observable<any> {
  //
  //   /**
  //    * Always update Task, GanttTask is a slave / GanttTask SHOULD extends Task in the future
  //    */
  //   const updatedTask = this.ganttTaskAdapter.adaptToTask(updatedGanttTask);
  //
  //   /**
  //    * Check for Task changes from Gantt Lightbox
  //    */
  //   if (fields) {
  //     if (fields[TASK.statusId]) {
  //       updatedTask.statusId = fields[TASK.statusId];
  //     }
  //   }
  //
  //   /** Select original Task from the store **/
  //   return this.store.select(TaskSelectors.selectTaskById(updatedGanttTask.id)).pipe(
  //     switchMap(originalTask => {
  //       const backendObj = this.taskAdapter.encode(updatedTask);
  //       return this.projectManagementService.patch(`${API_URL}tasks`, backendObj).pipe(
  //         map(apiResult => {
  //           const taskResult = this.taskAdapter.adapt(apiResult.payload);
  //           const ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(taskResult);
  //           this.store.dispatch(GanttTaskActions.updateGanttTask({update: {id: ganttTaskUpdate.id, changes: ganttTaskUpdate}}));
  //           this.store.dispatch(TaskActions.updateTask({update: {id: taskResult.id, changes: taskResult}, ignoreApiCall: true}));
  //           return true;
  //         }),
  //       );
  //     })
  //   );
  // }

  update(ganttTask: GanttTask): void {
    /**
     * Always update Task, GanttTask is a slave / GanttTask SHOULD extends Task in the future
     */
    const task = this.ganttTaskAdapter.adaptToTask(ganttTask) as Task;

    /** Select original Task from the store **/
    this.store.select(TaskSelectors.selectTaskById(task.id)).pipe(
      first(),
      tap(taskFromStore => {
        const update = {...taskFromStore, ...task};
        const diff = Utils.diff(taskFromStore, update);
        if (Object.keys(diff).length > 1) {
          this.store.dispatch(TaskActions.updateTask({
            update: {
              id: task.id,
              changes: update
            } as Update<Task>
          }));
        }

      })
    ).subscribe();

    // return this.store.dispatch(TaskActions.updateTask({
    //   update: {
    //     id: task.id,
    //     changes: task
    //   } as Update<Task>
    // }));
  }

  remove(id: number): Observable<void> {
    // Check if Ta exists in store before deletion, avoid bad API calls in deleteTask$ TaskEffects
    return this.store.select(TaskSelectors.selectTaskById(id)).pipe(
      filter(task => task !== undefined),
      map(task => this.store.dispatch(TaskActions.deleteTask({id: id})))
    );
  }

  getRootTask(id: number): Observable<Task> {
    return this.get(API_ENDPOINTS.dynH + `${TASK.databaseTableName}/rootitem/${id}`)
      .pipe(
        map(data => this.taskAdapter.adapt(data.payload))
      );
  }

  getReconcileTasks(taskId: number): Observable<Task[]> {
    const params = new HttpParams().set('blueprintTaskId', String(taskId));
    return this.get(`${API_ENDPOINTS.reconcileTasks}`, UsageContextIdEnum.none, params)
      .pipe(
        map(data => data.payload.map(t => this.taskAdapter.adapt(t)))
      );
  }

  public isBlueprintBusinessType(businessTypeId: number): boolean {
    return businessTypeId === TaskBusinessTypeEnum.SpacePlanning
      || businessTypeId === TaskBusinessTypeEnum.BlueprintImport;
  }

  public isBlueprintBusinessType$(businessTypeId: number): Observable<boolean> {
    return of(this.isBlueprintBusinessType(businessTypeId));
  }

  public isFacilityBusinessType(businessTypeId: number): boolean {
    return businessTypeId === TaskBusinessTypeEnum.CurativeMaintenance
      || businessTypeId === TaskBusinessTypeEnum.PreventiveMaintenance
      || businessTypeId === TaskBusinessTypeEnum.Intervention
      || businessTypeId === TaskBusinessTypeEnum.Works;
  }

  public isFacilityBusinessType$(businessTypeId: number): Observable<boolean> {
    return of(this.isFacilityBusinessType(businessTypeId));
  }

  isTaskBusinessTypeRootItem(id: number): boolean {
    /** Check if task TaBuTy_Id is a root business type or not **/
    return id === TaskBusinessTypeStandardRootItemsEnum.Blueprint
      || id === TaskBusinessTypeStandardRootItemsEnum.Maintenance
      || id === TaskBusinessTypeStandardRootItemsEnum.Move
      || id === TaskBusinessTypeStandardRootItemsEnum.Works;
  }

  getTaskFacility(taskId: number): Observable<TaskFacility[]> {
    let httpParams = new HttpParams().set('primaryFilterId', String(taskId));
    httpParams = httpParams.append('primaryColumnName', TASK_FACILITY.taskId);
    return this.get(`${API_ENDPOINTS.dynT}${TASK_FACILITY.databaseTableName}`, UsageContextIdEnum.none, httpParams)
      .pipe(
        map(data => data.payload.map(t => this.taskFacilityAdapter.adapt(t)))
      );
  }

  getFacilityCompanyTaskByCompanyTaskId(companyTaskId: number): Observable<FacilityCompanyTaskView> {
    let httpParams = new HttpParams().set('primaryFilterId', String(companyTaskId));
    httpParams = httpParams.append('primaryColumnName', FACILITY_COMPANY_TASK.id);
    return this.get(`${API_ENDPOINTS.dynT}${FACILITY_COMPANY_TASK_VIEW.databaseTableName}`, UsageContextIdEnum.none, httpParams).pipe(
      map(data => data.payload.map(r => this.facilityCompanyTaskViewAdapter.adapt(r))[0])
    );
  }
}
