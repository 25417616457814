import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import * as TaskFloorActions from '../actions/task-floor.actions';
import { Store } from '@ngrx/store';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { TaskFloorAdapter } from '../../../domain-models/business/task-floor.model';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { State } from '../../../reducers';


@Injectable()
export class TaskFloorEffects {

  beginGetAllTaskFloors$ = createEffect(() => this.actions$.pipe(
    ofType(TaskFloorActions.beginGetAllTaskFloors),
    exhaustMap((action) =>
      this.projectManagementService.get(API_ENDPOINTS.dynT + 'task_floor').pipe(
        map(data => data.payload.map(item => this.taskFloorAdapter.adapt(item))),
        //tap(e => console.log(e)),
        map(items => TaskFloorActions.addTaskFloors({taskFloors: items}))
      )
    ))
  );

  constructor(
    private actions$: Actions,
    private taskFloorAdapter: TaskFloorAdapter,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }
}
