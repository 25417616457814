import { createSelector } from '@ngrx/store';
import * as fromRoomAllocation from '../reducers/room-allocation.reducer';
import { selectFloorsState } from '../reducers';
import { selectFloorDataIdsByBuildingId, selectFloorDataIdsByTaskId } from '../../svg-store/selectors/floor-data.selectors';

/** Select store slice **/

export const selectRoomAllocations = createSelector(
  selectFloorsState,
  state => state[fromRoomAllocation.roomAllocationsFeatureKey]
);

/** Basics selectors **/

export const selectRoomAllocationEntities = createSelector(
  selectRoomAllocations,
  fromRoomAllocation.selectRoomAllocationEntities
);

export const selectAllRoomAllocations = createSelector(
  selectRoomAllocations,
  fromRoomAllocation.selectAllRoomAllocations
);

export const selectRoomAllocationByFloorDataId = (floorDataId: number) => createSelector(
  selectAllRoomAllocations,
  (roomAllocations) => roomAllocations.filter(r => r.floorDataId === floorDataId)
);

export const selectRoomAllocationByFloorDataIds = (ids: number[]) => createSelector(
  selectAllRoomAllocations,
  (roomAllocations) => roomAllocations.filter(r => ids.find(id => id === r.floorDataId))
);

export const selectAllRoomAllocationsByTaskId = (taskId: number) => createSelector(
  selectAllRoomAllocations,
  selectFloorDataIdsByTaskId(taskId),
  (roomAllocations, floorDataIds) => roomAllocations.filter(r => floorDataIds.find(id => id === r.floorDataId))
);

export const selectAllRoomAllocationsByBuildingId = (buildingId: number) => createSelector(
  selectAllRoomAllocations,
  selectFloorDataIdsByBuildingId(buildingId),
  (roomAllocations, floorDataIds) => roomAllocations.filter(r => floorDataIds.find(id => id === r.floorDataId))
);

/** Advanced selectors **/

export const selectBuildingRoomAllocationById = (businessUnitId: number, buildingId: number) => createSelector(
  selectAllRoomAllocationsByBuildingId(buildingId),
  selectFloorDataIdsByBuildingId(buildingId),
  (roomAllocations, buildingFloorDataIds) =>
    roomAllocations.filter(a => buildingFloorDataIds.find(id => id === a.floorDataId))
      .filter(a => a.businessUnitId === businessUnitId)
);

export const selectBuildingRoomAllocationAreaById = (businessUnitId: number, buildingId: number) => createSelector(
  selectAllRoomAllocationsByBuildingId(buildingId),
  // selectBuildingRoomAllocationById(businessUnitId, buildingId),
  (roomAllocations) => {
    let totalArea = 0;
    roomAllocations.forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorRoomAllocationById = (businessUnitId: number, taskId: number) => createSelector(
  selectAllRoomAllocationsByTaskId(taskId),
  selectFloorDataIdsByTaskId(taskId),
  (roomAllocations, taskFloorDataIds) =>
    roomAllocations.filter(a => taskFloorDataIds.find(id => id === a.floorDataId))
      .filter(a => a.businessUnitId === businessUnitId)
);

export const selectFloorRoomAllocationAreaById = (businessUnitId: number, taskId: number) => createSelector(
  selectFloorRoomAllocationById(businessUnitId, taskId),
  (roomAllocations) => {
    let totalArea = 0;
    roomAllocations.forEach(r => totalArea += r.area);
    return totalArea;
  }
);
