import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CoreSelectors } from '../../store/core-store/selectors';
import { EcSyFile } from '../../domain-models/models/EcSyFile.model';
import { CoreActions } from '../../store/core-store/actions';
import { ClientService } from '../services/client.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<State>,
              private router: Router,
              private clientService: ClientService) {
  }

  // wrapping the logic so we can .switchMap() it
  getFromStore(state?: RouterStateSnapshot): Observable<any> {
    // return an Observable stream from the store
    return this.store.select(CoreSelectors.selectClient).pipe(
      // the tap() operator allows for a side effect, at this
      // point, we're checking if items total are different of zero
      switchMap((client) => this.clientService.getClients().pipe(
        map(clientItems => [client, clientItems])
      )),
      tap(([client, clientItems]) => {
        const sessionStorageClient = JSON.parse(sessionStorage.getItem('client_file')) as EcSyFile;
        const contractorsAccountManagementUrl = state.url === '/accounts/contractors-account-management';
        if (!client) {
          if (sessionStorageClient) {
            this.store.dispatch(CoreActions.setClient({client: sessionStorageClient}));
          } else if (contractorsAccountManagementUrl || (<EcSyFile[]>clientItems).length < 1) {
            if ((<EcSyFile[]>clientItems).length < 1 && !contractorsAccountManagementUrl) {
              this.router.navigate(['/accounts/contractors-account-management']);
            }
          } else {
            this.router.navigate(['/clients']);
          }
        }
      }),
      // filter out zero values, no value === empty!
      filter((client) => client !== null),
      // which if empty, we will never take()
      // this is the same as first() which will only
      // take 1 value from the Observable then complete
      // which does our unsubscribing, technically.
      take(1),
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.getFromStore(state).pipe(
      //tap(r => console.log(r)),
      // if it was successful, we can return Observable.of(true)
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false))
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.getFromStore(state).pipe(
      //tap(r => console.log(r)),
      // if it was successful, we can return Observable.of(true)
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false))
    );
  }

  // canActivateChild(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   if (sessionStorage.getItem('client_file')) {
  //     return true;
  //   } else {
  //     this.router.navigate(['/clients']);
  //     return false;
  //   }
  // }
}
