import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../../shared/api-endpoints';
import { Z_APP_PARAMETER } from '../../domain-models/core/Z_App_Parameter';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from './logger.service';
import { SnackBarService } from './snack-bar.service';
import { LoaderService } from './loader.service';
import { AppParameter } from '../models/app-parameter.model';

@Injectable({
  providedIn: 'root'
})
export class AppService extends ApiService {

  appParams: AppParameter = null;
  appParams$ = new BehaviorSubject(this.appParams);

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService) {
    super(http, loggerService, snackBarService, httpLoaderService);
    /** TODO Make better async process between all core services loading processes (Clients / Params / Api)**/
    if (sessionStorage.getItem('appParams')) {
      this.loadAppParams();
    }
  }

  public loadAppParams() {
    this.get(API_ENDPOINTS.appParams).subscribe(
      data => {
        this.appParams = {} as AppParameter;
        data.payload.forEach(
          e => {
            let value = e[Z_APP_PARAMETER.value];
            // Cast string numeric representations to real number
            Number(value) ? value = Number(value) : value = value;
            this.appParams[e[Z_APP_PARAMETER.name]] = value;
          }
        );
        localStorage.setItem('appParams', JSON.stringify(this.appParams));
        this.appParams$.next(this.appParams);
        // console.log(this.appParams);
      });
  }
}
