<div class="wrapper">
  <!--  <mat-form-field>
      <mat-select
        (ngModelChange)="selectNewTheme($event)"
        [(ngModel)]="displayThemeId">
        <mat-option *ngFor="let displayTheme of (displayThemes$ | async)"
                    [value]="displayTheme.id">{{displayTheme.name}}</mat-option>
      </mat-select>
    </mat-form-field>-->

  <div class='sticky'>
    <mat-form-field>
      <mat-select (ngModelChange)="selectNewTheme($event)"
                  [(ngModel)]="displayThemeId">
        <!--      <mat-option>&#45;&#45; None &#45;&#45;</mat-option>-->
        <ng-container *ngFor="let themeCategory of displayThemeCategories$ | async">
          <mat-optgroup [label]="themeCategory.displayNameId | language | async">
            <mat-option *ngFor="let displayTheme of (displayThemesByCategoryIdDictionary$ | async)[themeCategory.id]" [value]="displayTheme.id">
              {{displayTheme.name}}
            </mat-option>
          </mat-optgroup>
        </ng-container>

      </mat-select>
    </mat-form-field>
  </div>


  <!--{{displayThemeId}}-->
  <mat-nav-list *ngIf="displayThemeItems$ | async"
                (mouseleave)="toggleHoveredItem(null)"
  >
    <div mat-subheader class='subheader' (click)='showAllItems($event)'>
      Afficher tout
      <span class='spacer'></span>
      <mat-icon>zoom_out</mat-icon></div>
    <ng-container *ngFor="let item of (displayThemeItems$ | async)">


      <mat-list-item *ngIf="idsToDisplay.includes(item.resId)"
                     (mouseenter)="toggleHoveredItem(item)"
                     (click)="toggleItemVisibility(item)">

        <button matListAvatar *ngIf='(item.displayThemeIds.includes(DisplayThemeEnum.ReconcileUninstalled) || item.displayThemeIds.includes(DisplayThemeEnum.ReconcileInstalled))
        && (currentTaskHasReconcileTasks$ | async)'
                [disabled]='(isEditingEquipment$ | async) || (item.resId === EQUIPMENT_PLANNING_STATE.databaseTableName + "-" + EquipmentPlanningStateEnum.Unchanged)'
                mat-icon-button (click)="applyThemeItem($event, item)" class='action' color="secondary" aria-label="Appliquer">
          <mat-icon [ngClass]="{ 'active-marker': (isPaintingItem$ | async)?.resId === item.resId}">brush</mat-icon>
          <!-- + is used to force the cast from string to number) -->
        </button>

        <button matListAvatar *ngIf='item.displayThemeIds.includes(DisplayThemeEnum.Identification)' disabled
                mat-icon-button (click)="applyThemeItem($event, item)" class='action' color="secondary" aria-label="Appliquer">
          <mat-icon [ngClass]="{ 'active-marker': (isPaintingItem$ | async)?.resId === item.resId}">qr_code_scanner</mat-icon>
          <!-- + is used to force the cast from string to number) -->
        </button>

        <span class='spacer'></span>
        <div fxLayoutAlign='end'>
          <div class='icon-small' fxLayoutAlign='end'
               [matTooltip]='"Isoler"'
               matTooltipPosition='before'
               (click)="isolateItemVisibility($event, item)"
               [ngClass]='{"hidden" : hoveredItem?.resId !== item.resId }'>
            <mat-icon>zoom_in</mat-icon>
            <!-- + is used to force the cast from string to number) -->
          </div>
          <div [ngClass]="{'color-cell': item.active, 'color-cell-stroke': !item.active, 'stripes': item.svgPatternId === 1 }"
               [style.background-color]="item.color?.toHexRgb()" [style.border-color]="item.color?.toHexRgb()"></div>
        </div>
        <!--        <div [ngClass]="{'color-cell': item.active, 'color-cell-stroke': !item.active, 'stripes': item.svgPatternId === 1 }"-->
        <!--          [style.background-color]="item.color?.toHexRgb()" [style.border-color]="item.color?.toHexRgb()"></div>-->
        <h3 matLine> {{item.name}} </h3>
        <p matLine *ngIf='item.displayThemeIds.includes(DisplayThemeEnum.ActivityStatus)
      || item.displayThemeIds.includes(DisplayThemeEnum.Allocations)
      || item.displayThemeIds.includes(DisplayThemeEnum.Mixed)
      || item.displayThemeIds.includes(DisplayThemeEnum.LayoutType)
      || item.displayThemeIds.includes(DisplayThemeEnum.Attributions)'>
          <span> Total </span>
          <!--        <span> &#45;&#45; 42 m<sup>2</sup> </span>&lt;!&ndash;{{item.area | number:'1.1-2'}}&ndash;&gt;-->
          <span> -- {{(item.resId | roomArea: selectedTaskId: selectedBuildingId | async) | number:'1.1-2'}} m<sup>2</sup> </span>
        </p>
        <p matLine *ngIf='item.displayThemeIds.includes(DisplayThemeEnum.WorkplaceTypes)'>
          <span> Total </span>
          <span> -- {{(item.resId | workplaceTypeCount: selectedTaskId: selectedBuildingId | async)}} </span>
        </p>
        <p matLine *ngIf='item.displayThemeIds.includes(DisplayThemeEnum.ReconcileInstalled)
        || item.displayThemeIds.includes(DisplayThemeEnum.ReconcileUninstalled)'>
          <span> Total</span>
          <!--<span> &#45;&#45; {{(item.resId | roomArea: selectedTaskId: selectedBuildingId | async) | number:'1.1-2'}} </span>-->
        </p>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</div>


<!--<mat-nav-list>-->
<!--  <h3 mat-subheader *ngIf="label">{{displayTheme.label}}</h3>-->
<!--  <mat-list-item (click)="item.active = !item.active; legendItemsOut.emit(displayTheme.displayThemeItems)"-->
<!--                 (mouseenter)="overLegendItem.emit(item)"-->
<!--                 (mouseleave)="overLegendItem.emit(null)"-->
<!--                 *ngFor="let item of displayTheme.displayThemeItems | orderBy: 'name' :true"-->
<!--  >-->
<!--    <div-->
<!--      [ngClass]="{'color-cell': item.active || !clickable, 'color-cell-stroke': !item.active && clickable,-->
<!--      'stripes': item.displayThemeSvgPattern !== 1 }"-->
<!--      [style.background-color]="item.hexColor" [style.border-color]="item.hexColor"></div>-->
<!--    <h3 matLine> {{item.name}} </h3>-->
<!--    <p matLine>-->
<!--      <span> Total </span>-->
<!--      <span> &#45;&#45; {{item.area | number:'1.1-2'}} m<sup>2</sup> </span>-->
<!--    </p>-->
<!--  </mat-list-item>-->
<!--</mat-nav-list>-->
