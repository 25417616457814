import { NgModule, Optional, Self } from '@angular/core';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { MergedRouterStateSerializer } from './merged-route-serialzer';
import { Router } from '@angular/router';

//https://medium.com/simars/ngrx-router-store-reduce-select-route-params-6baff607dd9

export const routerStateConfig = {
  stateKey: 'router', // state-slice name for routing state
};

@NgModule({
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
  ],
  exports: [
    StoreModule,
    StoreRouterConnectingModule
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    }
  ]
})
export class NgrxRouterStoreModule {

  constructor(@Self() @Optional() router: Router) {
    // if (router-store) {
    //   console.log('All good, NgrxRouterStoreModule');
    // } else {
    //   console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
    // }
  }

}
