import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'echo-color-form-field',
  templateUrl: './color-form-field.component.html',
  styleUrls: ['./color-form-field.component.scss']
})
export class ColorFormFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
