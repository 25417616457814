import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum ZConstraintTypeEnum {
  Minimum = 1,
  Maximum = 2,
  Step = 3,
  Value = 4,
  List = 5,
  AllowsDiacritics = 6,
  AllowsNull = 7,
  IsUnique = 8,
  DefaultValue = 9,
  RoundedValue = 10,
  Dependency = 11,
  IsReadOnlyAfterCreate= 12,
  HasDisplayName = 13,
  IsReadOnlyOnApplicationScope = 14
}

export const Z_CONSTRAINT_TYPE = {
  databaseTableName:  'z_constraint_type',
  id: 'CoTy_Id',
  name: 'CoTy_Name',
};

export class ZConstraintType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})


export class ZConstraintTypeAdapter implements Adapter<ZConstraintType> {
  adapt(item: any): ZConstraintType {
    return new ZConstraintType(
      Z_CONSTRAINT_TYPE.databaseTableName,
      item[Z_CONSTRAINT_TYPE.id],
      item[Z_CONSTRAINT_TYPE.name]
    );
  }
}
