import { createSelector } from '@ngrx/store';
import * as fromFloorCatalog from '../reducers/floor-catalog.reducer';
import { selectSvgState } from '../reducers';

export const selecFloorCatalog = createSelector(
  selectSvgState,
  state => state[fromFloorCatalog.floorCatalogFeatureKey]
);

export const selectFloorCatalogIds = createSelector(
  selecFloorCatalog,
  fromFloorCatalog.selectFloorCatalogIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectFloorCatalogEntities = createSelector(
  selecFloorCatalog,
  fromFloorCatalog.selectFloorCatalogEntities
);
export const selectAllFloorCatalog = createSelector(
  selecFloorCatalog,
  fromFloorCatalog.selectAllFloorCatalogs
);
export const selectFloorCatalogTotal = createSelector(
  selecFloorCatalog,
  fromFloorCatalog.selectFloorCatalogTotal
);

export const selectFloorCatalogById = (id: number) => createSelector(
  selectFloorCatalogEntities,
  entities => entities[id]
);

export const selectFloorCatalogByIds = (ids: number[]) => createSelector(
  selectFloorCatalogEntities,
  (entities) => ids.map(id => entities[id])
);
