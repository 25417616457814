import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { TaskFloorModelSelectors } from '../selectors';
import { EMPTY, of } from 'rxjs';
import { RendererActions, TaskFloorModelActions, WallActions } from '../actions';
import { FloorModelEnum } from '../../../domain-models/business/floor-model.model';
import { normalizeArray } from '../../../shared/utils';
import { FloorSelectors } from '../../floor-store/selectors';
import { Update } from '@ngrx/entity';
import { TaskFloorModel } from '../../../domain-models/business-extended/task-floor-model.model';
import { State } from '../../../reducers';
import { FloorModelSelectors } from '../../shared-store/selectors';

@Injectable()
export class TaskFloorModelEffects {

  /** Delete floorData items by taskIds **/

  removeFloorModelItemsByTaskIds$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds),
      withLatestFrom(this.store.select(TaskFloorModelSelectors.selectAllFloorModels)),
      switchMap(([action, floorModels]) => {
        let floorModelIds = [];
        action.taskIds.forEach(id => {
          floorModelIds = [...floorModelIds, ...floorModels.filter(e => e.taskId === id)
            .map(e => `${e.id}-${e.taskId}`)];
        });
        return of(TaskFloorModelActions.removeTaskFloorModelItems({ids: floorModelIds}));
      }),
    )
  );

  updateTaskFloorModelItems$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.updateTaskFloorModelItems),
      switchMap(action => {
        const floorModels = normalizeArray(action.updates.map(u => {
          return {...u, id: Number(String(u.id).split('-')[0])};
        }));
        if (floorModels[FloorModelEnum.PartitioningFrame]) {
          if (floorModels[FloorModelEnum.PartitioningFrame].changes.isVisible) {
            return of(WallActions.showPartitioningFrame());
          } else {
            return of(WallActions.hidePartitioningFrame());
          }
        } else if (floorModels[FloorModelEnum.Partitioning]) {
          /** Clear selection if any child of FlMo_Partitioning is hidden **/
          if (floorModels[FloorModelEnum.Partitioning].changes.isVisible) {
            return EMPTY;
          } else {
            return of(RendererActions.resetSelection());
          }
        } else if (floorModels[FloorModelEnum.Equipment]) {
          /** Clear selection if any child of FlMo_Equipment is hidden **/
          if (floorModels[FloorModelEnum.Equipment].changes.isVisible) {
            return EMPTY;
          } else {
            return of(RendererActions.resetSelection());
          }
        } else {
          return EMPTY;
        }
      }),
    )
  );

  // updateTaskFloorModelItems$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(TaskFloorModelActions.updateTaskFloorModelItems),
  //     switchMap(action => {
  //       const partitioningFrame = action.updates.find(e => Number(String(e.id).split('-')[0]) === FloorModelEnum.PartitioningFrame) as Update<TaskFloorModel>;
  //       if (partitioningFrame) {
  //         if (partitioningFrame.changes.isVisible) {
  //           return of(WallActions.showPartitioningFrame());
  //         } else {
  //           return of(WallActions.hidePartitioningFrame());
  //         }
  //       } else {
  //         return EMPTY;
  //       }
  //     }),
  //   )
  // );

  updateTaskFloorModel$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.updateTaskFloorModel),
      switchMap(action => {
        const splittedTaskFloorModelId = Number(String(action.update.id).split('-'));
        const floorModelId = splittedTaskFloorModelId[0];
        const taskId = splittedTaskFloorModelId[1];
        if (floorModelId === FloorModelEnum.PartitioningFrame) {
          if (action.update.changes.isVisible) {
            return of(WallActions.showPartitioningFrame());
          } else {
            return of(WallActions.hidePartitioningFrame());
          }
        } else if (floorModelId === FloorModelEnum.Rooms) {
          if (action.update.changes.isVisible) {
            return EMPTY;
            // return of(RendererActions.resetSelection());
          } else {
            return of(RendererActions.resetSelection());
          }
        } else {
          return EMPTY;
        }
      }),
    )
  );

  showFloorPlanning$ = createEffect(() => this.actions$.pipe(
    ofType(TaskFloorModelActions.showFloorPlanning),
    switchMap(action => this.store.select(FloorModelSelectors.selectFloorModelChildren(FloorModelEnum.Planning)).pipe(
      switchMap(ids =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModelItems({
                updates: [...ids, FloorModelEnum.Planning].map(id => {
                  return {
                    id: `${id}-${currentFloor.taskId}`,
                    changes: {
                      isVisible: true
                    }
                  };
                }) as Update<TaskFloorModel>[],
              })
            ))
        )),
    )),
    ),
  );

  hideFloorPlanning$ = createEffect(() => this.actions$.pipe(
    ofType(TaskFloorModelActions.hideFloorPlanning),
    switchMap(action => this.store.select(FloorModelSelectors.selectFloorModelChildren(FloorModelEnum.Planning)).pipe(
      switchMap(ids =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModelItems({
                updates: [...ids, FloorModelEnum.Planning].map(id => {
                  return {
                    id: `${id}-${currentFloor.taskId}`,
                    changes: {
                      isVisible: false
                    }
                  };
                }) as Update<TaskFloorModel>[],
              })
            ))
        )),
    )),
    ),
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
