import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { SvgAttributes } from '../../features/svg-factory/models/svg-attributes.model';
import { FLOOR_MODEL, FloorModel } from '../business/floor-model.model';
import { FLOOR_DATA } from '../business/floor-data.model';

export const TASK_FLOOR_MODEL = {
  ...FLOOR_MODEL,
  taskId: FLOOR_DATA.taskId,
};

export class TaskFloorModel extends FloorModel {
  constructor(
    databaseTableName: string,
    id: number,
    parentId: number,
    depth: number,
    name: string,
    displayNameId: number,
    blueprintAttributes: string,
    displayOrder: number,
    scope: number,
    isActive: boolean,
    tableName: string,
    contentTypeId: number,
    //canHaveChildren: boolean,
    readGrantId: number,
    updateGrantId: number,
    public attributes: SvgAttributes,
    public taskId: number,
    public isVisible: boolean) {
    super(databaseTableName,
      id,
      parentId,
      depth,
      name,
      displayNameId,
      blueprintAttributes,
      displayOrder,
      scope,
      isActive,
      tableName,
      contentTypeId,
      //canHaveChildren
      readGrantId,
      updateGrantId
    );
  }

}

@Injectable({
  providedIn: 'root'
})

export class TaskFloorModelAdapter implements Adapter<TaskFloorModel> {
  adapt(item: any): TaskFloorModel {
    // let isVisibe: boolean;
    // if (item[TASK_FLOOR_MODEL.id] === FloorModelEnum.PartitioningFrame
    // || item[TASK_FLOOR_MODEL.id] === FloorModelEnum.FloorCoreContours
    // || item[TASK_FLOOR_MODEL.id] === FloorModelEnum.FloorInsideContours) {
    //   isVisibe = false;
    // } else {
    //   isVisibe = true;
    // }
    const isVisibe = true;
    return new TaskFloorModel(
      FLOOR_MODEL.databaseTableName,
      item[TASK_FLOOR_MODEL.id],
      item[TASK_FLOOR_MODEL.parentId],
      item[TASK_FLOOR_MODEL.depth],
      item[TASK_FLOOR_MODEL.name],
      item[TASK_FLOOR_MODEL.displayNameId],
      item[TASK_FLOOR_MODEL.blueprintAttributes],
      item[TASK_FLOOR_MODEL.displayOrder],
      item[TASK_FLOOR_MODEL.scope],
      item[TASK_FLOOR_MODEL.isActive],
      item[TASK_FLOOR_MODEL.tableName],
      item[TASK_FLOOR_MODEL.contentTypeId],
      //item[TASK_FLOOR_MODEL.canHaveChildren],
      item[TASK_FLOOR_MODEL.readGrantId],
      item[TASK_FLOOR_MODEL.updateGrantId],
      item['attributes'] as SvgAttributes,
      item[TASK_FLOOR_MODEL.taskId],
      isVisibe,
    );
  }

  encode(item: TaskFloorModel): ISingleBackendObj {
    const encoded = {
      TableName: TASK_FLOOR_MODEL.databaseTableName,
      [TASK_FLOOR_MODEL.id]: item.id,
      [TASK_FLOOR_MODEL.parentId]: item.parentId,
      [TASK_FLOOR_MODEL.depth]: item.depth,
      [TASK_FLOOR_MODEL.name]: item.name,
      [TASK_FLOOR_MODEL.displayNameId]: item.displayNameId,
      [TASK_FLOOR_MODEL.blueprintAttributes]: item.blueprintAttributes,
      [TASK_FLOOR_MODEL.displayOrder]: item.displayOrder,
      [TASK_FLOOR_MODEL.scope]: item.scope,
      [TASK_FLOOR_MODEL.isActive]: item.isActive,
      [TASK_FLOOR_MODEL.tableName]: item.tableName,
      [TASK_FLOOR_MODEL.contentTypeId]: item.contentTypeId,
      //[TASK_FLOOR_MODEL.canHaveChildren]: item.canHaveChildren,
      [TASK_FLOOR_MODEL.readGrantId]: item.readGrantId,
      [TASK_FLOOR_MODEL.updateGrantId]: item.updateGrantId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
