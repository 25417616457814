import { createAction, props } from '@ngrx/store';
import { BusinessUnit } from '../../../domain-models/business/business-unit.model';

export const beginGetAllBusinessUnits = createAction('[Shared/API] - Begin Get All BusinessUnits');

export const successGetAllBusinessUnits = createAction(
  '[API] - Success Get All BusinessUnits',
  props<{ businessUnits: BusinessUnit[] }>()
);

export const errorGetAllBusinessUnits = createAction('[Shared/API] - Error Get All BusinessUnits', props<Error>());
