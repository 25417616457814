<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.documentVersionIndex}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef> Créé </th>
    <td mat-cell *matCellDef="let element"> {{element.documentVersionCreationDate | date}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="memo">
    <th mat-header-cell *matHeaderCellDef> Memo </th>
    <td mat-cell *matCellDef="let element"> {{element.documentVersionMemo}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="fileName">
    <th mat-header-cell *matHeaderCellDef> Nom du fichier </th>
    <td mat-cell *matCellDef="let element"> {{element.documentFileName}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef style='text-align: end;'>
      <button mat-icon-button aria-label="Download file" class='events-all'
              matTooltip='Ajouter une version plus récente'
              matTooltipPosition='before'
              (click)='openUploadDialog($event)'>
        <mat-icon>upload_file</mat-icon>
      </button>
      <button mat-icon-button
              [disabled]='selection.selected.length < 1'
              matTooltip='Documents selectionnés'
              matTooltipPosition='before'
              [matMenuTriggerFor]="menu" aria-label="Actions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item disabled>
          <span>Télécharger</span>
        </button>
        <button mat-menu-item>
          <span>Supprimer</span>
        </button>
      </mat-menu>
    </th>
    <td mat-cell *matCellDef="let row" class='actions'>
      <button mat-icon-button aria-label="Preview" class='events-all'
              (click)='openFilePreviewDialog(row, $event)'
              matTooltip='Prévisualiser'
              matTooltipPosition='before'>
        <mat-icon>find_in_page</mat-icon>
      </button>
      <button mat-icon-button aria-label="Download file" class='events-all'
              (click)='downloadFile(row, $event)'
              matTooltip='Télécharger'
              matTooltipPosition='before'>
        <mat-icon>file_download</mat-icon>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="selection.toggle(row)">
  </tr>
</table>
