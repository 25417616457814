import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export const ROOM_LAYOUT_TYPE = {
  databaseTableName: 'RoomLayoutType',
  id: 'RoLaTy_Id',
  code: 'RoLaTy_Code',
  depth: 'RoLaTy_Depth',
  parentId: 'RoLaTy_ParentId',
  isActive: 'RoLaTy_IsActive',
  name: 'RoLaTy_Name',
  color: 'RoLaTy_Color',
  scopeId: 'RoLaTy_ScopeId',
  isNUA: 'RoLaTy_IsNUA',
  canHaveChildren: 'RoLaTy_CanHaveChildren'
};

export class RoomLayoutType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public depth: number,
    public parentId: number,
    public isActive: boolean,
    public name: string,
    public color: Color,
    public scopeId: number,
    public isNUA: boolean,
    public canHaveChildren: boolean
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class RoomLayoutTypeAdapter implements Adapter<RoomLayoutType> {
  adapt(item: any): RoomLayoutType {
    return new RoomLayoutType(
      ROOM_LAYOUT_TYPE.databaseTableName,
      item[ROOM_LAYOUT_TYPE.id],
      item[ROOM_LAYOUT_TYPE.code],
      item[ROOM_LAYOUT_TYPE.depth],
      item[ROOM_LAYOUT_TYPE.parentId],
      item[ROOM_LAYOUT_TYPE.isActive],
      item[ROOM_LAYOUT_TYPE.name],
    ColorUtils.colorFromHexARGB(item[ROOM_LAYOUT_TYPE.color]),
      item[ROOM_LAYOUT_TYPE.scopeId],
      item[ROOM_LAYOUT_TYPE.isNUA],
      item[ROOM_LAYOUT_TYPE.canHaveChildren],
    );
  }

  encode(item: RoomLayoutType): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [ROOM_LAYOUT_TYPE.id]: item.id,
      [ROOM_LAYOUT_TYPE.code]: item.code,
      [ROOM_LAYOUT_TYPE.depth]: item.depth,
      [ROOM_LAYOUT_TYPE.parentId]: item.parentId,
      [ROOM_LAYOUT_TYPE.isActive]: item.isActive,
      [ROOM_LAYOUT_TYPE.name]: item.name,
      [ROOM_LAYOUT_TYPE.color]: item.color.toHexArgb(),
      [ROOM_LAYOUT_TYPE.scopeId]: item.scopeId,
      [ROOM_LAYOUT_TYPE.isNUA]: item.isNUA,
      [ROOM_LAYOUT_TYPE.isNUA]: item.canHaveChildren
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
