<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content class='mat-typography'>
  <ng-container *ngIf='data.message; then msgTpl; else defaultMsgTpl'></ng-container>
  <ng-template #msgTpl><p>{{data.message}}</p></ng-template>
  <ng-template #defaultMsgTpl><p>Êtes-vous sûr ?</p></ng-template>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <ng-container *ngIf='data?.cancelBtn?.color || cancelBtnWithoutColor; then customColoredCancelBtnTpl; else cancelBtnTpl'
                [ngTemplateOutlet]='customColoredCancelBtnTpl'
                [ngTemplateOutletContext]="{cancelBtn: data.cancelBtn}"></ng-container>
  <ng-template #customColoredCancelBtnTpl let-cancelBtn>
    <button *ngIf='cancelBtn.color' mat-button (click)="onNoClick()" [color]="cancelBtn.color">{{ cancelBtnText }}</button>
    <button *ngIf='cancelBtnWithoutColor' mat-button (click)="onNoClick()">{{ cancelBtnText }}</button>
  </ng-template>
  <ng-template #cancelBtnTpl>
    <button mat-button (click)="onNoClick()" color='warn'>{{ cancelBtnText }}</button>
  </ng-template>
  <button mat-button cdkFocusInitial (click)='onSubmit()'>{{ submitBtnText }}</button>
</mat-dialog-actions>
