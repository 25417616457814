import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const DOCUMENT_DATA_MODEL = {
  databaseTableName: 'document_data_model',
  id: 'DoDaMo_Id',
  tableName: 'DoDaMo_TableName',
};

export class DocumentDataModelModel {
  constructor(
    public databaseTableName: string,
    public id: number,
    public tableName: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class DocumentDataModelAdapter implements Adapter<DocumentDataModelModel> {
  adapt(item: any): DocumentDataModelModel {
    return new DocumentDataModelModel (
      DOCUMENT_DATA_MODEL.databaseTableName,
      item[DOCUMENT_DATA_MODEL.id],
      item[DOCUMENT_DATA_MODEL.tableName],
    );
  }
  encode(item: DocumentDataModelModel): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DOCUMENT_DATA_MODEL.id]: item.id,
      [DOCUMENT_DATA_MODEL.tableName]: item.tableName,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
