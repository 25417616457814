import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../models/menu-item.model';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_ENDPOINTS } from '../../shared/api-endpoints';
import { AppMenu, AppMenuAdapter } from '../../domain-models/core/z-app-menu.model';
import { ApiData } from '../models/api-data.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { SnackBarService } from './snack-bar.service';
import { LoaderService } from './loader.service';
import { LanguageService } from '../../shared/services/language.service';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { LanguageItemSelectors } from '../../store/shared-store/selectors';
import { UsageContextIdEnum } from '../../shared/models/usage-context-id.enum';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends ApiService {
  public currentPage$ = new BehaviorSubject<any>('');
  public itemToDisplay$ = new BehaviorSubject<any>(null);

  //menuItemsFlat$: Observable<AppMenu[]>;

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private store: Store<State>,
              private appMenuAdapter: AppMenuAdapter,
              private languageService: LanguageService,
  ) {
    super(http, loggerService, snackBarService, httpLoaderService);
    // this.menuItemsFlat$ = this.get(`${API_ENDPOINTS.dynT}${Z_APP_MENU.databaseTableName}`).pipe(
    //   map(data => data.payload.map(e => this.appMenuAdapter.adapt(e)))
    // );
  }

  public loadMenu() {
    let httpParams = new HttpParams();
    if (environment.envName === 'localhost') {
      httpParams = httpParams.append('granted', false);
    }
    this.get(API_ENDPOINTS.appMenus, UsageContextIdEnum.none, httpParams)
      .pipe(
        switchMap((data: ApiData) => this.store.select(LanguageItemSelectors.selectLanguageItemsTotal).pipe(
          filter(languageItemsTotal => languageItemsTotal > 0),
          map(() => this.itemToDisplay$.next(this.buildMenu(data))),
        )),
      )
      .subscribe();
  }

  buildMenu(data: ApiData): MenuItem[] {
    const menu: MenuItem[] = [];
    const appMenuItems = data.payload.map(i => {
      return {
        appMenu: this.appMenuAdapter.adapt(i),
        children: i['Children']
      };
    });
    appMenuItems.forEach(e => {
      menu.push(this.addMenuItem(e));
    });
    menu.sort((a: MenuItem, b: MenuItem) => a.orderIndex - b.orderIndex);
    return menu;
  }

  addMenuItem(item: { appMenu: AppMenu, children: any[] }): MenuItem {
    const menuItem = new MenuItem(item.appMenu);
    menuItem.label = this.languageService.searchDisplayName(menuItem.displayNameId);
    if (item.children) {
      const subItems: MenuItem[] = [];
      item.children.forEach(e => {
        const subItem = new MenuItem(this.appMenuAdapter.adapt(e));
        subItem.label = this.languageService.searchDisplayName(subItem.displayNameId);
        subItems.push(subItem);
      });
      menuItem.items = subItems;
      menuItem.items.sort((a: MenuItem, b: MenuItem) => a.orderIndex - b.orderIndex);
    }
    return menuItem;
  }

}
