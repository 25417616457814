import { createAction, props } from '@ngrx/store';
import { EcSyFile } from '../../../domain-models/models/EcSyFile.model';
import { UserIdentity } from '../../../domain-models/business-extended/user.model';
import { AppUser } from '../../../domain-models/business/app-user.model';

/**
 * Client
 */

export const checkClientFiles = createAction('[Core] - Check Client Files');
export const releaseTicket = createAction('[Core] - Release Client Ticket');
export const setClient = createAction(
  '[Core] - Set Client',
  props<{ client: EcSyFile, navigateToIndex?: boolean }>()
);

export const setClientFile = createAction(
  '[Core] - Set Client File',
  props<{ client: EcSyFile}>()
);

export const clearClient = createAction('[Core] - Clear Client File');

/**
 * User
 **/
export const setUser = createAction('[Core] - Set User', props<{ user: UserIdentity }>());
export const setAppUser = createAction('[Core] - Set AppUser', props<{ appUser: AppUser }>());

/**
 * General
 */

export const setCallerRequestId = createAction('[Core] - Set Caller Request Id', props<{ callerRequestId: string }>());
