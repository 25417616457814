import { createAction, props } from '@ngrx/store';
import { Workplace } from '../../../domain-models/business/workplace.model';
import { EntityMap, Predicate, Update } from '@ngrx/entity';

/**
 * CRUD Actions
 */

export const loadWorkplaces = createAction('[Workplace/API] Load Workplaces', props<{ workplaces: Workplace[] }>());
export const addWorkplace = createAction('[Workplace/API] Add Workplace', props<{ workplace: Workplace }>());
export const setWorkplace = createAction('[Workplace/API] Set Workplace', props<{ workplace: Workplace }>());
export const upsertWorkplace = createAction('[Workplace/API] Upsert Workplace', props<{ workplace: Workplace }>());
export const addWorkplaces = createAction('[Workplace/API] Add Workplaces', props<{ workplaces: Workplace[] }>());
export const upsertWorkplaces = createAction('[Workplace/API] Upsert Workplaces', props<{ workplaces: Workplace[] }>());
export const updateWorkplace = createAction('[Workplace/API] Update Workplace', props<{ update: Update<Workplace> }>());
export const updateWorkplaces = createAction('[Workplace/API] Update Workplaces', props<{ updates: Update<Workplace>[] }>());
export const mapWorkplaces = createAction('[Workplace/API] Map Workplaces', props<{ entityMap: EntityMap<Workplace> }>());
export const deleteWorkplace = createAction('[Workplace/API] Delete Workplace', props<{ id: string }>());
export const deleteWorkplaces = createAction('[Workplace/API] Delete Workplaces', props<{ ids: string[] }>());
export const deleteWorkplacesByPredicate = createAction('[Workplace/API] Delete Workplaces By Predicate', props<{ predicate: Predicate<Workplace> }>());
export const clearWorkplaces = createAction('[Workplace/API] Clear Workplaces');

/** END of CRUD **/

export const updateSelectedWorkplaceId = createAction(
  '[API] - Save Selected Workplace Id',
  props<{ id: number }>()
);

export const beginGetWorkplacesByTaskId = createAction(
  '[API] - Begin All Workplaces By taskId',
  props<{ id: number }>()
);

export const beginGetCurrentFloorWorkplaces = createAction('[API] - Get Current Floor Workplaces');

export const errorGetAllWorkplaces = createAction('[API] - Error Get All Workplaces', props<Error>());

export const addToSelection = createAction(
  '[Workplace] - Add Workplace Id to selection',
  props<{ id: number, clearSelection?: boolean }>()
);

export const removeFromSelection = createAction(
  '[Workplace] - Remove Workplace Id to selection',
  props<{ id: number }>()
);

export const updateSelection = createAction(
  '[Workplace] - Update Workplace Selection Ids',
  props<{ addedId: number, removedId: number }>()
);

export const clearSelection = createAction('[Workplace] - Clear Workplace Selection');


export const deleteWorkplaceFromFloorMap = createAction(
  '[Floor/WorkplaceOverlay] - Delete Workplace',
  props<{ id: number }>(),
);

export const deleteWorkplacesFromFloorMap = createAction(
  '[Floor/WorkplaceOverlay] - Delete Workplaces',
  props<{ ids: number[] }>(),
);

export const updateWorkplaceLabel = createAction(
  '[Floor/WorkplaceOverlay] - Update Workplace Label',
  props<{ id: number, code: string }>(),
);

export const updateWorkplaceType = createAction(
  '[Floor/WorkplaceOverlay] - Update Workplace Type',
  props<{ id: number, typeId: number }>(),
);

export const updateWorkplacesType = createAction(
  '[Floor/WorkplaceOverlay] - Update Workplaces Type',
  props<{ ids: number[], typeId: number }>(),
);
