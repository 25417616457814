import { createAction, props } from '@ngrx/store';
import { SvgPoint } from '../../../features/svg-factory/models/svg-point.model';

export const translateDoor = createAction(
  '[Floor/API] Translate door (SvgEntity)',
  props<{ id:  number, point: SvgPoint }>()
);

export const updateDoorStyle = createAction(
  '[Floor/DoorForm] - Update door style',
  props<{ id: number, doorStyleId: number }>(),
);

export const updateDoorsStyle = createAction(
  '[Floor/DoorForm] - Update doors style',
  props<{ ids: number[], doorStyleId: number }>(),
);

export const updateDoorWidth = createAction(
  '[Floor/DoorForm] - Update door width',
  props<{ id: number, width: number }>(),
);

export const updateDoorsWidth = createAction(
  '[Floor/DoorForm] - Update doors width',
  props<{ ids: number[], width: number }>(),
);

export const updateDoorOpeningAngle = createAction(
  '[Floor/DoorForm] - Update door opening angle',
  props<{ id: number, openingAngle: number }>(),
);

export const updateDoorsOpeningAngle = createAction(
  '[Floor/DoorForm] - Update doors opening angle',
  props<{ ids: number[], openingAngle: number }>(),
);

export const updateDoorOpeningDirection = createAction(
  '[Floor/DoorForm] - Update door opening direciton',
  props<{ id: number, openingDirection: number }>(),
);

export const updateDoorsOpeningDirection = createAction(
  '[Floor/DoorForm] - Update doors opening direciton',
  props<{ ids: number[], openingDirection: number }>(),
);

export const updateDoorWallSideOrientation = createAction(
  '[Floor/DoorForm] - Update door wall side orientation',
  props<{ id: number, wallSideOrientation: number }>(),
);

export const updateDoorsWallSideOrientation = createAction(
  '[Floor/DoorForm] - Update doors opening wall side orientation',
  props<{ ids: number[], wallSideOrientation: number }>(),
);

export const deleteDoor = createAction(
  '[Floor/DoorOverlay] - Delete door',
  props<{ id: number }>(),
);

export const deleteDoors = createAction(
  '[Floor/DoorOverlay] - Delete doors',
  props<{ ids: number[] }>(),
);
