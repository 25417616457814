import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as PerimetersActions from '../actions/perimeters.actions';
import { Perimeter } from '../../../domain-models/business/perimeter.model';

export const perimetersFeatureKey = 'perimeters';

export interface State extends EntityState<Perimeter> {}

export const adapter: EntityAdapter<Perimeter> = createEntityAdapter<Perimeter>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(PerimetersActions.successGetAllPerimeters, (state, {perimeters}) => {
    return adapter.addMany(perimeters, state);
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectPerimetersIds = selectIds;
export const selectPerimetersEntities = selectEntities;
export const selectAllPerimeters = selectAll;
export const selectPerimetersTotal = selectTotal;
