import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Utils } from '../../shared/utils';

export const TASK = {
  databaseTableName: 'task',
  id: 'Ta_Id',
  name: 'Ta_Name',
  description: 'Ta_Description',
  parentId: 'Ta_ParentId',
  depth: 'Ta_Depth',
  startDate: 'Ta_StartDate',
  duration: 'Ta_Duration',
  isTemplate: 'Ta_IsTemplate',
  load: 'Ta_Load',
  statusId: 'Ta_StatusId',
  fulfilled: 'Ta_Fulfilled',
  typeId: 'Ta_TypeId',
  rootTaskId: 'Ta_RootTaskId',
  businessId: 'Ta_BusinessId',
  businessTypeId: 'Ta_BusinessTypeId',
  canHaveChildren: 'Ta_CanHaveChildren',

};

export class Task {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public description: string,
    public parentId: number,
    public depth: number,
    public startDate: Date,
    public duration: number,
    public isTemplate: boolean,
    public load: number,
    public statusId: number,
    public fulfilled: number,
    public typeId: number,
    public rootTaskId: number,
    public businessId: number,
    public businessTypeId: number,
    public canHaveChildren: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskAdapter implements Adapter<Task> {
  adapt(item: any): Task {
    return new Task(
      TASK.databaseTableName,
      item[TASK.id],
      item[TASK.name],
      item[TASK.description],
      item[TASK.parentId],
      item[TASK.depth],
      new Date(item[TASK.startDate]),
      item[TASK.duration],
      item[TASK.isTemplate],
      item[TASK.load],
      item[TASK.statusId],
      item[TASK.fulfilled],
      item[TASK.typeId],
      item[TASK.rootTaskId],
      item[TASK.businessId],
      item[TASK.businessTypeId],
      item[TASK.canHaveChildren]
    );
  }

  encode(item: Task | Partial<Task>): ISingleBackendObj {
    const encoded = {
      TableName: TASK.databaseTableName,
      [TASK.id]: item.id,
      [TASK.name]: item.name,
      [TASK.description]: item.description,
      [TASK.parentId]: item.parentId,
      [TASK.depth]: item.depth,
      //[TASK.startDate]: item.startDate?.toISOString(),
      [TASK.startDate]: Utils.dateToBackendString(item.startDate),
      [TASK.duration]: item.duration,
      [TASK.isTemplate]: item.isTemplate,
      [TASK.load]: item.load,
      [TASK.statusId]: item.statusId,
      [TASK.fulfilled]: item.fulfilled,
      [TASK.typeId]: item.typeId,
      [TASK.rootTaskId]: item.rootTaskId,
      [TASK.businessId]: item.businessId,
      [TASK.businessTypeId]: item.businessTypeId,
      [TASK.canHaveChildren]: item.canHaveChildren,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
