import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'echo-mat-input-tree',
  templateUrl: './mat-input-tree.component.html',
  styleUrls: ['./mat-input-tree.component.scss'],
})
export class MatInputTreeComponent implements OnInit, AfterViewInit, OnChanges {

  isOpen = false;
  initialItem: any;
  formControl = new UntypedFormControl();

  @Input() tableName: string;
  @Input() label: string;
  @Input() placeHolder: string;
  //@Input() initialItem: any; // Any object coming from the backend under the apprpriate "domain model" structure
  @Input() _formControl: AbstractControl | UntypedFormControl;
  @Output() selectedNode = new EventEmitter<any>();

  //selectedNode: any;

  constructor(private _viewContainerRef: ViewContainerRef,
              private languageService: LanguageService
  ) {
    if(!this._formControl) { //if formControl is not provided
      this._formControl = new UntypedFormControl();
    }
    if(!this.placeHolder) {
      this.placeHolder = this.label;
    }
  }


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.updateFormControlStatus(this._formControl.status);
    this.formControl.patchValue(this.languageService.searchDisplayNameInItem(this._formControl.value));

    if(this._formControl.disabled) {
      this.formControl.disable();
    }

    if(this._formControl.enabled) {
      this.formControl.enable();
    }

    this._formControl.statusChanges.pipe(
      map(status => {
        this.updateFormControlStatus(status);
      })
    ).subscribe();

    this._formControl.valueChanges.pipe(
      // tap(a => console.log(a)),
      filter(x => x !== undefined),
      map(item => {
        if (item) {
          console.log(item);
          this.formControl.patchValue(this.languageService.searchDisplayNameInItem(item));
        } else {
          this.formControl.patchValue(null);
        }
      })
    ).subscribe();

    //this.formControl.markAsPristine();


  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.initialItem
    //   && changes.initialItem.currentValue) {
    //   this.formControl.setValue(this.languageService.searchDisplayNameInItem(this.initialItem));
    // }
  }

  onBackdropClick(event) {
    this.isOpen = false;
  }

  onSelectedNodeChange(item: any) {
    // Wether item is not null
    // console.log(item);
    if (item) {
      this._formControl.setValue(item.node);
      this.selectedNode.next(item.node);
    } else {
      //this._formControl.setValue(undefined);
      //this.updateFormControlStatus(this._formControl.status);
    }
  }

  updateFormControlStatus(status: string) {
    this.formControl.clearValidators();
    this.formControl.enable();
    this.formControl.updateValueAndValidity();
    switch (status) {
      case 'DISABLED':
        this.formControl.disable();
        break;
      case 'VALID':
        this.formControl.setErrors(null);
        break;
      case 'INVALID':
        console.log(status);
        this.formControl.setValidators(this._formControl.validator);
        this.formControl.updateValueAndValidity();
        break;
      default:
        this.formControl.enable();
        break;
    }
  }

}
