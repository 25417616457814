import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { ApiData } from '../../core/models/api-data.model';
import { LoggerService } from '../../core/services/logger.service';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { LoaderService } from '../../core/services/loader.service';

const url = 'http://localhost:8000/upload';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends ApiService {

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
  ) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  public upload(files: Set<File>, uploadUrl: string, fields?: any, httpParams?: HttpParams):
    { [key: string]: { progress: Observable<number>, response: Observable<ApiData> } } {
    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number>, response: Observable<ApiData> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      if (fields) {
        formData.append('jsonString', JSON.stringify(fields));
      }

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', uploadUrl, formData, {
        reportProgress: true
      });

      if (httpParams) {
        Object.assign(req.params, httpParams);
      }

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // create a new response-subject for every file
      const response = new Subject<ApiData>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          const data = event.body as ApiData;
          response.next(data);
          if (event.ok) {
            // message could be null
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.snackBarService.displayError(data.message);
              }
            }
          } else {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.snackBarService.displayError(data.message);
              }
            }
          }

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          response.complete();
          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
        response: response.asObservable(),
      };
    });

    // return the map of progress.observables
    return status;
  }
}
