<mat-accordion displayMode="flat">
  <mat-expansion-panel *ngFor="let item of menuItems; let i = index" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ item.label }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list *ngIf="item.items">
      <mat-list-item *ngFor="let subItem of item['items']" (click)="onMenuItemClick(subItem)">
        {{ subItem.label }}
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-accordion>
