import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as FloorModelsActions from '../actions/task-floor-model.actions';
import { TaskFloorModel } from '../../../domain-models/business-extended/task-floor-model.model';

export const taskFloorModelsFeatureKey = 'taskFloorModels';

export interface State extends EntityState<TaskFloorModel> {
  // additional entities state properties
  // selectedFloorId: number | null;
  // selectedDocumentVersionId: number | null;
}

export function generateId(a: TaskFloorModel): string {
  return `${a.id}-${a.taskId}`;
}

export function sortByDisplayOrder(a: TaskFloorModel, b: TaskFloorModel): number {
  if (a.displayOrder > b.displayOrder) {
    return 1;
  }
  if (a.displayOrder < b.displayOrder) {
    return -1;
  }
  return 0;
}

export const adapter: EntityAdapter<TaskFloorModel> = createEntityAdapter<TaskFloorModel>({
  selectId: generateId,
  sortComparer: sortByDisplayOrder
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(FloorModelsActions.addTaskFloorModel, (state, {taskFloorModel}) => {
    return adapter.addOne(taskFloorModel, state);
  }),
  on(FloorModelsActions.addTaskFloorModels, (state, {taskFloorModels}) => {
    return adapter.addMany(taskFloorModels, state);
  }),
  on(FloorModelsActions.removeTaskFloorModelItems, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(FloorModelsActions.successGetAllTaskFloorModels, (state, {taskFloorModels}) => {
    return adapter.addMany(taskFloorModels, state);
  }),
  on(FloorModelsActions.clearTaskFloorModels, state => {
    return adapter.removeAll({...state});
  }),
  on(FloorModelsActions.updateTaskFloorModel, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(FloorModelsActions.updateTaskFloorModelItems, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  })
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorModelsIds = selectIds;
export const selectFloorModelsEntities = selectEntities;
export const selectAllFloorModels = selectAll;
export const selectFloorModelsTotal = selectTotal;
