import { createSelector } from '@ngrx/store';
import * as fromBusinessUnits from '../reducers/business-unit.reducer';
import { selectSharedState } from '../reducers';

export const selectBusinessUnits = createSelector(
  selectSharedState,
  state => state[fromBusinessUnits.businessUnitsFeatureKey]
);

export const selectBusinessUnitsIds = createSelector(
  selectBusinessUnits,
  fromBusinessUnits.selectBusinessUnitsIds
);
export const selectBusinessUnitsEntities = createSelector(
  selectBusinessUnits,
  fromBusinessUnits.selectBusinessUnitsEntities
);
export const selectAllBusinessUnits = createSelector(
  selectBusinessUnits,
  fromBusinessUnits.selectAllBusinessUnits
);
export const selectBusinessUnitsTotal = createSelector(
  selectBusinessUnits,
  fromBusinessUnits.selectBusinessUnitsTotal
);

export const selectBusinessUnitsById = (id: number) => createSelector(
  selectBusinessUnitsEntities,
  (entities) => entities[id]
);

export const selectBusinessUnitsByIds = (ids: number[]) => createSelector(
  selectBusinessUnitsEntities,
  (entities) => ids.map(id => entities[id])
);
