import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSvg from '../svg-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { GraphicsFloorEffects } from './effects/graphics-floor.effects';
import { RendererEffects } from './effects/renderer.effects';
import { FloorDataEffects } from './effects/floor-data.effects';
import { TaskFloorModelEffects } from './effects/task-floor-model.effects';
import { GraphicsBuildingEffects } from './effects/graphics-building.effects';
import { FloorCatalogEffects } from './effects/floor-catalog.effects';
import { FloorUsesEffects } from './effects/floor-uses.effects';
import { WallEffects } from './effects/wall.effects';
import { DoorEffects } from './effects/door.effects';
import { EquipmentEffects } from './effects/equipment.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSvg.svgFeatureKey, fromSvg.reducers),
    EffectsModule.forFeature([GraphicsFloorEffects,
      FloorUsesEffects,
      GraphicsBuildingEffects,
      RendererEffects,
      FloorCatalogEffects,
      FloorDataEffects,
      TaskFloorModelEffects,
      WallEffects,
      DoorEffects,
      EquipmentEffects,
    ]),
  ]
})
export class SvgStoreModule {
}
