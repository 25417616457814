import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const SITE = {
  databaseTableName: 'site',
  id: 'St_Id',
  parentId: 'St_ParentId',
  depth: 'St_Depth',
  code: 'St_Code',
  name: 'St_Name',
  isActive: 'St_IsActive',
  isFictive: 'St_IsFictive',
  location: 'St_Location',
  defaultPerimeterId: 'St_DefaultPerimeterId',
  canHaveChildren: 'St_CanHaveChildren'
};

export class Site {
  constructor(
    public databaseTableName: string,
    public id: number,
    public parentId: number,
    public depth: number,
    public code: string,
    public name: string,
    public isActive: boolean,
    public isFictive: boolean,
    public location: string,
    public defaultPerimeterId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class SiteAdapter implements Adapter<Site> {
  adapt(item: any): Site {
    return new Site (
      SITE.databaseTableName,
      item[SITE.id],
      item[SITE.parentId],
      item[SITE.depth],
      item[SITE.code],
      item[SITE.name],
      item[SITE.isActive],
      item[SITE.isFictive],
      item[SITE.location],
      item[SITE.defaultPerimeterId],
    );
  }
  encode(item: Site): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [SITE.id]: item.id,
      [SITE.parentId]: item.parentId,
      [SITE.depth]: item.depth,
      [SITE.code]: item.code,
      [SITE.name]: item.name,
      [SITE.isActive]: item.isActive,
      [SITE.isFictive]: item.isFictive,
      [SITE.location]: item.location,
      [SITE.defaultPerimeterId]: item.defaultPerimeterId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
