import { ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SelectableTreeComponent } from '../../features/trees/components/selectable-tree/selectable-tree.component';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[echoMatInputTree]',
})
export class MatInputTreeDirective implements OnInit {

  @Input() tableName: string;
  @Input() existingNode: any;
  @Input() disabled: boolean;
  @Output() selectedNode$ = new EventEmitter<any>();

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private scrollStrategyOptions: ScrollStrategyOptions,
              private elementRef: ElementRef) { }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: -16,
      }]);

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'mat-input-tree-pane',
      positionStrategy,
      scrollStrategy: this.scrollStrategyOptions.noop(),
      minWidth: `${this.elementRef.nativeElement.clientWidth}px`,
      maxHeight: `256px`
    });
  }

  @HostListener('mouseup')
  show() {
    if(!this.disabled) {
      const treeRef: ComponentRef<SelectableTreeComponent>
        = this.overlayRef.attach(new ComponentPortal(SelectableTreeComponent));

      treeRef.instance.selectedNode$.pipe(
        map(item => this.selectedNode$.emit(item))
      ).subscribe(() => this.overlayRef.detach());

      treeRef.instance.tableName = this.tableName;
      treeRef.instance.expandNode = this.existingNode;

      this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    }
  }

  // Listen on keydown events
  @HostListener('keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.stopImmediatePropagation();
      this.overlayRef.detach();
    }
  }

}
