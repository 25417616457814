import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskLinkActions } from '../actions';
import { TaskLink } from '../../../domain-models/business/task-link.model';

export const taskLinkFeatureKey = 'taskLinks';

export interface State extends EntityState<TaskLink> {
  selectedTaskLinkId: number | null;
}

export const adapter: EntityAdapter<TaskLink> = createEntityAdapter<TaskLink>();

export const initialState: State = adapter.getInitialState({
  selectedTaskLinkId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskLinkActions.addTaskLink, (state, { taskLink }) => {
    return adapter.addOne(taskLink, state);
  }),
  on(TaskLinkActions.setTaskLink, (state, { taskLink }) => {
    return adapter.setOne(taskLink, state);
  }),
  on(TaskLinkActions.upsertTaskLink, (state, { taskLink }) => {
    return adapter.upsertOne(taskLink, state);
  }),
  on(TaskLinkActions.addTaskLinks, (state, { taskLinks }) => {
    return adapter.addMany(taskLinks, state);
  }),
  on(TaskLinkActions.upsertTaskLinks, (state, { taskLinks }) => {
    return adapter.upsertMany(taskLinks, state);
  }),
  on(TaskLinkActions.updateTaskLink, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskLinkActions.updateTaskLinks, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskLinkActions.mapTaskLinks, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskLinkActions.deleteTaskLink, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskLinkActions.deleteTaskLinks, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskLinkActions.deleteTaskLinksByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskLinkActions.loadTaskLinks, (state, { taskLinks }) => {
    return adapter.setAll(taskLinks, state);
  }),
  on(TaskLinkActions.clearTaskLinks, state => {
    return adapter.removeAll({ ...state, selectedTaskLinkId: null });
  }),
  /** END OF CRUD **/
  on(TaskLinkActions.updateSelectedTaskLinkId, (state, {taskLinkId}) => {
    return ({...state, selectedTaskLinkId: taskLinkId});
  }),
);

export const getSelectedTaskLinkId = (state: State) => state.selectedTaskLinkId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskLink ids
export const selectTaskLinkIds = selectIds;

// select the dictionary of taskLink entities
export const selectTaskLinkEntities = selectEntities;

// select the array of taskLinks
export const selectAllTaskLinks = selectAll;

// select the total taskLink count
export const selectTaskLinkTotal = selectTotal;
