import { createAction, props } from '@ngrx/store';
import { FloorDoorStyle } from '../../../domain-models/business/floor-door-style.model';

export const beginGetAllFloorDoorStyles = createAction('[Shared/API] - Begin Get All FloorDoorStyles');

export const addFloorDoorStyles = createAction(
  '[API] - Add FloorDoorStyles',
  props<{ floorDoorStyles: FloorDoorStyle[] }>()
);

export const successGetAllFloorDoorStyles = createAction('[API] - Success Get All FloorDoorStyles');
export const errorGetAllFloorDoorStyles = createAction('[Shared/API] - Error Get All FloorDoorStyles', props<Error>());
