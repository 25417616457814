import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, first, mergeMap, switchMap } from 'rxjs/operators';

import { FloorService } from '../../../features/floor/services/floor.service';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { EMPTY, of, zip } from 'rxjs';
import { FloorDataActions, GraphicsFloorActions, RendererActions, TaskFloorModelActions, UsesActions } from '../../svg-store/actions';
import { FloorSelectors, UiContextSelectors } from '../selectors';
import { RoomActions, UiContextActions } from '../actions';
import { deepCopy } from '../../../shared/utils';
import { TaskActions, TaskContributorActions, TaskFloorActions, TaskValidationActions } from '../../project-management/actions';
import { TaskSelectors } from '../../project-management/selectors';
import { DisplayThemeItemActions } from '../../shared-store/actions';
import { DisplayThemeEnum } from '../../../domain-models/business/display-theme.model';
import { TaskBusinessTypeEnum } from '../../../domain-models/business/task-business.model';
import { SvgUse } from '../../../features/svg-factory/models/svg-use.model';

@Injectable()
export class UiContextEffects {

  beginRoomCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.beginRoomCreation),
      switchMap((action) =>
        /** Reset any previously selected item if room creation has started **/
        of(RendererActions.resetSelection())
      ),
      catchError(err => of(err)),
    ),
  );

  cancelRoomCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.cancelRoomCreation),
      switchMap((action) => of(RendererActions.clearRoomContour())),
      // catchError(err => of(err)),
    ),
  );

  createRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.createRoom),
      switchMap((action) => this.floorService.createRoom(action).pipe(
        concatMap(data => [
          /** Reload TaskFloorData for latest updated data **/
          GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: action.taskId}),
          UiContextActions.cancelRoomCreation(),
          /** Reset flags **/
          // UiContextActions.isSelectingContour({selecting: false}),
          // UiContextActions.beginRoomCreation({isCreatingRoom: false}),
          // RendererActions.clearRoomContour()
        ]),
        // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorById(err)))
        catchError(err => of(UiContextActions.cancelRoomCreation())),
      )),
    ),
  );

  recalculateRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.recalculateRoom),
      switchMap((action) => this.floorService.reCalculateRoom(action.roomId, action.props).pipe(
        //withLatestFrom(),
        switchMap(data => this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
          first(),
          switchMap(currentTaskId => [
            /** Reload TaskFloorData for latest updated data **/
            RoomActions.upsertRoom({room: data.room}),
            FloorDataActions.upsertFloorDataItems({svgGroups: data.svgGroups}),
            //GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: currentTaskId}),
            UiContextActions.cancelRoomRecalculateArea(),
          ]),
        )),
        // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorById(err)))
        catchError(err => of(UiContextActions.cancelRoomRecalculateArea())),
      )),
    ),
  );

  // isVerifyingSpacePlanningImport$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UiContextActions.isVerifyingSpacePlanningImport),
  //     switchMap((action) => {
  //       if (action.verifying) {
  //         return this.store.select(TaskSelectors.selectCurrentTask).pipe(
  //           switchMap(currentTask => {
  //             console.log(currentTask);
  //             if (currentTask.businessTypeId === TaskBusinessTypeEnum.SpacePlanningImport) {
  //               return this.store.select(TaskSelectors.selectSpacePlanningImportTaskSourceTaskByTaskId(currentTask.id)).pipe(
  //                 switchMap(t => {
  //                   console.log(t);
  //                   if (t) {
  //                     return [
  //                       TaskFloorModelActions.hideFloorPlanning(),
  //                       DisplayThemeItemActions.updateDisplayThemeId({id: DisplayThemeEnum.ImportReconcile}),
  //                       UiContextActions.deleteDisplayedTaskVisibility({id: t.id})
  //                     ];
  //                   } else {
  //                     return EMPTY;
  //                   }
  //                 })
  //               );
  //             }
  //           })
  //         );
  //       } else {
  //         return [
  //           TaskFloorModelActions.showFloorPlanning(),
  //           DisplayThemeItemActions.updateDisplayThemeId({id: DisplayThemeEnum.LayoutType}),
  //         ];
  //       }
  //     }),
  //   ),
  // );

  isVerifyingSpacePlanningImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.isVerifyingSpacePlanningImport),
      switchMap((action) => {
        if (action.verifying && action.taskId) {
          return this.store.select(TaskSelectors.selectSpacePlanningImportTaskSourceTaskByTaskId(action.taskId)).pipe(
            switchMap(t => {
              console.log(t);
              if (t) {
                return [
                  TaskFloorModelActions.hideFloorPlanning(),
                  DisplayThemeItemActions.updateDisplayThemeId({id: DisplayThemeEnum.ImportReconcile}),
                  UiContextActions.deleteDisplayedTaskVisibility({id: t.id})
                ];
              } else {
                return EMPTY;
              }
            })
          );
        } else {
          return [
            TaskFloorModelActions.showFloorPlanning(),
            DisplayThemeItemActions.updateDisplayThemeId({id: DisplayThemeEnum.LayoutType}),
          ];
        }
      }),
    ),
  );

  setBindingEquipmentImportedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.setBindingEquipmentImportedItem),
      switchMap((action) => {
        if (action.proposedAddedFloorDataId && action.proposedDeletedFloorDataId) {
          return this.floorService.equipmentReconcileBindImportedItems(action.proposedDeletedFloorDataId, action.proposedAddedFloorDataId).pipe(
            switchMap(data => {
              const svgUseDTO = (data.payload.FloorData as SvgUse);
              return [
                UsesActions.deleteUse({id: action.proposedDeletedFloorDataId}),
                UsesActions.updateUse({
                  svgUse: {
                    id: svgUseDTO.id,
                    changes: {
                      dataStateId: svgUseDTO.dataStateId
                    }
                  }
                }),
                UiContextActions.setBindingEquipmentImportedItem({active: action.active, proposedAddedFloorDataId: null, proposedDeletedFloorDataId: null}),
              ];
            })
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  enableStudyMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.enableStudyMode),
      switchMap(action => {
          /** Single task loading **/
          if (!Array.isArray(action.taskId)) {
            return [
              /** Update selectedTaskId **/
              TaskActions.updateSelectedTaskId({taskId: action.taskId}),
              /** Add taskId to displayedTaskIds **/
              UiContextActions.addDisplayedTask({id: action.taskId}),
              UiContextActions.addDisplayedTaskVisibility({id: action.taskId}),
              /** Get FloorData **/
              GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: action.taskId}),
              /** Get current Task TaskValidation **/
              TaskValidationActions.getTaskValidationsByTaskId({taskId: action.taskId}),
              /** Get current Task TaskContributors **/
              TaskContributorActions.beginGetTaskContributorsByTaskId({taskId: action.taskId}),
              /** Cancel selection tool if used **/
              UiContextActions.isUsingSelectionTool({floorModelId: null}),
              /** Clear any previously selected items **/
              RendererActions.resetSelection(),
              // TaskFloorModelActions.hideFloorPlanning(),
            ];
          } else {
            /** Multiple tasks loading **/
            let arr = [];
            action.taskId.forEach(taskId => {
              arr = [...arr,
                /** Update selectedTaskId **/
                TaskActions.updateSelectedTaskId({taskId: taskId}),
                /** Add taskId to displayedTaskIds **/
                UiContextActions.addDisplayedTask({id: taskId}),
                UiContextActions.addDisplayedTaskVisibility({id: taskId}),
                /** Get FloorData **/
                GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: taskId}),
                /** Get current Task TaskContributors **/
                TaskContributorActions.beginGetTaskContributorsByTaskId({taskId: taskId}),
                /** Get current Task TaskValidation **/
                TaskValidationActions.getTaskValidationsByTaskId({taskId: taskId})];
            });
            arr = [...arr,
              /** Cancel selection tool if used **/
              UiContextActions.isUsingSelectionTool({floorModelId: null}),
              /** Clear any previously selected items **/
              RendererActions.resetSelection(),
            ];
            return arr;
          }
        }
      ),
      catchError(err => of(UiContextActions.studyModeError(err))),
    )
  );

  // enableStudyMode$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UiContextActions.enableStudyMode),
  //     switchMap(action => [
  //         /** Update selectedTaskId **/
  //         TaskActions.updateSelectedTaskId({taskId: action.taskId}),
  //         /** Add taskId to displayedTaskIds **/
  //         UiContextActions.addDisplayedTask({id: action.taskId}),
  //         UiContextActions.addDisplayedTaskVisibility({id: action.taskId}),
  //         /** Get FloorData **/
  //         GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: action.taskId}),
  //         /** Get current Task TaskValidation **/
  //         TaskValidationActions.getTaskValidationsByTaskId({taskId: action.taskId}),
  //         /** Cancel selection tool if used **/
  //         UiContextActions.isUsingSelectionTool({floorModelId: null}),
  //         /** Clear any previously selected items **/
  //         RendererActions.resetSelection(),
  //         // TaskFloorModelActions.hideFloorPlanning(),
  //       ]
  //     ),
  //     catchError(err => of(UiContextActions.studyModeError(err))),
  //   )
  // );

  removeStudy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.removeStudy),
      concatMap((action) =>
        zip([
          of(action.taskId),
          this.store.select(UiContextSelectors.selectDisplayedTasksIds),
          this.store.select(TaskSelectors.selectTaskById(action.taskId)),
          this.store.select(TaskSelectors.selectSpacePlanningImportTaskByTaskId(action.taskId)),
          this.store.select(TaskSelectors.selectSpacePlanningImportTaskSourceTaskByTaskId(action.taskId))
        ])),
      concatMap(([taskId, displayedTaskIds, removedTask, removedTaskSpacePlanningImportTask, spacePlanningImportTaskSourceTask]) => {
        /** Copy displayedTaskIds except the deleted taskId (the one which we are deleting) **/
        const arr = deepCopy(displayedTaskIds);
        arr.splice(arr.indexOf(taskId), 1);
        let actions = [];
        let disableStudyMode = false;
        if (arr.length > 1) {
          actions = [
            ...actions,
            /** Remove data previously loaded when studyMode was enabled**/
            FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [taskId]}),
            TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [taskId]}),
            /** Set back selectedTaskId to last displayedTaskIds element **/
            TaskActions.updateSelectedTaskId({taskId: arr[arr.length - 1]}),
            /** Replace displayedTasks remaining taskIds **/
            UiContextActions.setDisplayedTasks({ids: arr}),
            UiContextActions.setDisplayedTaskVisibility({ids: arr}),
          ];
        } else {
          disableStudyMode = true;
          actions = [
            ...actions,
            UiContextActions.disableStudyMode()
          ];
        }
        if (removedTask.businessTypeId === TaskBusinessTypeEnum.SpacePlanningImport && !disableStudyMode) {
          actions = [
            ...actions,
            UiContextActions.isVerifyingSpacePlanningImport({verifying: false}),
            UiContextActions.removeStudy({taskId: spacePlanningImportTaskSourceTask.id}),
          ];
        } else if (removedTaskSpacePlanningImportTask && !disableStudyMode) {
          actions = [
            ...actions,
            UiContextActions.isVerifyingSpacePlanningImport({verifying: false}),
            UiContextActions.removeStudy({taskId: removedTaskSpacePlanningImportTask.id}),
          ];
        }
        console.log(actions);
        return actions;
      })
    )
  );

  // removeStudy$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UiContextActions.removeStudy),
  //     tap(a => console.log(a)),
  //     exhaustMap((action) =>
  //       this.store.select(UiContextSelectors.selectDisplayedTasksIds).pipe(
  //         tap(a => console.log(a)),
  //         /** VERY IMPORTANT : since we subscribe to selectDisplayedTasksIds, this effect is precisely
  //          * updating selectedDisplayedTaskId, using first rxjs operator avoid infinite loop
  //          **/
  //         //first(),
  //         exhaustMap(displayedTaskIds => this.store.select(TaskSelectors.selectTaskById(action.taskId)).pipe(
  //           withLatestFrom(
  //             this.store.select(TaskSelectors.selectSpacePlanningImportTaskByTaskId(action.taskId)),
  //             this.store.select(TaskSelectors.selectSpacePlanningImportTaskSourceTaskByTaskId(action.taskId))
  //           ),
  //           tap(a => console.log(a)),
  //           concatMap(([removedTask, removedTaskSpacePlanningImportTask, spacePlanningImportTaskSourceTask]) => {
  //             /** Copy displayedTaskIds except the deleted taskId (the one which we are deleting) **/
  //             const arr = deepCopy(displayedTaskIds);
  //             arr.splice(arr.indexOf(action.taskId), 1);
  //             let actions = [];
  //             if (arr.length > 1) {
  //               actions = [
  //                 ...actions,
  //                 /** Remove data previously loaded when studyMode was enabled**/
  //                 FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [action.taskId]}),
  //                 TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [action.taskId]}),
  //                 /** Set back selectedTaskId to last displayedTaskIds element **/
  //                 TaskActions.updateSelectedTaskId({taskId: arr[arr.length - 1]}),
  //                 /** Replace displayedTasks remaining taskIds **/
  //                 UiContextActions.setDisplayedTasks({ids: arr}),
  //                 UiContextActions.setDisplayedTaskVisibility({ids: arr}),
  //               ];
  //             } else {
  //               actions = [
  //                 ...actions,
  //                 UiContextActions.disableStudyMode()
  //               ];
  //             }
  //             if (removedTask.businessTypeId === TaskBusinessTypeEnum.SpacePlanningImport) {
  //               actions = [
  //                 ...actions,
  //                 UiContextActions.isVerifyingSpacePlanningImport({verifying: false})
  //               ];
  //             }
  //             if (spacePlanningImportTaskSourceTask) {
  //               actions = [
  //                 ...actions,
  //                 UiContextActions.removeStudy({taskId: spacePlanningImportTaskSourceTask.id}),
  //               ];
  //             }
  //             if (removedTaskSpacePlanningImportTask) {
  //               actions = [
  //                 ...actions,
  //                 UiContextActions.removeStudy({taskId: removedTaskSpacePlanningImportTask.id}),
  //               ];
  //             }
  //             console.log(actions);
  //             return actions;
  //
  //           })
  //         )),
  //         // switchMap(displayedTaskIds => {
  //         //   /** Copy displayedTaskIds except the deleted taskId (the one which we are deleting) **/
  //         //   const arr = deepCopy(displayedTaskIds);
  //         //   arr.splice(arr.indexOf(action.taskId), 1);
  //         //   // const arr = deepCopy(displayedTaskIds.slice(0, displayedTaskIds.length - 1));
  //         //   if (arr.length > 1) {
  //         //     return [
  //         //       /** Remove data previously loaded when studyMode was enabled**/
  //         //       FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [action.taskId]}),
  //         //       TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [action.taskId]}),
  //         //       /** Set back selectedTaskId to last displayedTaskIds element **/
  //         //       TaskActions.updateSelectedTaskId({taskId: arr[arr.length - 1]}),
  //         //       /** Replace displayedTasks remaining taskIds **/
  //         //       UiContextActions.setDisplayedTasks({ids: arr}),
  //         //       UiContextActions.setDisplayedTaskVisibility({ids: arr}),
  //         //     ];
  //         //   } else {
  //         //     return of(UiContextActions.disableStudyMode());
  //         //   }
  //         //
  //         // })
  //       )),
  //   )
  // );

  disableStudyMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.disableStudyMode),
      mergeMap((action) =>
        zip([this.store.select(UiContextSelectors.selectDisplayedTasksIds), this.store.select(FloorSelectors.selectCurrentFloor)]).pipe(
          /** VERY IMPORTANT : since we use combineLatest with selectDisplayedTasksIds, this effect is precisely
           * updating selectedDisplayedTaskId, using first rxjs operator avoid infinite loop
           **/
          first(),
          // tap(([displayedTaskIds, currentFloor]) => {
          //   console.log([...displayedTaskIds].splice(1));
          // }),
          switchMap(([displayedTaskIds, currentFloor]) => [
            /** Remove data previously loaded when studyMode was enabled**/
            FloorDataActions.clearFloorDataItems(),
            UsesActions.clearUses(),
            // FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [...displayedTaskIds].splice(1)}),
            // TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [...displayedTaskIds].splice(1)}),
            TaskFloorModelActions.clearTaskFloorModels(),
            /** Set back edited floor model to null**/
            UiContextActions.isEditingFloorModel({id: null}),
            /** Set back selectedTaskId to currentFloor taskId**/
            TaskActions.updateSelectedTaskId({taskId: currentFloor.taskId}),
            /** Replace displayedTasks with current floor taskId **/
            UiContextActions.setDisplayedTasks({ids: [currentFloor.taskId]}),
            UiContextActions.setDisplayedTaskVisibility({ids: [currentFloor.taskId]}),
            /** Set blueprint to false **/
            RendererActions.setBlueprint({blueprint: false}),
            /** Disable eventually enabled space planning import verification mode **/
            UiContextActions.isVerifyingSpacePlanningImport({verifying: false}),
            /** Reload FloorData **/
            GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: currentFloor.taskId}),
            // TaskFloorModelActions.showFloorPlanning(),
          ])
        )),
    )
  );

  /**
   *
   * CODE BELOW MAKEs WHOLE UI CRASH WHEN SWITCHING BETWEEN WALLS OVERLAY AND ROOM OVERLAY
   *
   ***/

    // isEditingFloorModel$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(UiContextActions.isEditingFloorModel),
    //     switchMap(action =>
    //       this.store.select(FloorSelectors.selectCurrentFloor).pipe(
    //         tap(a => console.log(a)),
    //         concatMap(currentFloor => {
    //             if (currentFloor) {
    //               console.log(currentFloor);
    //               /** If user is editing Walls floor model in partioning study, display needed technical layers /*/
    //               if (action.id === FloorModelEnum.Walls) {
    //                 return [
    //                   this.toggleContoursFloorModels(currentFloor.taskId, true),
    //                   WallActions.showPartitioningFrame(),
    //                 ];
    //               } else {
    //                 return [
    //                   this.toggleContoursFloorModels(currentFloor.taskId, false),
    //                   WallActions.hidePartitioningFrame(),
    //                 ];
    //               }
    //             } else {
    //               console.log(currentFloor);
    //               return EMPTY;
    //               //return of(action); /** NEVER DO THAT AGAIN **/
    //             }
    //           }
    //         )
    //       )),
    //   ),
    // );

  cancelRoomRecalculateArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.cancelRoomRecalculateArea),
      switchMap((action) => of(RendererActions.clearRoomContour())),
      // catchError(err => of(err)),
    ),
  );

  // isCreatingEquipment$ = createEffect(() => this.actions$.pipe(
  //   ofType(UiContextActions.beginEquipmentCreation),
  //   concatMap(action => this.store.select(UseSelectors.getSelectedUsesIds).pipe(
  //     first(),
  //     switchMap(selectionStatus => {
  //       console.log(selectionStatus);
  //       if (selectionStatus.selecting) {
  //         return of(RendererActions.resetSelection());
  //       } else {
  //         return EMPTY;
  //       }
  //     })
  //   )),
  // ));

  isCreatingEquipment$ = createEffect(() => this.actions$.pipe(
    ofType(UiContextActions.beginEquipmentCreation),
    switchMap(action => of(UsesActions.clearSelection())),
  ));

  createDirectTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.createDirectTask),
      switchMap((action) => this.floorService.createDirectTask(action.ids, action.name).pipe(
        concatMap(dto => [
          TaskActions.addTask({task: dto.task}),
          TaskFloorActions.addTaskFloor({taskFloor: dto.taskFloor}),
          /** Load study **/
          UiContextActions.enableStudyMode({taskId: dto.task.id}),
        ]),
        catchError(err => of(UiContextActions.disableStudyMode())),
      )),
    ),
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }

  // toggleContoursFloorModels(taskId: number, isVisible: boolean):  Action {
  //   return TaskFloorModelActions.updateTaskFloorModelItems({
  //     updates: [
  //       {
  //         id: `${FloorModelEnum.FloorCoreContours}-${taskId}`,
  //         changes: {
  //           isVisible: isVisible
  //         }
  //       },
  //       {
  //         id: `${FloorModelEnum.FloorInsideContours}-${taskId}`,
  //         changes: {
  //           isVisible: isVisible
  //         }
  //       }] as Update<TaskFloorModel>[]
  //   });
  // }
}
