import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { TaskFunction } from '../../../domain-models/business/task-function.model';

/**
 * CRUD Actions
 */

export const loadTaskFunctions = createAction('[TaskFunction/API] Load TaskFunctions', props<{ taskFunctions: TaskFunction[] }>());
export const addTaskFunction = createAction('[TaskFunction/API] Add TaskFunction', props<{ taskFunction: TaskFunction }>());
export const setTaskFunction = createAction('[TaskFunction/API] Set TaskFunction', props<{ taskFunction: TaskFunction }>());
export const upsertTaskFunction = createAction('[TaskFunction/API] Upsert TaskFunction', props<{ taskFunction: TaskFunction }>());
export const addTaskFunctions = createAction('[TaskFunction/API] Add TaskFunctions', props<{ taskFunctions: TaskFunction[] }>());
export const upsertTaskFunctions = createAction('[TaskFunction/API] Upsert TaskFunctions', props<{ taskFunctions: TaskFunction[] }>());
export const updateTaskFunction = createAction('[TaskFunction/API] Update TaskFunction', props<{ update: Update<TaskFunction> }>());
export const updateTaskFunctions = createAction('[TaskFunction/API] Update TaskFunctions', props<{ updates: Update<TaskFunction>[] }>());
export const mapTaskFunctions = createAction('[TaskFunction/API] Map TaskFunctions', props<{ entityMap: EntityMap<TaskFunction> }>());
export const deleteTaskFunction = createAction('[TaskFunction/API] Delete TaskFunction', props<{ id: string }>());
export const deleteTaskFunctions = createAction('[TaskFunction/API] Delete TaskFunctions', props<{ ids: string[] }>());
export const deleteTaskFunctionsByPredicate = createAction('[TaskFunction/API] Delete TaskFunctions By Predicate', props<{ predicate: Predicate<TaskFunction> }>());
export const clearTaskFunctions = createAction('[TaskFunction/API] Clear TaskFunctions');

/** END of CRUD **/

export const beginGetAllTaskFunctions = createAction('[TaskFunction/API] - Begin Get All TaskFunctions');
export const errorGetAllTaskFunctions = createAction('[API] - Error Get All TaskFunctions', props<Error>());

export const updateSelectedTaskFunctionId = createAction(
  '[TaskFunction/API] - Save Selected TaskFunction Id',
  props<{ taskFunctionId: number }>()
);

export const beginGetCurrentTaskFunctionPerimeterIds = createAction('[API] - Get Current TaskFunction Perimeter Ids');
export const beginGetCurrentTaskFunctionLayoutTypeIds = createAction('[API] - Get Current TaskFunction Layout Type Ids');
export const beginGetCurrentTaskFunctionActivityStatusTypeIds = createAction('[API] - Get Current TaskFunction Activity Status Type Ids');
export const beginGetCurrentTaskFunctionAttributionTypeIds = createAction('[API] - Get Current TaskFunction Attribution Type Ids');
export const beginGetCurrentTaskFunctionBusinessUnitIds = createAction('[API] - Get Current TaskFunction Business Units Ids');

export const successGetTaskFunctionPerimeterIds = createAction(
  '[API] - Success Get TaskFunction Perimeter Ids',
  props<{ taskFunctionId: number; taskFunctionPerimeterIds: number[] }>()
);

export const addTaskFunctionRoomLayoutTypeId = createAction('[SvgGroup] - Add TaskFunction present Layout Type Id', props<{taskFunctionId: number; layoutTypeId: number}>());
