import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum TaskFunctionEnum {
  Owner = 1,
  Control,
  Validation,
  SpacePlanning,
  AllocationPlanning,
  WorkplacePlanning
}

export const TASK_FUNCTION = {
  databaseTableName: 'task_function',
  id: 'TaFu_Id',
  name: 'TaFu_Name',
  isActive: 'TaFu_IsActive',
  displayNameId: 'TaFu_DisplayNameId',
  scope: 'TaFu_Scope'
};

export class TaskFunction {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public isActive: boolean,
    public displayNameId: number,
    public scope: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskFunctionAdapter implements Adapter<TaskFunction> {
  adapt(item: any): TaskFunction {
    return new TaskFunction(
      TASK_FUNCTION.databaseTableName,
      item[TASK_FUNCTION.id],
      item[TASK_FUNCTION.name],
      item[TASK_FUNCTION.isActive],
      item[TASK_FUNCTION.displayNameId],
      item[TASK_FUNCTION.scope],
    );
  }

  encode(item: TaskFunction): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_FUNCTION.id]: item.id,
      [TASK_FUNCTION.name]: item.name,
      [TASK_FUNCTION.isActive]: item.isActive,
      [TASK_FUNCTION.displayNameId]: item.displayNameId,
      [TASK_FUNCTION.scope]: item.scope,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
