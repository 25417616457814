import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { SvgViewBox } from '../models/svg-view-box.model';
import { HighlightedFloorData } from '../models/highlighted-floor-data.model';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { FloorDataSelectors, UseSelectors } from '../../../store/svg-store/selectors';
import { distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { deepCopy } from '../../../shared/utils';

export interface Contour {
  floorDataId: number;
  floorModelId: number;
  rightClick: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SvgRendererService {

  rendererViewContainerRef: ViewContainerRef;

  currentViewBox$ = new BehaviorSubject<SvgViewBox>(null);

  isDraggingFloorDataSubject$ = new Subject<number>();

  zoomLevel$ = new BehaviorSubject<number>(null);
  mobileZoom$ = new Subject<number>();

  private hoveredFloorDataSubject$ = new Subject<{id: number, floorModelId: number, isUse?: boolean}>();
  private highlightedFloorDataSubject$ = new BehaviorSubject<HighlightedFloorData>({
    deleted: false,
    added: false,
    updated: false
  });
  private toggleGrayscaleSubject$ = new BehaviorSubject<boolean>(false);

  highlightedFloorData$ = this.highlightedFloorDataSubject$.asObservable();
  toggleGrayscale$ = this.toggleGrayscaleSubject$.asObservable();
  hoveredFloorData$ = this.hoveredFloorDataSubject$.asObservable();

  destroySub: Subscription;
  destroyContextMenu$ = new Subject<number>();
  destroyAllContextMenus$ = new Subject<void>();

  detectChanges$ = new Subject<void>();

  isCreatingSecondWallSegmentPoint$ = new Subject<boolean>();
  rightClickFloorDataId$ = new BehaviorSubject<number>(null);

  private floorDataContoursSubject$ = new BehaviorSubject<Contour[]>([]);
  private floorDataContours = [] as Contour[];
  private selectedFloorDataItems$ = combineLatest([
    this.store.select(FloorDataSelectors.getSelectedFloorDataItems).pipe(
      map(selectedFloorDataItems => selectedFloorDataItems.map(floorData => {
        return {
          id: floorData.id,
          floorModelId: floorData.floorModelId
        };
      }))
    ),
    this.store.select(UseSelectors.getSelectedUses).pipe(
      map(selectedFloorDataItems => selectedFloorDataItems.map(floorData => {
        return {
          id: floorData.id,
          floorModelId: floorData.floorModelId
        };
      }))
    )
  ]).pipe(
    map(([arrA, arrB]) => {
      return arrA.concat(arrB);
    }),
  ) as Observable<{id: number, floorModelId: number}[]>;
  readonly floorDataContours$ = this.floorDataContoursSubject$.asObservable();

  constructor(private store: Store<State>) {
    this.selectedFloorDataItems$.pipe(
      distinctUntilChanged(),
      tap(selectedFloorDataItems => {
        this.floorDataContours = deepCopy(selectedFloorDataItems.map(floorData => {
          return {
            floorDataId: floorData.id,
            floorModelId: floorData.floorModelId,
            rightClick: false
          } as Contour;
        }));
        this.floorDataContoursSubject$.next(this.floorDataContours);
      })
    ).subscribe();

    this.destroyContextMenu$.pipe(
      switchMap(destroyedId => this.selectedFloorDataItems$.pipe(
        first(),
        tap(selectedFloorDataItems => {
          this.floorDataContours.forEach(c => {
            c.rightClick = false;
          });
          const floorDataIsSelected = selectedFloorDataItems.find(floorData => floorData.id === destroyedId);
          const existingContourIndex = this.floorDataContours.indexOf(this.floorDataContours.find(c => c.floorDataId === destroyedId));
          const contourAlreadyExists = (existingContourIndex !== -1);
          if (floorDataIsSelected) {
            if (!contourAlreadyExists) {
              this.floorDataContours.push({
                floorDataId: floorDataIsSelected.id,
                floorModelId: floorDataIsSelected.floorModelId,
                rightClick: false
              });
            }
          } else {
            if (contourAlreadyExists) {
              this.floorDataContours.splice(existingContourIndex, 1);
            }
          }
          // console.log(`end of destroy ${destroyedId}`);
          this.floorDataContoursSubject$.next(this.floorDataContours);
        }))
      ),
    ).subscribe();
  }

  hoverFloorData(id: number, floorModelId: number, isUse?: boolean) {
    this.hoveredFloorDataSubject$.next({id: id, floorModelId: floorModelId, isUse: isUse});
  }

  highlightFloorData(floorDataStateId: HighlightedFloorData) {
    this.highlightedFloorDataSubject$.next(floorDataStateId);
  }

  toggleGrayscale(toggleGrayscale: boolean): void {
    this.toggleGrayscaleSubject$.next(toggleGrayscale);
  }

  /**
   * CONTOURS WIP
   */


  rightClickOnFloorData(floorDataId: number, floorModelId: number): void {
    this.selectedFloorDataItems$.pipe(
      first(),
      tap(selectedFloorDataItems => {
        const alreadyExistingContourIndex = this.floorDataContours.indexOf(this.floorDataContours.find(c => c.floorDataId === floorDataId));
        if (alreadyExistingContourIndex > -1) {
          this.floorDataContours[alreadyExistingContourIndex].rightClick = true;
        } else {
          this.floorDataContours.push({
            floorDataId: floorDataId,
            floorModelId: floorModelId,
            rightClick: true
          });
        }

        if (selectedFloorDataItems.length === 1) {
          this.floorDataContours.forEach(c => {
            if (c.floorDataId !== floorDataId) {
              c.rightClick = false;
            }
          });
        } else if (selectedFloorDataItems.length > 1) {
          // If at least 2 FloorData are selected, right click selection concern these items + eventually another one clicked
          if (this.floorDataContours.every(c => c.floorModelId === floorModelId)) {
            this.floorDataContours.forEach(c => {
              c.rightClick = true;
            });
          }

        }

        this.floorDataContoursSubject$.next(this.floorDataContours);
      }),
    ).subscribe();

    // this.selectedFloorDataIds$.pipe(
    //   first(),
    //   tap(selectedFloorDataIds => {
    //     let result = [] as Contour[];
    //     if (selectedFloorDataIds.length === 1) {
    //       result.push({
    //         floorDataId: selectedFloorDataIds[0],
    //         rightClick: false,
    //       });
    //       result.push({
    //         floorDataId: floorDataId,
    //         rightClick: true,
    //       });
    //     } else if (selectedFloorDataIds.length > 1) {
    //       // If at least 2 FloorData are selected, right click selection concern these items + eventually another one clicked
    //       result = [
    //         ...selectedFloorDataIds.map(id => {
    //           return {
    //             floorDataId: id,
    //             rightClick: true
    //           };
    //         })];
    //       if (!selectedFloorDataIds.find(id => id === floorDataId)) {
    //         result.push({
    //           floorDataId: floorDataId,
    //           rightClick: true
    //         });
    //       }
    //     } else {
    //       result.push({
    //         floorDataId: floorDataId,
    //         rightClick: true,
    //       });
    //     }
    //     this.floorDataContours = result;
    //     this.floorDataContoursSubject$.next(this.floorDataContours);
    //   }),
    // ).subscribe();
  }

  // initializeFloorDataContours() {
  //   this.store.select(FloorDataSelectors.getSelectedFloorDataIds).pipe(
  //     tap(ids => {
  //       this.floorDataContours = ids.map(id => {
  //         return {
  //           floorDataId: id,
  //           rightClick: false
  //         } as Contour;
  //       });
  //       this.drawFloorDataContours$.next(this.floorDataContours);
  //     })
  //   ).subscribe();
  // }

  /** Used to manually trigger change detection of SvgRendererComponent **/
  detectChanges() {
    this.detectChanges$.next();
  }
}
