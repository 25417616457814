import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, zip } from 'rxjs';
import { ItemNode } from '../models/item-node.model';
import { TreeService } from './tree.service';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { ApiData } from '../../../core/models/api-data.model';
import { map } from 'rxjs/operators';
import { HierarchicTable, HierarchicTableAdapter, Z_HIERARCHIC_TABLE } from '../../../domain-models/core/z-hierarchic-table.model';
import { LanguageService } from '../../../shared/services/language.service';
import { Color, ColorUtils } from '../../../core/utils/color';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */

@Injectable()
export class TreeDatabaseService {

  public tableName = new ReplaySubject<{ tableName: string, withGrants?: boolean, parentsItems?: boolean }>(1);

  dataChange = new BehaviorSubject<ItemNode<any>[]>([]);
  private treeData$: Observable<Array<any>>;
  private treeData: Array<any>;
  private treeDataFlat$: Observable<Array<any>>;
  private treeDataFlat: Array<any>;

  public hierarchyMetadata$: Observable<HierarchicTable>;
  private hierarchyMetadata: HierarchicTable;


  constructor(private treeService: TreeService,
              private languageService: LanguageService) {

    /** Initialize data each time tree's TableName source changes **/
    this.tableName.subscribe(tableName => {
      this.initialize(tableName.tableName, tableName.withGrants, tableName.parentsItems);
    });

  }

  get data(): ItemNode<any>[] {
    return this.dataChange.value;
  }

  initialize(tableName: string, withGrants?: boolean, parentsItems?: boolean) {
    /** Load HierarchicTable metadata **/
    this.hierarchyMetadata$ = this.treeService.get(API_ENDPOINTS.dynT + 'z_hierarchic_table')
      .pipe(
        map((data: ApiData) => {
          const hierarchicTableAdapter = new HierarchicTableAdapter();
          return hierarchicTableAdapter.adapt(data.payload.find(e => e[Z_HIERARCHIC_TABLE.tableName] === tableName));
        })
      );

    /** Load flat & nested data from API **/
    this.treeData$ = this.treeService.getTreeData(tableName, withGrants, parentsItems);
    this.treeDataFlat$ = this.treeService.getFlatTreeData(tableName, withGrants);

    /** Wait for data to be loaded **/
    zip(this.treeData$, this.treeDataFlat$, this.hierarchyMetadata$)
      .pipe(
        map((array) => {
          this.treeData = array[0];
          this.treeDataFlat = array[1];
          this.hierarchyMetadata = array[2];
          const orderColumnName = Object.keys(this.treeDataFlat[0]).find(k => k.includes('OrderIndex'));
          const idArray = this.hierarchyArrayByKeyField(this.treeData, this.hierarchyMetadata.idColumnName);
          const data = this.buildDataTree(idArray, 0, this.hierarchyMetadata.displayColumnName, this.hierarchyMetadata.idColumnName, orderColumnName);
          // Notify the change.
          this.dataChange.next(data);
        })
      )
      .subscribe();
  }

  hierarchyArrayByKeyField(array: Array<any>, keyField: string): any {
    const container = {};
    array.forEach((e, index) => {
      container[e[keyField]] = null;
      const children = e['Children'];
      if (children) {
        container[e[keyField]] = this.hierarchyArrayByKeyField(children, keyField);
      }
    });
    return container;

  }

  /**
   * Build the data structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `ItemNode`.
   */
  buildDataTree(obj: { [key: string]: any }, level: number, displayKey: string, idKey: string, orderKey: string): ItemNode<any>[] | null {// ! |null
    return Object.keys(obj).reduce<ItemNode<any>[]>((accumulator, key) => {
      const sourceRootItem = this.treeDataFlat.find(item => item[idKey] === Number(key));
      const value = obj[key];

      const node = new ItemNode();

      const displayName = this.languageService.searchDisplayName(sourceRootItem[displayKey]);

      /** Find if business object has a _Color db column **/
      const c = this.findColorInRawSourceData(sourceRootItem);
      node.color = c ? c : null;
      // node.displayNameId = sourceRootItem[displayKey];
      if (displayName) {
        node.name = displayName;
      } else {
        node.name = sourceRootItem[displayKey];
      }
      node.data = sourceRootItem;

      if (value !== null) {
        if (typeof value === 'object') {
          node.children = this.buildDataTree(value, level + 1, displayKey, idKey, orderKey);//value
        } else {
          const sourceSubItem = this.treeDataFlat.find(item => item[value] === Number(value));

          const subDisplayName = this.languageService.searchDisplayName(sourceSubItem[displayKey]);
          // node.displayNameId = sourceSubItem[displayKey];
          /** Find if business object has a _Color db column **/
          const sc = this.findColorInRawSourceData(sourceSubItem);
          node.color = sc ? sc : null;
          if (subDisplayName) {
            node.name = subDisplayName;
          } else {
            node.name = sourceSubItem[displayKey];
          }
          node.data = sourceSubItem;
        }
      }
      if (orderKey && node.children) {
        node.children.sort((a, b) => a.data[orderKey] - b.data[orderKey]);
      }
      return accumulator.concat(node);

    }, []).sort((a, b) => {
      if (orderKey) {
        return a.data[orderKey] - b.data[orderKey];
      }
    });
  }

  findColorInRawSourceData(item: any): Color {
    let color: Color;
    Object.entries(item).find(([k, v]) => {
      if (k.includes('_Color')) {
        color = new Color();
        color = ColorUtils.colorFromHexARGB(v as string);
      }
    });
    return color;
  }


  /** Add an item to to-do list */
  insertItem(parent: ItemNode<any>, name: string) {
    if (parent.children) {
      parent.children.push({data: name} as ItemNode<any>);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: ItemNode<any>, name: string) {
    node.data = name;
    this.dataChange.next(this.data);
  }
}
