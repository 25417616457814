import { Dictionary } from '../core/models/dictionary.model';
import { LanguageService } from './services/language.service';

export function normalizeArray<T>(innerData: T[], primaryKey?: string): Dictionary<T> {
  // type InnerType = T & { id: number | string };
  primaryKey = primaryKey ? primaryKey : 'id';

  return innerData.reduce((acc: Dictionary<any>, curr: any) => {
    return {
      ...acc,
      [curr[primaryKey]]: curr // [curr.id]
    };
  }, {});
}

export function uniqueArray<T>(array: Array<T>): Array<T> {
  return array.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
}

/**https://masteringjs.io/tutorials/fundamentals/compare-arrays**/
export function arrayEquals(a: Array<any>, b: Array<any>) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

/**https://stackoverflow.com/questions/2218999/remove-duplicates-from-an-array-of-objects-in-javascript**/
export function removeDuplicates<T>(array: Array<T>): Array<T> {
  // slow for large arrays
  return array.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i);
}

/** https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/6470794#6470794 **/
export function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}
/**
 * Sort array of objects based on another array
 * https://gist.github.com/ecarter/1423674
 */

export function mapOrder(array: Array<any>, order: Array<string | number>, key: string) {

  array.sort( function (a, b) {
    const A = a[key], B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }

  });

  return array;
}

// // source: https://github.com/jashkenas/underscore/blob/master/underscore.js#L1320
// function isObject(obj) {
//   const type = typeof obj;
//   return type === 'function' || type === 'object' && !!obj;
// }
// export function deepCopy(src) {
//   const target = {};
//   for (const prop in src) {
//     if (src.hasOwnProperty(prop)) {
//       // if the value is a nested object, recursively copy all it's properties
//       if (isObject(src[prop])) {
//         target[prop] = deepCopy(src[prop]);
//       } else {
//         target[prop] = src[prop];
//       }
//     }
//   }
//   return target;
// }

/**
 * JavaScript function that will make a deep copy of nested objects or arrays.
 * https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
 * @param inObject
 */
export function deepCopy (inObject) {
  let outObject, value, key;

  // TODO check if isDate "checking" is correct and doesn't do shallow copy of date object instead of deep copy...

  if (typeof inObject !== 'object' || inObject === null || isDate(inObject)) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
}

// /**
//  * Deep copy function for TypeScript.
//  * @param T Generic type of target/copied value.
//  * @param target Target value to be copied.
//  * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
//  * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
//  */
// export const deepCopy = <T>(target: T): T => {
//   if (target === null) {
//     return target;
//   }
//   if (target instanceof Date) {
//     return new Date(target.getTime()) as any;
//   }
//   if (target instanceof Array) {
//     const cp = [] as any[];
//     (target as any[]).forEach((v) => { cp.push(v); });
//     return cp.map((n: any) => deepCopy<any>(n)) as any;
//   }
//   if (typeof target === 'object' && target !== {}) {
//     const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
//     Object.keys(cp).forEach(k => {
//       cp[k] = deepCopy<any>(cp[k]);
//     });
//     return cp as T;
//   }
//   return target;
// };

/**
 * Function to check whether an 'input' is a date object or not.
 * https://www.w3resource.com/javascript-exercises/javascript-date-exercise-1.php
 * @param input
 */

export function isDate(input) {
  return Object.prototype.toString.call(input) === '[object Date]';
}

/** Returns true if the all records of a collection are equal and false otherwise
 * https://dev.to/rajnishkatharotiya/function-to-check-if-all-records-are-equal-in-array-javascript-3mo3
 * @param arr
 */
export const allEqual = arr => arr.every(val => val === arr[0]);

export function getDescendants (arr, id) {
  // arr.reduce((acc, n) => n.parentId === id ? [...acc, n, ...getDescendants(arr, n)] : acc, []);
}

// export function dateToBackendString (date: Date): string {
//   return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
// }

export class Utils {
  public static dateToBackendString (date: Date): string {
    if (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else {
      return undefined;
    }
  }

  public static mapDisplayNames<T>(array: T[], languageService: LanguageService): Array<T> {
    return array.map((t) => {
      return { ...t, name: languageService.searchDisplayName(t['displayNameId']) };
    }).sort((a, b) => a.name.localeCompare(b.name));
  }

  public static getFileNameFromPath (str: string): string {
    return str.split('\\').pop().split('/').pop();
  }

  /**
   * https://codepen.io/cferdinandi/pen/oVvKoe
   * @param obj1
   * @param obj2
   */
  public static diff(obj1: any, obj2: any) {

    // Make sure an object to compare is provided
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      return obj1;
    }

    //
    // Variables
    //

    const diffs = {};
    let key;


    //
    // Methods
    //

    /**
     * Check if two arrays are equal
     * @param  {Array}   arr1 The first array
     * @param  {Array}   arr2 The second array
     * @return {Boolean}      If true, both arrays are equal
     */
    const arraysMatch = function (arr1, arr2) {

      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) { return false; }

      // Check if all items exist and are in the same order
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) { return false; }
      }

      // Otherwise, return true
      return true;

    };

    /**
     * Compare two items and push non-matches to object
     * @param  {*}      item1 The first item
     * @param  {*}      item2 The second item
     * @param  {String} key   The key in our object
     */
    const compare = function (item1, item2, key) {

      // Get the object type
      const type1 = Object.prototype.toString.call(item1);
      const type2 = Object.prototype.toString.call(item2);

      // If type2 is undefined it has been removed
      if (type2 === '[object Undefined]') {
        diffs[key] = null;
        return;
      }

      // If items are different types
      if (type1 !== type2) {
        diffs[key] = item2;
        return;
      }

      // If an object, compare recursively
      if (type1 === '[object Object]') {
        // tslint:disable-next-line:prefer-const
        let objDiff = Utils.diff(item1, item2);
        if (Object.keys(objDiff).length > 0) {
          diffs[key] = objDiff;
        }
        return;
      }

      // If an array, compare
      if (type1 === '[object Array]') {
        if (!arraysMatch(item1, item2)) {
          diffs[key] = item2;
        }
        return;
      }

      // If a Date, compare
      if (type1 === '[object Date]') {
        if (item1.getTime() !== item2.getTime()) {
          diffs[key] = item2;
        }
        return;
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (type1 === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          diffs[key] = item2;
        }
      } else {
        if (item1 !== item2 ) {
          diffs[key] = item2;
        }
      }

    };


    //
    // Compare our objects
    //

    // Loop through the first object
    for (key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        compare(obj1[key], obj2[key], key);
      }
    }

    // Loop through the second object and find missing items
    for (key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (!obj1[key] && obj1[key] !== obj2[key] ) {
          diffs[key] = obj2[key];
        }
      }
    }

    // Return the object of differences
    return diffs;

  }


}

export class StringUtils {
  // https://www.codegrepper.com/code-examples/javascript/typescript+check+if+object+or+string
  public static isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }
}

export class ArrayUtils {
  /** https://stackoverflow.com/questions/52038918/recursively-find-all-children-from-parent-menu **/
  public static getChildren<T extends {id: number | string; parentId: number}>(array: T[], id: number | string) {
    // console.log(id);
    return array.reduce((r, e) => {
      // console.log(e);
      if (e.parentId === id) {
        r.push(e, ...this.getChildren(array, e.id));
      }
      return r;
    }, []);
  }

  /** https://24ways.org/2019/five-interesting-ways-to-use-array-reduce/ **/
  public static groupBy<T>(arr: T[], criteria: any): Dictionary<T[]> {
    return arr.reduce(function (obj, item) {

      // Check if the criteria is a function to run on the item or a property of it
      const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

      // If the key doesn't exist yet, create it
      if (!obj.hasOwnProperty(key)) {
        obj[key] = [];
      }

      // Push the value to the object
      obj[key].push(item);

      // Return the object to the next item in the loop
      return obj;

    }, {});
  }

  /**
   * POC
   * @param array Tableau d'objets
   * @param propertyName Nom de la propriété à sommer
   * @returns Retourne la somme pour la propriété
   */
  public static sumBy(array: any[], propertyName: string): number {
    return array.reduce((sum, item) => {
      return sum + item[propertyName];
    }, 0);
  }
}

// POC
export class DateUtils {
  /**
   * POC
   * @param date Date à laquelle ajouter des jours
   * @param days Nombre de jours à ajouter
   * @returns Nouvelle date. La date passée en paramètre n'est pas modifiée
   */
  public static addDays(date: Date, days: number): Date {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }
}
