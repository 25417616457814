import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const FACILITY_COMPANY = {
  databaseTableName: 'facility_company',
  id: 'FaCo_Id',
  name: 'FaCo_Name',
  isActive: 'FaCo_IsActive'
};

export class FacilityCompany {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public isActive: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class FacilityCompanyAdapter implements Adapter<FacilityCompany> {
  adapt(item: any): FacilityCompany {
    return new FacilityCompany (
      FACILITY_COMPANY.databaseTableName,
      item[FACILITY_COMPANY.id],
      item[FACILITY_COMPANY.name],
      item[FACILITY_COMPANY.isActive],
    );
  }
  encode(item: FacilityCompany): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FACILITY_COMPANY.id]: item.id,
      [FACILITY_COMPANY.name]: item.name,
      [FACILITY_COMPANY.isActive]: item.isActive,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
