import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TreeDatabaseService } from '../../services/tree-database.service';
import { ItemFlatNode } from '../../models/item-flat-node.model';
import { ItemNode } from '../../models/item-node.model';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { HierarchicTable } from '../../../../domain-models/core/z-hierarchic-table.model';
import { combineLatest, Subject } from 'rxjs';

/**
 * @title Tree with checkboxes
 */

@Component({
  selector: 'echo-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeDatabaseService],
})

export class TreeComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input() tableName: string;
  @Input() withGrants: boolean;
  @Input() selectedIds: Array<number | string>;
  @Input() checkboxes: boolean;
  @Input() onlyShowParents: boolean;
  @Input() onlySelectParents: boolean;
  @Input() expandNode: any;
  @Input() disabled;
  @Input() refreshData$: Subject<void>;
  @Input() parentsItems: boolean;

  @Output() selection = new EventEmitter<Array<ItemFlatNode<any>>>();
  @Output() sourceItems = new EventEmitter<Array<ItemFlatNode<any>>>();
  @Output() changes = new EventEmitter<{ added: ItemFlatNode<any>[]; removed: ItemFlatNode<any>[] }>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode<any>, ItemNode<any>>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode<any>, ItemFlatNode<any>>();
  /** A selected parent node to be inserted */
  selectedParent: ItemFlatNode<any> | null = null;
  /** The new item's name */
  newItemName = '';
  treeControl: FlatTreeControl<ItemFlatNode<any>>;
  treeFlattener: MatTreeFlattener<ItemNode<any>, ItemFlatNode<any>>;
  dataSource: MatTreeFlatDataSource<ItemNode<any>, ItemFlatNode<any>>;
  flattenedData;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode<any>>(true, []);

  public hierarchyMetaData: HierarchicTable;
  private emitEvents = true;

  // selectedNode: ItemFlatNode<any>;

  constructor(protected _database: TreeDatabaseService) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode<any>>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    // Emit event every time selection changes
    this.checklistSelection.changed.pipe(
    ).subscribe(data => {
      if (this.emitEvents) {
        this.selection.next(this.checklistSelection.selected);
        this.changes.next({
          added: data.added,
          removed: data.removed
        });
      }
      // console.log(data);

    });
  }

  ngOnInit() {
    if (this.refreshData$) {
      this.refreshData$.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          /** Trigger db change to refresh data **/
          this._database.tableName.next({tableName: this.tableName, withGrants: this.withGrants, parentsItems: this.parentsItems});
          this.loadData();
        })
      ).subscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);

    /** Whether nodes are selected on selectedIds @Input change. */
    if (changes.selectedIds
      && this.selectedIds !== undefined && this.flattenedData !== undefined && this.hierarchyMetaData !== undefined) {
      // console.log(this.selectedIds);
      this.checkSelectedIds();
    }

    if (changes.expandNode && changes.expandNode.currentValue) {
      /**
       * Search for node to be expanded in the tree
       */
      this._database.tableName.next({tableName: this.tableName, withGrants: this.withGrants, parentsItems: this.parentsItems});
      this._database.dataChange.pipe(
        takeUntil(this.unsubscribe$),
        map(([nodes, flattened]) => {
          const flattenedNodes = this.treeFlattener.flattenNodes(this.dataSource.data);
          const found = flattenedNodes.find(node => node.data[this.hierarchyMetaData.idColumnName] === this.expandNode[this.hierarchyMetaData.idColumnName]);
          if (found) {
            this.treeControl.expand(this.getParentNode(found));
          }
        })
      ).subscribe();

    }

    if (changes.tableName) {
      this.tableName = changes.tableName.currentValue;
      // Update tree database tableName to refresh data
      this._database.tableName.next({tableName: this.tableName, withGrants: this.withGrants, parentsItems: this.parentsItems});
    }
  }

  ngAfterViewInit() {
    this._database.tableName.next({tableName: this.tableName, withGrants: this.withGrants, parentsItems: this.parentsItems});

    // combineLatest([this._database.hierarchyMetadata$, this._database.dataChange])
    //   .pipe(
    //     // tap(a => console.log(a)),
    //     map((a) => {
    //       this.hierarchyMetaData = a[0];
    //       this.dataSource.data = a[1];
    //       this.flattenedData = this.treeFlattener.flattenNodes(this.dataSource.data);
    //       this.sourceItems.next(this.flattenedData);
    //     })
    //   ).subscribe(_ => this.checkSelectedIds());

    this.loadData();

    // this.dataSource._flattenedData.pipe(
    //   //tap(e => console.log(e)),
    //   // Filter out empty array
    //   filter(array => array.length > 0),
    //   map(flattenedData => this.flattenedData = flattenedData)
    // ).subscribe(_ => this.checkSelectedIds());
  }

  loadData(): void {
    combineLatest([this._database.hierarchyMetadata$, this._database.dataChange])
      .pipe(
        first(),
        map((a) => {
          this.hierarchyMetaData = a[0];
          this.sortAlphabetically(a[1]);
          this.dataSource.data = a[1];
          // console.log(this.dataSource.data);
          this.flattenedData = this.treeFlattener.flattenNodes(this.dataSource.data);
          this.sourceItems.next(this.flattenedData);
        })
      ).subscribe(_ => this.checkSelectedIds());
  }

  getLevel = (node: ItemFlatNode<any>) => node.level;

  isExpandable = (node: ItemFlatNode<any>) => node.expandable;

  getChildren = (node: ItemNode<any>): ItemNode<any>[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode<any>) => _nodeData.expandable;

  displayChildren = (_: number, _nodeData: ItemFlatNode<any>) => !this.onlyShowParents && !_nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode<any>) => _nodeData.data === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode<any>, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.data === node.data
      ? existingNode
      : new ItemFlatNode();
    flatNode.displayNameId = node.displayNameId;
    flatNode.data = node.data;
    flatNode.name = node.name;
    flatNode.level = level;
    if (node.children) { // TODO Check BusDraggableTree Children // expandable
      flatNode.expandable = !!node.children.length; // flatNode.expandable = !!node.children;
    }
    if (node.color) {
      flatNode.color = node.color;
    }
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  checkSelectedIds() {
    this.emitEvents = false;
    this.checklistSelection.clear();
    if (this.selectedIds && this.selectedIds.length > 0) {
      const valuesToSelect = this.flattenedData.filter(n => this.selectedIds.includes(n.data[this.hierarchyMetaData.idColumnName]));
      this.checklistSelection.select(...valuesToSelect);
      this.checklistSelection.selected.forEach(n => {
        if (n !== undefined) {
          const p = this.getParentNode(n);
          if (p.expandable && this.descendantsAllSelected(p)) {
            this.checklistSelection.select(p);
          }
        }

      });
    }
    this.emitEvents = true;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode<any>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode<any>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode<any>): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ItemFlatNode<any>): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode<any>): void {
    let parent: ItemFlatNode<any> | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode<any>): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode<any>): ItemFlatNode<any> | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    // console.log(this.treeControl)
    // console.log(this.treeControl.dataNodes);

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: ItemFlatNode<any>) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode, ''); // parentNode!
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: ItemFlatNode<any>, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode, itemValue); // nestedNode!
  }

  // selectNode(node: ItemFlatNode<any>) {
  //   if (this.selectedNode === node) {
  //     this.selectedNode$.next(null);
  //     this.selectedNode = null;
  //   } else {
  //     this.selectedNode$.next(node);
  //     this.selectedNode = node;
  //   }
  // }

  sortAlphabetically(nodes: ItemNode<any>[]): void {
    nodes = nodes.sort( (a, b) => {
      return a.name.localeCompare(b.name);
    });
    nodes.forEach(n => {
      if (n.children && n.children.length > 0) {
        this.sortAlphabetically(n.children);
      }
    });

  }
}
