import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SIMULATION_PHOTO } from '../../../domain-models/business/simulation-photo.model';
import { SIMULATION } from '../../../domain-models/business/simulation.model';

@Component({
  selector: 'echo-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({opacity: 0}),
        animate(300, style({opacity: 1})),
      ]),
      transition(':leave', [
        animate(300, style({opacity: 0})),
      ]),
    ]),
  ],
})
export class CustomTooltipComponent implements OnInit {

  SIMULATION_PHOTO = SIMULATION_PHOTO;
  SIMULATION = SIMULATION;

  @Input() data: any;

  constructor() {
  }

  ngOnInit() {
  }

}
