import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Utils } from '../../shared/utils';

export const FLOOR_DATA_IMPORT = {
  databaseTableName: 'FloorDataImport',
  requestId: 'FlDaIm_RequestId',
  requestTimestamp: 'FlDaIm_RequestTimestamp',
  tempFolderPath: 'FlDaIm_TempFolderPath',
  taskId: 'FlDaIm_TaskId',
  variantTaskId: 'FlDaIm_VarianteTaskId',
};

export class FloorDataImport {
  constructor(
    public databaseTableName: string,
    public requestId: string,
    public requestTimestamp: Date,
    public tempFolderPath: string,
    public taskId: number,
    public variantTaskId: number
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorDataImportAdapter implements Adapter<FloorDataImport> {
  adapt(item: any): FloorDataImport {
    return new FloorDataImport(
      FLOOR_DATA_IMPORT.databaseTableName,
      item[FLOOR_DATA_IMPORT.requestId],
      new Date(item[FLOOR_DATA_IMPORT.requestTimestamp]),
      item[FLOOR_DATA_IMPORT.tempFolderPath],
      item[FLOOR_DATA_IMPORT.taskId],
      item[FLOOR_DATA_IMPORT.variantTaskId]
    );
  }

  encode(item: FloorDataImport): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_DATA_IMPORT.databaseTableName,
      [FLOOR_DATA_IMPORT.requestId]: item.requestId,
      [FLOOR_DATA_IMPORT.requestTimestamp]: Utils.dateToBackendString(item.requestTimestamp),
      [FLOOR_DATA_IMPORT.tempFolderPath]: item.tempFolderPath,
      [FLOOR_DATA_IMPORT.taskId]: item.taskId,
      [FLOOR_DATA_IMPORT.variantTaskId]: item.variantTaskId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
