import { createSelector } from '@ngrx/store';
import * as fromRoom from '../reducers/room.reducer';
import { State } from '../reducers/room.reducer';
import { RoomAttributionTypeEnum } from '../../../domain-models/business/room-attribution-type.model';
import { selectFloorsState } from '../reducers';
import { RoomActivityStatusTypeEnum } from '../../../domain-models/business/room-activity-status-type.model';
import { FloorDataSelectors } from '../../svg-store/selectors';
import { selectAllRoomAllocationsByBuildingId, selectAllRoomAllocationsByTaskId } from './room-allocation.selectors';

/** Select store slice **/

export const selectRooms = createSelector(
  selectFloorsState,
  state => state[fromRoom.roomFeatureKey]
);

/** Basics selectors **/

export const selectRoomIds = createSelector(
  selectRooms,
  fromRoom.selectRoomIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectRoomEntities = createSelector(
  selectRooms,
  fromRoom.selectRoomEntities
);
export const selectAllRooms = createSelector(
  selectRooms,
  fromRoom.selectAllRooms
);
export const selectRoomTotal = createSelector(
  selectRooms,
  fromRoom.selectRoomTotal
);
export const selectCurrentRoomId = createSelector(
  selectRooms,
  fromRoom.getSelectedRoomId
);

export const selectCurrentRoom = createSelector(
  selectRoomEntities,
  selectCurrentRoomId,
  (roomEntities, roomId) => roomEntities[roomId]
);

export const selectRoomById = (id: number) => createSelector(
  selectRoomEntities,
  (entities) => entities[id]
);

/**
 * Advanced
 * **/

export const getSelectedRoomIds = createSelector(
  selectRooms,
  (state: State) => state.selectedIds
);

export const getSelectedRooms = createSelector(
  selectAllRooms,
  getSelectedRoomIds,
  (rooms, ids) =>
    rooms.filter(r => r.floorDataId === ids.find(e => e === r.floorDataId))
);

/** Room Selectors **/
export const selectRoomsByBuildingId = (buildingId: number) => createSelector(
  selectAllRooms,
  FloorDataSelectors.selectFloorDataIdsByBuildingId(buildingId),
  (rooms, buildingFloorDataIds) => rooms.filter(r => buildingFloorDataIds.find(id => id === r.floorDataId))
);

export const selectRoomIdsByBuildingId = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => rooms.map(r => r.floorDataId)
);

export const selectRoomsByTaskId = (taskId: number) => createSelector(
  selectAllRooms,
  FloorDataSelectors.selectFloorDataIdsByTaskId(taskId),
  (rooms, taskFloorDataIds) => rooms.filter(r => taskFloorDataIds.find(id => id === r.floorDataId))
);

export const selectRoomIdsByTaskId = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => rooms.map(r => r.floorDataId)
);

/** Select rooms with multiple roomAllocations by Bu_Id **/
export const selectMultiAllocatedRoomsByBuildingId = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  selectAllRoomAllocationsByBuildingId(buildingId),
  (rooms, roomAllocations) =>
    rooms.filter(r => roomAllocations.filter(a => a.floorDataId === r.floorDataId).length > 1)
);

/** Select rooms with multiple roomAllocations by Ta_Id **/
export const selectMultiAllocatedRoomsByTaskId = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  selectAllRoomAllocationsByTaskId(taskId),
  (rooms, roomAllocations) =>
    rooms.filter(r => roomAllocations.filter(a => a.floorDataId === r.floorDataId).length > 1)
);

/**
 * Area Selectors
 */

export const selectBuildingRoomLayoutTypeAreaById = (layoutTypeId: number, buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.layoutTypeId === layoutTypeId)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorRoomLayoutTypeAreaById = (layoutTypeId: number, taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.layoutTypeId === layoutTypeId)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingNotAllocatedArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  selectAllRoomAllocationsByBuildingId(buildingId),
  (rooms, roomAllocations) => {
    let totalArea = 0;
    const filteredBuildingRooms = rooms.filter(r => r.attributionTypeId !== RoomAttributionTypeEnum.Allocation
      && !roomAllocations.find(a => a.floorDataId === r.floorDataId));
    filteredBuildingRooms.forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorNotAllocatedArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  selectAllRoomAllocationsByTaskId(taskId),
  (rooms, roomAllocations) => {
    let totalArea = 0;
    const filteredTaskRooms = rooms.filter(r => r.attributionTypeId !== RoomAttributionTypeEnum.Allocation
      && !roomAllocations.find(a => a.floorDataId === r.floorDataId));
    filteredTaskRooms.forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingSharingdArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Sharing)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorSharingArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Sharing)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingAllocatedArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Allocation)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorAllocatedArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Allocation)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingMultiAllocatedArea = (buildingId: number) => createSelector(
  selectMultiAllocatedRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorMultiAllocatedArea = (taskId: number) => createSelector(
  selectMultiAllocatedRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingExploitationArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Exploitation)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorExploitationArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.attributionTypeId === RoomAttributionTypeEnum.Exploitation)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingUsableArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.Usable)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorUsableArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.Usable)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingEmptyArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.Empty)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorEmptyArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.Empty)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectBuildingUnderConstructionArea = (buildingId: number) => createSelector(
  selectRoomsByBuildingId(buildingId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);

export const selectFloorUnderConstructionArea = (taskId: number) => createSelector(
  selectRoomsByTaskId(taskId),
  (rooms) => {
    let totalArea = 0;
    rooms.filter(r => r.activityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction)
      .forEach(r => totalArea += r.area);
    return totalArea;
  }
);
