import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskContributorActions } from '../actions';
import { TaskContributor } from '../../../domain-models/business/task-contributor.model';

export const taskContributorFeatureKey = 'taskContributors';

export interface State extends EntityState<TaskContributor> {
  selectedTaskContributorId: number | null;
}

export function generateId(a: TaskContributor): string {
  return `${a.taskId}-${a.contributorId}-${a.functionId}`;
}

export const adapter: EntityAdapter<TaskContributor> = createEntityAdapter<TaskContributor>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  selectedTaskContributorId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskContributorActions.addTaskContributor, (state, { taskContributor }) => {
    return adapter.addOne(taskContributor, state);
  }),
  on(TaskContributorActions.setTaskContributor, (state, { taskContributor }) => {
    return adapter.setOne(taskContributor, state);
  }),
  on(TaskContributorActions.upsertTaskContributor, (state, { taskContributor }) => {
    return adapter.upsertOne(taskContributor, state);
  }),
  on(TaskContributorActions.addTaskContributors, (state, { taskContributors }) => {
    return adapter.addMany(taskContributors, state);
  }),
  on(TaskContributorActions.upsertTaskContributors, (state, { taskContributors }) => {
    return adapter.upsertMany(taskContributors, state);
  }),
  on(TaskContributorActions.updateTaskContributor, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskContributorActions.updateTaskContributors, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskContributorActions.mapTaskContributors, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskContributorActions.deleteTaskContributor, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskContributorActions.deleteTaskContributors, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskContributorActions.deleteTaskContributorsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskContributorActions.loadTaskContributors, (state, { taskContributors }) => {
    return adapter.setAll(taskContributors, state);
  }),
  on(TaskContributorActions.clearTaskContributors, state => {
    return adapter.removeAll({ ...state, selectedTaskContributorId: null });
  }),
  /** END OF CRUD **/
  on(TaskContributorActions.updateSelectedTaskContributorId, (state, {taskContributorId}) => {
    return ({...state, selectedTaskContributorId: taskContributorId});
  }),
);

export const getSelectedTaskContributorId = (state: State) => state.selectedTaskContributorId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskContributor ids
export const selectTaskContributorIds = selectIds;

// select the dictionary of taskContributor entities
export const selectTaskContributorEntities = selectEntities;

// select the array of taskContributors
export const selectAllTaskContributors = selectAll;

// select the total taskContributor count
export const selectTaskContributorTotal = selectTotal;
