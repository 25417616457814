import { createSelector } from '@ngrx/store';
import * as fromProjectManagement from '../reducers/project-management-ui.reducer';
import { selectProjectManagementState } from '../reducers';

/** Select store slice **/

export const selectProjectManagementUi = createSelector(
  selectProjectManagementState,
  state => state[fromProjectManagement.projectManagementFeatureKey]
);

export const selectTaskCreationDto = createSelector(
  selectProjectManagementUi,
  (state) => state.taskCreationDto
);
