import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const EQUIPMENT_STORAGE_AREA = {
  databaseTableName: 'EquipmentStorageArea',
  id: 'EqStAr_Id',
  name: 'EqStAr_Name',
  addressId: 'EqStAr_AddressId',
  roomId: 'EqStAr_RoomId',
  scope: 'EqStAr_Scope'
};

export class EquipmentStorageArea {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public addressId: number,
    public roomId: number,
    public scope: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class EquipmentStorageAreaAdapter implements Adapter<EquipmentStorageArea> {
  adapt(item: any): EquipmentStorageArea {
    return new EquipmentStorageArea(
      EQUIPMENT_STORAGE_AREA.databaseTableName,
      item[EQUIPMENT_STORAGE_AREA.id],
      item[EQUIPMENT_STORAGE_AREA.name],
      item[EQUIPMENT_STORAGE_AREA.addressId],
      item[EQUIPMENT_STORAGE_AREA.roomId],
      item[EQUIPMENT_STORAGE_AREA.scope]
    );
  }

  encode(item: EquipmentStorageArea | Partial<EquipmentStorageArea>): ISingleBackendObj {
    const encoded = {
      TableName: EQUIPMENT_STORAGE_AREA.databaseTableName,
      [EQUIPMENT_STORAGE_AREA.id]: item.id,
      [EQUIPMENT_STORAGE_AREA.name]: item.name,
      [EQUIPMENT_STORAGE_AREA.addressId]: item.addressId,
      [EQUIPMENT_STORAGE_AREA.roomId]: item.roomId,
      [EQUIPMENT_STORAGE_AREA.scope]: item.scope,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
