import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum ZAppMenuEnum {
  FIMMap = 25,
  FIMLayers = 35,
  FIMPictograms = 36,
  FIMWallStyles = 39,
  FIMDoorStyles = 40,
  RealEstateAddresses = 55,
  RealEstateGeoRef = 37,
  RealEstateLeaseContractors = 51,
  RealEstateLeaseDuration = 49,
  RealEstateLeaseRentItems = 53,
  RealEstateLeases = 24,
  RealEstateLeaseTypes = 54,
  RealEstatePerimeters = 60,
  ProjectsConttributors = 23,
  ProjectsFunctions = 22,
  ProjectsGantt = 14,
  ProjectsSimulations = 27,
  ProjectsStatus = 28,
  ProjectsTasks = 38,
  PlanningRoomLayoutTypes = 8,
  PlanningWorkplaceTypes = 20,
  InventoryCatalog = 26,
  InventoryCategories = 44,
  InventoryDashboard = 48,
  InventoryProviders = 15,
  InventoryPurchase = 57,
  InventoryStorage = 46,
  FacilityCompanies = 64,
  FacilityCompanySkills = 65,
  FacilityJobTypes = 16,
  FacilityLinesOfWork = 63,
  FacilityUnitsOfWork = 19,
  DirectoriesBus = 10,
  DirectoriesPeople = 17,
  AccountsContractorsGrants = 66,
  AccountsContractorsReferencing = 59,
  AccountsGrants = 41,
  AccountsMailDomains = 58,
  AccountsRoles = 4,
  AccountsUsers = 2,
}

export const Z_APP_MENU = {
  databaseTableName: 'z_app_menu',
  id: 'ApMe_Id',
  displayNameId: 'ApMe_DisplayNameId',
  parentId: 'ApMe_ParentId',
  grantId: 'ApMe_GrantId',
  scopeId: 'ApMe_ScopeId',
  orderIndex: 'ApMe_OrderIndex',
  depth: 'ApMe_Depth',
  isCustomizable: 'ApMe_IsCustomizable',
  apiEndPoint: 'ApMe_ApiEndPoint',
  componentPath: 'ApMe_ComponentPath',
  dataContext: 'ApMe_DataContext',
  usageContextId: 'ApMe_ContextUIId',
};

export class AppMenu {
  constructor(
    public databaseTableName: string,
    public id: number,
    public displayNameId: number,
    public parentId: number,
    public grantId: number,
    public scopeId: number,
    public orderIndex: number,
    public depth: number,
    public isCustomizable: boolean,
    public apiEndPoint: string,
    public componentPath: string,
    public dataContext: string,
    public usageContextId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class AppMenuAdapter implements Adapter<AppMenu> {

  adapt(item: any): AppMenu {
    return new AppMenu(
      Z_APP_MENU.databaseTableName,
      item[Z_APP_MENU.id],
      item[Z_APP_MENU.displayNameId],
      item[Z_APP_MENU.parentId],
      item[Z_APP_MENU.grantId],
      item[Z_APP_MENU.scopeId],
      item[Z_APP_MENU.orderIndex],
      item[Z_APP_MENU.depth],
      item[Z_APP_MENU.isCustomizable],
      item[Z_APP_MENU.apiEndPoint],
      item[Z_APP_MENU.componentPath],
      item[Z_APP_MENU.dataContext],
      item[Z_APP_MENU.usageContextId],
    );
  }

  encode(item: AppMenu): ISingleBackendObj {
    const encoded = {
      TableName: Z_APP_MENU.databaseTableName,
      [Z_APP_MENU.id]: item.id,
      [Z_APP_MENU.displayNameId]: item.displayNameId,
      [Z_APP_MENU.parentId]: item.parentId,
      [Z_APP_MENU.grantId]: item.grantId,
      [Z_APP_MENU.scopeId]: item.scopeId,
      [Z_APP_MENU.grantId]: item.orderIndex,
      [Z_APP_MENU.grantId]: item.depth,
      [Z_APP_MENU.grantId]: item.isCustomizable,
      [Z_APP_MENU.grantId]: item.apiEndPoint,
      [Z_APP_MENU.grantId]: item.componentPath,
      [Z_APP_MENU.grantId]: item.dataContext,
      [Z_APP_MENU.grantId]: item.usageContextId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
