import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';
import { DISPLAY_THEME } from '../business/display-theme.model';
import { DISPLAY_THEMABLE } from '../business/display-themable.model';
import { Z_LANGUAGE_ITEM } from '../core/z-language-item.model';

export const DISPLAY_THEME_ITEM_VIEW = {
  databaseTableName: 'DisplayThemeItemView',
  displayThemeId: DISPLAY_THEME.id,
  displayThemeDisplayNameId: DISPLAY_THEME.displayNameId,
  displayThemeCategoryId: DISPLAY_THEME.categoryId,
  displayThemableId: DISPLAY_THEMABLE.id,
  displayThemableDisplayNameId: DISPLAY_THEMABLE.displayNameId,
  displayThemableColor: DISPLAY_THEMABLE.color,
  displayThemableSvgPatternId: DISPLAY_THEMABLE.svgPatternId,
  displayThemableName: Z_LANGUAGE_ITEM.text,
  themableTableName: 'ThemableTableName',
};

export class DisplayThemeItemView {
  constructor(
    public databaseTableName: string,
    public displayThemeId: number,
    public displayThemeDisplayNameId: number,
    public displayThemeCategoryId: number,
    public displayThemableId: number,
    public displayThemableDisplayNameId: number,
    public displayThemableColor: Color,
    public displayThemableSvgPatternId: number,
    public displayThemableName: string,
    public themableTableName: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class DisplayThemeItemViewAdapter implements Adapter<DisplayThemeItemView> {
  adapt(item: any): DisplayThemeItemView {
    return new DisplayThemeItemView(
      DISPLAY_THEME_ITEM_VIEW.databaseTableName,
      item[DISPLAY_THEME_ITEM_VIEW.displayThemeId],
      item[DISPLAY_THEME_ITEM_VIEW.displayThemeDisplayNameId],
      item[DISPLAY_THEME_ITEM_VIEW.displayThemeCategoryId],
      item[DISPLAY_THEME_ITEM_VIEW.displayThemableId],
      item[DISPLAY_THEME_ITEM_VIEW.displayThemableDisplayNameId],
      ColorUtils.colorFromHexARGB(item[DISPLAY_THEME_ITEM_VIEW.displayThemableColor]),
      item[DISPLAY_THEME_ITEM_VIEW.displayThemableSvgPatternId],
      item[DISPLAY_THEME_ITEM_VIEW.displayThemableName],
      item[DISPLAY_THEME_ITEM_VIEW.themableTableName]
    );
  }
}
