import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const DIRECTORY = {
  databaseTableName: 'Directory',
  id: 'Di_Id',
  code: 'Di_Code',
  title: 'Di_Title',
  firstName: 'Di_FirstName',
  lastName: 'Di_LastName',
  businessUnitId: 'Di_BusinessUnitId',
  occupancyRatio: 'Di_OccupancyRatio',
  isInternal: 'Di_IsInternal'
};

export class Directory {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public title: string,
    public firstName: string,
    public lastName: string,
    public businessUnitId: number,
    public occupancyRatio: number,
    public isInternal: boolean
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class DirectoryAdapter implements Adapter<Directory> {
  adapt(item: any): Directory {
    return new Directory (
      DIRECTORY.databaseTableName,
      item[DIRECTORY.id],
      item[DIRECTORY.code],
      item[DIRECTORY.title],
      item[DIRECTORY.firstName],
      item[DIRECTORY.lastName],
      item[DIRECTORY.businessUnitId],
      item[DIRECTORY.occupancyRatio],
      item[DIRECTORY.isInternal],
    );
  }
  encode(item: Directory): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DIRECTORY.id]: item.id,
      [DIRECTORY.code]: item.code,
      [DIRECTORY.title]: item.title,
      [DIRECTORY.firstName]: item.firstName,
      [DIRECTORY.lastName]: item.lastName,
      [DIRECTORY.businessUnitId]: item.businessUnitId,
      [DIRECTORY.occupancyRatio]: item.occupancyRatio,
      [DIRECTORY.isInternal]: item.isInternal,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
