import { createAction, props } from '@ngrx/store';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';
import { Update } from '@ngrx/entity';

export const addFloorCatalog = createAction(
  '[Floor/API] Add FloorCatalog (SvgGroup)',
  props<{ svgGroup: SvgGroup }>()
);

export const addFloorCatalogItems = createAction(
  '[Floor/API] - Add FloorCatalog Items (SvgGroups)',
  props<{ svgGroups: SvgGroup[] }>()
);

export const removeFloorCatalogItem = createAction(
  '[Floor/API] - Remove FloorCatalog',
  props<{ id: number }>()
);

export const removeFloorCatalogItems = createAction(
  '[Floor/API] - Remove FloorCatalogItems',
  props<{ ids: number[] }>()
);

export const updateFloorCatalog = createAction(
  '[Floor] Update FloorCatalog (SvgGroup)',
  props<{ svgGroup: Update<SvgGroup> }>()
);

export const updateFloorCatalogItems = createAction(
  '[Floor] Update FloorCatalogItems (SvgGroup[])',
  props<{ svgGroups: Update<SvgGroup>[] }>()
);

export const upsertFloorCatalog = createAction(
  '[Floor] Upsert FloorCatalog item (SvgGroup)',
  props<{ svgGroup: SvgGroup }>()
);

export const upsertFloorCatalogItems = createAction(
  '[Floor] Upsert FloorCatalog items (SvgGroup[])',
  props<{ svgGroups: SvgGroup[] }>()
);

export const clearFloorCatalogItems = createAction('[Floor] Clear FloorCatalog (SvgGroups)');

// export const addFloorCatalogItems = createAction(
//   '[Floor/API] - Add all Floor Catalog',
//   props<{ floorCatalog: SvgGroup[] }>()
// );
//
// export const addFloorCatalog = createAction(
//   '[Floor/API] - Add Floor Catalog',
//   props<{ floorCatalog: SvgGroup }>()
// );
//
// export const addItemToTask = createAction(
//   '[SVG/Renderer] - Add FloorCatalog to task',
//   props<{ id: number, taskId: number, x: number, y: number }>()
// );

export const getAllFloorCatalog = createAction(
  '[Floor/API] - Get All Floor Catalog'
);

export const clearFloorCatalog = createAction('[Floor] Clear FloorCatalog');
