import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const TASK_CONTRIBUTOR = {
  databaseTableName: 'task_contributor',
  taskId: 'TaCr_TaskId',
  contributorId: 'TaCr_ContributorId',
  load: 'TaCr_Load',
  functionId: 'TaCr_FunctionId',
};

export class TaskContributor {
  constructor(
    public databaseTableName: string,
    public taskId: number,
    public contributorId: number,
    public load: number,
    public functionId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskContributorAdapter implements Adapter<TaskContributor> {
  adapt(item: any): TaskContributor {
    return new TaskContributor(
      TASK_CONTRIBUTOR.databaseTableName,
      item[TASK_CONTRIBUTOR.taskId],
      item[TASK_CONTRIBUTOR.contributorId],
      item[TASK_CONTRIBUTOR.load],
      item[TASK_CONTRIBUTOR.functionId],
    );
  }

  encode(item: TaskContributor | Partial<TaskContributor>): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_CONTRIBUTOR.taskId]: item.taskId,
      [TASK_CONTRIBUTOR.contributorId]: item.contributorId,
      [TASK_CONTRIBUTOR.load]: item.load,
      [TASK_CONTRIBUTOR.functionId]: item.functionId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
