import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { CoreSelectors } from '../../store/core-store/selectors';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

/** Append Echo-Company-Id to the next request handler. */
@Injectable()
export class CallerRequestIdInterceptor implements HttpInterceptor {

  callerRequestId$: Observable<string>;
  callerRequestId: string;

  constructor(private store: Store<State>) {
    this.callerRequestId$ = this.store.select(CoreSelectors.selectCallerRequestId);
    this.callerRequestId$.pipe(
      //tap(a => console.log(a)),
      map(callerRequestId => this.callerRequestId = callerRequestId)
    ).subscribe();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq: HttpRequest<any>;
    if (environment.envName !== 'localhost') {
      modifiedReq = req.clone({
        headers: this.addExtraHeaders(req.headers)
      });
    } else {
      modifiedReq = req;
    }
    return next.handle(modifiedReq);
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    // return this.client$.pipe(
    //   //tap(a => console.log(a)),
    //   switchMap(client => {
    //     if(client) {
    //       headers = headers.append('Echo-Company-Id', client.CompanyId);
    //     }
    //     return of(headers);
    //   })
    // );

    headers = headers.append('echo-caller', environment.envName);
    if (this.callerRequestId) {
      headers = headers.append('echo-caller-request-id', this.callerRequestId);
    } else {
      headers = headers.append('echo-caller-request-id', 'NA');
    }


    return headers;

    // if (sessionStorage.getItem('client_file')) {
    //   this.client = JSON.parse(sessionStorage.getItem('client_file'));
    //   headers = headers.append('Echo-Company-Id', this.client.CompanyId);
    // }
    // return headers;
  }
}
