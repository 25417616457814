import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'echo-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss']
})
export class FilePreviewDialogComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  loaded = false;
  @ViewChild('iframe', {static: false}) viewer: ElementRef;

  constructor(public dialogRef: MatDialogRef<FilePreviewDialogComponent>,
              public sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: { url: string }) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${this.data.url}&embedded=true`);
    // this.dialogRef.updateSize('80%', '90%');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  viewerLoaded(event: Event): void {
    if (this.viewer) {
      this.loaded = true;
      this.dialogRef.updateSize('80%', '90%');
    }
  }

}
