import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../../core/services/user.service';
import { Router } from '@angular/router';
import { LayoutService } from '../../../../core/services/layout.service';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { Observable } from 'rxjs';
import { CoreSelectors } from '../../../../store/core-store/selectors';
import { UserIdentity } from '../../../../domain-models/business-extended/user.model';

@Component({
  selector: 'echo-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIconComponent implements OnInit {

  @Input('companion') isCompanion: boolean;

  user$: Observable<UserIdentity>;
  toggleMenu: boolean;
  notificationsCount: number;

  constructor(private store: Store<State>,
              private userService: UserService,
              private layoutService: LayoutService,
              private router: Router) {
    this.user$ = this.store.select(CoreSelectors.selectUser);

    this.layoutService.rightDrawer$.subscribe(o => {
      this.toggleMenu = o;
    });
  }

  ngOnInit() {
  }

  navigate() {
    this.router.navigateByUrl('app/(debug//sidenav:debug)');
  }

  drawerToggle() {
    //this.toggleMenu = !this.toggleMenu;
    //this.layoutService.rightDrawer$.next(this.toggleMenu);
  }

}
