import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as FloorDataActions from '../actions/floor-data.actions';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';

export const floorDataFeatureKey = 'floorData';

export interface State extends EntityState<SvgGroup> {
  selectedIds: number[];
}

export const adapter: EntityAdapter<SvgGroup> = createEntityAdapter<SvgGroup>();

export const initialState: State = adapter.getInitialState({
  selectedIds: [],
});

export const reducer = createReducer(
  initialState,
  on(FloorDataActions.addFloorDataItems, (state, {svgGroups}) => {
    return adapter.addMany(svgGroups, state);
  }),
  on(FloorDataActions.addFloorData, (state, {svgGroup}) => {
    return adapter.addOne(svgGroup, state);
  }),
  on(FloorDataActions.updateFloorData, (state, {svgGroup}) => {
    return adapter.updateOne(svgGroup, state);
  }),
  on(FloorDataActions.updateFloorDataItems, (state, {svgGroups}) => {
    return adapter.updateMany(svgGroups, state);
  }),
  on(FloorDataActions.upsertFloorData, (state, {svgGroup}) => {
    return adapter.upsertOne(svgGroup, state);
  }),
  on(FloorDataActions.upsertFloorDataItems, (state, {svgGroups}) => {
    return adapter.upsertMany(svgGroups, state);
  }),
  on(FloorDataActions.removeFloorDataItem, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(FloorDataActions.removeFloorDataItems, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(FloorDataActions.clearFloorDataItems, state => {
    return adapter.removeAll({...state});
  }),
  on(FloorDataActions.addToSelection, (state, {id, clearSelection}) => {
    const existingId = state.selectedIds.find(e => e === id);
    if (clearSelection) {
      return {...state, selectedIds: [id]};
    } else {
      if (existingId) {
        return {...state, selectedIds: state.selectedIds};
      } else {
        return {...state, selectedIds: [...state.selectedIds, id]};
      }
    }
  }),
  on(FloorDataActions.updateSelection, (state, {addedId, removedId}) => {
    const alreadySelectedId = state.selectedIds.find(e => e === addedId);
    const idToRemoveIndex = state.selectedIds.indexOf(removedId, 0);

    let cases: number;
    if (addedId && !removedId) {
      if (!alreadySelectedId) {
        cases = 1;
      } else {
        cases = 0;
      }
    } else if (!addedId && removedId) {
      if (idToRemoveIndex > -1) {
        cases = 2;
      } else {
        cases = 0;
      }
    } else if (addedId && removedId) {
      if (!alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 3;
        } else {
          cases = 1;
        }
      } else if (alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 2;
        } else {
          cases = 0;
        }
      }
    } else {
      cases = 0;
    }
    switch (cases) {
      case 0:
        return state;
      case 1:
        return {...state, selectedIds: [...state.selectedIds, addedId]};
      case 2:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1)]};
      case 3:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1), addedId ]};
      default: {
        return state;
      }
    }
  }),
  on(FloorDataActions.clearSelection, (state) => {
    return {...state, selectedIds: state.selectedIds.slice(0, 0)};
  }),
  on(FloorDataActions.removeFromSelection, (state, {id}) => {
    return {...state, selectedIds: state.selectedIds.filter(e => e !== id)};
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorDataIds = selectIds;
export const selectFloorDataEntities = selectEntities;
export const selectAllFloorDataItems = selectAll;
export const selectFloorDataTotal = selectTotal;
