import { createAction, props } from '@ngrx/store';

export const clearSvgState = createAction('[Floor] - Clear SVG State');

export const beginGetGraphicsFloorFromRouterState = createAction(
  '[Floor/API] - Begin Get Graphic Floor By Id From Router State',
);

export const beginGetGraphicsFloorByTaskId = createAction(
  '[Floor/API] - Begin Get Graphic Floor By Ta_Id',
  props<{ id: number }>()
);

export const currentTaskIsEmpty = createAction(
  '[Floor/API] - Success Load EMPTY FLOOR',
  props<{ isEmpty: boolean }>()
);
export const currentGraphicsFloorIsEmpty = createAction(
  '[Floor/API] - Current Graphic Floor Is Empty',
  props<{ isEmpty: boolean }>()
);
export const successGetGraphicsFloorByTaskId = createAction(
  '[Floor/API] - Success Get Graphic Floor By taskId',
);
export const errorGetGraphicsFloorByTaskId = createAction('[API] - Error Get Graphic Floor By taskId', props<Error>());
