import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../api-endpoints';
import { map } from 'rxjs/operators';
import { DISPLAY_THEME, DisplayTheme, DisplayThemeAdapter } from '../../domain-models/business/display-theme.model';
import { ApiData } from '../../core/models/api-data.model';
import { Observable } from 'rxjs';
import { DISPLAY_THEME_ITEM, DisplayThemeItem, DisplayThemeItemAdapter } from '../../domain-models/business/display-theme-item.model';
import { DISPLAY_THEMABLE, DisplayThemable, DisplayThemableAdapter } from '../../domain-models/business/display-themable.model';
import { LoggerService } from '../../core/services/logger.service';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { LoaderService } from '../../core/services/loader.service';
import {
  DISPLAY_THEME_ITEM_VIEW,
  DisplayThemeItemView,
  DisplayThemeItemViewAdapter
} from '../../domain-models/views/display-theme-item-view.model';
import {
  DISPLAY_THEME_CATEGORY,
  DisplayThemeCategory,
  DisplayThemeCategoryAdapter
} from '../../domain-models/business/display-theme-category.model';

@Injectable({
  providedIn: 'root'
})
export class DisplayThemeService extends ApiService {

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private displayThemeItemAdapter: DisplayThemeItemAdapter,
              private displayThemableAdapter: DisplayThemableAdapter,
              private displayThemeItemViewAdapter: DisplayThemeItemViewAdapter,
              private displayThemeCategoryAdapter: DisplayThemeCategoryAdapter,
              private displayThemeAdapter: DisplayThemeAdapter) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  getDisplayTheme(): Observable<DisplayTheme[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DISPLAY_THEME.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeAdapter.adapt(item));
      })
    );
  }

  getDisplayThemeCategories(): Observable<DisplayThemeCategory[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DISPLAY_THEME_CATEGORY.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeCategoryAdapter.adapt(item));
      })
    );
  }

  getDisplayThemables(): Observable<DisplayThemable[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DISPLAY_THEMABLE.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemableAdapter.adapt(item));
      })
    );
  }

  getDisplayThemeItems(): Observable<DisplayThemeItem[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DISPLAY_THEME_ITEM.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeItemAdapter.adapt(item));
      })
    );
  }

  getDisplayThemeItemView(): Observable<DisplayThemeItemView[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DISPLAY_THEME_ITEM_VIEW.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeItemViewAdapter.adapt(item));
      })
    );
  }

}
