import { createAction, props } from '@ngrx/store';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';
import { Update } from '@ngrx/entity';
import { SvgPoint } from '../../../features/svg-factory/models/svg-point.model';

export const addFloorData = createAction(
  '[Floor/API] Add FloorData (SvgGroup)',
  props<{ svgGroup: SvgGroup }>()
);

export const addFloorDataItems = createAction(
  '[Floor/API] - Add FloorData Items (SvgGroups)',
  props<{ svgGroups: SvgGroup[] }>()
);

export const removeFloorDataItem = createAction(
  '[Floor/API] - Remove FloorData',
  props<{ id: number }>()
);

export const removeFloorDataItems = createAction(
  '[Floor/API] - Remove FloorDataItems',
  props<{ ids: number[] }>()
);

export const updateFloorData = createAction(
  '[Floor] Update FloorData (SvgGroup)',
  props<{ svgGroup: Update<SvgGroup> }>()
);

export const updateFloorDataItems = createAction(
  '[Floor] Update FloorDataItems (SvgGroup[])',
  props<{ svgGroups: Update<SvgGroup>[] }>()
);

export const upsertFloorData = createAction(
  '[Floor] Upsert FloorData item (SvgGroup)',
  props<{ svgGroup: SvgGroup }>()
);

export const upsertFloorDataItems = createAction(
  '[Floor] Upsert FloorData items (SvgGroup[])',
  props<{ svgGroups: SvgGroup[] }>()
);

export const clearFloorDataItems = createAction('[Floor] Clear FloorData (SvgGroups)');

/** Study Mode **/
export const addFloorDataToTask = createAction(
  '[Floor/StudyMode] - Add FloorData to Task',
  props<{ floorDataId: number, taskId: number }>()
);

export const removeFloorDataItemsByTaskIds = createAction(
  '[Floor/StudyMode] - Remove FloorData by taskIds',
  props<{ taskIds: number[] }>()
);

export const updateSelection = createAction(
  '[SvgRenderer] - Update FloorData selection ids',
  props<{ addedId: number, removedId: number }>()
);

export const addToSelection = createAction(
  '[SvgRenderer] - Add FloorDataId to selection',
  props<{ id: number, clearSelection?: boolean }>()
);

export const removeFromSelection = createAction(
  '[SvgRenderer] - Remove FloorDataId from selection',
  props<{ id: number }>()
);

export const clearSelection = createAction('[SvgRenderer] - Clear Selected FloorDataId');

export const translateLabel = createAction(
  '[Floor/API] Translate Label (SvgGroup)',
  props<{ id:  number, translationVector: SvgPoint }>()
);

export const floorDataTransformUpdateError = createAction(
  '[Floor/API] Error on FloorData(s) Translate or Rotate',
);
