import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromRoomLayoutType from '../reducers/room-layout-type.reducer';
import * as fromRoomActivityStatusType from '../reducers/room-activity-status-type.reducer';
import * as fromRoomAttributionType from '../reducers/room-attribution-type.reducer';
import * as fromPerimeters from '../reducers/perimeters.reducer';
import * as fromBusinessUnits from '../reducers/business-unit.reducer';
import * as fromLanguageItems from '../reducers/language-item.reducer';
import * as fromDisplayThemeItems from '../reducers/display-theme-item.reducer';
import * as fromDbColumns from '../reducers/db-column.reducer';
import * as fromFloorModels from '../reducers/floor-model.reducer';
import * as fromFloorCatalog from '../reducers/floor-catalog.reducer';
import * as fromFloorDoorStyle from '../reducers/floor-door-style.reducer';
import * as fromFloorWallStyle from '../reducers/floor-wall-style.reducer';

import * as fromRoot from '../../../reducers';

export const sharedFeatureKey = 'shared';

export interface SharedState {
  [fromRoomLayoutType.roomLayoutTypesFeatureKey]: fromRoomLayoutType.State;
  [fromRoomActivityStatusType.roomActivityStatusTypesFeatureKey]: fromRoomActivityStatusType.State;
  [fromRoomAttributionType.roomAttributionTypesFeatureKey]: fromRoomAttributionType.State;
  [fromPerimeters.perimetersFeatureKey]: fromPerimeters.State;
  [fromBusinessUnits.businessUnitsFeatureKey]: fromBusinessUnits.State;
  [fromLanguageItems.languageItemsFeatureKey]: fromLanguageItems.State;
  [fromDisplayThemeItems.displayThemeItemFeatureKey]: fromDisplayThemeItems.State;
  [fromDbColumns.dbColumnFeatureKey]: fromDbColumns.State;
  [fromFloorModels.floorModelsFeatureKey]: fromFloorModels.State;
  [fromFloorCatalog.floorCatalogsFeatureKey]: fromFloorCatalog.State;
  [fromFloorDoorStyle.floorDoorStylesFeatureKey]: fromFloorDoorStyle.State;
  [fromFloorWallStyle.floorWallStylesFeatureKey]: fromFloorWallStyle.State;
}

export interface State extends fromRoot.State {
  [sharedFeatureKey]: SharedState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: SharedState | undefined, action: Action) {
  return combineReducers({
    [fromRoomLayoutType.roomLayoutTypesFeatureKey]: fromRoomLayoutType.reducer,
    [fromRoomActivityStatusType.roomActivityStatusTypesFeatureKey]: fromRoomActivityStatusType.reducer,
    [fromRoomAttributionType.roomAttributionTypesFeatureKey]: fromRoomAttributionType.reducer,
    [fromPerimeters.perimetersFeatureKey]: fromPerimeters.reducer,
    [fromBusinessUnits.businessUnitsFeatureKey]: fromBusinessUnits.reducer,
    [fromLanguageItems.languageItemsFeatureKey]: fromLanguageItems.reducer,
    [fromDisplayThemeItems.displayThemeItemFeatureKey]: fromDisplayThemeItems.reducer,
    [fromDbColumns.dbColumnFeatureKey]: fromDbColumns.reducer,
    [fromFloorModels.floorModelsFeatureKey]: fromFloorModels.reducer,
    [fromFloorCatalog.floorCatalogsFeatureKey]: fromFloorCatalog.reducer,
    [fromFloorDoorStyle.floorDoorStylesFeatureKey]: fromFloorDoorStyle.reducer,
    [fromFloorWallStyle.floorWallStylesFeatureKey]: fromFloorWallStyle.reducer,
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectSharedState = createFeatureSelector<SharedState>(
  sharedFeatureKey
);
