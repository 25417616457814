import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EquipmentPlanningActions } from '../actions';
import { EquipmentPlanning } from '../../../domain-models/business/equipment-planning.model';


export const equipmentPlanningFeatureKey = 'equipmentPlanning';

export interface State extends EntityState<EquipmentPlanning> {
  // additional entities state properties
  // selectedEquipmentPlanningId: number | null;
}

export function generateId(a: EquipmentPlanning): number {
  return a.floorDataId;
}

export const adapter: EntityAdapter<EquipmentPlanning> = createEntityAdapter<EquipmentPlanning>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  // selectedEquipmentPlanningId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(EquipmentPlanningActions.addEquipmentPlanning, (state, {equipmentPlanning}) => {
    return adapter.addOne(equipmentPlanning, state);
  }),
  on(EquipmentPlanningActions.setEquipmentPlanning, (state, {equipmentPlanning}) => {
    return adapter.setOne(equipmentPlanning, state);
  }),
  on(EquipmentPlanningActions.upsertEquipmentPlanning, (state, {equipmentPlanning}) => {
    return adapter.upsertOne(equipmentPlanning, state);
  }),
  on(EquipmentPlanningActions.addEquipmentPlanningItems, (state, {equipmentPlanningItems}) => {
    return adapter.addMany(equipmentPlanningItems, state);
  }),
  on(EquipmentPlanningActions.upsertEquipmentPlanningItems, (state, {equipmentPlanningItems}) => {
    return adapter.upsertMany(equipmentPlanningItems, state);
  }),
  on(EquipmentPlanningActions.updateEquipmentPlanning, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(EquipmentPlanningActions.updateEquipmentPlanningItems, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(EquipmentPlanningActions.mapEquipmentPlanningItems, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(EquipmentPlanningActions.deleteEquipmentPlanning, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(EquipmentPlanningActions.deleteEquipmentPlanningItems, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(EquipmentPlanningActions.deleteEquipmentPlanningItemsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(EquipmentPlanningActions.loadEquipmentPlanningItems, (state, {equipmentPlanningItems}) => {
    return adapter.setAll(equipmentPlanningItems, state);
  }),
  on(EquipmentPlanningActions.clearEquipmentPlanningItems, state => {
    return adapter.removeAll({...state});
  }),
);

// export const getSelectedEquipmentPlanningId = (state: State) => state.selectedEquipmentPlanningId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectEquipmentPlanningIds = selectIds;
export const selectEquipmentPlanningEntities = selectEntities;
export const selectAllEquipmentPlannings = selectAll;
export const selectEquipmentPlanningTotal = selectTotal;
