import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Injectable } from '@angular/core';

export const Z_LANGUAGE_ITEM = {
  databaseTableName: 'z_language_item',
  id: 'LaIt_Id',
  entryId: 'LaIt_EntryId',
  text: 'LaIt_Text',
  culture: 'LaIt_Culture',
  updateTimeStamp: 'LaIt_UpdateTimestamp'
};

export class LanguageItem {
  constructor(
    public databaseTableName: string,
    public id: number,
    public entryId: number,
    public text: string,
    public culture: string,
    public updateTimeStamp: Date
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class LanguageItemAdapter implements Adapter<LanguageItem> {
  adapt(item: any): LanguageItem {
    return new LanguageItem(
      Z_LANGUAGE_ITEM.databaseTableName,
      item[Z_LANGUAGE_ITEM.id],
      item[Z_LANGUAGE_ITEM.entryId],
      item[Z_LANGUAGE_ITEM.text],
      item[Z_LANGUAGE_ITEM.culture],
      new Date(item[Z_LANGUAGE_ITEM.updateTimeStamp])
    );
  }

  encode(item: LanguageItem): ISingleBackendObj {
    const encoded = {
      TableName: Z_LANGUAGE_ITEM.databaseTableName,
      [Z_LANGUAGE_ITEM.id]: item.id,
      [Z_LANGUAGE_ITEM.entryId]: item.entryId,
      [Z_LANGUAGE_ITEM.text]: item.text,
      [Z_LANGUAGE_ITEM.culture]: item.culture,
      [Z_LANGUAGE_ITEM.updateTimeStamp]: item.updateTimeStamp.toDateString(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
