import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { DbColumnSelectors, LanguageItemSelectors } from '../../store/shared-store/selectors';
import { DbColumnActions, LanguageItemActions } from '../../store/shared-store/actions';
import { CoreSelectors } from '../../store/core-store/selectors';

@Injectable({
  providedIn: 'root'
})
export class CoreStatesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<State>) {
  }

  // wrapping the logic so we can .switchMap() it
  getFromStore(): Observable<any> {
    /** Trigger actions below ONLY IF a client is actually set, avoiding empty Echo-Company Headers **/
    return this.store.select(CoreSelectors.selectClient).pipe(
      filter(client => client !== null),
      tap(client => {
        this.store.dispatch(LanguageItemActions.beginGetAllLanguageItems());
        this.store.dispatch(DbColumnActions.beginGetAllDbColumns());
      }),
      switchMap(() => combineLatest([
        // selecting some slices of state total items
        this.store.select(LanguageItemSelectors.selectLanguageItemsTotal),
        this.store.select(DbColumnSelectors.selectDbColumnTotal)
      ]).pipe(
        // the tap() operator allows for a side effect, at this
        // point, we're checking if items total are different of zero
        tap(([languageItemTotal, dbColumnTotal]) => {
          // if there are no items (0 items), dispatch an action to hit the backend
          // if (languageItemTotal === 0) {
          //   this.store.dispatch(LanguageItemActions.beginGetAllLanguageItems());
          // }
          // if (dbColumnTotal === 0) {
          //   this.store.dispatch(DbColumnActions.beginGetAllDbColumns());
          // }
        }),
        // filter out zero values, no value === empty!
        filter(([languageItemTotal, dbColumnTotal]) => languageItemTotal !== 0 && dbColumnTotal !== 0),
        // which if empty, we will never take()
        // this is the same as first() which will only
        // take 1 value from the Observable then complete
        // which does our unsubscribing, technically.
        first(),
        //take(1),
      )),
    );



    // this.store.dispatch(LanguageItemActions.beginGetAllLanguageItems());
    // this.store.dispatch(DbColumnActions.beginGetAllDbColumns());
    // // return an Observable stream from the store
    // return combineLatest([
    //   // selecting some slices of state total items
    //   this.store.select(LanguageItemSelectors.selectLanguageItemsTotal),
    //   this.store.select(DbColumnSelectors.selectDbColumnTotal)
    // ]).pipe(
    //   // the tap() operator allows for a side effect, at this
    //   // point, we're checking if items total are different of zero
    //   tap(([languageItemTotal, dbColumnTotal]) => {
    //     // if there are no items (0 items), dispatch an action to hit the backend
    //     // if (languageItemTotal === 0) {
    //     //   this.store.dispatch(LanguageItemActions.beginGetAllLanguageItems());
    //     // }
    //     // if (dbColumnTotal === 0) {
    //     //   this.store.dispatch(DbColumnActions.beginGetAllDbColumns());
    //     // }
    //   }),
    //   // filter out zero values, no value === empty!
    //   filter(([languageItemTotal, dbColumnTotal]) => languageItemTotal !== 0 && dbColumnTotal !== 0),
    //   // which if empty, we will never take()
    //   // this is the same as first() which will only
    //   // take 1 value from the Observable then complete
    //   // which does our unsubscribing, technically.
    //   first(),
    //   //take(1),
    // );



  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.getFromStore().pipe(
      // tap(r => console.log(r)),
      // if it was successful, we can return Observable.of(true)
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false))
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.getFromStore().pipe(
      //tap(r => console.log(r)),
      // if it was successful, we can return Observable.of(true)
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false))
    );
  }
}
