import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum TaskLinkTypeEnum { //ProjectLinkTypeEnum
  EndToStart = 1,
  StartToStart= 2,
  EndToEnd= 3,
  StartToEnd= 4,
}

export const TASK_LINK_TYPE = {
  databaseTableName: 'task_link_type',
  id: 'TaLiTy_Id',
  name: 'TaLiTy_Name',
  displayNameId: 'TaLiTy_DisplayNameId',
};

export class TaskLinkType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskLinkTypeAdapter implements Adapter<TaskLinkType> {
  adapt(item: any): TaskLinkType {
    return new TaskLinkType(
      TASK_LINK_TYPE.databaseTableName,
      item[TASK_LINK_TYPE.id],
      item[TASK_LINK_TYPE.name],
      item[TASK_LINK_TYPE.displayNameId],
    );
  }

  encode(item: TaskLinkType): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_LINK_TYPE.id]: item.id,
      [TASK_LINK_TYPE.name]: item.name,
      [TASK_LINK_TYPE.displayNameId]: item.displayNameId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
