import { createSelector } from '@ngrx/store';
import * as fromUses from '../reducers/uses.reducer';
import { State } from '../reducers/uses.reducer';
import { selectSvgState } from '../reducers';
import { selectFloorModelsIds } from './task-floor-model.selectors';
import { Dictionary } from '@ngrx/entity';
import { SvgBoundingBox } from '../../../features/svg-factory/models/svg-bounding-box.model';
import { deepCopy } from '../../../shared/utils';
import { selectCurrentTaskId, selectTasks } from '../../project-management/selectors/task.selectors';
import { FloorDataStateEnum } from '../../../domain-models/business/floor-data-state.model';

export const selectUses = createSelector(
  selectSvgState,
  state => state[fromUses.usesFeatureKey]
);

export const selectUseIds = createSelector(
  selectUses,
  fromUses.selectUseIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectUsesEntities = createSelector(
  selectUses,
  fromUses.selectUseEntities
);
export const selectAllUses = createSelector(
  selectUses,
  fromUses.selectAllUses
);
export const selectUsesTotal = createSelector(
  selectUses,
  fromUses.selectUseTotal
);

export const selectCurrentSelectedUseDOMRect = createSelector(
  selectUses,
  (uses) => uses.selectedUseDOMRect
);

export const selectCurrentSelectedUseBoundingBox = createSelector(
  selectUses,
  (uses) => uses.selectedUseBoundingBox
);

export const selectUsesByLayerEntities = createSelector(
  selectAllUses,
  selectFloorModelsIds,
  (uses, layerIds) => {
    const returnObj = {} /*as {[key: string]: Array<SvgGroup>}*/;
    layerIds.forEach(l => returnObj[l] = []);
    Object.keys(returnObj).forEach(id => {
      returnObj[id] = uses.filter(u => `${u.floorModelId}-${u.taskId}` === id);
    });
    return returnObj;
  }
);

export const getSelectedUsesIds = createSelector(
  selectUses,
  (state: State) => state.selectedIds
);

export const getSelectedUses = createSelector(
  selectAllUses,
  getSelectedUsesIds,
  (uses, ids) =>
    uses.filter(u => u.id === ids.find(e => e === u.id))
);

export const getBoundingBoxesEntities = createSelector(
  selectUses,
  (state) => state.selectedUsesBoundingBoxes
);

export const getSelectedUsesBoundingBoxesEntities = createSelector(
  getBoundingBoxesEntities,
  getSelectedUses,
  (boundingBoxes, uses) => {
    const matchingKeys = uses.map(u => u.id.toString());
    let dict = deepCopy(boundingBoxes) as Dictionary<SvgBoundingBox>;
    for (const [key, value] of Object.entries(boundingBoxes)) {
      //console.log(`${key}: ${value}`);
      if (!matchingKeys.includes(key)) {
        delete dict[key];
      }
    }
    return dict;
  }
);

// export const selectUsesByLayerEntities = createSelector(
//   selectAllUses,
//   selectFloorModelsIds,
//   (uses, layerIds) => {
//     let returnObj = {} /*as {[key: string]: Array<SvgGroup>}*/;
//     layerIds.forEach(l => returnObj[Number(l)] = []);
//     Object.keys(returnObj).forEach(id => {
//       returnObj[Number(id)] = uses.filter(u => u.floorModelId === Number(id));
//     });
//     return returnObj;
//   }
// );

/** FloorDataState selectors **/

export const selectCurrentTaskUses = createSelector(
  // selectTasks,
  selectAllUses,
  selectCurrentTaskId,
  (uses, currentTaskId) =>
    uses.filter(f => f.taskId === currentTaskId)
);

export const selectCurrentTaskAddedUses = createSelector(
  selectTasks,
  selectAllUses,
  selectCurrentTaskId,
  (tasksState, uses, currentTaskId) =>
    uses.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Added)
);

export const selectCurrentTaskDeletedUses = createSelector(
  selectTasks,
  selectAllUses,
  selectCurrentTaskId,
  (tasksState, uses, currentTaskId) =>
    uses.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Deleted)
);

export const selectCurrentTaskUpdatedUses = createSelector(
  selectTasks,
  selectAllUses,
  selectCurrentTaskId,
  (tasksState, uses, currentTaskId) =>
    uses.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Updated)
);
