import { createReducer, on } from '@ngrx/store';
import { ProjectManagementUiActions } from '../actions';

export const projectManagementFeatureKey = 'projectManagementUi';

export interface State {
  taskCreationDto: any;
}

const initialState: State = {
  taskCreationDto: null
};

export const reducer = createReducer(
  initialState,
  on(ProjectManagementUiActions.setTaskCreationDto, (state, action) => {
    return {...state, taskCreationDto: action.taskCreationDto};
  }),
  on(ProjectManagementUiActions.clearProjectManagementState, (state) => {
    return initialState;
  }),
);
