import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export const CITY = {
  databaseTableName: 'City',
  id: 'Ci_Id',
  name: 'Ci_Name',
  location: 'Ci_Location',
  countryId: 'Ci_CountryId'
};

export class City {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public location: string,
    public countryId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class CityAdapter implements Adapter<City> {
  adapt(item: any): City {
    return new City(
      CITY.databaseTableName,
      item[CITY.id],
      item[CITY.name],
      item[CITY.location],
      item[CITY.countryId]
    );
  }
}
