import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const WORKPLACE = {
  databaseTableName: 'workplace',
  floorDataId: 'Wo_FloorDataId',
  code: 'Wo_Code',
  workplaceType: 'Wo_WorkplaceType',
  usable: 'Wo_Usable',
};

export class Workplace {
  constructor(
    public databaseTableName: string,
    public floorDataId: number,
    public code: string,
    public workplaceType: number,
    public usable: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class WorkplaceAdapter implements Adapter<Workplace> {
  adapt(item: any): Workplace {
    return new Workplace(
      WORKPLACE.databaseTableName,
      item[WORKPLACE.floorDataId],
      item[WORKPLACE.code],
      item[WORKPLACE.workplaceType],
      item[WORKPLACE.usable]
    );
  }

  encode(item: Workplace): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [WORKPLACE.floorDataId]: item.floorDataId,
      [WORKPLACE.code]: item.code,
      [WORKPLACE.workplaceType]: item.workplaceType,
      [WORKPLACE.usable]: item.usable
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
