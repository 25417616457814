import { environment } from '../../environments/environment';

export const API_URL = environment.apiUrl;
//export const API_URL = 'https://ecsy.echo-on.fr/api/';
export const NOT_FOUND_IMG = 'https://static.echo-on.fr/public/img/baseline_image_not_supported_black_48dp.png';

export const API_ENDPOINTS = {
  root: API_URL,
  ecsyFile: API_URL + 'ecsyfile/items',
  appMenus: API_URL + 'dynh/z_app_menu/all',
  appParams: API_URL + 'dynt/z_app_parameter',
  appUsers: API_URL + 'appuserview/',
  appUsersMe: API_URL + 'appusers/me',
  appRoles: API_URL + 'approles/',
  appRoleGrants: API_URL + 'approlegrants/',
  appGrantGroups: API_URL + 'appgrantgroups/',
  appGrants: API_URL + 'appgrants/',
  buildings: API_URL + 'buildings/items/',
  constraints: API_URL + 'constraints',
  dashboard: API_URL + 'dashboard/',
  dynT: API_URL + 'dynt/',
  dynH: API_URL + 'dynh/',
  displayThemes: API_URL + 'displaythemes/',//site/id
  filters: API_URL + 'filters/',
  paginationUpdate: API_URL + 'pagination/updatepageitemscount',
  realEstate: API_URL + 'realestate/items',
  realEstateSubItems: API_URL + 'realestate/subitems/', //+siteid
  realEstateSite: API_URL + 'realestate/site',
  realEstateBuilding: API_URL + 'realestate/building',
  realEstateFloor: API_URL + 'realestate/floor',
  fsImg: API_URL + 'fs/thumbnail/',
  fsSvg: API_URL + 'fs/svg/',
  graphicsFloor: API_URL + 'graphics/floor/',
  graphicsCadTask: API_URL + 'graphics/CadTask/', //floor/docId
  graphicsBuilding: API_URL + 'graphics/building/',
  graphicsBuildingTest: API_URL + 'graphics/building/test/',
  floorRoomsDynView: 'dynview/room_dynview/Items/',
  floorAllocationDynView: 'dynview/floor_allocation_dynview/items/',
  floorDirectoryDynView: 'dynview/floor_directory_dynview/items/',
  floorWorkplaceDynView: 'dynview/floor_workplace_dynview/items/',
  floorFurnitureDynView: 'dynview/floor_furniture_dynview/items/',
  activeCadTasks: API_URL + 'floors/ActiveCadTasks/',
  activeCadRootTasks: API_URL + 'floors/ActiveCadRootTasks/',
  activeCadTasksDocument: API_URL + 'floors/ActiveCadTaskDocuments/',
  rootTasks: API_URL + 'dynh/task/rootitems/',
  rootSimulation: API_URL + 'dynh/simulation/rootitems/',
  rootBus: API_URL + 'dynh/business_unit/rootitems/',
  simulationChildren: API_URL + 'dynh/simulation/subitems/', //parentid
  simulations: API_URL + 'dynh/simulation/', //idsim,
  simulationPhoto: API_URL + 'simulationphotos/',//sim photo id
  simulationOccupationsPatchActivityStatus: API_URL + 'simulationoccupations/activitystatus/', //{SiOc}
  addToSandbox: API_URL + 'simulationoccupations/intosandbox',
  removeFromSandbox: API_URL + 'simulationoccupations/fromsandbox',
  simulationOccupationsDelete: API_URL + 'simulationoccupations/',
  simulationOccupationsUpdate: API_URL + 'simulationoccupations/move',
  simulationOccupationsPatchArea: API_URL + 'simulationoccupations/patcharea/', // {SiOc}
  svgContour: API_URL + 'floordata/contour', // {taskId, x, y}
  tasks: API_URL + 'tasks/rootitems',
  tasksTemplates: API_URL + 'tasks/templates',
  taskValidationsRefAreCheck: (taskId: number): string => `${API_URL}tasks/validations/${taskId}/RefAreCheck`,
  taskLinks: API_URL + 'tasklinks/items',
  uploadLayersUrl: API_URL + 'floordata/import',
  uploadBlueprintFile: (taskId: number): string => `${API_URL}Dwg/blueprintFile/${taskId}`,
  floorCatalogImportUrl: API_URL + 'Dwg/CatalogFile',
  floorCatalogValidatedImport: API_URL + 'FloorCatalog/ValidatedImport', // requestId&cancel où cancel est facultatif et false par défaut
  cadProjectsDirectTask: API_URL + 'cadprojects/directtask',
  postTaskChatMessage: `${API_URL}Broadcast/postTaskChatMessage`,
  initiatePrepareFloorFile: `${API_URL}dwg/InitiatePrepareFloorFile`,
  initiatePrepareCatalogFile: `${API_URL}dwg/InitiatePrepareCatalogFile`,
  completeCadRequest: `${API_URL}dwg/CompleteCadRequest`,
  convertedBlueprintFile: `${API_URL}dwg/ConvertedBlueprintFile`,
  appRoleGrantsRoleId: (roleId: number): string => `${API_URL}AppRoleGrants/${roleId}`,
  bus: API_URL + 'bus/',

  /**
   * FLOOORDATA
   */

  floorDataRoom: API_URL + 'floordata/room',
  floorDataWalls: API_URL + 'floordata/walls',
  floorDataDoors: API_URL + 'floordata/doors',
  floorDataEquipment: API_URL + 'floordata/equipments',
  floorDataWorkplace: API_URL + 'floordata/workplaces',
  floorDataWorkplaceAllocation: API_URL + 'floordata/workplaces/allocation',
  /**
   * ROOMS
   */

  rooms: API_URL + 'rooms/', // {roomId} to get a specific payload / also PATCH point

  /**
   * Project management
   */
  taskContributors: API_URL + 'TaskContributors',
  hasValidationFunction:  API_URL + 'TaskContributors/HasValidationFunction',
  datalessFloors: API_URL + 'dynt/DatalessFloorsView',
  reconcileTasks: `${API_URL}projects/reconcileTasks`, // blueprintTaskId
  createFromProjectTemplate: `${API_URL}Projects/FromTemplate/`, // {templateId}

  /**
   * EQUIPMENT
   */
  equipmentPlanning: `${API_URL}equipmentplanning/task/`,
  equipmentPlanningState: `${API_URL}equipmentplanning/PlanningState`,
  equipmentReconcileTwinTasks: `${API_URL}EquipmentReconcile/TwinTasks`,
  equipmentReconcilePurchases: `${API_URL}EquipmentReconcile/Purchases`,
  equipmentReconcileSourceStorageAreas: `${API_URL}EquipmentReconcile/SourceStorageAreas`,
  equipmentDashboard: `${API_URL}equipmentDashboard/EquipmentCategory`, // equipmentcategoryId
  purchaseHasReceiptTask: `${API_URL}Purchase/HasReceiptTask`, // &ids as HTTP params
  purchaseDetail: `${API_URL}purchase/purchaseDetail`, // {purchaseId}
  purchaseReceipt: `${API_URL}purchase/PurchaseReceipt`, // POST body EqPu_Id && EqPu_DeliveryDate && EquipmentItem[]
  equipmentReconcileBindImportedItems: `${API_URL}EquipmentReconcile/BindImportedItems`, // firstEquipmentId secondEquipmentId
  /** Documents **/
  documentsDownload: `${API_URL}Documents`,

  /** MISC **/
  contractors: `${API_URL}Contractors`,
  contractorsRegistration: `${API_URL}Contractors/Registration`,
  contractorsCompany: (companyId: string): string => `${API_URL}Contractors/Company/${companyId}`,
  contractorsClients: (companyId: string): string => `${API_URL}Contractors/Clients/${companyId}`,
  contractorsRegistrationRequest: `${API_URL}Contractors/RegistrationRequest`,
  contractorsGrantRequests: `${API_URL}Contractors/GrantRequests`,
  contractorsGrantRequest: `${API_URL}Contractors/GrantRequest`,
  contractorsUserAccount: `${API_URL}Contractors/UserAccount`,
  contractorsUserGrant: `${API_URL}Contractors/UserGrant`,
  contractorsCancelGrantRequest: `${API_URL}Contractors/CancelGrantRequest`,
  contractorsUser: `${API_URL}Contractors/User`,
  contractorsUserAccounts: `${API_URL}Contractors/UsersAccounts`,
  contractorsUsersGrantRequest: `${API_URL}Contractors/Users/GrantRequest`,
  addressCity: `${API_URL}Address/City`,
  addressCountries: `${API_URL}Address/Countries`,
  policyCitiesReferences: `${API_URL}Address/PolicyCitiesReferences`,
  perimetersCustom: `${API_URL}perimeters/custom`,
  dwgCancelImport: `${API_URL}dwg/CancelCatalogImport`, // requestId
  dwgValidateCatalogImport: `${API_URL}dwg/validateCatalogImport`, // requestId

};
