import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const PERIMETER_DETAIL = {
  databaseTableName: 'perimeter_detail',
  id: 'PeDe_Id',
  perimeterId: 'PeDe_PerimeterId',
  floorId: 'PeDe_FloorId',
};

export class PerimeterDetail {
  constructor(
    public databaseTableName: string,
    public id: number,
    public perimeterId: number,
    public floorId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class PerimeterDetailAdapter implements Adapter<PerimeterDetail> {
  adapt(item: any): PerimeterDetail {
    return new PerimeterDetail (
      PERIMETER_DETAIL.databaseTableName,
      item[PERIMETER_DETAIL.id],
      item[PERIMETER_DETAIL.perimeterId],
      item[PERIMETER_DETAIL.floorId],
    );
  }
  encode(item: PerimeterDetail): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [PERIMETER_DETAIL.id]: item.id,
      [PERIMETER_DETAIL.perimeterId]: item.perimeterId,
      [PERIMETER_DETAIL.floorId]: item.floorId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
