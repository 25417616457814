import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export const Z_DB_TABLE = {
  databaseTableName: 'z_db_table',
  tableName: 'DbTa_TableName',
  tableDisplayName: 'DbTa_TableDisplayName',
  categoryId: 'DbTa_CategoryId',
  scope: 'DbTa_Scope',
  hasIdentityId: 'DbTa_HasIdentityId',
  tableTypeId: 'DbTa_TableTypeId',
  isHierarchical: 'DbTa_IsHierarchical',
  insertGrantId: 'DbTa_InsertGrantId',
  deleteGrantId: 'DbTa_DeleteGrantId',
  updateGrantId: 'DbTa_UpdateGrantId',
  readGrantId: 'DbTa_ReadGrantId'
};

export class DbTable {
  constructor(
    public databaseTableName: string,
    public tableName: string,
    public tableDisplayName: number,
    public categoryId: number,
    public scope: number,
    public hasIdentityId: number,
    public tableTypeId: number,
    public isHierarchical: boolean,
    public insertGrantId: number,
    public deleteGrantId: number,
    public updateGrantId: number,
    public readGrantId: number
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DbTableAdapter implements Adapter<DbTable> {

  adapt(item: any): DbTable {
    return new DbTable(
      Z_DB_TABLE.databaseTableName,
      item[Z_DB_TABLE.tableName],
      item[Z_DB_TABLE.tableDisplayName],
      item[Z_DB_TABLE.categoryId],
      item[Z_DB_TABLE.scope],
      item[Z_DB_TABLE.hasIdentityId],
      item[Z_DB_TABLE.tableTypeId],
      item[Z_DB_TABLE.isHierarchical],
      item[Z_DB_TABLE.insertGrantId],
      item[Z_DB_TABLE.deleteGrantId],
      item[Z_DB_TABLE.updateGrantId],
      item[Z_DB_TABLE.readGrantId]
    );
  }
}
