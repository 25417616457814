import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

// export const authConfig: AuthConfig = {
//
//   issuer: 'https://identity.echo-on.fr',
//   clientId: 'ecsy.front',
//   redirectUri: window.location.origin,
//   //logoutUrl: 'https://identity.echo-on.fr/connect/endsession',
//   postLogoutRedirectUri: environment.postLogoutRedirectUri,
//   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//   scope: environment.scope,
//   //silentRefreshTimeout: 5000, // For faster testing
//   //timeoutFactor: 0.1, // For faster testing
//   sessionChecksEnabled: true,
//   requestAccessToken: true,
//   showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
// };

// export const authConfig: AuthConfig = {
//   issuer: 'https://identity.echo-on.fr',
//   redirectUri: window.location.origin, //window.location.origin + '/index.html'
//   clientId: 'ecsy.front',
//   responseType: 'code',
//   scope: 'openid profile ecsy.back policy.back',
//   showDebugInformation: true,
//   timeoutFactor: 0.01
// };

export const authConfig: AuthConfig = {
  issuer: 'https://identity.echo-on.fr',
  clientId: 'ecsy.front', // The "Auth Code + PKCE" client
  dummyClientSecret: 'ecsy.front.secret',
  responseType: 'code',
  redirectUri: window.location.origin,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  scope: environment.scope, // Ask offline_access to support refresh token refreshes
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  //silentRefreshTimeout: 5000, // For faster testing
  //timeoutFactor: 0.25, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
};
