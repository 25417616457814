import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EcSyFile } from '../../../domain-models/models/EcSyFile.model';
import { ClientService } from '../../services/client.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'echo-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsComponent implements OnInit {

  public clients$: Observable<EcSyFile[]>;

  constructor(private clientService: ClientService,
              private snackBarService: SnackBarService) {
    this.clients$ = this.clientService.clientFiles$;
  }

  ngOnInit() {
    this.clients$.pipe(
      map(clients => {
        /** If user can access only 1 EcsyFile, automatically set client to this file **/
        if (clients.length === 1) {
          this.select(clients[0]);
        }
      })
    ).subscribe();
  }

  select(clientFile: EcSyFile): void {
    if (clientFile.IsAvailable) {
      this.clientService.selectClient(clientFile);
    } else {
      this.snackBarService.displayMessage(`${clientFile.CompanyName} est actuellement en maintenance, merci de réessayer ultérieurement.`);
    }
  }

}
