import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const BUILDING = {
  databaseTableName: 'building',
  id: 'Bu_Id',
  code: 'Bu_Code',
  name: 'Bu_Name',
  siteId: 'Bu_SiteId',
  isActive: 'Bu_IsActive',
  isFictive: 'Bu_IsFictive',
  location: 'Bu_Location',
  defaultPerimeterId: 'Bu_DefaultPerimeterId',
};

export class Building {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public name: string,
    public siteId: number,
    public isActive: boolean,
    public isFictive: boolean,
    public location: string,
    public defaultPerimeterId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class BuildingAdapter implements Adapter<Building> {
  adapt(item: any): Building {
    return new Building(
      BUILDING.databaseTableName,
      item[BUILDING.id],
      item[BUILDING.code],
      item[BUILDING.name],
      item[BUILDING.siteId],
      item[BUILDING.isActive],
      item[BUILDING.isFictive],
      item[BUILDING.location],
      item[BUILDING.defaultPerimeterId],
    );
  }

  encode(item: Building): ISingleBackendObj {
    const encoded = {
      TableName: BUILDING.databaseTableName,
      [BUILDING.id]: item.id,
      [BUILDING.code]: item.code,
      [BUILDING.name]: item.name,
      [BUILDING.siteId]: item.siteId,
      [BUILDING.isActive]: item.isActive,
      [BUILDING.isFictive]: item.isFictive,
      [BUILDING.location]: item.location,
      [BUILDING.defaultPerimeterId]: item.defaultPerimeterId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
