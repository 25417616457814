import { createSelector } from '@ngrx/store';
import * as fromDisplayThemeItems from '../reducers/display-theme-item.reducer';
import { selectSharedState } from '../reducers';

export const selectDisplayThemeItems = createSelector(
  selectSharedState,
  state => state[fromDisplayThemeItems.displayThemeItemFeatureKey]
);

export const selectDisplayThemeItemsIds = createSelector(
  selectDisplayThemeItems,
  fromDisplayThemeItems.selectDisplayThemeItemsIds
);
export const selectDisplayThemeItemsEntities = createSelector(
  selectDisplayThemeItems,
  fromDisplayThemeItems.selectDisplayThemeItemsEntities
);
export const selectAllDisplayThemeItems = createSelector(
  selectDisplayThemeItems,
  fromDisplayThemeItems.selectAllDisplayThemeItems
);
export const selectDisplayThemeItemsTotal = createSelector(
  selectDisplayThemeItems,
  fromDisplayThemeItems.selectDisplayThemeItemsTotal
);

export const selectDisplayThemes = createSelector(
  selectDisplayThemeItems,
  (state) => state.themes
);

export const selectCurrentDisplayThemeId = createSelector(
  selectDisplayThemeItems,
  (state) => state.selectedDisplayThemeId
);

export const selectIsPaintingItem = createSelector(
  selectDisplayThemeItems,
  selectDisplayThemeItemsEntities,
  (state, entities) => entities[state.isPaintingItem]
);

export const selectDisplayItemsByDisplayThemeId = (id: number) => createSelector(
  selectAllDisplayThemeItems,
  (entities) => entities.filter(e => e.displayThemeIds.includes(id))
);

export const selectAllCurrentDisplayThemeItems = createSelector(
  selectCurrentDisplayThemeId,
  selectAllDisplayThemeItems,
  (id, items) => {
    return items.filter(e => e.displayThemeIds.includes(id));
  }
);

export const selectDisplayThemeItemById = (id: string) => createSelector(
  selectDisplayThemeItemsEntities,
  (entities) => {
    return entities ? entities[id] : null;
  }
);

export const selectDisplayThemeItemsByIds = (ids: string[]) => createSelector(
  selectDisplayThemeItemsEntities,
  (entities) => ids.map(id => entities[id])
);

export const selectDisplayThemeCategories = createSelector(
  selectDisplayThemeItems,
  (state) => state.themeCategories
);

export const selectLegendContext = createSelector(
  selectDisplayThemeItems,
  (state) => state.legendContext
);

export const selectHoveredThemeItemId = createSelector(
  selectDisplayThemeItems,
  (state) => state.hoveredThemeItemId
);
