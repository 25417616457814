import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

const allowedUrls = [
  'https://policy.echo-on.fr/api',
  'https://fs.ecsy.fr',
  ...environment.allowedUrls,
];

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: allowedUrls,
    sendAccessToken: true,
  }
};
