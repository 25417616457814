import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SnackBarService } from './snack-bar.service';
import { LoaderService } from './loader.service';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { CoreSelectors } from '../../store/core-store/selectors';
import { concatMap, filter, first, switchMap, tap } from 'rxjs/operators';
import { SignalRHttpClient } from '../../shared/signal-r-http-client';
import { ClientService } from './client.service';
import { Notification } from '../models/notification.model';
import { NotificationMessage } from '../models/notification-message.model';
import { NotificationContextData } from '../models/notification-context-data.model';
import { NotificationCallToAction } from '../models/notification-call-to-action.model';
import { API_ENDPOINTS } from '../../shared/api-endpoints';
import { TaskSelectors } from '../../store/project-management/selectors';
import { TASK } from '../../domain-models/business/task.model';
import { CadRequest } from '../../features/floor/models/cad-request.model';

interface BroadcastNotificationDto {
  messageView: NotificationMessage;
  contextData: NotificationContextData[];
  callsToAction: NotificationCallToAction[];
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService extends ApiService {

  private notifications = [] as Notification[];
  private chatMessages = [] as Notification[];
  private cadRequests = [] as CadRequest[];

  notifications$ = new BehaviorSubject<Notification[]>(this.notifications);
  chatMessages$ = new BehaviorSubject<Notification[]>(this.chatMessages);
  cadRequests$ = new BehaviorSubject<CadRequest[]>(this.cadRequests);
  unreadNotificationsCount$ = new BehaviorSubject(this.notifications.filter(n => !n.message.hasBeenRead).length);
  unreadChatMessagesCount$ = new BehaviorSubject(this.chatMessages.filter(n => !n.message.hasBeenRead).length);
  private connection: HubConnection;
  private isConnectedSubject$ = new ReplaySubject<boolean>();
  private isConnected$ = this.isConnectedSubject$.asObservable();

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private authService: AuthService,
              private clientService: ClientService,
              private store: Store<State>) {
    super(http, loggerService, snackBarService, httpLoaderService);

    this.clientService.client$.pipe(
      filter(client => client !== null && client !== undefined),
      tap(client => {
        if (this.connection?.state) {
          this.connection.stop();
        }
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl('https://broadcast.ecsy.fr/broadcasthub', {
            httpClient: new SignalRHttpClient(client.CompanyId),
            accessTokenFactory: () => this.authService.accessToken,
            transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling
          })
          .withAutomaticReconnect()
          .build();
        this.connection.start()
          .then(() => {
            this.isConnectedSubject$.next(true);
            this.connection.invoke('NowListening', client.CompanyId);
            this.connection.on('GetMessages', (notifications: BroadcastNotificationDto[]) => {
              this.notifications = this.addNotifications(notifications);
              this.notifications$.next(this.notifications);
              this.unreadNotificationsCount$.next(this.notifications.filter(n => !n.message.hasBeenRead).length);
            });

            this.connection.on('GetMessage', (notification: BroadcastNotificationDto) => {
              this.notifications = this.addNotification(notification, this.notifications);
              this.notifications$.next(this.notifications);
              this.unreadNotificationsCount$.next(this.notifications.filter(n => !n.message.hasBeenRead).length);
            });

            this.connection.on('GetTaskChatMessages', (messages) => {
              this.chatMessages = this.addNotifications(messages, true);
              this.chatMessages$.next(this.chatMessages);
              this.unreadChatMessagesCount$.next(this.chatMessages.filter(n => !n.message.hasBeenRead).length);
              // console.log(messages);
            });

            this.connection.on('GetTaskChatMessage', (message) => {
              this.chatMessages = this.addNotification(message, this.chatMessages, true);
              this.chatMessages$.next(this.chatMessages);
              this.unreadChatMessagesCount$.next(this.chatMessages.filter(n => !n.message.hasBeenRead).length);
              // console.log(message);
            });

            this.connection.on('GetCadFilePath', (cadRequest) => {
              // console.log(cadRequest);
              this.addCadRequest(cadRequest);
            });

            this.connection.on('GetDwgConvertResult', (cadRequest) => {
              console.log(cadRequest);
              this.addCadRequest(cadRequest);
            });
          })
          .catch(err => this.loggerService.log(err));
      }),
    ).subscribe();
  }

  listenToTaskChannel(taskId: number) {
    if (taskId) {
      this.store.select(CoreSelectors.selectClient).pipe(
        concatMap(client => this.isConnected$
          /** wait for connection to be opened **/
          .pipe(
            filter(isConnected => isConnected),
            tap(() => {
              /** invoke method on hub **/
              this.connection.invoke('NowListeningOnTaskChannel', client.CompanyId, taskId);
            })
          )
        ),
        first(),
      ).subscribe();
    }
  }

  readMessage(messageId: number, hasBeenRead: boolean): void {
    this.store.select(CoreSelectors.selectClient).pipe(
      concatMap(client => this.isConnected$
        /** wait for connection to be opened **/
        .pipe(
          filter(isConnected => isConnected),
          tap(() => {
            /**
             * public string CompanyId { get; set; }
             public string UserPolicyId { get; set; }
             public int MessageId { get; set; }
             public bool HasBeenRead { get; set; }
             public bool HasBeenHidden { get; set; }
             */
            const dto = {
              CompanyId: client.CompanyId,
              UserPolicyId: client.UserId,
              MessageId: messageId,
              HasBeenRead: hasBeenRead
            };
            // console.log(dto);
            /** invoke method on hub **/
            this.connection.invoke('MessageHasBeenRead', dto);
          })
        )
      ),
      first(),
    ).subscribe();
  }

  hideMessage(notification: Notification, hasBeenHidden: boolean): void {
    let taskId = null;
    if (notification.message.channelId === 3) {
      taskId = Number(notification.contextDataItems.find(e => e.propertyName === TASK.id).propertyValue);
    }
    this.store.select(CoreSelectors.selectClient).pipe(
      concatMap(client => this.isConnected$
        /** wait for connection to be opened **/
        .pipe(
          filter(isConnected => isConnected),
          tap(() => {
            let dto = {};
            if (taskId) {
              dto = {...dto,
                TaskId: taskId
              };
            }
            dto = {
              ...dto,
              CompanyId: client.CompanyId,
              UserPolicyId: client.UserId,
              MessageId: notification.message.id,
              ChannelId: notification.message.channelId,
              HasBeenHidden: hasBeenHidden
            };
            /** invoke method on hub **/
            this.connection.invoke('MessageHasBeenHidden', dto);
          })
        )
      ),
      first(),
    ).subscribe();
  }

  hideAll(hasBeenHidden: boolean): void {
    this.store.select(CoreSelectors.selectClient).pipe(
      concatMap(client => this.isConnected$
        /** wait for connection to be opened **/
        .pipe(
          filter(isConnected => isConnected),
          tap(() => {
            const dto = {
              CompanyId: client.CompanyId,
              UserPolicyId: client.UserId,
              HasBeenHidden: hasBeenHidden
            };
            /** invoke method on hub **/
            this.connection.invoke('ArchiveAll', dto);
          })
        )
      ),
      first(),
    ).subscribe();
  }

  readAll(hasBeenRead: boolean): void {
    this.store.select(CoreSelectors.selectClient).pipe(
      concatMap(client => this.isConnected$
        /** wait for connection to be opened **/
        .pipe(
          filter(isConnected => isConnected),
          tap(() => {
            const dto = {
              CompanyId: client.CompanyId,
              UserPolicyId: client.UserId,
              HasBeenRead: hasBeenRead
            };
            /** invoke method on hub **/
            this.connection.invoke('AllHasBeenRead', dto);
          })
        )
      ),
      first(),
    ).subscribe();
  }

  addNotification(notificationDto: BroadcastNotificationDto, notificationsArray: Notification[], sortLatestAtBottom?: boolean) {
    const existingNotification = notificationsArray.find(n => n.message.id === notificationDto.messageView.id);
    if (existingNotification) {
      const index = notificationsArray.indexOf(existingNotification);
      if (notificationDto.messageView.hasBeenHidden) {
        notificationsArray.splice(index, 1);
      } else {
        notificationsArray[index] = {
          ...notificationsArray[index],
          message: notificationDto.messageView,
          contextDataItems: notificationDto.contextData,
          actions: notificationDto.callsToAction
        };
      }
    } else {
      notificationsArray.push({
        message: notificationDto.messageView,
        contextDataItems: notificationDto.contextData,
        actions: notificationDto.callsToAction
      });
    }
    notificationsArray.sort((a, b) => this.sortNotifications(a, b, sortLatestAtBottom));
    return notificationsArray;
  }

  addNotifications(broadcastNotificationDto: BroadcastNotificationDto[], sortLatestAtBottom?: boolean): Notification[] {
    let result = [];
    broadcastNotificationDto.forEach(notification => {
      result = this.addNotification(notification, result, sortLatestAtBottom);
    });
    return result;
  }

  sortNotifications(a: Notification, b: Notification, sortLatestAtBottom?: boolean): number {
    /**
     * https://codepen.io/odinsride/pen/MWYLeJm
     */

    const dateA = new Date(a.message.wroteTimestamp);
    const dateB = new Date(b.message.wroteTimestamp);

    // If the boolean value is the same between x and y,
    // then we want to compare the dates.
    if (a.message.hasBeenRead === b.message.hasBeenRead) {
      if (sortLatestAtBottom) {
        return dateB > dateA ? -1 : 1;
      } else {
        return dateB > dateA ? 1 : -1;
      }
    }

    // Otherwise, compare the boolean values.
    return (a.message.hasBeenRead === b.message.hasBeenRead) ? 0 : a.message.hasBeenRead ? 1 : -1;
  }

  postTaskChatMessage(message: string) {
    this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
      first(),
      switchMap(taskId => this.post(`${API_ENDPOINTS.postTaskChatMessage}/${taskId}`, message))
    ).subscribe();
  }

  /**
   * CAD Related
   **/

  getCadFilePath(requestId: string) {
    if (requestId) {
      this.cadRequests.push({
        requestId: requestId,
        filePath: null
      });
      this.isConnected$.pipe(
        /** wait for connection to be opened **/
        filter(isConnected => isConnected),
        tap(() => {
          /** invoke method on hub **/
          this.connection.invoke('WaitingForCadProcessResult', requestId);
        }),
        first(),
      ).subscribe();
    }
  }

  cancelCadFileRequest(cadRequest: CadRequest) {
    const existingCadRequest = this.cadRequests.find(r => r.requestId === cadRequest.requestId);
    if (existingCadRequest) {
      const index = this.cadRequests.indexOf(existingCadRequest);
      this.cadRequests.splice(index, 1);
      this.isConnected$.pipe(
        /** wait for connection to be opened **/
        filter(isConnected => isConnected),
        tap(() => {
          /** invoke method on hub **/
          this.connection.invoke('CancelCadProcessResult', cadRequest.requestId);
          this.cadRequests$.next(this.cadRequests);
        }),
        first(),
      ).subscribe();
    }
  }

  addCadRequest(cadRequest: CadRequest) {
    const existingCadRequest = this.cadRequests.find(r => r.requestId === cadRequest.requestId);
    if (existingCadRequest) {
      const index = this.cadRequests.indexOf(existingCadRequest);
      this.cadRequests[index] = {
        ...this.cadRequests[index],
        filePath: cadRequest.filePath,
        // Only used in GetDwgConvertResult
        jobComplete: cadRequest.jobComplete ? cadRequest.jobComplete : null
      };
    } else {
      this.cadRequests.push(cadRequest);
    }
    this.cadRequests$.next(this.cadRequests);
  }

  completeCadRequest(cadRequest: CadRequest) {
    const existingCadRequest = this.cadRequests.find(r => r.requestId === cadRequest.requestId);
    if (existingCadRequest) {
      const index = this.cadRequests.indexOf(existingCadRequest);
      this.cadRequests.splice(index, 1);
      this.cadRequests$.next(this.cadRequests);
    }
    const dto = [{
      FilePath: cadRequest.filePath,
      RequestId: cadRequest.requestId,
    }];
    this.post(`${API_ENDPOINTS.completeCadRequest}`, dto).pipe(
      first(),
    ).subscribe();
  }

  clearAllCadRequests() {
    this.cadRequests = [];
    this.cadRequests$.next(this.cadRequests);
  }


}
