import { createSelector } from '@ngrx/store';
import * as fromFloorModel from '../reducers/floor-model.reducer';
import { selectSharedState } from '../reducers';
import { selectActiveFloorModelId } from '../../svg-store/selectors/renderer.selectors';
import { FloorModel } from '../../../domain-models/business/floor-model.model';

export const selectFloorModels = createSelector(
  selectSharedState,
  state => state[fromFloorModel.floorModelsFeatureKey]
);

export const selectFloorModelsIds = createSelector(
  selectFloorModels,
  fromFloorModel.selectFloorModelsIds
);
export const selectFloorModelsEntities = createSelector(
  selectFloorModels,
  fromFloorModel.selectFloorModelsEntities
);
export const selectAllFloorModels = createSelector(
  selectFloorModels,
  fromFloorModel.selectAllFloorModels
);
export const selectFloorModelsTotal = createSelector(
  selectFloorModels,
  fromFloorModel.selectFloorModelsTotal
);

export const selectFloorModelById = (id: number) => createSelector(
  selectFloorModelsEntities,
  entities => entities[id]
);

export const selectFloorModelChildren = (floorModelId: number) => createSelector(
  selectAllFloorModels,
  floorModelItems => {
    /** refac
     * https://stackoverflow.com/questions/54876419/sort-flat-array-by-parentid-javascript
     */
    const getDescendants = ({ id }) =>
      floorModelItems.reduce((acc, n) => n.parentId === id ? [...acc, n, ...getDescendants(n)] : acc, []);
    return getDescendants({id: floorModelId}).map(f => f.id);
  }
);

export const selectFloorModelAncestor = (id: number, limitId?: number | number[]) => createSelector(
  selectAllFloorModels,
  (floorModels) => {
    const floorModel = floorModels.find(f => f.id === id);
    if (floorModel !== undefined) {
      return findRootFloorModel(floorModels, floorModel, limitId);
    } else {
      return null;
    }
  }
);

export const selectActiveFloorModelAncestor = (limitId?: number | number[]) => createSelector(
  selectAllFloorModels,
  selectActiveFloorModelId,
  (floorModels, activeFloorModelId) => {
    const activeFloorModel = floorModels.find(f => f.id === activeFloorModelId);
    if (activeFloorModel !== undefined) {
      return findRootFloorModel(floorModels, activeFloorModel, limitId);
    } else {
      return null;
    }
  }
);

function findRootFloorModel(floorModels: FloorModel[], current: FloorModel, limitId?: number | number[]): FloorModel {
  const parent = floorModels.find(f => f.id === current.parentId);
  if (current.parentId && (limitId === undefined || limitId === null)) {
    if (parent) {
      return findRootFloorModel(floorModels, parent, limitId);
    }
  } else if (current.parentId && limitId) {
    if (Array.isArray(limitId)) {
      if (!limitId.find(id => id === current.id)) {
        if (!limitId.find(id => id === parent.id)) {
          return findRootFloorModel(floorModels, parent, limitId);
        } else {
          return parent;
        }
      } else {
        return current;
      }
    } else {
      if (current.id !== limitId) {
        if (parent.id !== limitId) {
          return findRootFloorModel(floorModels, parent, limitId);
        } else {
          return parent;
        }
      } else {
        return current;
      }
    }
  } else {
    return current; // current
  }
}
