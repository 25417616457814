import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskContributorDirectoryActions } from '../actions';
import { TaskContributorDirectory } from '../../../domain-models/business/task-contributor-directory.model';

export const taskContributorDirectoryFeatureKey = 'taskContributorDirectoryItems';

export interface State extends EntityState<TaskContributorDirectory> {
  selectedTaskContributorDirectoryId: number | null;
}

export const adapter: EntityAdapter<TaskContributorDirectory> = createEntityAdapter<TaskContributorDirectory>();

export const initialState: State = adapter.getInitialState({
  selectedTaskContributorDirectoryId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskContributorDirectoryActions.addTaskContributorDirectory, (state, { taskContributorDirectory }) => {
    return adapter.addOne(taskContributorDirectory, state);
  }),
  on(TaskContributorDirectoryActions.setTaskContributorDirectory, (state, { taskContributorDirectory }) => {
    return adapter.setOne(taskContributorDirectory, state);
  }),
  on(TaskContributorDirectoryActions.upsertTaskContributorDirectory, (state, { taskContributorDirectory }) => {
    return adapter.upsertOne(taskContributorDirectory, state);
  }),
  on(TaskContributorDirectoryActions.addTaskContributorDirectoryItems, (state, { taskContributorDirectoryItems }) => {
    return adapter.addMany(taskContributorDirectoryItems, state);
  }),
  on(TaskContributorDirectoryActions.upsertTaskContributorDirectoryItems, (state, { taskContributorDirectoryItems }) => {
    return adapter.upsertMany(taskContributorDirectoryItems, state);
  }),
  on(TaskContributorDirectoryActions.updateTaskContributorDirectory, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskContributorDirectoryActions.updateTaskContributorDirectoryItems, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskContributorDirectoryActions.mapTaskContributorDirectoryItems, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskContributorDirectoryActions.deleteTaskContributorDirectory, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskContributorDirectoryActions.deleteTaskContributorDirectoryItems, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskContributorDirectoryActions.deleteTaskContributorDirectoryItemsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskContributorDirectoryActions.loadTaskContributorDirectoryItems, (state, { taskContributorDirectoryItems }) => {
    return adapter.setAll(taskContributorDirectoryItems, state);
  }),
  on(TaskContributorDirectoryActions.clearTaskContributorDirectoryItems, state => {
    return adapter.removeAll({ ...state, selectedTaskContributorDirectoryId: null });
  }),
  /** END OF CRUD **/
  on(TaskContributorDirectoryActions.updateSelectedTaskContributorDirectoryId, (state, {taskContributorDirectoryId}) => {
    return ({...state, selectedTaskContributorDirectoryId: taskContributorDirectoryId});
  }),
);

export const getSelectedTaskContributorDirectoryId = (state: State) => state.selectedTaskContributorDirectoryId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskContributorDirectory ids
export const selectTaskContributorDirectoryIds = selectIds;

// select the dictionary of taskContributorDirectory entities
export const selectTaskContributorDirectoryEntities = selectEntities;

// select the array of taskContributorDirectoryItems
export const selectAllTaskContributorDirectoryItems = selectAll;

// select the total taskContributorDirectory count
export const selectTaskContributorDirectoryTotal = selectTotal;
