import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const DOCUMENT_VERSION = {
  databaseTableName: 'document_version',
  id: 'DoVe_Id',
  creationDate: 'DoVe_CreationDate',
  index: 'DoVe_Index',
  indexCode: 'DoVe_IndexCode',
  documentId: 'DoVe_DocumentId',
  memo: 'DoVe_Memo'
};

export class DocumentVersion {
  constructor(
    public databaseTableName: string,
    public id: number,
    public creationDate: Date,
    public index: number,
    public indexCode: number,
    public documentId: number,
    public memo: string
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DocumentVersionAdapter implements Adapter<DocumentVersion> {
  adapt(item: any): DocumentVersion {
    return new DocumentVersion(
      DOCUMENT_VERSION.databaseTableName,
      item[DOCUMENT_VERSION.id],
      new Date(item[DOCUMENT_VERSION.creationDate]),
      item[DOCUMENT_VERSION.index],
      item[DOCUMENT_VERSION.indexCode],
      item[DOCUMENT_VERSION.documentId],
      item[DOCUMENT_VERSION.memo]
    );
  }

  encode(item: DocumentVersion): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DOCUMENT_VERSION.id]: item.id,
      [DOCUMENT_VERSION.creationDate]: item.creationDate.toDateString(),
      [DOCUMENT_VERSION.index]: item.index,
      [DOCUMENT_VERSION.indexCode]: item.indexCode,
      [DOCUMENT_VERSION.documentId]: item.documentId,
      [DOCUMENT_VERSION.memo]: item.memo
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
