import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const TASK_CONTRIBUTOR_DIRECTORY = {
  databaseTableName: 'task_contributor_directory',
  id: 'TaCrDi_Id',
  code: 'TaCrDi_Code',
  title: 'TaCrDi_Title',
  firstName: 'TaCrDi_FirstName',
  lastName: 'TaCrDi_LastName',
  isActive: 'TaCrDi_IsActive'
};

export class TaskContributorDirectory {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public title: string,
    public firstName: string,
    public lastName: string,
    public isActive: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskContributorDirectoryAdapter implements Adapter<TaskContributorDirectory> {
  adapt(item: any): TaskContributorDirectory {
    return new TaskContributorDirectory(
      TASK_CONTRIBUTOR_DIRECTORY.databaseTableName,
      item[TASK_CONTRIBUTOR_DIRECTORY.id],
      item[TASK_CONTRIBUTOR_DIRECTORY.code],
      item[TASK_CONTRIBUTOR_DIRECTORY.title],
      item[TASK_CONTRIBUTOR_DIRECTORY.firstName],
      item[TASK_CONTRIBUTOR_DIRECTORY.lastName],
      item[TASK_CONTRIBUTOR_DIRECTORY.isActive],
    );
  }

  encode(item: TaskContributorDirectory): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_CONTRIBUTOR_DIRECTORY.id]: item.id,
      [TASK_CONTRIBUTOR_DIRECTORY.code]: item.code,
      [TASK_CONTRIBUTOR_DIRECTORY.title]: item.title,
      [TASK_CONTRIBUTOR_DIRECTORY.firstName]: item.firstName,
      [TASK_CONTRIBUTOR_DIRECTORY.lastName]: item.lastName,
      [TASK_CONTRIBUTOR_DIRECTORY.isActive]: item.isActive,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
