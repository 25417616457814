import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS, API_URL } from '../../../shared/api-endpoints';
import { map } from 'rxjs/operators';
import { ApiData } from '../../../core/models/api-data.model';
import { Building, BuildingAdapter } from '../../../domain-models/business/building.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../core/services/api.service';
import { ApiBuildingsAreaIds } from '../../../domain-models/endpoint-specific/buildings.areaids.model';
import { ApiBuildingsFloors, ApiBuildingsFloorsAdapter } from '../../../domain-models/endpoint-specific/buildings-floors.model';
import { RoomAllocation, RoomAllocationAdapter } from '../../../domain-models/business/room-allocation.model';
import { LoggerService } from '../../../core/services/logger.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { LoaderService } from '../../../core/services/loader.service';
import {
  ApiBuildingsPlanningData,
  ApiBuildingsPlanningDataAdapter
} from '../../../domain-models/endpoint-specific/buildings.planning-data.model';

@Injectable({
  providedIn: 'root'
})
export class BuildingService extends ApiService {

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private apiBuildingsPlanningDataAdapter: ApiBuildingsPlanningDataAdapter,
              private apiBuildingFloorsAdapter: ApiBuildingsFloorsAdapter,
              private roomAllocationAdapter: RoomAllocationAdapter,
              private buildingAdapter: BuildingAdapter) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  getAllBuildings(): Observable<Building[]> {
    return this.get(API_ENDPOINTS.dynT + `building`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.buildingAdapter.adapt(item));
        })
      );
  }

  getRoomAllocationsByBuildingId(buildingId: number): Observable<RoomAllocation[]> {
    return this.get(`${API_URL}buildings/${buildingId}/roomallocations`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAllocationAdapter.adapt(item));
        })
      );
  }

  getBuildingAreaIds(buildingId: number): Observable<ApiBuildingsAreaIds> {
    return this.get(API_URL + `buildings/${buildingId}/areaids`)
      .pipe(
        map((data: ApiData) => {
          return data.payload as ApiBuildingsAreaIds;
        })
      );
  }

  getBuildingFloors(buildingId: number): Observable<ApiBuildingsFloors> {
    return this.get(API_URL + `buildings/${buildingId}/floors`)
      .pipe(
        map((data: ApiData) => {
          return this.apiBuildingFloorsAdapter.adapt(data.payload);
        })
      );
  }

  getBuildingPlanningData(buildingId: number): Observable<ApiBuildingsPlanningData> {
    return this.get(API_URL + `buildings/${buildingId}/PlanningData`)
      .pipe(
        map(data => this.apiBuildingsPlanningDataAdapter.adapt(data.payload))
      );
  }
}
