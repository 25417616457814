import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FloorCatalogActions } from '../actions';
import { FloorCatalog } from '../../../domain-models/business/floor-catalog.model';

export const floorCatalogsFeatureKey = 'floorCatalog';

export interface State extends EntityState<FloorCatalog> {}

export const adapter: EntityAdapter<FloorCatalog> = createEntityAdapter<FloorCatalog>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(FloorCatalogActions.addFloorCatalogItems, (state, {floorCatalogItems}) => {
    return adapter.addMany(floorCatalogItems, state);
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorCatalogsIds = selectIds;
export const selectFloorCatalogEntities = selectEntities;
export const selectAllFloorCatalogItems = selectAll;
export const selectFloorCatalogItemsTotal = selectTotal;
