import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromProjectManagement from '../project-management/reducers';
import { TaskEffects } from './effects/task.effects';
import { TaskFloorEffects } from './effects/task-floor.effects';
import { TaskContributorDirectoryEffects } from './effects/task-contributor-directory.effects';
import { TaskBusinessTypeEffects } from './effects/task-business-type.effects';
import { TaskTypeEffects } from './effects/task-type.effects';
import { ProjectManagementEffects } from './effects/project-management.effects';
import { GanttTaskEffects } from './effects/gantt-task.effects';
import { TaskLinkEffects } from './effects/task-link.effects';
import { TaskStatusEffects } from './effects/task-status.effects';
import { TaskFunctionEffects } from './effects/task-function.effects';
import { TaskContributorEffects } from './effects/task-contributor.effects';
import { TaskValidationEffects } from './effects/task-validation.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProjectManagement.projectManagementFeatureKey, fromProjectManagement.reducers),
    EffectsModule.forFeature([
      TaskEffects,
      TaskFloorEffects,
      TaskContributorDirectoryEffects,
      TaskBusinessTypeEffects,
      TaskTypeEffects,
      ProjectManagementEffects,
      GanttTaskEffects,
      TaskLinkEffects,
      TaskStatusEffects,
      TaskFunctionEffects,
      TaskContributorEffects,
      TaskValidationEffects
    ]),
  ],
})
export class ProjectManagementStoreModule { }
