import { createReducer, on } from '@ngrx/store';
import * as CoreActions from '../actions/core.actions';
import { EcSyFile } from '../../../domain-models/models/EcSyFile.model';
import { UserIdentity } from '../../../domain-models/business-extended/user.model';

export const coreFeatureKey = 'core';

export interface State {
  client: EcSyFile;
  user: UserIdentity;
  callerRequestId: string;
}

export const initialState: State = {
  client: null,
  user: null,
  callerRequestId: null
};

export const reducer = createReducer(
  initialState,
  on(CoreActions.setClient, (state, {client}) => ({...state, client: client})),
  on(CoreActions.setClientFile, (state, {client}) => ({...state, client: client})),
  on(CoreActions.clearClient, (state) => ({...state, client: null})),
  on(CoreActions.setUser, (state, {user}) => ({...state, user: user})),
  on(CoreActions.setCallerRequestId, (state, {callerRequestId}) => ({...state, callerRequestId: callerRequestId})),
  on(CoreActions.setAppUser, (state, {appUser}) => {
    const user = new UserIdentity(state.user.name, state.user.employer, state.user.preferred_username, state.user.role, state.user.sub);
    user.setAppUser(appUser);
    return {...state, user: user};
  })
);
