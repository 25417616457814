import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum EquipmentPlanningStateEnum {
  StandingBy = 1,
  Installed,
  Unchanged,
  ToStore,
  ToMove,
  ToRemove,
  FromStore,
  FromMove,
  FromPurchase,
  Updated
}

export const EQUIPMENT_PLANNING_STATE = {
  databaseTableName: 'EquipmentPlanningState',
  id: 'EqPlSt_Id',
  name: 'EqPlSt_Name',
  color: 'EqPlSt_Color',
  displayNameId: 'EqPlSt_DisplayNameId',
  scopeId: 'EqPlSt_ScopeId'

};

export class EquipmentPlanningState {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public color: Color,
    public displayNameId: number,
    public scopeId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class EquipmentPlanningStateAdapter implements Adapter<EquipmentPlanningState> {
  adapt(item: any): EquipmentPlanningState {
    return new EquipmentPlanningState(
      EQUIPMENT_PLANNING_STATE.databaseTableName,
      item[EQUIPMENT_PLANNING_STATE.id],
      item[EQUIPMENT_PLANNING_STATE.name],
      ColorUtils.colorFromHexARGB(item[EQUIPMENT_PLANNING_STATE.color]),
      item[EQUIPMENT_PLANNING_STATE.displayNameId],
      item[EQUIPMENT_PLANNING_STATE.scopeId]
    );
  }

  encode(item: EquipmentPlanningState | Partial<EquipmentPlanningState>): ISingleBackendObj {
    const encoded = {
      TableName: EQUIPMENT_PLANNING_STATE.databaseTableName,
      [EQUIPMENT_PLANNING_STATE.id]: item.id,
      [EQUIPMENT_PLANNING_STATE.name]: item.name,
      [EQUIPMENT_PLANNING_STATE.color]: item.color.toHexArgb(),
      [EQUIPMENT_PLANNING_STATE.displayNameId]: item.displayNameId,
      [EQUIPMENT_PLANNING_STATE.scopeId]: item.scopeId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
