export enum FunctionPointEnum {
  Dashboard = '9A2CD164-E590-42B2-8862-BD674ECC4058',
  FIMMap = 'D8B256ED-D743-4969-98B5-A3017389288F',
  FIMLayers = '83885DC4-AF93-4B54-9895-2244E3587CC5',
  FIMPictograms = '8D210950-6FB6-4272-A9F8-DDC1A314D8EF',
  FIMWallStyles = 'A93E5D06-0013-411E-9369-660F2A43FA77',
  FIMDoorStyles = '2F6B3BEC-E9DF-4DBF-A7E1-7E2614C94637',
  RealEstateAddresses = '4976082D-0FDB-441E-9D33-A0B4A5F14FD9',
  RealEstateGeoRef = 'BB6DF253-24FE-4E49-8D43-A6F12B729E0D',
  RealEstateLeaseContractors = '96ADC50A-21E5-40FA-944D-3D1E8D228A76',
  RealEstateLeaseDuration = '6DEF9FE2-CFF1-421D-AFF8-68682793EF90',
  RealEstateLeaseRentItems = 'D213CAE0-8AAB-4EF8-9E9D-ECD8A539F72D',
  RealEstateLeases = 'EBA3B24B-027E-4B2F-AC21-9A87B368639B',
  RealEstateLeaseTypes = '6542E1B1-44E6-47F3-8CD3-EB5FD73B13F6',
  RealEstatePerimeters = 'C77BF497-8C88-4367-850B-07EE695ACCC5',
  ProjectsConttributors = 'EA90FBE0-CABD-4BAF-BFD4-665F1AB1D231',
  ProjectsFunctions = '2CF82C6D-A57E-4094-91E8-B8C58440659E',
  ProjectsGantt = '31B691A1-7EEF-41AB-9B68-57625D05D860',
  ProjectsSimulations = '47E54AD1-DDF5-4002-B84F-C0A3C1E5CB30',
  ProjectsStatus = '0C5DB25B-0995-4BB5-BB12-F5C0978A6F59',
  ProjectsTasks = '31E05305-7F97-4E6F-9053-5EBA8ECAEE6F',
  PlanningRoomLayoutTypes = '96BC1665-B602-4556-BE04-FBA963644A37',
  PlanningWorkplaceTypes = 'AE1B10C6-C8A9-4366-9978-32F93AE7BDD9',
  InventoryCatalog = '022F1730-4608-4EC4-88C4-A2B550B6DA72',
  InventoryCategories = '4AE89F70-B3D1-4D94-AF57-7F33BA5947E1',
  InventoryDashboard = 'BD80868F-8C93-4D0F-9D2D-9979E33DFB79',
  InventoryProviders = '8229CAEF-CFA7-4DE9-B214-B8BE3FCE9E6F',
  InventoryPurchase = 'CD45D79D-27F3-4A4F-87B9-B0F922010F2F',
  InventoryStorage = '16127573-FC8E-4C27-BDDF-E8F0C0A29CCE',
  FacilityCompanies = '8A9753F9-74AD-4797-AF4E-AFAFB263BCF5',
  FacilityCompanySkills = '143C2A1D-FA81-4E8A-936E-2A13C9AF799B',
  FacilityJobTypes = 'A2F5D2D0-096E-4569-87F9-82DB44893498',
  FacilityLinesOfWork = '3374330E-A73A-4426-98DA-F7D2C6ECB0B2',
  FacilityUnitsOfWork = '1D71F203-C49B-4C26-B443-D5CDEB43703E',
  DirectoriesBus = '6E2ABE2F-470D-4E9C-BA5C-1E7D78E45B90',
  DirectoriesPeople = '7C8D4D41-37FB-4D68-8091-96A95DF4A851',
  AccountsContractorsGrants = '014B33AC-C829-43F5-B142-A955C6A05436',
  AccountsContractorsReferencing = '040B59E9-7418-4CDD-86D7-C9A55E1877D2',
  AccountsGrants = '9FC383D3-84BF-449E-8A1C-7908524B3B17',
  AccountsMailDomains = 'EDDF207F-685B-401A-859B-AB1342C7F3BA',
  AccountsRoles = '5466E28D-CE05-4DBA-898C-A61E5577EEB1',
  AccountsUsers = '5E8A2BC6-C722-4772-9C6C-7C714AFE1AB3',
}
