<div class='notifications-panel-container'>
  <div class='panel-heading'>
    <h3>Notifications</h3>
    <span class='spacer'></span>
<!--    <button mat-icon-button (click)="readAllTimeout ? clearReadAllTimeout() : readAll()" matTooltipPosition='before' matTooltip='Tout marquer comme lu' aria-label="Tout marquer comme lu">-->
<!--      <mat-icon [color]='!readAllTimeout ? null : "warn"'>{{!readAllTimeout ? 'done_all' : 'clear'}}</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button (click)="hideAllTimeout ? clearHideAllTimeout() : hideAll()" matTooltipPosition='before' matTooltip='Tout archiver' aria-label="Tout supprimer">-->
<!--      <mat-icon [color]='!hideAllTimeout ? null : "warn"'>{{!hideAllTimeout ? 'archive' : 'clear'}}</mat-icon>-->
<!--    </button>-->
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf='(broadcastService.notifications$ | async)?.length > 0; then notifications else emptyNotifications'></ng-container>
</div>

<ng-template #notifications>
  <ng-container *ngFor='let notification of (broadcastService.notifications$ | async)'>
    <mat-card *ngIf='!notification.message.hasBeenHidden'
              class="notification mat-elevation-z0"
              [ngClass]='{"notification-unread" : !notification.message.hasBeenRead }'>
      <ng-container *ngIf='timeouts[notification.message.id]; then timeoutTpl else notificationTpl;'></ng-container>
      <ng-template #notificationTpl>
        <mat-card-content class='notification-content'>
          <div class='row'>
            <p>
              {{notification.message.content}}
            </p>
            <span class='spacer'></span>
            <mat-icon class='icon-action' inline
                      color='accent'
                      matTooltipPosition='before'
                      [matTooltip]='notification.message.hasBeenRead ? "Marquer comme non lu" : "Marquer comme lu"'
                      (click)='readMessage(notification)'
                      [fontSet]="notification.message.hasBeenRead ? 'material-icons-outlined': 'material-icons'">circle</mat-icon>
            <mat-icon inline class='icon-action'
                      matTooltipPosition='before'
                      matTooltip='Archiver'
                      (click)='hideMessage(notification)'>clear</mat-icon>
          </div>
          <span class='mat-caption timestamp'>{{notification.message.wroteTimestamp | dateAgo}}</span>
        </mat-card-content>
        <mat-card-actions *ngIf='notification.actions.length > 0' align='end'>
          <button mat-flat-button *ngIf="notification.actions | find:'actionTypeId':1" (click)='loadNotificationContextData(notification)'>Voir la tâche</button>
          <button mat-flat-button *ngIf="notification.actions | find:'actionTypeId':2" (click)='loadNotificationContextData(notification)'>Habilitations des contractants</button>
        </mat-card-actions>
      </ng-template>
      <ng-template #timeoutTpl>
        <mat-card-content class='timeout'>
          <mat-spinner diameter='32' color='accent'></mat-spinner>
          <button mat-button color='warn' (click)='clearTimeout(notification.message.id)'>Annuler l'archivage</button>
        </mat-card-content>
      </ng-template>
    </mat-card>
  </ng-container>
</ng-template>
<ng-template #emptyNotifications>
  <mat-card>Vous n'avez pas de notification en attente.</mat-card>
</ng-template>
