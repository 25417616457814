import { createSelector } from '@ngrx/store';
import * as fromBuilding from '../reducers/building.reducer';
import { selectBuildingsState } from '../reducers';

/** Select store slice **/
export const selectBuildings = createSelector(
  selectBuildingsState,
  state => state[fromBuilding.buildingFeatureKey]
);

/** Basics selectors **/

export const selectBuildingIds = createSelector(
  selectBuildings,
  fromBuilding.selectBuildingIds // shorthand for buildingsState => fromBuilding.selectBuildingIds(buildingsState)
);
export const selectBuildingEntities = createSelector(
  selectBuildings,
  fromBuilding.selectBuildingEntities
);
export const selectAllBuildings = createSelector(
  selectBuildings,
  fromBuilding.selectAllBuildings
);
export const selectBuildingTotal = createSelector(
  selectBuildings,
  fromBuilding.selectBuildingTotal
);
export const selectCurrentBuildingId = createSelector(
  selectBuildings,
  fromBuilding.getSelectedBuildingId
);

export const selectCurrentBuilding = createSelector(
  selectBuildingEntities,
  selectCurrentBuildingId,
  (buildingEntities, buildingId) => buildingEntities[buildingId]
);

/** Advanced selectors **/
