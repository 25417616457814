<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="treeControl.dataNodes">

  <!-- Invisible node template -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="empty-node">
    <button mat-icon-button disabled></button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: displayChildren" matTreeNodeToggle matTreeNodePadding
                 [disabled]="onlySelectParents || disabled"
                 [ngClass]="{'only-select-parents-child-node': onlySelectParents,
                 'selectable': true}"
  >
    <button mat-icon-button disabled></button>
    <span *ngIf="!checkboxes">{{node.name}}</span>
    <mat-checkbox *ngIf="checkboxes"
                  class="checklist-leaf-node"
                  [disabled]='disabled'
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                 [ngClass]="{'only-select-parents-parent-node': onlySelectParents}">
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>

    <mat-checkbox *ngIf="checkboxes"
                  [disabled]='disabled'
                  [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
    <span *ngIf="!checkboxes">{{node.name}}</span>
  </mat-tree-node>

</mat-tree>
