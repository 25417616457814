/** Flat to-do item node with expandable and level information */
import { Color } from '../../../core/utils/color';

export class ItemFlatNode<T> {
  displayNameId: number;
  name: string;
  color?: Color;
  data: T;
  level: number;
  expandable: boolean;
}
