import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeComponent } from '../tree/tree.component';
import { TreeDatabaseService } from '../../services/tree-database.service';
import { ItemFlatNode } from '../../models/item-flat-node.model';
import { filter, map } from 'rxjs/operators';
import { FLOOR_MODEL } from '../../../../domain-models/business/floor-model.model';
import { BUSINESS_UNIT } from '../../../../domain-models/business/business-unit.model';
import { ApiService } from '../../../../core/services/api.service';
import { NodeSelection } from '../../models/node-selection.model';

@Component({
  selector: 'echo-selectable-tree',
  templateUrl: './selectable-tree.component.html',
  styleUrls: ['./selectable-tree.component.scss'],
  providers: [TreeDatabaseService],
})
export class SelectableTreeComponent extends TreeComponent implements AfterViewInit, OnChanges {

  FLOOR_MODEL = FLOOR_MODEL;
  BUSINESS_UNIT = BUSINESS_UNIT;

  @Output() selectedNode$ = new EventEmitter<NodeSelection<any>>();
  @Output() addToNode$ = new EventEmitter<any>();
  @Input() expandNode;

  @Input() clearSelectedNode: any;
  @Input() canHaveChildrenColName: string;

  selectedNode: ItemFlatNode<any>;

  constructor(_database: TreeDatabaseService,
              private api: ApiService) {
    super(_database);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.expandNode) {
      /**
       * Search for node to be expanded in the tree
       */
      console.log(this.expandNode);
      this._database.dataChange.pipe(
        filter(nodes => nodes.length > 0),
        //tap(a => console.log(a)),
        map(nodes => {
          const flattened = this.treeFlattener.flattenNodes(this.dataSource.data);
          const found = flattened.find(node => node.data[this.hierarchyMetaData.idColumnName] === this.expandNode[this.hierarchyMetaData.idColumnName]);
          if (found) {
            //console.log(found);
            //console.log(this.treeControl);
            //console.log(this.treeControl['dataNodes']);
            //this.treeControl.expand(this.treeControl.dataNodes.find(e => e === found));
          }
        })
      ).subscribe();

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearSelectedNode) {
      this.selectedNode = null;
    }
    super.ngOnChanges(changes);
  }

  selectNode(node: ItemFlatNode<any>) {
    if (this.selectedNode === node) {
      this.selectedNode$.next(null);
      this.selectedNode = null;
    } else {
      const selection: NodeSelection<any> = {node: node};
      const parent = this.getParent(node);
      if (parent) {
        selection.parentNode = parent;
      }
      this.selectedNode$.next(selection);
      this.selectedNode = node;
    }
  }

  addToNode(event: MouseEvent, node: ItemFlatNode<any>) {
    event.stopImmediatePropagation();
    this.addToNode$.next(node.data);
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  getParent(node: ItemFlatNode<any>) {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.flattenedData.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.flattenedData[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

}
