import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter';
import { Z_DB_COLUMN } from '../z-db-column.model';
import { Z_SCOPE } from '../z-scope.model';
import { Z_DB_TYPE } from '../z-db-type.model';


export const Z_COLUMN_VIEW = {
  databaseTableName: 'z_column_view',
  dbColumnColumnName: Z_DB_COLUMN.columnName,
  dbColumnColumnDisplayNameId: Z_DB_COLUMN.columnDisplayNameId,
  dbColumnIsBrowsable: Z_DB_COLUMN.isBrowsable,
  dbColumnIsReadOnly: Z_DB_COLUMN.isReadOnly,
  dbColumnScopeId: Z_DB_COLUMN.scopeId,
  dbColumnCategoryId: Z_DB_COLUMN.categoryId,
  dbColumndisplayOrder: Z_DB_COLUMN.displayOrder,
  dbColumnIsDefault: Z_DB_COLUMN.isDefault,
  scopeId: Z_SCOPE.id,
  scopeName: Z_SCOPE.name,
  columnViewColumnDisplayName: 'CoVi_ColumnDisplayName',
  columnViewColumnCategoryDisplayName: 'CoVi_ColumnCategoryDisplayName',
  dbColumnTableName: Z_DB_COLUMN.tableName,
  dbColumnIsPrimaryKey: Z_DB_COLUMN.isPrimaryKey,
  dbColumnIsIdentity: Z_DB_COLUMN.isIdentity,
  dbColumnType: Z_DB_COLUMN.type,
  dbColumnDisplayStringFormat: Z_DB_COLUMN.displayStringFormat,
  dbColumnReadGrandId: Z_DB_COLUMN.readGrantId,
  dbColumnUpdateGrantId: Z_DB_COLUMN.updateGrantId,
  dbTypeId: Z_DB_TYPE.id,
  dbTypeSystemTypeName: Z_DB_TYPE.systemTypeName,
  dbTypeDatabaseTypeName: Z_DB_TYPE.databaseTypeName
};

export class ZColumnView {
  constructor(
    public databaseTableName: string,
    public dbColumnColumnName: string,
    public dbColumnColumnDisplayNameId: number,
    public dbColumnIsBrowsable: boolean,
    public dbColumnIsReadOnly: boolean,
    public dbColumnScopeId: number,
    public dbColumnCategoryId: number,
    public dbColumndisplayOrder: number,
    public dbColumnIsDefault: boolean,
    public scopeId: number,
    public scopeName: string,
    public columnViewColumnDisplayName: string,
    public columnViewColumnCategoryDisplayName: string,
    public dbColumnTableName: string,
    public dbColumnIsPrimaryKey: boolean,
    public dbColumnIsIdentity: boolean,
    public dbColumnType: number,
    public dbColumnDisplayStringFormat: string,
    public dbColumnReadGrandId: number,
    public dbColumnUpdateGrantId: number,
    public dbTypeId: number,
    public dbTypeSystemTypeName: string,
    public dbTypeDatabaseTypeName: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class ZColumnViewAdapter implements Adapter<ZColumnView> {
  adapt(item: any): ZColumnView {
    return new ZColumnView(
      Z_COLUMN_VIEW.databaseTableName,
      item[Z_COLUMN_VIEW.dbColumnColumnName],
      item[Z_COLUMN_VIEW.dbColumnColumnDisplayNameId],
      item[Z_COLUMN_VIEW.dbColumnIsBrowsable],
      item[Z_COLUMN_VIEW.dbColumnIsReadOnly],
      item[Z_COLUMN_VIEW.dbColumnScopeId],
      item[Z_COLUMN_VIEW.dbColumnCategoryId],
      item[Z_COLUMN_VIEW.dbColumndisplayOrder],
      item[Z_COLUMN_VIEW.dbColumnIsDefault],
      item[Z_COLUMN_VIEW.scopeId],
      item[Z_COLUMN_VIEW.scopeName],
      item[Z_COLUMN_VIEW.columnViewColumnDisplayName],
      item[Z_COLUMN_VIEW.columnViewColumnCategoryDisplayName],
      item[Z_COLUMN_VIEW.dbColumnTableName],
      item[Z_COLUMN_VIEW.dbColumnIsPrimaryKey],
      item[Z_COLUMN_VIEW.dbColumnIsIdentity],
      item[Z_COLUMN_VIEW.dbColumnType],
      item[Z_COLUMN_VIEW.dbColumnDisplayStringFormat],
      item[Z_COLUMN_VIEW.dbColumnReadGrandId],
      item[Z_COLUMN_VIEW.dbColumnUpdateGrantId],
      item[Z_COLUMN_VIEW.dbTypeId],
      item[Z_COLUMN_VIEW.dbTypeSystemTypeName],
      item[Z_COLUMN_VIEW.dbTypeDatabaseTypeName]
    );
  }
}
