import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FLOOR_DATA } from '../business/floor-data.model';
import { BUILDING } from '../business/building.model';
import { FLOOR } from '../business/floor.model';
import { Color, ColorUtils } from '../../core/utils/color';
import { ROOM } from '../business/room.model';
import { ROOM_ATTRIBUTION_TYPE } from '../business/room-attribution-type.model';
import { ROOM_ATTRIBUTION_TYPE_VIEW } from '../business/Room_Attribution_Type_View';

export const FLOOR_ATTRIBUTION_VIEW = {
  databaseTableName: 'FloorAttributionView',
  siteId: BUILDING.siteId,
  buildingId: FLOOR.buildingId,
  floorId: FLOOR.id,
  taskId: FLOOR_DATA.taskId,
  roomAttributionTypeId: ROOM.attributionTypeId,
  roomAttributionTypeName: ROOM_ATTRIBUTION_TYPE.name,
  roomAttributionTypeColor: ROOM_ATTRIBUTION_TYPE.color,
  roomAttributionTypeDisplayNameId: ROOM_ATTRIBUTION_TYPE.displayNameId,
  roomAttributionTypeDisplayName: ROOM_ATTRIBUTION_TYPE_VIEW.displayName,
  area: 'FlAtVi_Area',
  roundedArea: 'FlAtVi_RoundedArea',
};

export class FloorAttributionView {
  constructor(
    public databaseTableName: string,
    public siteId: number,
    public buildingId: number,
    public floorId: number,
    public taskId: number,
    public roomAttributionTypeId: number,
    public roomAttributionTypeName: string,
    public roomAttributionTypeColor: Color,
    public roomAttributionTypeDisplayNameId: number,
    public roomAttributionTypeDisplayName: string,
    public area: number,
    public roundedArea: number) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class FloorAttributionViewAdapter implements Adapter<FloorAttributionView> {
  adapt(item: any): FloorAttributionView {
    return new FloorAttributionView(
      FLOOR_ATTRIBUTION_VIEW.databaseTableName,
      item[FLOOR_ATTRIBUTION_VIEW.siteId],
      item[FLOOR_ATTRIBUTION_VIEW.buildingId],
      item[FLOOR_ATTRIBUTION_VIEW.floorId],
      item[FLOOR_ATTRIBUTION_VIEW.taskId],
      item[FLOOR_ATTRIBUTION_VIEW.roomAttributionTypeId],
      item[FLOOR_ATTRIBUTION_VIEW.roomAttributionTypeName],
      ColorUtils.colorFromHexARGB(item[FLOOR_ATTRIBUTION_VIEW.roomAttributionTypeColor]),
      item[FLOOR_ATTRIBUTION_VIEW.roomAttributionTypeDisplayNameId],
      item[FLOOR_ATTRIBUTION_VIEW.roomAttributionTypeDisplayName],
      item[FLOOR_ATTRIBUTION_VIEW.area],
      item[FLOOR_ATTRIBUTION_VIEW.roundedArea],
    );
  }
}
