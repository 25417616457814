import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Room } from '../../../domain-models/business/room.model';
import { RoomActions } from '../actions';

export const roomFeatureKey = 'rooms';

export interface State extends EntityState<Room> {
  // additional entities state properties
  selectedRoomId: number;
  selectedIds: number[];
}

export function selectFloorDataId(a: Room): number {
  return a.floorDataId;
}

export const adapter: EntityAdapter<Room> = createEntityAdapter<Room>({
  selectId: selectFloorDataId
});

export const initialState: State = adapter.getInitialState({
  selectedRoomId: null,
  selectedIds: [],
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(RoomActions.addRoom, (state, {room}) => {
    return adapter.addOne(room, state);
  }),
  on(RoomActions.setRoom, (state, {room}) => {
    return adapter.setOne(room, state);
  }),
  on(RoomActions.upsertRoom, (state, {room}) => {
    return adapter.upsertOne(room, state);
  }),
  on(RoomActions.addRooms, (state, {rooms}) => {
    return adapter.addMany(rooms, state);
  }),
  on(RoomActions.upsertRooms, (state, {rooms}) => {
    return adapter.upsertMany(rooms, state);
  }),
  on(RoomActions.updateRoom, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(RoomActions.updateRooms, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(RoomActions.mapRooms, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(RoomActions.deleteRoom, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(RoomActions.deleteRooms, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(RoomActions.deleteRoomsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(RoomActions.loadRooms, (state, {rooms}) => {
    return adapter.setAll(rooms, state);
  }),
  on(RoomActions.clearRooms, state => {
    return adapter.removeAll({...state, selectedRoomId: null});
  }),
  /** END OF CRUD **/
  on(RoomActions.updateSelectedRoomId, (state, {id}) => {
    return {...state, selectedRoomId: id};
  }),
  on(RoomActions.updateSelection, (state, {addedId, removedId}) => {
    const alreadySelectedId = state.selectedIds.find(e => e === addedId);
    const idToRemoveIndex = state.selectedIds.indexOf(removedId, 0);

    let cases: number;
    if (addedId && !removedId) {
      if (!alreadySelectedId) {
        cases = 1;
      } else {
        cases = 0;
      }
    } else if (!addedId && removedId) {
      if (idToRemoveIndex > -1) {
        cases = 2;
      } else {
        cases = 0;
      }
    } else if (addedId && removedId) {
      if (!alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 3;
        } else {
          cases = 1;
        }
      } else if (alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 2;
        } else {
          cases = 0;
        }
      }
    } else {
      cases = 0;
    }
    switch (cases) {
      case 0:
        return state;
      case 1:
        return {...state, selectedIds: [...state.selectedIds, addedId]};
      case 2:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1)]};
      case 3:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1), addedId ]};
      default: {
        return state;
      }
    }
  }),
  on(RoomActions.clearSelection, (state) => {
    return {...state, selectedIds: state.selectedIds.slice(0, 0)};
  }),
);

export const getSelectedRoomId = (state: State) => state.selectedRoomId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoomIds = selectIds;
export const selectRoomEntities = selectEntities;
export const selectAllRooms = selectAll;
export const selectRoomTotal = selectTotal;
