import { Pipe, PipeTransform } from '@angular/core';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { BusinessUnit } from '../../domain-models/business/business-unit.model';
import { BusinessUnitSelectors } from '../../store/shared-store/selectors';
import { Observable } from 'rxjs';

@Pipe({
  name: 'businessUnit'
})
export class BusinessUnitPipe implements PipeTransform {

  constructor(private store: Store<State>) {
  }

  transform(value: number): Observable<BusinessUnit> {
    return this.store.select(BusinessUnitSelectors.selectBusinessUnitsById(value));
  }

}
