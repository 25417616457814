import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { TaskSelectors } from '../../project-management/selectors';
import { State } from '../../../reducers';
import { EMPTY } from 'rxjs';
import { EquipmentPlanningActions } from '../actions';

@Injectable()
export class EquipmentPlanningEffects {

  beginGetCurrentFloorEquipmentPlannings$ = createEffect(() => this.actions$.pipe(
    ofType(EquipmentPlanningActions.beginGetCurrentFloorEquipmentPlanningItems),
    switchMap((action) =>
      this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
        switchMap(taskId => {
          if (taskId) {
            return this.floorService.getEquipmentPlanningItemsByTaskId(taskId).pipe(
              map((equipmentPlanningItems) => EquipmentPlanningActions.addEquipmentPlanningItems({equipmentPlanningItems: equipmentPlanningItems}))
            );
          } else {
            return EMPTY;
          }
        })
      )
    ))
  );

  updateEquipmentPlanningState$ = createEffect(() => this.actions$.pipe(
    ofType(EquipmentPlanningActions.updateEquipmentPlanningState),
    switchMap((action) =>
      this.floorService.updateEquipmentPlanningState(action.dialogResult).pipe(
        map((equipmentPlanningItems) => EquipmentPlanningActions.upsertEquipmentPlanningItems({equipmentPlanningItems: equipmentPlanningItems}))
      )
    )
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
