import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { MenuItem } from '../../models/menu-item.model';
import { Router } from '@angular/router';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'echo-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  public menuItems;

  constructor(private menuService: MenuService,
              private layoutService: LayoutService,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.menuService.itemToDisplay$.subscribe(menu => {
      this.menuItems = menu;
    });
  }

  onMenuItemClick(item: MenuItem) {
    if (item.componentPath === 'list') {
      const href = `data-tables?endpoint=${item.apiEndPoint}&usageContextId=${item.usageContextId}&dataContext=${item.dataContext}`;

      this.router.navigate([`/data-tables/${item.label}`], {
        queryParams: {'endpoint': item.apiEndPoint, 'usageContextId': item.usageContextId, 'dataContext': item.dataContext},
      });
    } else {
      //this.menuService.currentPage$.next(item.label);
      this.router.navigate([item.componentPath]);
    }
    this.layoutService.leftDrawer$.next(false);
  }

}
