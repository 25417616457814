import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum FloorWallStyleEnum {
  Standard = 1,
  FullHeightGlass = 2,
  GlazedModule = 3,
  Firewall = 4,
  Virtual = 5
}

export const FLOOR_WALL_STYLE = {
  databaseTableName: 'FloorWallStyle',
  id: 'FlWaSt_Id',
  name: 'FlWaSt_Name',
  displayNameId: 'FlWaSt_DisplayNameId',
  color: 'FlWaSt_Color',
  scope: 'FlWaSt_Scope',
};

export class FloorWallStyle {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color,
    public scope: number,
  ) {
  }

}

@Injectable({
  providedIn: 'root'
})
export class FloorWallStyleAdapter implements Adapter<FloorWallStyle> {
  adapt(item: any): FloorWallStyle {
    return new FloorWallStyle(
      FLOOR_WALL_STYLE.databaseTableName,
      item[FLOOR_WALL_STYLE.id],
      item[FLOOR_WALL_STYLE.name],
      item[FLOOR_WALL_STYLE.displayNameId],
      ColorUtils.colorFromHexARGB(item[FLOOR_WALL_STYLE.color]),
      item[FLOOR_WALL_STYLE.scope]
    );
  }

  encode(item: FloorWallStyle): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FLOOR_WALL_STYLE.id]: item.id,
      [FLOOR_WALL_STYLE.name]: item.name,
      [FLOOR_WALL_STYLE.displayNameId]: item.displayNameId,
      [FLOOR_WALL_STYLE.color]: item.color.toHexArgb(),
      [FLOOR_WALL_STYLE.scope]: item.scope
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
