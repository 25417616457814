import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum DoorStyleEnum {
  SingleSwing = 1,
  DoubleSwing = 2,
  UnevenSwing = 3,
  SingleSwingDoubleHung = 4,
  DoubleSwingDoubleHung = 5,
  UnevenSwingDoubleHung = 6,
  SingleSliding = 7,
  DoubleSliding = 8,
  SinglePocketSliding = 9,
  DoublePocketSliding = 10,
  SingleBiFold = 11,
  DoubleBiFold = 12,
  Revolving = 13
}

// type de porte (SvgDoorOpeningType enum)
// nombre de battants
// direction d’ouverture (poussant droite ou gauche)
// côté du mur de l’ouverture
// angle du mur
// largeur de la porte
// épaisseur du mur (wall width)
// angle d’ouverture
// battante (bool)

export interface DoorData {
  type: number; //SvgDoorOpeningTypeEnum
  leafCount: number;
  openingDirection: number;
  openingWallSide: number;
  wallAngle: number;
  doorWidth: number;
  wallThickness: number;
  openingAngle: number;
  swinging: boolean;
}

export const FLOOR_DOOR_STYLE = {
  databaseTableName: 'FloorDoorStyle',
  id: 'FlDoSt_Id',
  name: 'FlDoSt_Name',
  displayNameId: 'FlDoSt_DisplayNameId',
  color: 'FlDoSt_Color',
  scope: 'FlDoSt_Scope',
  openingTypeId: 'FlDoSt_OpeningTypeId',
  leafCount: 'FlDoSt_LeafCount'
};

export class FloorDoorStyle {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color,
    public scope: number,
    public openingTypeId: number,
    public leafCount: number
  ) {
  }

}

@Injectable({
  providedIn: 'root'
})
export class FloorDoorStyleAdapter implements Adapter<FloorDoorStyle> {
  adapt(item: any): FloorDoorStyle {
    return new FloorDoorStyle(
      FLOOR_DOOR_STYLE.databaseTableName,
      item[FLOOR_DOOR_STYLE.id],
      item[FLOOR_DOOR_STYLE.name],
      item[FLOOR_DOOR_STYLE.displayNameId],
      ColorUtils.colorFromHexARGB(item[FLOOR_DOOR_STYLE.color]),
      item[FLOOR_DOOR_STYLE.scope],
      item[FLOOR_DOOR_STYLE.openingTypeId],
      item[FLOOR_DOOR_STYLE.leafCount]
    );
  }

  encode(item: FloorDoorStyle): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_DOOR_STYLE.databaseTableName,
      [FLOOR_DOOR_STYLE.id]: item.id,
      [FLOOR_DOOR_STYLE.name]: item.name,
      [FLOOR_DOOR_STYLE.displayNameId]: item.displayNameId,
      [FLOOR_DOOR_STYLE.color]: item.color.toHexArgb(),
      [FLOOR_DOOR_STYLE.scope]: item.scope,
      [FLOOR_DOOR_STYLE.openingTypeId]: item.openingTypeId,
      [FLOOR_DOOR_STYLE.leafCount]: item.leafCount
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
