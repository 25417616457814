import { createSelector } from '@ngrx/store';
import * as fromCore from '../reducers/core.reducer';
import { selectCoreState } from '../reducers';

export const selectCore = createSelector(
  selectCoreState,
  state => state[fromCore.coreFeatureKey]
);

export const selectUser = createSelector(
  selectCore,
  state => state.user
);

export const selectUserRoles = createSelector(
  selectCore,
  state => state.user.role
);

export const selectClient = createSelector(
  selectCore,
  state => state.client
);

export const selectCallerRequestId = createSelector(
  selectCore,
  state => state.callerRequestId
);

export const selectCompanyId = createSelector(
  selectCore,
  state => state.client.CompanyId
);
