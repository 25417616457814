import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const PERIMETER = {
  databaseTableName: 'perimeter',
  id: 'Pe_Id',
  name: 'Pe_Name',
  description: 'Pe_Description',
  typeId: 'Pe_TypeId',
  isSharingArea: 'Pe_IsSharingAreaItem',
  readGrantId: 'Pe_ReadGrantId'
};

export class Perimeter {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public description: string,
    public typeId: number,
    public isSharingArea: boolean,
    public readGrantId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class PerimeterAdapter implements Adapter<Perimeter> {
  adapt(item: any): Perimeter {
    return new Perimeter (
      PERIMETER.databaseTableName,
      item[PERIMETER.id],
      item[PERIMETER.name],
      item[PERIMETER.description],
      item[PERIMETER.typeId],
      item[PERIMETER.isSharingArea],
      item[PERIMETER.readGrantId],
    );
  }
  encode(item: Perimeter): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [PERIMETER.id]: item.id,
      [PERIMETER.name]: item.name,
      [PERIMETER.description]: item.description,
      [PERIMETER.typeId]: item.typeId,
      [PERIMETER.isSharingArea]: item.isSharingArea,
      [PERIMETER.readGrantId]: item.readGrantId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
