import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { Z_DB_COLUMN } from '../core/z-db-column.model';

export const Z_GRANTED_COLUMN_VIEW = {
  databaseTableName: 'ZGrantedColumnView',
  tableName: Z_DB_COLUMN.tableName,
  columnName: Z_DB_COLUMN.columnName,
  columnDisplayNameId: Z_DB_COLUMN.columnDisplayNameId,
  isPrimaryKey: Z_DB_COLUMN.isPrimaryKey,
  isIdentity: Z_DB_COLUMN.isIdentity,
  isBrowsable: Z_DB_COLUMN.isBrowsable,
  isReadOnly: Z_DB_COLUMN.isReadOnly,
  scopeId: Z_DB_COLUMN.scopeId,
  categoryId: Z_DB_COLUMN.categoryId,
  displayOrder: Z_DB_COLUMN.displayOrder,
  isDefault: Z_DB_COLUMN.isDefault,
  type: Z_DB_COLUMN.type,
  displayStringFormat: Z_DB_COLUMN.displayStringFormat,
  readGrantId: Z_DB_COLUMN.readGrantId,
  updateGrantId: Z_DB_COLUMN.updateGrantId,
};

export class GrantedColumnView {
  constructor(
    public databaseTableName: string,
    public tableName: string,
    public columnName: string,
    public columnDisplayNameId: number,
    public isPrimaryKey: boolean,
    public isIdentity: boolean,
    public isBrowsable: boolean,
    public isReadOnly: boolean,
    public scopeId: number,
    public categoryId: number,
    public displayOrder: number,
    public isDefault: boolean,
    public type: number,
    public displayStringFormat: number,
    public readGrantId: number,
    public updateGrantId: number
    ) {}
}

@Injectable({
  providedIn: 'root'
})

export class GrantedColumnViewAdapter implements Adapter<GrantedColumnView> {
  adapt(item: any): GrantedColumnView {
    return new GrantedColumnView(
      Z_GRANTED_COLUMN_VIEW.databaseTableName,
      item[Z_GRANTED_COLUMN_VIEW.tableName],
      item[Z_GRANTED_COLUMN_VIEW.columnName],
      item[Z_GRANTED_COLUMN_VIEW.columnDisplayNameId],
      item[Z_GRANTED_COLUMN_VIEW.isPrimaryKey],
      item[Z_GRANTED_COLUMN_VIEW.isIdentity],
      item[Z_GRANTED_COLUMN_VIEW.isBrowsable],
      item[Z_GRANTED_COLUMN_VIEW.isReadOnly],
      item[Z_GRANTED_COLUMN_VIEW.scopeId],
      item[Z_GRANTED_COLUMN_VIEW.categoryId],
      item[Z_GRANTED_COLUMN_VIEW.displayOrder],
      item[Z_GRANTED_COLUMN_VIEW.isDefault],
      item[Z_GRANTED_COLUMN_VIEW.type],
      item[Z_GRANTED_COLUMN_VIEW.displayStringFormat],
      item[Z_GRANTED_COLUMN_VIEW.readGrantId],
      item[Z_GRANTED_COLUMN_VIEW.updateGrantId]
    );
  }
}
