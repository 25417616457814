export const Z_APP_PARAMETER = {
  databaseTableName: 'z_app_parameter',
  currentCultureParameterName : 'CurrentCulture',
  paginationDefaultItemsCountParameterName : 'PaginationDefaultItemsCount',
  edmBpsSupportIdParameterName : 'EdmSupportId',
  edmRevitSharedFileIdParameterName : 'EdmRevitSharedFileId',
  edmRevitTemplateIdParameterName : 'EdmRevitTemplateId',
  edmAcadTemplateFileIdParameterName : 'EdmAcadTemplateFileId',
  edmSupportCaoIdParameterName : 'EdmSupportCaoId',
  asBuiltCadTaskTypeIdParameterName : 'AsBuiltCadTaskTypeId',
  acadDisplayThemeLayerIdParameterName : 'AcadDisplayThemeLayerId',
  useGrosAreaRootItemIdParameterName : 'UseGrosAreaRootItemId',
  useNetAreaRootItemIdParameterName : 'UseNetAreaRootItemId',
  acadDynamicBlocVisibilityParameterNameParameterName : 'AcadDynamicBlocVisibilityParameterName',
  simulationMinAreaParameterName : 'SimulationMinArea',
  multiAllocatedDisplayThemeColorParameterName : 'MultiAllocatedDisplayThemeColor',
  notAllocatableDisplayThemeColorParameterName : 'NotAllocatableDisplayThemeColor',
  multiAllocatedDisplayThemeDisplayNameParameterName : 'MultiAllocatedDisplayThemeDisplayName',
  notAllocatableDisplayThemeDisplayNameParameterName : 'NotAllocatableDisplayThemeDisplayName',
  name: 'ApPa_Name',
  value: 'ApPa_Value',
  type: 'ApPa_Type',
};
