import { createAction, props } from '@ngrx/store';
import { Building } from '../../../domain-models/business/building.model';
import { EntityMap, Predicate, Update } from '@ngrx/entity';

/**
 * CRUD Actions
 */

export const loadBuildings = createAction('[Building/API] Load Users', props<{ buildings: Building[] }>());
export const addBuilding = createAction('[Building/API] Add Building', props<{ building: Building }>());
export const setBuilding = createAction('[Building/API] Set Building', props<{ building: Building }>());
export const upsertBuilding = createAction('[Building/API] Upsert Building', props<{ building: Building }>());
export const addBuildings = createAction('[Building/API] Add Buildings', props<{ buildings: Building[] }>());
export const upsertBuildings = createAction('[Building/API] Upsert Buildings', props<{ buildings: Building[] }>());
export const updateBuilding = createAction('[Building/API] Update Building', props<{ update: Update<Building> }>());
export const updateBuildings = createAction('[Building/API] Update Buildings', props<{ updates: Update<Building>[] }>());
export const mapBuildings = createAction('[Building/API] Map Buildings', props<{ entityMap: EntityMap<Building> }>());
export const deleteBuilding = createAction('[Building/API] Delete Building', props<{ id: string }>());
export const deleteBuildings = createAction('[Building/API] Delete Buildings', props<{ ids: string[] }>());
export const deleteBuildingsByPredicate = createAction('[Building/API] Delete Buildings By Predicate', props<{ predicate: Predicate<Building> }>());
export const clearBuildings = createAction('[Building/API] Clear Buildings');

/** END of CRUD **/

export const beginGetAllBuildings = createAction('[Building/API] - Begin Get All Buildings');
export const errorGetAllBuildings = createAction('[API] - Error Get All Buildings', props<Error>());

export const updateSelectedBuildingId = createAction(
  '[Building/API] - Save Selected Building Id',
  props<{ buildingId: number }>()
);

export const beginGetBuildingAreaIds = createAction('[Building/API] - Begin get Building area ids');


export const clearBuildingState = createAction('[APP] - Clear Building State');
