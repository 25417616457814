import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum ZDbTypeEnum {
  BooleanTinyint = 1,
  ColorChar = 2,
  DateTimeDatetime = 3,
  DoubleDouble = 4,
  Int32Int = 5,
  StringVarchar = 6,
  StringChar = 7,
  ByteArrayGeometry = 8,
  StringText = 9,
  Int64Int = 10,
  DecimalDecimal = 11,
  DateTimeTimestamp
}

export const Z_DB_TYPE = {
  databaseTableName: 'z_db_type',
  id: 'DbTy_Id',
  systemTypeName: 'DbTy_SystemTypeName',
  databaseTypeName: 'DbTy_DatabaseTypeName'
};

export class ZDbType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public systemTypeName: string,
    public databaseTypeName: string
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ZDbTypeAdapter implements Adapter<ZDbType> {
  adapt(item: any): ZDbType {
    return new ZDbType(
      Z_DB_TYPE.databaseTableName,
      item[Z_DB_TYPE.id],
      item[Z_DB_TYPE.systemTypeName],
      item[Z_DB_TYPE.databaseTypeName],
    );
  }
}
