import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FloorEffects } from './effects/floor.effects';
import * as fromFloor from './reducers';
import { RoomEffects } from './effects/room.effects';
import { UiContextEffects } from './effects/ui-context.effects';
import { RoomAllocationEffects } from './effects/room-allocation.effects';
import { WorkplaceEffects } from './effects/workplace.effects';
import { WorkplaceAllocationEffects } from './effects/workplace-allocation.effects';
import { EquipmentPlanningEffects } from './effects/equipment-planning.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFloor.floorFeatureKey, fromFloor.reducers),
    EffectsModule.forFeature([
      FloorEffects,
      RoomEffects,
      RoomAllocationEffects,
      WorkplaceAllocationEffects,
      UiContextEffects,
      WorkplaceEffects,
      EquipmentPlanningEffects
    ]),
  ],
})
export class FloorStoreModule {
}
