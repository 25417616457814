import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { API_ENDPOINTS, API_URL } from '../../../shared/api-endpoints';
import { map, tap } from 'rxjs/operators';
import { ApiData } from '../../../core/models/api-data.model';
import { TASK_BUSINESS_TYPE, TaskBusinessType, TaskBusinessTypeAdapter } from '../../../domain-models/business/task-business-type.model';
import {
  TASK_CONTRIBUTOR_DIRECTORY,
  TaskContributorDirectory,
  TaskContributorDirectoryAdapter
} from '../../../domain-models/business/task-contributor-directory.model';
import { TASK_STATUS, TaskStatus, TaskStatusAdapter } from '../../../domain-models/business/task-status.model';
import { TASK_TYPE, TaskType, TaskTypeAdapter } from '../../../domain-models/business/task-type.model';
import { ApiTasksRootItems, ApiTasksRootItemsAdapter } from '../../../domain-models/endpoint-specific/tasks-root-items.model';
import { TASK_FUNCTION, TaskFunction, TaskFunctionAdapter } from '../../../domain-models/business/task-function.model';
import { TaskValidation, TaskValidationAdapter } from '../../../domain-models/business/task-validation.model';
import { TaskChangelog, TaskChangelogAdapter } from '../../../domain-models/business/task-changelog.model';
import { Floor, FloorAdapter } from '../../../domain-models/business/floor.model';
import { LoggerService } from '../../../core/services/logger.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ApiTasksValidations, ApiTasksValidationsAdapter } from '../../../domain-models/endpoint-specific/tasks.validations.model';
import { ApiTasksValidationsRefAreCheck } from '../../../domain-models/endpoint-specific/tasks.validations.refarecheck.model';
import { TASK_LINK, TaskLink, TaskLinkAdapter } from '../../../domain-models/business/task-link.model';
import { UsageContextIdEnum } from '../../../shared/models/usage-context-id.enum';
import { TaskDisplayFilterOption } from '../models/task-display-filter-option.model';
import { Task, TaskAdapter } from '../../../domain-models/business/task.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService extends ApiService {

  public taskDisplayFilterValue$ = new ReplaySubject<TaskDisplayFilterOption>();
  private _taskDisplayFilterValue: TaskDisplayFilterOption;

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private taskContributorDirectoryAdapter: TaskContributorDirectoryAdapter,
              private taskBusinessTypeAdapter: TaskBusinessTypeAdapter,
              private taskAdapter: TaskAdapter,
              private taskFunctionAdapter: TaskFunctionAdapter,
              private taskStatusAdapter: TaskStatusAdapter,
              private taskTypeAdapter: TaskTypeAdapter,
              private floorAdapter: FloorAdapter,
              private taskValidationAdapter: TaskValidationAdapter,
              private apiTaskRootItemsAdapter: ApiTasksRootItemsAdapter,
              private taskChangelogAdapter: TaskChangelogAdapter,
              private taskLinkAdapter: TaskLinkAdapter,
              private apiTasksValidationsAdapter: ApiTasksValidationsAdapter,
  ) {
    super(http, loggerService, snackBarService, httpLoaderService);
    this.taskDisplayFilterValue$.pipe(
      tap(option => this._taskDisplayFilterValue = option)
    ).subscribe();
  }

  get taskDisplayFilterValue(): TaskDisplayFilterOption {
    return this._taskDisplayFilterValue;
  }

  loadTasks(): Observable<ApiTasksRootItems> {
    const params = new HttpParams()
      .set('isActive', 'true');

    return this.get(`${API_ENDPOINTS.tasks}`, null, params)
      .pipe(
        map((data: ApiData) => this.apiTaskRootItemsAdapter.adapt(data.payload)),
      );
  }

  getTasksTemplates(): Observable<Task[]> {
    return this.get(`${API_ENDPOINTS.tasksTemplates}`, null)
      .pipe(
        map((data: ApiData) => data.payload.map(t => this.taskAdapter.adapt(t)),
        )
      );
  }

  getTaskBusinessType(taskBusinessTypeId: number): Observable<TaskBusinessType> {
    return this.get(`${API_ENDPOINTS.dynT}${TASK_BUSINESS_TYPE.databaseTableName}/${taskBusinessTypeId}`)
      .pipe(
        map((data: ApiData) => this.taskBusinessTypeAdapter.adapt(data.payload)),
      );
  }

  getAllTaskContributorDirectory(): Observable<TaskContributorDirectory[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_CONTRIBUTOR_DIRECTORY.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskContributorDirectoryAdapter.adapt(item)))
      );
  }

  getAllTaskFunctions(): Observable<TaskFunction[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_FUNCTION.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskFunctionAdapter.adapt(item)))
      );
  }

  getAllTaskBusinessTypes(): Observable<TaskBusinessType[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_BUSINESS_TYPE.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskBusinessTypeAdapter.adapt(item)))
      );
  }

  getAllTaskStatusItems(): Observable<TaskStatus[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_STATUS.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskStatusAdapter.adapt(item)))
      );
  }

  getAllTaskTypes(): Observable<TaskType[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_TYPE.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskTypeAdapter.adapt(item)))
      );
  }

  getAllTaskValidationsByTaskId(taskId: number): Observable<TaskValidation[]> {
    return this.get(`${API_URL}tasks/validations`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskValidationAdapter.adapt(item)))
      );
  }

  getTaskChangelogByTaskId(taskId: number): Observable<TaskChangelog[]> {
    return this.get(`${API_URL}tasks/changelog`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskChangelogAdapter.adapt(item)).sort((a, b) => b.timeStamp.getTime() - a.timeStamp.getTime()))
      );
  }

  getTaskValidationByTaskId(taskId: number): Observable<TaskValidation[]> {
    return this.get(`${API_URL}tasks/validations`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskValidationAdapter.adapt(item)))
      );
  }

  refAreCheck(taskId: number): Observable<ApiTasksValidationsRefAreCheck> {
    return this.get(`${API_ENDPOINTS.taskValidationsRefAreCheck(taskId)}`)
      .pipe(
        map(data => data.payload as ApiTasksValidationsRefAreCheck),
        // tap(a => console.log(a)),
      );
  }


  validateTask(taskId: number, isApproved: boolean, comment: string, updateRefArea?: boolean): Observable<ApiTasksValidations> {
    const taskValidationPartial = {
      taskId: taskId,
      isApproved: isApproved,
      comment: comment,
    } as Partial<TaskValidation>;

    let dto = {
      ...this.taskValidationAdapter.encode(taskValidationPartial)
    } as any;

    if (updateRefArea) {
      dto = {
        ...dto,
        UpdateRefArea: updateRefArea,
      };
    }

    return this.post(`${API_URL}tasks/validations`, dto, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        // map((data: ApiData) => {
        //   return {
        //     taskValidation: this.taskValidationAdapter.adapt(data.payload['TaskValidation']),
        //     isLastValidation: data.payload['IsLastValidation']
        //   };
        // })
        map(data => this.apiTasksValidationsAdapter.adapt(data.payload)),
        tap(a => console.log(a)),
      );
  }

  getDatalessFloors(): Observable<Floor[]> {
    return this.get(`${API_ENDPOINTS.datalessFloors}`).pipe(
      map((data: ApiData) => data.payload.map(f => this.floorAdapter.adapt(f)))
    );
  }

  getTaskLinksByTaskId(taskId: number): Observable<TaskLink[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('primaryColumnName', TASK_LINK.sourceTaskId);
    httpParams = httpParams.append('primaryFilterId', taskId);
    return this.get(`${API_ENDPOINTS.dynT}/${TASK_LINK.databaseTableName}`, UsageContextIdEnum.none, httpParams)
      .pipe(
        map(data => data.payload.map(r => this.taskLinkAdapter.adapt(r)))
      );
  }
}
