import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { Z_DB_TABLE } from '../core/z-db-table.model';

/**
 * DbTa_CategoryId: 3
 * DbTa_DeleteGrantId: 97
 * DbTa_HasIdentityId: false
 * DbTa_InsertGrantId: 96
 * DbTa_IsHierarchical: false
 * DbTa_ReadGrantId: 99
 * DbTa_Scope: 1
 * DbTa_TableDisplayName: 3178
 * DbTa_TableName: "Directory"
 * DbTa_TableTypeId: 3
 * DbTa_UpdateGrantId: 98
 * TableName: "ZGrantedTableView"
 */

export const Z_GRANTED_TABLE_VIEW = {
  databaseTableName: 'ZGrantedTableView',
  categoryId: Z_DB_TABLE.categoryId,
  deleteGrantId: Z_DB_TABLE.deleteGrantId,
  hasIdentityId: Z_DB_TABLE.hasIdentityId,
  insertGrantId: Z_DB_TABLE.insertGrantId,
  isHierarchical: Z_DB_TABLE.isHierarchical,
  readGrantId: Z_DB_TABLE.readGrantId,
  scope: Z_DB_TABLE.scope,
  tableDisplayName: Z_DB_TABLE.tableDisplayName,
  tableName: Z_DB_TABLE.tableName,
  tableTypeId: Z_DB_TABLE.tableTypeId,
  updateGrantId: Z_DB_TABLE.updateGrantId
};

export class GrantedTableView {
  constructor(
    public databaseTableName: string,
    public categoryId: number,
    public deleteGrantId: number,
    public hasIdentityId: number,
    public insertGrantId: number,
    public isHierarchical: boolean,
    public readGrantId: number,
    public scope: number,
    public tableDisplayName: number,
    public tableName: string,
    public tableTypeId: number,
    public updateGrantId: number) {}
}

@Injectable({
  providedIn: 'root'
})

export class GrantedTableViewAdapter implements Adapter<GrantedTableView> {
  adapt(item: any): GrantedTableView {
    return new GrantedTableView(
      Z_GRANTED_TABLE_VIEW.databaseTableName,
      item[Z_GRANTED_TABLE_VIEW.categoryId],
      item[Z_GRANTED_TABLE_VIEW.deleteGrantId],
      item[Z_GRANTED_TABLE_VIEW.hasIdentityId],
      item[Z_GRANTED_TABLE_VIEW.insertGrantId],
      item[Z_GRANTED_TABLE_VIEW.isHierarchical],
      item[Z_GRANTED_TABLE_VIEW.readGrantId],
      item[Z_GRANTED_TABLE_VIEW.scope],
      item[Z_GRANTED_TABLE_VIEW.tableDisplayName],
      item[Z_GRANTED_TABLE_VIEW.tableName],
      item[Z_GRANTED_TABLE_VIEW.tableTypeId],
      item[Z_GRANTED_TABLE_VIEW.updateGrantId]
    );
  }
}
