import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { UsesActions } from '../actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class FloorUsesEffects {

  /** Add existing FlDa to Ta **/

  deleteUse$ = createEffect(
    () => this.actions$.pipe(
      ofType(UsesActions.deleteUse),
      switchMap(action => {
        if (action.apiCall) {
          return this.floorService.deleteFloorData(action.id);
        } else {
          return EMPTY;
        }
      }),
      // concatMap(action => this.floorService.deleteFloorData(action.id)),
      // tap(r => console.log(r)),
      switchMap((result) => [
        UsesActions.updateSelectedUseId({id: null})
      ]),
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store$: Store<State>
  ) {
  }
}
