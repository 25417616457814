import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SAVER, Saver } from '../saver.provider';
import { download, Download } from '../models/download';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadService {

  //https://nils-mehlhorn.de/posts/angular-file-download-progress

  downloadComplete$ = new Subject<string>();

  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver
  ) {
  }

  download(url: string, filename?: string): Observable<Download> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(
      download(blob => this.save(blob, filename)),
      finalize(() => this.downloadComplete$.next(filename)),
    );
  }


  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }
}
