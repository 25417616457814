import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { FloorCatalogActions } from '../actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class FloorCatalogEffects {

  /** Add existing FlDa to Ta **/

  // addFloorCatalogItemToTask$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(FloorCatalogActions.addItemToTask),
  //     concatMap(action => this.floorService.createEquipment(action.taskId, action.id, action.x, action.y)),
  //     tap(r => console.log(r)),
  //     switchMap((result) => {
  //       return [
  //         UiContextActions.isCreatingEquipment({creating: false}),
  //         TaskFloorModelActions.addTaskFloorModels({taskFloorModels: result.floorModel}),
  //         FloorCatalogActions.addFloorCatalog({floorCatalog: result.floorCatalog}),
  //         UsesActions.addUse({svgUse: result.svgUse}),
  //         UsesActions.updateSelectedUseId({id: result.svgUse.id})
  //       ];
  //     }),
  //   )
  // );

  getAllFloorCatalog$ = createEffect(
    () => this.actions$.pipe(
      ofType(FloorCatalogActions.getAllFloorCatalog),
      switchMap(action => this.floorService.getAllFloorCatalog().pipe(
        map(svgGroups => FloorCatalogActions.upsertFloorCatalogItems({svgGroups: svgGroups}))
      )),
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store$: Store<State>
  ) {
  }
}
