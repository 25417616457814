import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum ZConstraintModeEnum {
  Constant = 1,
  Computed = 2,
  Request = 3
}

export const Z_CONSTRAINT_MODE = {
  databaseTableName:  'z_constraint_mode',
  id: 'CoMo_Id',
  name: 'CoMo_Name',
};

export class ZConstraintMode {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})


export class ZConstraintModeAdapter implements Adapter<ZConstraintMode> {
  adapt(item: any): ZConstraintMode {
    return new ZConstraintMode(
      Z_CONSTRAINT_MODE.databaseTableName,
      item[Z_CONSTRAINT_MODE.id],
      item[Z_CONSTRAINT_MODE.name]
    );
  }
}
