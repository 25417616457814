<mat-form-field
  echoMatInputTree [disabled]="formControl.disabled"
  [tableName]="tableName"
  [existingNode]="_formControl?.value"
  (selectedNode$)="onSelectedNodeChange($event)"
>
  <mat-label>{{ label }}</mat-label>
  <input matInput autocomplete="off" [placeholder]="placeHolder" [formControl]="formControl" />
  <mat-icon [ngClass]="{'disabled': formControl.disabled, 'error': formControl.invalid && formControl.touched}" matSuffix>arrow_drop_down</mat-icon>
</mat-form-field>
