import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskFunctionActions } from '../actions';
import { TaskFunction } from '../../../domain-models/business/task-function.model';

export const taskFunctionFeatureKey = 'taskFunctions';

export interface State extends EntityState<TaskFunction> {
  selectedTaskFunctionId: number | null;
}

export const adapter: EntityAdapter<TaskFunction> = createEntityAdapter<TaskFunction>();

export const initialState: State = adapter.getInitialState({
  selectedTaskFunctionId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskFunctionActions.addTaskFunction, (state, { taskFunction }) => {
    return adapter.addOne(taskFunction, state);
  }),
  on(TaskFunctionActions.setTaskFunction, (state, { taskFunction }) => {
    return adapter.setOne(taskFunction, state);
  }),
  on(TaskFunctionActions.upsertTaskFunction, (state, { taskFunction }) => {
    return adapter.upsertOne(taskFunction, state);
  }),
  on(TaskFunctionActions.addTaskFunctions, (state, { taskFunctions }) => {
    return adapter.addMany(taskFunctions, state);
  }),
  on(TaskFunctionActions.upsertTaskFunctions, (state, { taskFunctions }) => {
    return adapter.upsertMany(taskFunctions, state);
  }),
  on(TaskFunctionActions.updateTaskFunction, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskFunctionActions.updateTaskFunctions, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskFunctionActions.mapTaskFunctions, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskFunctionActions.deleteTaskFunction, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskFunctionActions.deleteTaskFunctions, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskFunctionActions.deleteTaskFunctionsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskFunctionActions.loadTaskFunctions, (state, { taskFunctions }) => {
    return adapter.setAll(taskFunctions, state);
  }),
  on(TaskFunctionActions.clearTaskFunctions, state => {
    return adapter.removeAll({ ...state, selectedTaskFunctionId: null });
  }),
  /** END OF CRUD **/
  on(TaskFunctionActions.updateSelectedTaskFunctionId, (state, {taskFunctionId}) => {
    return ({...state, selectedTaskFunctionId: taskFunctionId});
  }),
);

export const getSelectedTaskFunctionId = (state: State) => state.selectedTaskFunctionId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskFunction ids
export const selectTaskFunctionIds = selectIds;

// select the dictionary of taskFunction entities
export const selectTaskFunctionEntities = selectEntities;

// select the array of taskFunctions
export const selectAllTaskFunctions = selectAll;

// select the total taskFunction count
export const selectTaskFunctionTotal = selectTotal;
