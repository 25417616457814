import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EcSyFile } from '../../domain-models/models/EcSyFile.model';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { CoreSelectors } from '../../store/core-store/selectors';
import { map } from 'rxjs/operators';

/** Append Echo-Company-Id to the next request handler. */
@Injectable()
export class EchoCompanyInterceptor implements HttpInterceptor {

  client$: Observable<EcSyFile>;
  client: EcSyFile;

  constructor(private store: Store<State>) {
    this.client$ = this.store.select(CoreSelectors.selectClient);
    this.client$.pipe(
      //tap(a => console.log(a)),
      map(client => this.client = client)
    ).subscribe();
  }

  //private client: EcSyFile;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(req);

    // return this.addExtraHeaders(req.headers).pipe(
    //   concatMap(headers => {
    //     const modifiedReq = req.clone({
    //       headers: headers
    //     });
    //
    //     console.log(modifiedReq);
    //
    //     return next.handle(modifiedReq);
    //   }),
    // );
    const modifiedReq = req.clone({
      headers: this.addExtraHeaders(req.headers)
    });

    return next.handle(modifiedReq);
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    // return this.client$.pipe(
    //   //tap(a => console.log(a)),
    //   switchMap(client => {
    //     if(client) {
    //       headers = headers.append('Echo-Company-Id', client.CompanyId);
    //     }
    //     return of(headers);
    //   })
    // );

    if (this.client) {
      headers = headers.append('Echo-Company-Id', this.client.CompanyId);
    }
    return headers;

    // if (sessionStorage.getItem('client_file')) {
    //   this.client = JSON.parse(sessionStorage.getItem('client_file'));
    //   headers = headers.append('Echo-Company-Id', this.client.CompanyId);
    // }
    // return headers;
  }
}
