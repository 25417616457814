import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBuilding from './reducers';
import { BuildingEffects } from './effects/building.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBuilding.buildingFeatureKey, fromBuilding.reducers),
    EffectsModule.forFeature([BuildingEffects]),
  ],
})
export class BuildingStoreModule { }
