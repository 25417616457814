import { ROOM_ATTRIBUTION_TYPE, RoomAttributionTypeEnum } from './room-attribution-type.model';

export const ROOM_ATTRIBUTION_TYPE_VIEW = {
  id: ROOM_ATTRIBUTION_TYPE.id,
  AttributionType: RoomAttributionTypeEnum.Allocation,
  name: ROOM_ATTRIBUTION_TYPE.name,
  displayNameId: ROOM_ATTRIBUTION_TYPE.displayNameId,
  color: ROOM_ATTRIBUTION_TYPE.color,
  displayName: 'RoAtTyVi_DisplayName'
};
