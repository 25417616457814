import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface SnackBarMessage {
  message: string;
  action: string;
  alert?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class SnackBarService {

  message$ = new Subject<SnackBarMessage>();

  public displayError(message: string | string[], alert?: boolean) {
    if(Array.isArray(message)) {

    } else {
      this.message$.next({
        message: message,
        action: 'Ok',
        alert: alert,
      });
    }

  }

  public displayMessage(message: string | string[]) {
    if (Array.isArray(message)) {

    } else {
      this.message$.next({
        message: message,
        action: 'Ok',
        alert: false,
      });
    }

  }
}
