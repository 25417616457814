import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter';
import { Z_LANGUAGE_ITEM } from '../z-language-item.model';
import { Z_COLUMN_CONSTRAINT } from '../z-column-constraint.model';
import { Z_CONSTRAINT_TYPE } from '../z-constraint-type.model';
import { Z_CONSTRAINT_MODE } from '../z-constraint-mode.model';


export const Z_COLUMN_CONSTRAINT_VIEW = {
  databaseTableName: 'z_column_constraint_view',
  columnConstraintId: Z_COLUMN_CONSTRAINT.id,
  columnConstraintTableName: Z_COLUMN_CONSTRAINT.tableName,
  columnConstraintColumnName: Z_COLUMN_CONSTRAINT.columnName,
  columnConstraintConstraintType: Z_COLUMN_CONSTRAINT.constraintType,
  columnConstraintConstraintMode: Z_COLUMN_CONSTRAINT.constraintMode,
  columnConstraintValue: Z_COLUMN_CONSTRAINT.value,
  columnConstraintIndex: Z_COLUMN_CONSTRAINT.index,
  columnConstraintTriggeredId: Z_COLUMN_CONSTRAINT.triggeredId,
  columnConstraintMessageId: Z_COLUMN_CONSTRAINT.messageId,
  constraintTypeName: Z_CONSTRAINT_TYPE.name,
  constraintModeName: Z_CONSTRAINT_MODE.name,
  languageItemText: Z_LANGUAGE_ITEM.text,
  dependencySourceColumnName: 'DependencySourceColumnName'
};

export class ZColumnConstraintView {
  constructor(
    public databaseTableName: string,
    public columnConstraintId: number,
    public columnConstraintTableName: string,
    public columnConstraintColumnName: string,
    public columnConstraintConstraintType: number,
    public columnConstraintConstraintMode: number,
    public columnConstraintValue: any,
    public columnConstraintIndex: number,
    public columnConstraintTriggeredId: number,
    public columnConstraintMessageId: number,
    public constraintTypeName: string,
    public constraintModeName: string,
    public languageItemText: string,
    public dependencySourceColumnName?: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class ZColumnConstraintViewAdapter implements Adapter<ZColumnConstraintView> {
  adapt(item: any): ZColumnConstraintView {
    const dependencySourceColumnName = item[Z_COLUMN_CONSTRAINT_VIEW.dependencySourceColumnName] ? item[Z_COLUMN_CONSTRAINT_VIEW.dependencySourceColumnName] : null;
    return new ZColumnConstraintView(
      Z_COLUMN_CONSTRAINT_VIEW.databaseTableName,
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintId],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintTableName],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintColumnName],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintConstraintType],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintConstraintMode],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintValue],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintIndex],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintTriggeredId],
      item[Z_COLUMN_CONSTRAINT_VIEW.columnConstraintMessageId],
      item[Z_COLUMN_CONSTRAINT_VIEW.constraintTypeName],
      item[Z_COLUMN_CONSTRAINT_VIEW.constraintModeName],
      item[Z_COLUMN_CONSTRAINT_VIEW.languageItemText],
      dependencySourceColumnName
    );
  }
}
