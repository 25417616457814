/**
 * Bridge pattern https://sourcemaking.com/design_patterns/bridge
 * Source https://florimond.dev/blog/articles/2018/09/consuming-apis-in-angular-the-model-adapter-pattern/
 **/

export interface ISingleBackendObj {
  //[key: string]: any;
  TableName: string;
}

export interface Adapter<T> {
  /**
   * Convert backend data to internal representation
   * @param item Backend model
   */
  adapt(item: any): T;

  /**
   * Convert internal representation to backend data
   * @param item Client model
   */
  encode?(item: T): ISingleBackendObj; //optional method
}

export function deleteUndefinedFields(item: any): any {
  Object.keys(item).forEach(key => item[key] === undefined && delete item[key]);
  return item;
}
