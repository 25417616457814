import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT_VIEW, DocumentView } from '../../../domain-models/views/document-view.model';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { API_ENDPOINTS, API_URL } from '../../api-endpoints';
import { MatDialog } from '@angular/material/dialog';
import { DownloadService } from '../../../core/services/download.service';
import { UploadDialogData } from '../../../features/upload/models/upload-dialog-data.model';
import { UploadDialogComponent } from '../../../features/upload/components/upload-dialog/upload-dialog.component';

@Component({
  selector: 'echo-document-versions-table',
  templateUrl: './document-versions-table.component.html',
  styleUrls: ['./document-versions-table.component.scss']
})
export class DocumentVersionsTableComponent implements OnInit {

  @Input() documentVersions: DocumentView[];
  @Output() refreshData = new EventEmitter<void>();

  DOCUMENT_VIEW = DOCUMENT_VIEW;

  displayedColumns: string[] = ['select', 'index', 'memo', 'creationDate', 'fileName', 'action'];
  dataSource = new MatTableDataSource<DocumentView>([]);
  selection = new SelectionModel<DocumentView>(true, []);

  constructor(public dialog: MatDialog,
              private downloadService: DownloadService) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.documentVersions;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DocumentView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentVersionIndex + 1}`;
  }

  public openFilePreviewDialog(document: DocumentView, event: Event): void {
    event.stopImmediatePropagation();
    const dialogData = {
      url: `https://static.echo-on.fr/${document.filePath}`
    };

    const dialogRef = this.dialog.open(FilePreviewDialogComponent, {
      maxWidth: '90%',
      maxHeight: '90%',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The upload-dialog was closed');
    });
  }

  public downloadFile(document: DocumentView, event: Event): void {
    // console.log(document);
    event.stopImmediatePropagation();
    const url = `https://static.echo-on.fr/${document.filePath}`;
    // console.log(url);
    this.downloadService.download(`${API_ENDPOINTS.documentsDownload}/${document.documentVersionId}`, document.documentFileName).subscribe();
  }

  public openUploadDialog(event?: Event): void {
    if (event) {
      event.stopImmediatePropagation();
    }
    const uploadUrl = `${API_URL}Documents/Version/${this.documentVersions[0].documentVersionDocumentId}`;
    const uploadDialogData = {
      uploadUrl: uploadUrl,
      dialogTitle: `Ajouter un document`,
    } as UploadDialogData;

    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '50%',
      //height: '50%',
      data: uploadDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshData.next();
      //this.setDocuments();
      // console.log('The upload-dialog was closed');
    });
  }

}
