import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as LanguageItemActions from '../actions/language-item.actions';
import { LanguageItem } from '../../../domain-models/core/z-language-item.model';

export const languageItemsFeatureKey = 'languageItems';

export interface State extends EntityState<LanguageItem> {
  isLoaded: boolean;
}

export function selectEntryId(a: LanguageItem): number {
  return a.entryId;
}

export const adapter: EntityAdapter<LanguageItem> = createEntityAdapter<LanguageItem>({
  selectId: selectEntryId
});

export const initialState: State = adapter.getInitialState({
  isLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(LanguageItemActions.addLanguageItems, (state, {languageItems}) => {
    return adapter.addMany(languageItems, state);
  }),
  on(LanguageItemActions.beginGetAllLanguageItems,  state => ({ ...state, isLoaded: false })),
on(LanguageItemActions.successGetAllLanguageItems,  state => ({ ...state, isLoaded: true }))
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectLanguageItemsIds = selectIds;
export const selectLanguageItemsEntities = selectEntities;
export const selectAllLanguageItems = selectAll;
export const selectLanguageItemsTotal = selectTotal;
