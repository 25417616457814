import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, first, map, switchMap } from 'rxjs/operators';
import * as FloorDataActions from '../actions/floor-data.actions';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { DoorActions, TaskFloorModelActions } from '../actions';
import { State } from '../../../reducers';
import { UiContextSelectors } from '../../floor-store/selectors';
import { Update } from '@ngrx/entity';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';
import { UiContextActions } from '../../floor-store/actions';

@Injectable()
export class DoorEffects {

  createDoor$ = createEffect(() => this.actions$.pipe(
    ofType(UiContextActions.createDoor),
    concatMap(action => this.store.select(UiContextSelectors.selectCreateDoorProps).pipe(
      first(),
      concatMap(doorCreationProps => this.floorService.createDoor(action.wallId, action.insertionPoint, doorCreationProps).pipe(
        concatMap(data => {
          if (data.taskFloorModel) {
            return [
              TaskFloorModelActions.addTaskFloorModel({taskFloorModel: data.taskFloorModel}),
              FloorDataActions.addFloorData({svgGroup: data.svgGroup}),
              UiContextActions.cancelDoorCreation(),
            ];
          } else {
            return [
              FloorDataActions.addFloorData({svgGroup: data.svgGroup}),
              UiContextActions.cancelDoorCreation(),
            ];
          }
        }),
        catchError(err => of(UiContextActions.cancelDoorCreation())),
      ))
    )),
    )
  );

  translateDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.translateDoor),
    concatMap(action => this.floorService.translateDoor(action.id, action.point).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorStyle$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorStyle),
    switchMap(action => this.floorService.updateDoorStyle(action.id, action.doorStyleId).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorsStyle$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorsStyle),
    switchMap(action => this.floorService.updateDoorsStyle(action.ids, action.doorStyleId).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateDoorWidth$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorWidth),
    switchMap(action => this.floorService.updateDoorWidth(action.id, action.width).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorsWidth$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorsWidth),
    switchMap(action => this.floorService.updateDoorsWidth(action.ids, action.width).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateDoorOpeningAngle$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorOpeningAngle),
    switchMap(action => this.floorService.updateDoorOpeningAngle(action.id, action.openingAngle).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorsOpeningAngle$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorsOpeningAngle),
    switchMap(action => this.floorService.updateDoorsOpeningAngle(action.ids, action.openingAngle).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateDoorOpeningDirection$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorOpeningDirection),
    switchMap(action => this.floorService.updateDoorOpeningDirection(action.id, action.openingDirection).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorsOpeningDirection$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorsOpeningDirection),
    switchMap(action => this.floorService.updateDoorsOpeningDirection(action.ids, action.openingDirection).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateDoorWallSideOrientation$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorWallSideOrientation),
    switchMap(action => this.floorService.updateDoorWallSideOrientation(action.id, action.wallSideOrientation).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateDoorsWallSideOrientation$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoorsWallSideOrientation),
    switchMap(action => this.floorService.updateDoorsWallSideOrientation(action.ids, action.wallSideOrientation).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  deleteDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.deleteDoor),
    switchMap(action => this.floorService.deleteDoor(action.id).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        //const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
        return [
          //...arr,
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
    )),
  ));

  deleteDoors$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.deleteDoors),
    switchMap(action => this.floorService.deleteDoors(action.ids).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        //const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
        return [
          //...arr,
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
    )),
  ));

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
