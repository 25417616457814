import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'echo-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, AfterViewInit {

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  scannerLoaded = false;

  @Output() scanSuccess = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  scanSuccessHandler(event: any) {
    this.scanSuccess.next(event);
  }

  onAutostartedHandler(event: any) {
    this.scannerLoaded = true;
  }



}
