import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NOT_FOUND_IMG } from '../../api-endpoints';

@Component({
  selector: 'echo-secured-image',
  // encapsulation: ViewEncapsulation.None,
  template: `
    <ng-container *ngIf='dataUrl$ | async as dataUrl; then img; else spinner;'></ng-container>
    <ng-template #spinner>
      <mat-spinner [diameter]='elementRef.nativeElement.getBoundingClientRect().width'></mat-spinner>
    </ng-template>
    <ng-template #img let-dataUrl>
      <img [src]="dataUrl" (error)="imgErrorHandler($event)" alt="" />
    </ng-template>
  `,
  styles: [
    'img { width: 100%; height: 100%;}',
    // 'mat-spinner { height: 100% !important; width: 100% !important; z-index: 100000;}'
  ]
})
//https://blog.strongbrew.io/safe-image-requests-in-angular/
export class SecuredImageComponent implements OnChanges, OnInit, AfterViewInit {

  @Input() private src: string;

  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  private src$: BehaviorSubject<string>;

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$: Observable<any>;

  constructor(private http: HttpClient,
              public elementRef: ElementRef,
              private domSanitizer: DomSanitizer) {
    this.src$ = new BehaviorSubject(this.src);
    this.dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  private loadImage(url: string): Observable<any> {
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
    );
    // // load the image as a blob
    // .get(url, {responseType: 'blob'})
    // create an object url of that blob that we can use in the src attribute
  }

  imgErrorHandler(event) {
    event.target.src = NOT_FOUND_IMG;
  }

}
