import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const FACILITY_COMPANY_TASK = {
  databaseTableName: 'facility_company_task',
  id: 'FaCoTa_Id',
  company: 'FaCoTa_Company',
  jobTask: 'FaCoTa_JobTask',
  unitPrice: 'FaCoTa_UnitPrice',
  isActive: 'FaCoTa_IsActive'
};

export class FacilityCompanyTask {
  constructor(
    public databaseTableName: string,
    public id: number,
    public company: string,
    public jobTask: string,
    public unitPrice: string,
    public isActive: boolean
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class FacilityCompanyTaskAdapter implements Adapter<FacilityCompanyTask> {
  adapt(item: any): FacilityCompanyTask {
    return new FacilityCompanyTask (
      FACILITY_COMPANY_TASK.databaseTableName,
      item[FACILITY_COMPANY_TASK.id],
      item[FACILITY_COMPANY_TASK.company],
      item[FACILITY_COMPANY_TASK.jobTask],
      item[FACILITY_COMPANY_TASK.unitPrice],
      item[FACILITY_COMPANY_TASK.isActive],
    );
  }
  encode(item: FacilityCompanyTask): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FACILITY_COMPANY_TASK.id]: item.id,
      [FACILITY_COMPANY_TASK.company]: item.company,
      [FACILITY_COMPANY_TASK.jobTask]: item.jobTask,
      [FACILITY_COMPANY_TASK.unitPrice]: item.unitPrice,
      [FACILITY_COMPANY_TASK.isActive]: item.isActive,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}

