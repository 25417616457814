import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  GanttTaskActions,
  ProjectManagementActions,
  TaskActions,
  TaskBusinessTypeActions,
  TaskContributorActions,
  TaskContributorDirectoryActions,
  TaskFloorActions,
  TaskFunctionActions,
  TaskLinkActions,
  TaskStatusActions,
  TaskTypeActions,
  TaskValidationActions
} from '../actions';
import { map, switchMap } from 'rxjs/operators';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { GanttTaskAdapter } from '../../../features/project-management/models/gantt-task.model';
import { State } from '../../../reducers';
import { TaskFunctionEnum } from '../../../domain-models/business/task-function.model';

@Injectable()
export class ProjectManagementEffects {

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private ganttTaskAdapter: GanttTaskAdapter,
    private store: Store<State>
  ) {
  }

  beginLoadGanttTasks$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectManagementActions.beginLoadProjectManagement),
    //withLatestFrom(this.store.select(TaskTypeSelectors.selectAllTaskTypes)),
    //filter(([action, taskTypes]) => taskTypes.length > 0),
    switchMap(action =>
      this.projectManagementService.loadTasks().pipe(
        // tap(d => console.log(d)),
        map(apiTasksRootItems => {
          let ganttTasks = apiTasksRootItems.tasks.map(t => this.ganttTaskAdapter.adaptFromTask(t));
          ganttTasks.forEach(t => {
            const tc = apiTasksRootItems.taskContributors.find(c => c.taskId === t.id && c.functionId === TaskFunctionEnum.Owner);
            t.ownerId = tc ? tc.contributorId : null;
          });
          // ganttTasks.map(t => {
          //   t.color = taskTypes.find(type => type.id === t.type).color.toHexRgb();
          // });
          return {apiTasksRootItems: apiTasksRootItems, ganttTasks: ganttTasks};
        }),
        switchMap((data) =>
          [
            TaskActions.upsertTasks({tasks: data.apiTasksRootItems.tasks}),
            GanttTaskActions.upsertGanttTasks({
              ganttTasks: data.ganttTasks
            }),
            TaskLinkActions.loadTaskLinks({taskLinks: data.apiTasksRootItems.taskLinks}),
            TaskContributorDirectoryActions.addTaskContributorDirectoryItems({taskContributorDirectoryItems: data.apiTasksRootItems.taskContributorDirectoryItems}),
            TaskContributorActions.loadTaskContributors({taskContributors: data.apiTasksRootItems.taskContributors}),
            TaskFloorActions.loadTaskFloors({taskFloors: data.apiTasksRootItems.taskFloors}),
          ]
        ),
      )
    )));

  loadTasksTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectManagementActions.loadTasksTemplates),
    switchMap(action => this.projectManagementService.getTasksTemplates().pipe(
        switchMap(tasks => {
          const ganttTasks = tasks.map(t => this.ganttTaskAdapter.adaptFromTask(t));
          return [
            TaskActions.upsertTasks({ tasks }),
            GanttTaskActions.upsertGanttTasks({ ganttTasks }),
            ProjectManagementActions.successLoadTasksTemplates()
          ];
        }),
        //switchMap(() => of(ProjectManagementActions.successLoadTasksTemplates())),
      ),
    )
  ));

  clearProjectManagementStore$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectManagementActions.clearProjectManagementStore),
    switchMap(action => [
        TaskActions.clearTasks(),
        GanttTaskActions.clearGanttTasks(),
        TaskBusinessTypeActions.clearTaskBusinessTypes(),
        TaskContributorActions.clearTaskContributors(),
        TaskContributorDirectoryActions.clearTaskContributorDirectoryItems(),
        TaskFloorActions.clearTaskFloors(),
        TaskFunctionActions.clearTaskFunctions(),
        TaskLinkActions.clearTaskLinks(),
        TaskStatusActions.clearTaskStatusItems(),
        TaskTypeActions.clearTaskTypes(),
        TaskValidationActions.clearTaskValidations(),
      ]
    )));
}
