import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum DisplayThemeEnum {
  LayoutType = 1,
  Allocations = 2,
  Attributions = 3,
  ActivityStatus = 4,
  Mixed = 5,
  WorkplaceTypes = 6,
  InstallationInstalled = 7,
  InstallationUninstalled = 8,
  ReconcileInstalled = 9,
  ReconcileUninstalled = 10,
  None = 11,
  Identification,
  ImportReconcile
}

export const DISPLAY_THEME = {
  databaseTableName: 'DisplayTheme',
  id: 'DiTh_Id',
  name: 'DiTh_Name',
  categoryId: 'DiTh_CategoryId',
  displayNameId: 'DiTh_DisplayNameId',
};

export class DisplayTheme {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public categoryId: number,
    public displayNameId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DisplayThemeAdapter implements Adapter<DisplayTheme> {
  adapt(item: any): DisplayTheme {
    return new DisplayTheme(
      DISPLAY_THEME.databaseTableName,
      item[DISPLAY_THEME.id],
      item[DISPLAY_THEME.name],
      item[DISPLAY_THEME.categoryId],
      item[DISPLAY_THEME.displayNameId]
    );
  }

  encode(item: DisplayTheme): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DISPLAY_THEME.id]: item.id,
      [DISPLAY_THEME.name]: item.name,
      [DISPLAY_THEME.categoryId]: item.categoryId,
      [DISPLAY_THEME.displayNameId]: item.displayNameId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
