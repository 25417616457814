import { createSelector } from '@ngrx/store';
import * as fromFloorWallStyle from '../reducers/floor-wall-style.reducer';
import { selectSharedState } from '../reducers';

export const selectFloorWallStyles = createSelector(
  selectSharedState,
  state => state[fromFloorWallStyle.floorWallStylesFeatureKey]
);

export const selectFloorWallStylesIds = createSelector(
  selectFloorWallStyles,
  fromFloorWallStyle.selectFloorWallStylesIds
);
export const selectFloorWallStylesEntities = createSelector(
  selectFloorWallStyles,
  fromFloorWallStyle.selectFloorWallStylesEntities
);
export const selectAllFloorWallStyles = createSelector(
  selectFloorWallStyles,
  fromFloorWallStyle.selectAllFloorWallStyles
);
export const selectFloorWallStylesTotal = createSelector(
  selectFloorWallStyles,
  fromFloorWallStyle.selectFloorWallStylesTotal
);

export const allFloorWallStylesLoaded = createSelector(
  selectFloorWallStyles,
  state => state.isLoaded
);

export const selectFloorWallStyleById = (id: number) => createSelector(
  selectFloorWallStylesEntities,
  entities => entities[id]
);
