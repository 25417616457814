import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { GanttTask } from '../../../features/project-management/models/gantt-task.model';

/**
 * CRUD Actions
 */

export const loadGanttTasks = createAction('[GanttTask/API] Load GanttTasks', props<{ ganttTasks: GanttTask[] }>());
export const addGanttTask = createAction('[GanttTask/API] Add GanttTask', props<{ ganttTask: GanttTask }>());
export const setGanttTask = createAction('[GanttTask/API] Set GanttTask', props<{ ganttTask: GanttTask }>());
export const upsertGanttTask = createAction('[GanttTask/API] Upsert GanttTask', props<{ ganttTask: GanttTask }>());
export const addGanttTasks = createAction('[GanttTask/API] Add GanttTasks', props<{ ganttTasks: GanttTask[] }>());
export const upsertGanttTasks = createAction('[GanttTask/API] Upsert GanttTasks', props<{ ganttTasks: GanttTask[] }>());
export const updateGanttTask = createAction('[GanttTask/API] Update GanttTask', props<{ update: Update<GanttTask> }>());
export const updateGanttTasks = createAction('[GanttTask/API] Update GanttTasks', props<{ updates: Update<GanttTask>[] }>());
export const mapGanttTasks = createAction('[GanttTask/API] Map GanttTasks', props<{ entityMap: EntityMap<GanttTask> }>());
export const deleteGanttTask = createAction('[GanttTask/API] Delete GanttTask', props<{ id: number }>());
export const deleteGanttTasks = createAction('[GanttTask/API] Delete GanttTasks', props<{ ids: number[] }>());
export const deleteGanttTasksByPredicate = createAction('[GanttTask/API] Delete GanttTasks By Predicate', props<{ predicate: Predicate<GanttTask> }>());
export const clearGanttTasks = createAction('[GanttTask/API] Clear GanttTasks');

/** END of CRUD **/

export const beginGetAllGanttTasks = createAction('[GanttTask/API] - Begin Get All GanttTasks');
export const errorGetAllGanttTasks = createAction('[API] - Error Get All GanttTasks', props<Error>());

export const updateSelectedGanttTaskId = createAction(
  '[GanttTask/API] - Save Selected GanttTask Id',
  props<{ ganttTaskId: number }>()
);

/** ADVANCED **/
export const createGanttTask = createAction('[GanttTask/API] Create GanttTask', props<{ ganttTask: GanttTask, taskCreationDto: any }>());
