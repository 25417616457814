import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

interface HammerManager {
  new (element: HTMLElement | SVGElement, options?: any): HammerManager;
  destroy(): void;
  add(recognizer: Recognizer): void;
  on(eventName: string, callback: Function): void;
}

interface Recognizer {
  new (options?: any): Recognizer;
  recognizeWith(otherRecognizer: Recognizer | string): Recognizer;
}

@Directive({
  selector: '[echoPinchGesture]'
})
export class PinchGestureDirective implements OnInit, OnDestroy {
  /**
   * HANDS ON https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0
   */
  constructor(private elementRef: ElementRef, private zone: NgZone) {}
  /**
   * Return the hammerjs library if it's available
   */
  private get hammerLib() {
    return typeof window !== 'undefined' ? (window as any).Hammer : undefined;
  }

  private manager?: HammerManager;

  /**
   * Event fired when the element is tapped
   */
  @Output() cTap = new EventEmitter<any>();

  /**
   * Binds HammerJS Instances
   */
  ngOnInit() {
    if (this.hammerLib) {
      this.manager = this.bindHammer();
    }
  }

  /**
   * Unbinds HammerJS Instances
   */
  ngOnDestroy() {
    if (this.manager) {
      this.manager.destroy();
    }
  }

  protected bindHammer(): HammerManager {
    return this.zone.run(_ => {
      const hostElement = this.elementRef.nativeElement;
      const manager = new this.hammerLib.Manager(hostElement, {
        // touchAction: 'tap',
      });


      manager.add(new this.hammerLib.Tap({}));
      manager.add([new this.hammerLib.Pinch().recognizeWith(new this.hammerLib.Rotate())], {});

      manager.on('panleft panright tap press pinch pinchstart rotate pinchend', (ev: any) => {
        console.log('event');
        this.cTap.emit(ev);
        ev.preventDefault();
      });

      manager.on('pinch', (ev: any) => {
        console.log('p');
        this.cTap.emit(ev);
        ev.preventDefault();
      });

      // manager.on('pinch', (ev: any) => {
      //   console.log('e');
      //   this.cTap.emit(ev);
      //   ev.preventDefault();
      // });

      return manager;
    });
  }
}
