import { createAction, props } from '@ngrx/store';
import { RoomActivityStatusType } from '../../../domain-models/business/room-activity-status-type.model';

export const beginGetAllRoomActivityStatusTypes = createAction('[Shared/API] - Begin Get All RoomActivityStatusTypes');

export const successGetAllRoomActivityStatusTypes = createAction(
  '[API] - Success Get All RoomActivityStatusTypes',
  props<{ roomActivityStatusTypes: RoomActivityStatusType[] }>()
);

export const errorGetAllRoomActivityStatusTypes = createAction('[Shared/API] - Error Get All RoomActivityStatusTypes', props<Error>());

export const addRoomActivityStatusType = createAction('[Shared/API] Add RoomActivityStatusType', props<RoomActivityStatusType>());
