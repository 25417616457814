import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { SvgUse } from '../../features/svg-factory/models/svg-use.model';
import { SvgGroup } from '../../features/svg-factory/models/svg-group.model';
import { TaskFloorModel, TaskFloorModelAdapter } from '../business-extended/task-floor-model.model';

interface SvgDTO {
  SvgUseDTO: SvgUse[];
  SvgGroupDTO: SvgGroup[];
}

const CAD_PROJECTS_ADD_ROOM = {
  floorModel: 'FloorModel',
  svgDTO: 'SvgDTO',
  floorCatalog: 'FloorCatalog',
};

export class ApiCadProjectsAddRoom {
  constructor(
    public floorModel: TaskFloorModel[],
    public svgDTO: SvgDTO,
    public floorCatalog: SvgGroup[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiCadProjectsAddRoomAdapter implements Adapter<ApiCadProjectsAddRoom> {
  adapt(item: any): ApiCadProjectsAddRoom {

    const floorCatalog = [];
    item[CAD_PROJECTS_ADD_ROOM.floorCatalog].forEach(
      e => floorCatalog.push(e as SvgGroup)
    );

    const floorModel = [];
    item[CAD_PROJECTS_ADD_ROOM.floorModel].forEach(
      e => floorModel.push(new TaskFloorModelAdapter().adapt(e))
    );

    const svgDTO = item[CAD_PROJECTS_ADD_ROOM.svgDTO] as SvgDTO;

    return new ApiCadProjectsAddRoom(
      floorModel,
      svgDTO,
      floorCatalog,
    );
  }
}
