import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { TASK, Task, TaskAdapter } from '../business/task.model';
import { TASK_FLOOR, TaskFloor, TaskFloorAdapter } from '../business/task-floor.model';
import { TASK_CONTRIBUTOR, TaskContributor, TaskContributorAdapter } from '../business/task-contributor.model';

export class ApiTasksPost {
  constructor(
    public task: Task,
    public taskFloor: TaskFloor,
    public taskContributors: TaskContributor[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiTasksPostAdapter implements Adapter<ApiTasksPost> {
  constructor(private taskAdapter: TaskAdapter,
              private taskFloorAdapter: TaskFloorAdapter,
              private taskContributorAdapter: TaskContributorAdapter) {
  }

  adapt(item: any): ApiTasksPost {

    const task = this.taskAdapter.adapt(item[TASK.databaseTableName]);

    let taskFloor: TaskFloor;
    if(item.hasOwnProperty(TASK_FLOOR.databaseTableName)) {
      taskFloor = this.taskFloorAdapter.adapt(item[TASK_FLOOR.databaseTableName]);
    }

    const taskContributors = item[TASK_CONTRIBUTOR.databaseTableName]
      .map(t => this.taskContributorAdapter.adapt(t)) as TaskContributor[];

    return new ApiTasksPost(
      task,
      taskFloor,
      taskContributors,
    );
  }
}
