import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskStatusActions } from '../actions';
import { TaskStatus } from '../../../domain-models/business/task-status.model';

export const taskStatusFeatureKey = 'taskStatus';

export interface State extends EntityState<TaskStatus> {
  selectedTaskStatusId: number | null;
}

export const adapter: EntityAdapter<TaskStatus> = createEntityAdapter<TaskStatus>();

export const initialState: State = adapter.getInitialState({
  selectedTaskStatusId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskStatusActions.addTaskStatus, (state, { taskStatus }) => {
    return adapter.addOne(taskStatus, state);
  }),
  on(TaskStatusActions.setTaskStatus, (state, { taskStatus }) => {
    return adapter.setOne(taskStatus, state);
  }),
  on(TaskStatusActions.upsertTaskStatus, (state, { taskStatus }) => {
    return adapter.upsertOne(taskStatus, state);
  }),
  on(TaskStatusActions.addTaskStatusItems, (state, { taskStatusItems }) => {
    return adapter.addMany(taskStatusItems, state);
  }),
  on(TaskStatusActions.upsertTaskStatusItems, (state, { taskStatusItems }) => {
    return adapter.upsertMany(taskStatusItems, state);
  }),
  on(TaskStatusActions.updateTaskStatus, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskStatusActions.updateTaskStatusItems, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskStatusActions.mapTaskStatusItems, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskStatusActions.deleteTaskStatus, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskStatusActions.deleteTaskStatusItems, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskStatusActions.deleteTaskStatusItemsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskStatusActions.loadTaskStatusItems, (state, { taskStatusItems }) => {
    return adapter.setAll(taskStatusItems, state);
  }),
  on(TaskStatusActions.clearTaskStatusItems, state => {
    return adapter.removeAll({ ...state, selectedTaskStatusId: null });
  }),
  /** END OF CRUD **/
  on(TaskStatusActions.updateSelectedTaskStatusId, (state, {taskStatusId}) => {
    return ({...state, selectedTaskStatusId: taskStatusId});
  }),
);

export const getSelectedTaskStatusId = (state: State) => state.selectedTaskStatusId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskStatus ids
export const selectTaskStatusIds = selectIds;

// select the dictionary of taskStatus entities
export const selectTaskStatusEntities = selectEntities;

// select the array of taskStatusItems
export const selectAllTaskStatusItems = selectAll;

// select the total taskStatus count
export const selectTaskStatusTotal = selectTotal;
