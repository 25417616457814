import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BroadcastService } from '../../../../core/services/broadcast.service';
import { Notification } from '../../../../core/models/notification.model';
import { Dictionary } from '@ngrx/entity';
import { TASK_FLOOR } from '../../../../domain-models/business/task-floor.model';
import { TASK } from '../../../../domain-models/business/task.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { UiContextActions } from '../../../../store/floor-store/actions';
import { Subject } from 'rxjs';

@Component({
  selector: 'echo-notifications',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPanelComponent implements OnInit {

  timeouts = {} as Dictionary<NodeJS.Timeout>;
  hideAllTimeout: NodeJS.Timeout;
  readAllTimeout: NodeJS.Timeout;

  closePanel$ = new Subject<void>();

  constructor(private cd: ChangeDetectorRef,
              private router: Router,
              private store: Store<State>,
              public broadcastService: BroadcastService) {
  }

  ngOnInit(): void {
  }

  clearTimeout(notificationId: number) {
    clearTimeout(this.timeouts[notificationId]);
    delete this.timeouts[notificationId];
    this.cd.detectChanges();
  }

  clearReadAllTimeout() {
    clearTimeout(this.readAllTimeout);
    this.readAllTimeout = null;
    this.cd.detectChanges();
  }

  clearHideAllTimeout() {
    clearTimeout(this.hideAllTimeout);
    this.hideAllTimeout = null;
    this.cd.detectChanges();
  }

  readAll() {
    this.readAllTimeout = setTimeout(() => {
      this.broadcastService.readAll(true);
      this.readAllTimeout = null;
      this.cd.detectChanges();
    }, 3000);
    this.cd.detectChanges();
  }

  hideAll() {
    this.hideAllTimeout = setTimeout(() => {
      this.broadcastService.hideAll(true);
      this.hideAllTimeout = null;
      this.cd.detectChanges();
    }, 3000);
    this.cd.detectChanges();
  }

  readMessage(notification: Notification) {
    const hasBeenRead = !notification.message.hasBeenRead;
    this.broadcastService.readMessage(notification.message.id, hasBeenRead);
  }

  hideMessage(notification: Notification) {
    this.timeouts[notification.message.id] = setTimeout(() => {
      this.broadcastService.hideMessage(notification, true);
      delete this.timeouts[notification.message.id];
    }, 3000);
    this.cd.detectChanges();
  }

  loadNotificationContextData(notification: Notification): void {
    if (notification.message.channelId === 1) {
      /** planning task **/
      const floorId = Number(notification.contextDataItems.find(e => e.propertyName === TASK_FLOOR.floorId).propertyValue);
      const taskId = Number(notification.contextDataItems.find(e => e.propertyName === TASK.id).propertyValue);
      this.store.dispatch(UiContextActions.setLoadFloorComponentState({
        floorComponentState: {
          floorId: floorId,
          taskId: taskId,
          studyMode: true
        }
      }));
      this.router.navigate([`/floor/${floorId}`]);
    } else if (notification.actions.find(a => a.actionTypeId === 2)) {
      this.router.navigate([`/pages/manage/contractors-approval`]);
    }
    if (!notification.message.hasBeenRead) {
      this.readMessage(notification);
    }
    this.closePanel$.next();
  }

}
