import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const FACILITY_JOB = {
  databaseTableName: 'facility_job',
  id: 'FaJo_Id',
  name: 'FaJo_Name',
};

export class FacilityJob {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class FacilityJobAdapter implements Adapter<FacilityJob> {
  adapt(item: any): FacilityJob {
    return new FacilityJob (
      FACILITY_JOB.databaseTableName,
      item[FACILITY_JOB.id],
      item[FACILITY_JOB.name],
    );
  }
  encode(item: FacilityJob): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FACILITY_JOB.id]: item.id,
      [FACILITY_JOB.name]: item.name,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
