import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { concatMap, filter, switchMap } from 'rxjs/operators';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { TaskActions, TaskContributorActions } from '../actions';
import { TASK_CONTRIBUTOR, TaskContributorAdapter } from '../../../domain-models/business/task-contributor.model';
import { TaskContributorSelectors, TaskSelectors } from '../selectors';
import { State } from '../../../reducers';
import { Observable, of } from 'rxjs';
import { Update } from '@ngrx/entity/src/models';
import { Task } from '../../../domain-models/business/task.model';
import { HttpParams } from '@angular/common/http';
import { UsageContextIdEnum } from '../../../shared/models/usage-context-id.enum';


@Injectable()
export class TaskContributorEffects {

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private taskContributorAdapter: TaskContributorAdapter,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskContributorByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(TaskContributorActions.beginGetTaskContributorsByTaskId),
    concatMap((action) => {
        /** Use ConcatMap instead of SwitchMap, avoid requests to be canceled **/
        const params = new HttpParams()
          .set('primaryColumnName', TASK_CONTRIBUTOR.taskId)
          .set('primaryFilterId', action.taskId);
        return this.projectManagementService.get(`${API_ENDPOINTS.dynT}${TASK_CONTRIBUTOR.databaseTableName}`, UsageContextIdEnum.none, params).pipe(
          switchMap(apiResult => [
            TaskContributorActions.upsertTaskContributors({taskContributors: apiResult.payload.map(e => this.taskContributorAdapter.adapt(e))})
          ]),
        );
      }
    )),
  );

  // addTaskContributor$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskContributorActions.addTaskContributor),
  //   switchMap((action) =>
  //     this.store.select(TaskSelectors.selectTaskById(action.taskContributor.taskId)).pipe(
  //       switchMap((task) => {
  //         return this.projectManagementService.post(API_ENDPOINTS.dynT, this.taskContributorAdapter.encode(action.taskContributor)).pipe(
  //           switchMap((apiResult) => {
  //             return this.store.select(TaskContributorSelectors.selectTaskContributorsByTaskId(task.id)).pipe(
  //               switchMap((taskContributors) => {
  //                 let taskTotalLoad = 0;
  //                 taskContributors.forEach(taskContributor => taskTotalLoad + taskContributor.load);
  //                 return this.updateTaskLoad(task, taskTotalLoad);
  //               })
  //             );
  //           }),
  //         );
  //       }),
  //     )
  //   ),
  //   ),
  // );

  addTaskContributor$ = createEffect(() => this.actions$.pipe(
    ofType(TaskContributorActions.addTaskContributor),
    switchMap((action) =>
      this.projectManagementService.post(API_ENDPOINTS.dynT, this.taskContributorAdapter.encode(action.taskContributor)).pipe(
        concatMap(apiResult => this.store.select(TaskSelectors.selectTaskById(action.taskContributor.taskId)).pipe(
          filter(x => x !== undefined),
          concatMap((task) => {
            return this.store.select(TaskContributorSelectors.selectTaskContributorsByTaskId(task.id)).pipe(
              concatMap((taskContributors) => {
                let taskTotalLoad = 0;
                //taskTotalLoad += action.taskContributor.load;
                taskContributors.forEach(taskContributor => taskTotalLoad += taskContributor.load);
                return this.updateTaskLoad(task, taskTotalLoad);
              })
            );
          }),
        ))
      )
    ),
    )
  );

  deleteTaskContributor$ = createEffect(() => this.actions$.pipe(
    ofType(TaskContributorActions.deleteTaskContributor),
    switchMap((action) => {
      const endpoint = `${API_ENDPOINTS.dynT}${TASK_CONTRIBUTOR.databaseTableName}/${TASK_CONTRIBUTOR.taskId}-${TASK_CONTRIBUTOR.contributorId}-${TASK_CONTRIBUTOR.functionId}/${action.id}`;
      return this.projectManagementService.delete(endpoint).pipe(
        concatMap(apiResult => {
          // Extract taskId from action, TaskContributor's id is TaCr_TaskId-TaCr_ContributorId-TaCr_FunctionId
          const taskId = Number(action.id.split('-', 3)[0]);
          return this.store.select(TaskSelectors.selectTaskById(taskId)).pipe(
            concatMap(task => {
              // Select TaCr[] by taskId
              return this.store.select(TaskContributorSelectors.selectTaskContributorsByTaskId(taskId)).pipe(
                concatMap((taskContributors) => {
                  // Select TaskContributor[] and update new Ta_Load
                  let taskTotalLoad = 0;
                  taskContributors.forEach(taskContributor => taskTotalLoad += taskContributor.load);
                  return this.updateTaskLoad(task, taskTotalLoad);
                })
              );
            }),
          );
        })
      );
    }),
    ),
  );

  updateTaskLoad(task: Task, newLoad: number): Observable<Action> {
    console.log(task);
    console.log(newLoad);
    /**
     * Update task load
     */
    const update = {
      id: task.id,
      changes: {
        load: newLoad
      }
    } as Update<Task>;
    return of(TaskActions.updateTask({update: update}));
  }
}
