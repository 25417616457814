import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  transform<T>(array: T[], key: string, condition: any): T {
    // console.log(array);
    // console.log(key)
    // console.log(condition);
    return array ? array.find(e => e[key] === condition) : null;

  }

}
