import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform(value) {
    if (Array.isArray(value)) {
      return value.slice().reverse();
    } else {
      return null;
    }
  }

}
