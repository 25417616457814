<input #file class ="file-input" type="file" [attr.accept]="acceptedFiles" (change)="onFilesAdded()" [attr.multiple]='multi' />
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3>Sélectionner {{multi ? 'des fichiers' : 'un fichier'}}</h3>
    <button *ngIf='!simpleIcon'
      [disabled]="(uploading | async) || uploadSuccessful"
      mat-fab
      color="accent"
      class="add-files-btn mat-elevation-z0"
      (click)="addFiles()">
      <mat-icon>upload_file</mat-icon>
    </button>

    <button *ngIf='simpleIcon'
            [disabled]="(uploading | async) || uploadSuccessful"
            mat-icon-button
            color="accent"
            class="add-files-btn"
            (click)="addFiles()">
      <mat-icon>upload_file</mat-icon>
    </button>
  </div>

  <!-- This is the content of the upload-dialog, containing a list of the files to upload -->
  <mat-list>
    <mat-list-item *ngFor="let file of files">
      <mat-icon mat-list-icon>{{progressIcon}}</mat-icon>
      <h4 mat-line>{{file.name}}</h4>
      <pre mat-line *ngIf="progress">{{progress[file.name].progress | async}}</pre>
      <mat-progress-bar
        *ngIf="progress"
        mode="determinate"
        [value]="progress[file.name].progress | async"
      ></mat-progress-bar>
    </mat-list-item>
  </mat-list>
</div>
