import { Injectable } from '@angular/core';
import { TASK, Task } from '../../../domain-models/business/task.model';

export class GanttTask {
  public type?: any;
  public parent?: number;
  public progress?: number;
  public open?: boolean;
  public end_date?: Date | string;
  public readonly?: boolean;
  public editable?: boolean;
  public color?: string;
  public textColor?: string;
  public progressColor?: string;
  public $new?: boolean;
  public ownerId?: number;
  public $virtual: boolean;
  public isTemplate: boolean;
  constructor(
    public id: number,
    public start_date: Date | string,
    public text: string,
    public duration: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class GanttTaskAdapter {
  adaptFromTask(item: Task | Partial<Task>): GanttTask {
    const ganttTask = new GanttTask(
      item.id,
      item.startDate,
      item.name,
      item.duration
    );
    // Check for optional props
    if (item.typeId) {
      ganttTask.type = item.typeId;
    }
    if (item.parentId) {
      ganttTask.parent = item.parentId;
    } else {
      // ganttTask.parent = null;
    }
    if (item.fulfilled) {
      ganttTask.progress = item.fulfilled / 100;
    }
    if (item.isTemplate) {
      ganttTask.isTemplate = item.isTemplate;
    }
    return ganttTask;
  }

  adaptToTask(item: GanttTask): Task | Partial<Task> {
    let parent;
    if (item.parent === 0) {
      parent = null;
    } else {
      parent = item.parent;
    }
    return {
      databaseTableName: TASK.databaseTableName,
      id: item.id,
      startDate: new Date(item.start_date),
      name: item.text,
      duration: item.duration,
      typeId: item.type,
      parentId: parent,
      fulfilled: item.progress * 100, // Map progress (0-1) to Ta_Fullfilled (0-100)
    } as Partial<Task>;

  }
}
