import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
// import {DisplayTheme} from '../models/display-theme.model';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Dictionary, Update } from '@ngrx/entity';
import { ROOM_LAYOUT_TYPE } from '../../../domain-models/business/room-layout-type.model';
import { ThemeItemMockup } from '../../../features/floor/models/theme-item-mockup.model';
import { DisplayThemeMockup } from '../../../features/floor/models/display-theme-mockup.model';
import { ROOM_ALLOCATION } from '../../../domain-models/business/room-allocation.model';
import { ROOM_ATTRIBUTION_TYPE } from '../../../domain-models/business/room-attribution-type.model';
import { ROOM_ACTIVITY_STATUS_TYPE } from '../../../domain-models/business/room-activity-status-type.model';
import { Actions, ofType } from '@ngrx/effects';
import { DisplayThemeItemActions } from '../../../store/shared-store/actions';
import { RoomActions } from '../../../store/floor-store/actions';
import { UiContextSelectors } from '../../../store/floor-store/selectors';
import { DisplayThemeItemSelectors, FloorModelSelectors } from '../../../store/shared-store/selectors';
import { GraphicsFloorActions } from '../../../store/svg-store/actions';
import { BuildingSelectors, BuildingUiSelectors } from '../../../store/building-store/selectors';
import { LegendContextEnum } from '../../../domain-models/models/legend-context.enum';
import { TaskSelectors } from '../../../store/project-management/selectors';
import { TaskActions } from '../../../store/project-management/actions';
import { DisplayThemeCategory, DisplayThemeCategoryEnum } from '../../../domain-models/business/display-theme-category.model';
import { SharedService } from '../../services/shared.service';
import { WORKPLACE_TYPE } from '../../../domain-models/business/workplace-type.model';
import { DisplayThemeEnum } from '../../../domain-models/business/display-theme.model';
import { EQUIPMENT_PLANNING_STATE, EquipmentPlanningStateEnum } from '../../../domain-models/business/equipment-planning-state.model';
import { FLOOR_DATA_STATE } from '../../../domain-models/business/floor-data-state.model';
import { Task } from '../../../domain-models/business/task.model';
import { DISPLAY_THEMABLE, DisplayThemableEnum } from '../../../domain-models/business/display-themable.model';
import { TaskService } from '../../../features/project-management/services/task.service';
import { FloorModelEnum } from '../../../domain-models/business/floor-model.model';
import { FLOOR_DATA_IMPORT } from '../../../domain-models/business/floor-data-import.model';
import { TaskBusinessTypeEnum } from '../../../domain-models/business/task-business.model';

@Component({
  selector: 'echo-floor-legend',
  templateUrl: './floor-legend.component.html',
  styleUrls: ['./floor-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorLegendComponent implements OnInit, AfterViewInit, OnDestroy {

  DisplayThemeEnum = DisplayThemeEnum;
  DisplayThemeCategoryEnum = DisplayThemeCategoryEnum;
  LegendContextEnum = LegendContextEnum;
  EQUIPMENT_PLANNING_STATE = EQUIPMENT_PLANNING_STATE;
  EquipmentPlanningStateEnum = EquipmentPlanningStateEnum;

  private unsubscribe$: Subject<void> = new Subject<void>();

  idsToDisplay: string[] = [];

  displayThemeId$: Observable<number>;
  displayThemeId: number;

  legendContextId$: Observable<number>;
  selectedBuildingId$: Observable<number>;
  selectedTaskId$: Observable<number>;
  selectedTask$: Observable<Task>;
  detectChanges$: Observable<boolean>;

  isEditingEquipment$: Observable<boolean>;

  selectedTaskId: number;
  selectedBuildingId: number;

  studyMode$: Observable<boolean>;

  currentTaskHasReconcileTasks$: Observable<boolean>;
  currentTaskSpacePlanningImportTask$: Observable<Task>;

  displayThemes$: Observable<DisplayThemeMockup[]>;
  displayThemesByCategoryIdDictionary$: Observable<Dictionary<DisplayThemeMockup[]>>;
  displayThemeItems$: Observable<ThemeItemMockup[]>;

  displayThemeCategories$: Observable<DisplayThemeCategory[]>;

  isPaintingItem$: Observable<ThemeItemMockup>;

  hoveredItem: ThemeItemMockup;

  someInactiveItems$: Observable<boolean>;

  constructor(private store: Store<State>,
              private cd: ChangeDetectorRef,
              private sharedService: SharedService,
              private taskService: TaskService,
              private actions$: Actions) {
    // Moved to GraphicsFloorEffects
    //this.store.dispatch(DisplayThemeItemActions.buildDisplayThemes());
    this.studyMode$ = this.store.select(UiContextSelectors.selectStudyMode);
    this.displayThemes$ = this.store.select(DisplayThemeItemSelectors.selectDisplayThemes);
    this.displayThemeItems$ = this.store.select(DisplayThemeItemSelectors.selectAllCurrentDisplayThemeItems);
    this.someInactiveItems$ = this.store.select(DisplayThemeItemSelectors.selectAllCurrentDisplayThemeItems).pipe(
      map(themeItems => themeItems.every(t => t.active === true))
    );

    this.displayThemeId$ = this.store.select(DisplayThemeItemSelectors.selectCurrentDisplayThemeId);
    this.legendContextId$ = this.store.select(DisplayThemeItemSelectors.selectLegendContext);
    this.selectedBuildingId$ = this.store.select(BuildingSelectors.selectCurrentBuildingId);
    //this.selectedTaskId$ = this.store.select(TaskSelectors.selectCurrentTaskId);
    this.displayThemeCategories$ = this.store.select(DisplayThemeItemSelectors.selectDisplayThemeCategories);
    this.isPaintingItem$ = this.store.select(DisplayThemeItemSelectors.selectIsPaintingItem);
    this.selectedTask$ = this.store.select(TaskSelectors.selectCurrentTask);
    this.selectedTaskId$ = this.selectedTask$.pipe(map(selectedTask => selectedTask ? selectedTask.id : null));

    this.currentTaskHasReconcileTasks$ = this.store.select(TaskSelectors.currentTaskHasReconcileTasks);

    this.currentTaskSpacePlanningImportTask$ = this.selectedTaskId$.pipe(
      switchMap(selectedTaskId => this.store.select(TaskSelectors.selectSpacePlanningImportTaskByTaskId(selectedTaskId)))
    );

    this.isEditingEquipment$ = this.store.select(UiContextSelectors.selectCurrentlyEditedFloorModelId).pipe(
      switchMap(editingFloorModelId => {
        if (editingFloorModelId) {
          return this.store.select(FloorModelSelectors.selectFloorModelAncestor(editingFloorModelId, FloorModelEnum.Equipment)).pipe(
            map(activeFloorModelAncestor => activeFloorModelAncestor.id === FloorModelEnum.Equipment),
          );
        } else {
          return of(null);
        }
      }),
    );

    this.displayThemeCategories$ = combineLatest([
      this.store.select(DisplayThemeItemSelectors.selectDisplayThemeCategories),
      this.studyMode$,
      this.legendContextId$,
      this.selectedTask$,
      this.currentTaskHasReconcileTasks$,
    ]).pipe(
      tap(a => console.log(a)),
      map(([displayThemeCategories, studyMode, legendContextId, selectedTask, currentTaskHasReconcileTasks]) => {
        return displayThemeCategories.filter(c => {
          if ((!studyMode || !currentTaskHasReconcileTasks) && (c.id === DisplayThemeCategoryEnum.EquipmentMoveReconcile || c.id === DisplayThemeCategoryEnum.EquipmentScanReconcile)) {
            return;
          } else if ((!studyMode || !(selectedTask.businessTypeId === TaskBusinessTypeEnum.SpacePlanningImport)) && (c.id === DisplayThemeCategoryEnum.ImportReconcile)) {
            return;
          } else if (!studyMode && c.id === DisplayThemeCategoryEnum.EquipmentUpdates) {
            return;
          } else if (c.id === DisplayThemeCategoryEnum.Workplaces && legendContextId === LegendContextEnum.Building) {
            return;
          } else {
            return c;
          }
        });
      }),
      //tap(d => console.log(d)),
    );

    /** Reduce display themes by category ids https://dev.to/_bigblind/quick-tip-transform-an-array-into-an-object-using-reduce-2gh6 **/
    this.displayThemesByCategoryIdDictionary$ = this.displayThemes$.pipe(
      map(displayThemes => {
        const dict = displayThemes.reduce((acc, theme) => {
          const {id, categoryId, name} = theme;
          return {...acc, [categoryId]: [...(acc[categoryId] || []), theme]};
        }, {});
        return dict;
      }),
    );

    this.detectChanges$ = this.actions$.pipe(
      ofType(
        RoomActions.addRoom,
        RoomActions.updateRoom,
        GraphicsFloorActions.successGetGraphicsFloorByTaskId,
        TaskActions.updateSelectedTaskId
      ));

  }

  ngOnInit() {

    this.displayThemeItems$.pipe(
      tap(items => {
        console.log(items.filter(e => e.displayThemeIds.includes(9)));
      })
    ).subscribe();

    combineLatest([this.selectedBuildingId$, this.selectedTaskId$, this.legendContextId$]).pipe(
      map(([selectedBuildingId, selectedTaskId, legendContextId]) => {
        switch (legendContextId) {
          case LegendContextEnum.Building: {
            this.selectedBuildingId = selectedBuildingId;
            this.selectedTaskId = null;
            break;
          }
          case LegendContextEnum.Floor: {
            this.selectedBuildingId = null;
            this.selectedTaskId = selectedTaskId;
            break;
          }
        }
      }),
    ).subscribe(_ => this.cd.detectChanges());

    this.legendContextId$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.selectedBuildingId$, this.selectedTaskId$),
      filter(id => id !== null),
      switchMap(([id, selectedBuildingId, selectedTaskId]) => {
        switch (id) {
          case LegendContextEnum.Building: {
            this.selectedBuildingId = selectedBuildingId;
            this.selectedTaskId = selectedTaskId;
            return combineLatest([
              this.store.select(BuildingUiSelectors.selectCurrentBuildingRoomLayoutTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingActivityStatusTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingAttributionTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingBusinessUnitIds),
              this.sharedService.getAllWorkplaceTypes().pipe(map(workplaceTypes => workplaceTypes.map(w => w.id))),
              this.sharedService.getAllEquipmentPlanningStates().pipe(map(equipmentPlanningStates => equipmentPlanningStates.map(e => e.id))),
              this.sharedService.getAllFloorDataStates().pipe(map(floorDataStates => floorDataStates.map(s => s.id))),
            ]);
          }
          case LegendContextEnum.Floor: {
            this.selectedBuildingId = null;
            this.selectedTaskId = selectedTaskId;
            return combineLatest([
              this.store.select(UiContextSelectors.selectCurrentTaskRoomLayoutTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskActivityStatusTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskAttributionTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskBusinessUnitIds),
              this.sharedService.getAllWorkplaceTypes().pipe(map(workplaceTypes => workplaceTypes.map(w => w.id))),
              this.sharedService.getAllEquipmentPlanningStates().pipe(map(equipmentPlanningStates => equipmentPlanningStates.map(e => e.id))),
              this.sharedService.getAllFloorDataStates().pipe(map(floorDataStates => floorDataStates.map(s => s.id))),
            ]);
          }
          // default: {
          //   return EMPTY;
          // }
        }
      }),
      //filter(r => r !== undefined),
      //tap(a => console.log(a)),
      map(([currentTaskRoomLayoutTypeIds,
             currentTaskActivityStatusTypeIds,
             currentTaskAttributionTypeIds,
             currentTaskBusinessUnitIds,
             workplaceTypeIds,
             equipmentPlanningStateIds,
             floorDataStateIds]) =>
        this.idsToDisplay = this.checkIdsToDisplay(currentTaskRoomLayoutTypeIds,
          currentTaskActivityStatusTypeIds,
          currentTaskAttributionTypeIds,
          currentTaskBusinessUnitIds,
          workplaceTypeIds,
          equipmentPlanningStateIds,
          floorDataStateIds)
      ),
    ).subscribe(_ => {
      this.cd.detectChanges();

    });

    this.detectChanges$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cd.detectChanges());

    this.displayThemeId$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        this.displayThemeId = id;
      });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectNewTheme(event: number) {
    this.store.dispatch(DisplayThemeItemActions.updateDisplayThemeId({id: event}));
    /** Disable any eventually enabled "apply theme item" **/
    this.store.dispatch(DisplayThemeItemActions.applyThemeItem({resId: null}));
  }

  toggleItemVisibility(item: ThemeItemMockup) {
    const updatedItem = {
      id: item.resId,
      changes: {
        active: !item.active
      }
    } as Update<ThemeItemMockup>;
    this.store.dispatch(DisplayThemeItemActions.updateDisplayThemeItem({displayThemeItem: updatedItem}));
  }

  isolateItemVisibility(e: Event, item: ThemeItemMockup) {
    e.stopPropagation();
    this.store.dispatch(DisplayThemeItemActions.isolateThemeItem({id: item.resId}));
  }

  showAllItems(e: Event) {
    e.stopPropagation();
    this.store.dispatch(DisplayThemeItemActions.showAllThemeItems());
  }

  toggleHoveredItem(item: ThemeItemMockup) {
    if (item) {
      this.hoveredItem = item;
      this.store.dispatch(DisplayThemeItemActions.setHoveredThemeItemId({id: item.resId}));
    } else {
      this.hoveredItem = null;
      this.store.dispatch(DisplayThemeItemActions.setHoveredThemeItemId({id: null}));
    }
  }

  checkIdsToDisplay(roomLayoutTypeIds: number[], activityStatusTypeIds: number[], attributionTypeIds: number[], businessUnitIds: number[], workplaceTypeIds: number[], equipmentPlanningStateIds: number[], floorDataStateIds: number[]): string[] {
    const idsToDisplay = [];
    console.log(equipmentPlanningStateIds);
    roomLayoutTypeIds.forEach(id => idsToDisplay.push(ROOM_LAYOUT_TYPE.databaseTableName + '-' + id));
    activityStatusTypeIds.forEach(id => idsToDisplay.push(ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + id));
    attributionTypeIds.forEach(id => idsToDisplay.push(ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + id));
    businessUnitIds.forEach(id => idsToDisplay.push(ROOM_ALLOCATION.databaseTableName + '-' + id));
    workplaceTypeIds.forEach(id => idsToDisplay.push(`${WORKPLACE_TYPE.databaseTableName}-${id}`));
    equipmentPlanningStateIds.forEach(id => idsToDisplay.push(`${EQUIPMENT_PLANNING_STATE.databaseTableName}-${id}`));
    floorDataStateIds.forEach(id => idsToDisplay.push(`${FLOOR_DATA_STATE.databaseTableName}-${id}`));
    /** Enable MultiAllocated and NotAllocated in Attributions theme **/
    idsToDisplay.push(`${ROOM_ATTRIBUTION_TYPE.databaseTableName}-MultiAllocated`);
    idsToDisplay.push(`${ROOM_ATTRIBUTION_TYPE.databaseTableName}-NotAllocated`);
    /** Display Theme EquipmentScanReconcile **/
    idsToDisplay.push(`${DISPLAY_THEMABLE.databaseTableName}-${DisplayThemableEnum.StandingBy}`);
    idsToDisplay.push(`${DISPLAY_THEMABLE.databaseTableName}-${DisplayThemableEnum.Identified}`);
    /** Display Theme ImportReconcile **/
    idsToDisplay.push(`${FLOOR_DATA_IMPORT.databaseTableName}-${DisplayThemableEnum.Unchanged}`);
    idsToDisplay.push(`${FLOOR_DATA_IMPORT.databaseTableName}-${DisplayThemableEnum.ProposedAdded}`);
    idsToDisplay.push(`${FLOOR_DATA_IMPORT.databaseTableName}-${DisplayThemableEnum.ProposedDeleted}`);
    return idsToDisplay;
  }

  applyThemeItem(e: Event, item: ThemeItemMockup) {
    e.stopPropagation();
    this.isPaintingItem$.pipe(
      first(),
      tap(isPaintingItem => {
        if (isPaintingItem && isPaintingItem.resId === item.resId) {
          //Disable tool
          this.store.dispatch(DisplayThemeItemActions.applyThemeItem({resId: null}));
        } else {
          this.store.dispatch(DisplayThemeItemActions.applyThemeItem({resId: item.resId}));
        }
      }),
    ).subscribe();
  }
}
