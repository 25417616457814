<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="treeControl.dataNodes">

  <!-- Invisible node template -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="empty-node">
    <button mat-icon-button disabled></button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: displayChildren" matTreeNodeToggle matTreeNodePadding (click)="selectNode(node)"
                 [disabled]="onlySelectParents"
                 [ngClass]="{'only-select-parents-child-node': onlySelectParents,
                 'selectable': true,
                 'list-item-active': node === selectedNode}"
  >
    <button mat-icon-button disabled></button>
    <span class='wrapped-small' *ngIf="!checkboxes">{{node?.displayNameId ? (node.displayNameId | language | async) : node.name}}</span>
    <mat-checkbox *ngIf="checkboxes"
                  class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">{{node?.displayNameId ? (node.displayNameId | language | async) : node.name}}</mat-checkbox>

<!--    <ng-container *ngIf='tableName === FLOOR_MODEL.databaseTableName || tableName === BUSINESS_UNIT.databaseTableName'>-->
<!--      <button mat-icon-button aria-label="Créer un identifiant d'habilitation" (click)='addGrant(node)'>-->
<!--        <mat-icon inline>enhanced_encryption</mat-icon>-->
<!--      </button>-->
<!--    </ng-container>-->

    <!-- Color cell -->
    <ng-container *ngIf='node.color'>
      <span class='spacer'></span>
      <div [ngClass]="{'color-cell': true, 'color-cell-stroke': false, 'stripes': false }"
           [style.background-color]="node.color?.toHexRgb()" [style.border-color]="node.color?.toHexRgb()"></div>
    </ng-container>

    <button *ngIf="node.data[canHaveChildrenColName]" mat-icon-button (click)="addToNode($event, node)"><mat-icon>add</mat-icon></button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding (click)="selectNode(node)"
                 [ngClass]="{'only-select-parents-parent-node': onlySelectParents,
                 'list-item-active': node === selectedNode
                 }">
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox *ngIf="checkboxes"
                  [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node?.displayNameId ? (node.displayNameId | language | async) : node.name}}</mat-checkbox>
    <span class='wrapped' *ngIf="!checkboxes">{{node?.displayNameId ? (node.displayNameId | language | async) : node.name}}</span>
    <!-- Color cell -->
    <ng-container *ngIf='node.color'>
      <span class='spacer'></span>
      <div [ngClass]="{'color-cell': true, 'color-cell-stroke': false, 'stripes': false }"
           [style.background-color]="node?.color?.toHexRgb()" [style.border-color]="node?.color?.toHexRgb()"></div>
    </ng-container>
    <button *ngIf="node.data[canHaveChildrenColName]" mat-icon-button (click)="addToNode($event, node)"><mat-icon>add</mat-icon></button>

  </mat-tree-node>
</mat-tree>
