import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiData } from '../models/api-data.model';
import { Z_USER_DATAGRID_UI } from '../../domain-models/core/Z_User_Datagrid_UI';
import { API_ENDPOINTS } from '../../shared/api-endpoints';
import { ApiResponseCodeEnum } from '../../domain-models/models/api-response-code.model';
import { SnackBarService } from './snack-bar.service';
import { LoggerService } from './logger.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(protected http: HttpClient,
              protected loggerService: LoggerService,
              protected snackBarService: SnackBarService,
              protected loaderService: LoaderService) {
  }

  get(url: string, usageContextId?: number, params?: HttpParams): Observable<ApiData> {
    let httpOptions: object;
    if (usageContextId) {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': String(usageContextId)
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': '1'
        })
      };
    }
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }

    return this.http.get<ApiData>(url, httpOptions)
      .pipe(
        //tap(_ => this.log('fetched ' + url)),
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.log(data.message);
              }
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  post(url: string, object: any, params?: HttpParams, headers?: HttpHeaders): Observable<ApiData> {
    let httpOptions = {};
    // if (headers) {
    //   httpOptions = {
    //     ...httpOptions,
    //     headers: headers,
    //   };
    // } else {
    //   httpOptions = {
    //     ...httpOptions,
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json'
    //     }),
    //   };
    // }
    if (params) {
      httpOptions = {
        ...httpOptions,
        // headers: headers,
        params: params
      };
    }
    /*else {
      httpOptions = {
        headers: headers,
      };
    }*/
    return this.http.post<ApiData>(url, object, httpOptions) // JSON.stringify(object)
      .pipe(
        //tap(_ => this.log('posted ' + url)),
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.log(data.message);
              }
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this)) //prevent loosing context https://stackoverflow.com/questions/52078137/service-call-doesnt-work-inside-error-handler-in-angular-5
      );
  }

  patch(url: string, object: any, usageContextId?: number, params?: HttpParams): Observable<ApiData> {
    let httpOptions: object;
    if (usageContextId) {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': String(usageContextId),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': '1',
          'Content-Type': 'application/json',
        })
      };
    }
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }
    return this.http.patch<ApiData>(url, object, httpOptions)
      .pipe(
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.log(data.message);
              }
            }
            return data as ApiData;
          } else {
            // POC UPDATE
            // Remplace le throw qui interromp le code flow par la gestion d'erreur intégrée et un retour de valeur null qui
            // peut être reçu et interprété au niveau de la fonction appelante
            this.handleError(data);
            return data;
            //throw data;
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  put(url: string, object: any): Observable<any> {
    this.loggerService.log('Patch operation');
    return this.http.put<any>(url, object)
      .pipe(
        tap(_ => this.log('patched ' + url)),
        catchError(this.handleError)
      );
  }

  delete(url: string, params?: HttpParams): Observable<ApiData> {
    let httpOptions = {};

    httpOptions = {
      ...httpOptions,
      headers: new HttpHeaders({
        //'Echo-Usage-Context-Id': '1',
        'Content-Type': 'application/json',
      })
    };
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }
    return this.http.delete<ApiData>(url, httpOptions)
      .pipe(
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.log(data.message);
              }
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this))
        // tap(_ => this.log('deleted ' + url)),
        // catchError(this.handleError.bind(this))
      );
  }

  public updatePagination(tableName: string, usageContextId: string, pageSize: number): Promise<boolean> {
    return new Promise((resolve) => {
      const userDatagridUi = {};
      userDatagridUi[Z_USER_DATAGRID_UI.paginationItemsCount] = pageSize;
      userDatagridUi[Z_USER_DATAGRID_UI.usageContextId] = usageContextId;
      userDatagridUi[Z_USER_DATAGRID_UI.tableName] = tableName;
      this.patch(API_ENDPOINTS.paginationUpdate, userDatagridUi).subscribe(_ => {
        resolve(true);
      });
    });
  }

  protected handleError(error: any): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.loggerService.error(
        `Backend returned code ${error.status}, ` +
        `body message was: ${JSON.stringify(error.error)}`);
      switch (error.status) {
        case 500:
          // POC UPDATE
          //return throwError('Internal Server Error');
          return of('Internal Server Error');
        case 400:
          // POC UPDATE
          //return throwError('Bad Request');
          return of('Bad Request');
        case 401:
          // POC UPDATE
          //return throwError('Not authenticated');
          return of('Not authenticated');
        case 404:
          // POC UPDATE
          //return throwError('Page Not Found');
          return of('Page Not Found');
        default:
          // Reset HTTP Loading indicator
          this.loaderService.setHttpProgressStatus(false);
          // return an observable with a user-facing error message
          // POC UPDATE
          //return throwError(error.error);
          return of(error.error);
      }
    } else {
      // A client-side or network error occurred. Handle it accordingly.
      this.loggerService.error(`Domain error code: ${error.statusCode}\nError: ${error.message}`);
      this.snackBarService.displayError(error.message, true);
      // POC UPDATE
      //return throwError(error.message);
      return of(error.message);
      // return throwError(error.message);
    }
  }

  // /**
  //  * Handle Http operation that failed.
  //  * Let the app continue.
  //  * @param operation - name of the operation that failed
  //  * @param result - optional value to return as the observable result
  //  */
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead
  //
  //     // TODO: better job of transforming error for user consumption
  //     this.log(`${operation} failed: ${error.message}`);
  //
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }

  /** Log a HeroService message with the MessageService */
  protected log(message: string) {
    // this.loggerService.log(message);
    // this.loggerService.log(`ApiService: ${message}`);
  }
}
