import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { MenuService } from '../../services/menu.service';
import { MenuItem } from '../../models/menu-item.model';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { EcSyFile } from '../../../domain-models/models/EcSyFile.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { CoreSelectors } from '../../../store/core-store/selectors';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { NotificationsPanelComponent } from '../../../features/user/components/notifications-panel/notifications-panel.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { BroadcastService } from '../../services/broadcast.service';
import { UserIdentity } from '../../../domain-models/business-extended/user.model';
import { FunctionPointEnum } from '../../../domain-models/core/function-point.enum';
import { CoreActions } from '../../../store/core-store/actions';
import { ZAppMenuEnum } from '../../../domain-models/core/z-app-menu.model';

@Component({
  selector: 'echo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  toggleDrawer: boolean;
  toggleMobileMenu: boolean;

  @ViewChild('menu', {static: false}) public menu;
  @ViewChild('notificationsPanelBtn', {read: ElementRef}) private notificationsPanelBtn: ElementRef;

  public menuItems$: Observable<MenuItem[]>;
  public client$: Observable<EcSyFile>;
  user$: Observable<UserIdentity>;
  overlayRef: OverlayRef;

  constructor(private store: Store<State>,
              private layoutService: LayoutService,
              private menuService: MenuService,
              private clientService: ClientService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef,
              public broadcastService: BroadcastService,
              private media: MediaMatcher,
              private overlay: Overlay) {
    this.client$ = this.store.select(CoreSelectors.selectClient);
    this.mobileQuery = media.matchMedia('(max-width: 1280px)'); //'(max-width: 1048px)'
    this._mobileQueryListener = () => {
      if (!this.mobileQuery.matches) {
        this.layoutService.leftDrawer$.next(false);
      }
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.user$ = this.store.select(CoreSelectors.selectUser);
  }

  ngOnInit() {
    this.menuItems$ = this.menuService.itemToDisplay$;
    // this.client$ = this.store.clientFile$;
    this.layoutService.rightDrawerState$.subscribe(closed => this.toggleDrawer = closed);
    this.layoutService.leftDrawerState$.subscribe(closed => this.toggleMobileMenu = closed);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMenuItemClick(item: MenuItem) {
    // Find function point name
    const functionPoint = ZAppMenuEnum[item.id];
    // Look for function point GUID if exists
    const functionPointGUID = FunctionPointEnum[functionPoint];
    if (functionPointGUID) {
      this.store.dispatch(CoreActions.setCallerRequestId({callerRequestId: functionPointGUID}));
    } else {
      this.store.dispatch(CoreActions.setCallerRequestId({callerRequestId: null}));
    }
    if (item.componentPath === 'list') {
      this.router.navigate([`/data-tables/${item.label}`], {
        queryParams: {'endpoint': item.apiEndPoint, 'usageContextId': item.usageContextId, 'dataContext': item.dataContext},
      });
    } else if (item.componentPath === 'tree') {
      this.router.navigate([`/trees/${item.label}`], {
        queryParams: {'tableName': item.dataContext},
      });
    } else {
      // this.menuService.currentPage$.next(item.label);
      this.router.navigate([item.componentPath]);
    }
  }

  rightDrawerToggle() {
    this.toggleDrawer = !this.toggleDrawer;
    this.layoutService.rightDrawer$.next(this.toggleDrawer);
  }

  mobileMenuToggle() {
    this.toggleMobileMenu = !this.toggleMobileMenu;
    this.layoutService.leftDrawer$.next(this.toggleMobileMenu);
  }

  toggleMenuItem(item: MenuItem) {
    item.isOpened = !item.isOpened;
  }

  openNotificationsPanel() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        maxWidth: 320,
        width: 320,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        //panelClass: 'mat-elevation-z8',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.notificationsPanelBtn)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetX: -16
            }
          ])
      });
      // Then we create a portal to render a component
      const componentPortal = new ComponentPortal(NotificationsPanelComponent);
      // We add a custom CSS class to our overlay
      //overlayRef.addPanelClass('example-overlay');
      // We render the portal in the overlay
      const componentRef: ComponentRef<NotificationsPanelComponent> = this.overlayRef.attach(componentPortal);
      componentRef.instance.closePanel$.subscribe(() => this.closeNotificationsPanel());
      //this.overlayRef.attach(componentPortal);
      this.overlayRef.backdropClick().subscribe(() => this.closeNotificationsPanel());
    } else {
      this.closeNotificationsPanel();
    }

  }

  closeNotificationsPanel() {
    this.overlayRef.dispose();
    this.overlayRef = null;
  }

}
