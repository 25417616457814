import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum FloorDataStateEnum {
  Pristine = 1,
  Added,
  Deleted,
  Updated,
  Orphan,
  ChildUpdated
}

export const FLOOR_DATA_STATE = {
  databaseTableName: 'FloorDataState',
  id: 'FlDaSt_Id',
  name: 'FlDaSt_Name',
  displayNameId: 'FlDaSt_DisplayNameId',
  color: 'FlDaSt_Color'
};

export class FloorDataState {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorDataStateAdapter implements Adapter<FloorDataState> {
  adapt(item: any): FloorDataState {
    return new FloorDataState(
      FLOOR_DATA_STATE.databaseTableName,
      item[FLOOR_DATA_STATE.id],
      item[FLOOR_DATA_STATE.name],
      item[FLOOR_DATA_STATE.displayNameId],
      ColorUtils.colorFromHexARGB(item[FLOOR_DATA_STATE.color])
    );
  }

  encode(item: FloorDataState): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_DATA_STATE.databaseTableName,
      [FLOOR_DATA_STATE.id]: item.id,
      [FLOOR_DATA_STATE.name]: item.name,
      [FLOOR_DATA_STATE.displayNameId]: item.displayNameId,
      [FLOOR_DATA_STATE.color]: item.color.toHexArgb()
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
