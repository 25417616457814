import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';
import * as FloorDataActions from '../actions/floor-data.actions';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { RendererSelectors } from '../selectors';
import { of } from 'rxjs';
import { TaskFloorModelActions, WallActions } from '../actions';
import { GripAlignmentTypeEnum } from '../../../features/svg-factory/models/grip-alignment-type.model';
import { FloorModelEnum } from '../../../domain-models/business/floor-model.model';
import { FloorSelectors } from '../../floor-store/selectors';
import { Update } from '@ngrx/entity';
import { TaskFloorModel } from '../../../domain-models/business-extended/task-floor-model.model';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';
import { UiContextActions } from '../../floor-store/actions';
import { Floor } from '../../../domain-models/business/floor.model';
import { State } from '../../../reducers';

@Injectable()
export class WallEffects {

  createWall$ = createEffect(() => this.actions$.pipe(
      ofType(UiContextActions.createWall),
      concatMap(action => this.floorService.createWall(action.props.taskId, action.props.startPoint, action.props.endPoint, action.props.wallStyle, action.props.wallWidth, action.props.spaceBound).pipe(
        concatMap(floorData => {
          return [
            FloorDataActions.addFloorData({svgGroup: floorData}),
            UiContextActions.cancelWallCreation(),
          ];
        }),
        catchError(err => of(UiContextActions.cancelWallCreation())),
      )),
    )
  );

  stretchWall$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.stretchWall),
      concatMap(action => this.floorService.stretchWall(action.grabCircle.sourceFloorDataId, action.newPosition, action.grabCircle.startPoint).pipe(
        concatMap(data => {
          return [
            FloorDataActions.upsertFloorDataItems({svgGroups: data.svgGroup}),
            FloorDataActions.removeFloorDataItems({ids: data.removedFloorDataIds})
          ];
        })
      )),
    )
  );

  // https://www.petermorlion.com/iterating-a-typescript-enum/
  nextGripDetectionMode$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.nextGripDetectionMode),
      mergeMap(action => this.store.select(RendererSelectors.selectGripDetectionMode).pipe(
        first(),
        switchMap(mode => {
          //console.log(mode);
          const modes = [] as number[];
          for (const value in GripAlignmentTypeEnum) {
            if (!isNaN(Number(value))) {
              modes.push(Number(value));
            }
          }
          //console.log(modes);
          const currentModeIndex = modes.indexOf(mode);

          const nextMode = modes[(currentModeIndex + 1) % modes.length];
          //console.log(nextMode);
          return of(WallActions.setGripDetectionMode({mode: nextMode}));
        })
      )),
    )
  );

  showPartitioningFrame$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.showPartitioningFrame),
      switchMap(action =>
        this.store.select<Floor>(FloorSelectors.selectCurrentFloor).pipe(
          //first(),
          filter(currentFloor => currentFloor !== undefined),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.PartitioningFrame}-${currentFloor.taskId}`,
                changes: {
                  isVisible: true
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  hidePartitioningFrame$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.hidePartitioningFrame),
      switchMap(action =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          //first(),
          filter(currentFloor => currentFloor !== undefined),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.PartitioningFrame}-${currentFloor.taskId}`,
                changes: {
                  isVisible: false
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  showFloorCoreContours$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.showFloorCoreContours),
      switchMap(action =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          filter(currentFloor => currentFloor !== undefined),
          //first(),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.FloorCoreContours}-${currentFloor.taskId}`,
                changes: {
                  isVisible: true
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  hideFloorCoreContours$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.hideFloorCoreContours),
      switchMap(action =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          //first(),
          filter(currentFloor => currentFloor !== undefined),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.FloorCoreContours}-${currentFloor.taskId}`,
                changes: {
                  isVisible: false
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  showFloorInsideContours$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.showFloorInsideContours),
      switchMap(action =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          filter(currentFloor => currentFloor !== undefined),
          //first(),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.FloorInsideContours}-${currentFloor.taskId}`,
                changes: {
                  isVisible: true
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  hideFloorInsideContours$ = createEffect(() => this.actions$.pipe(
      ofType(WallActions.hideFloorInsideContours),
      switchMap(action =>
        this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          filter(currentFloor => currentFloor !== undefined),
          //first(),
          switchMap(currentFloor =>
            of(TaskFloorModelActions.updateTaskFloorModel({
              update: {
                id: `${FloorModelEnum.FloorInsideContours}-${currentFloor.taskId}`,
                changes: {
                  isVisible: false
                }
              } as Update<TaskFloorModel>
            }))
          )
        )),
    ),
  );

  updateWallStyle$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallStyle),
    switchMap(action => this.floorService.updateWallStyle(action.id, action.wallStyleId).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateWallsStyle$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallsStyle),
    switchMap(action => this.floorService.updateWallsStyle(action.ids, action.wallStyleId).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateWallWidth$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallWidth),
    switchMap(action => this.floorService.updateWallWidth(action.id, action.width).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateWallsWidth$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallsWidth),
    switchMap(action => this.floorService.updateWallsWidth(action.ids, action.width).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  updateWallSpaceBound$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallSpaceBound),
    switchMap(action => this.floorService.updateWallSpaceBound(action.id, action.spaceBound).pipe(
      map(svgGroup => FloorDataActions.upsertFloorData({
        svgGroup: svgGroup
      }))
    )),
  ));

  updateWallsSpaceBound$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.updateWallsSpaceBound),
    switchMap(action => this.floorService.updateWallsSpaceBound(action.ids, action.spaceBound).pipe(
      map(svgGroups => FloorDataActions.upsertFloorDataItems({
        svgGroups: svgGroups
      }))
    )),
  ));

  deleteWall$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.deleteWall),
    switchMap(action => this.floorService.deleteWall(action.id).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        //const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
        return [
          //...arr,
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
      // map(data => FloorDataActions.updateFloorDataItems({
      //   svgGroups: data.map(item => {
      //     return {
      //       id: item.floorDataId,
      //       changes: {
      //         dataStateId: item.dataStateId
      //       }
      //     } as Update<SvgGroup>;
      //   })
      // }))
    )),
  ));

  deleteWalls$ = createEffect(() => this.actions$.pipe(
    ofType(WallActions.deleteWalls),
    switchMap(action => this.floorService.deleteWalls(action.ids).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        //const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
        return [
          //...arr,
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
    )),
  ));

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
