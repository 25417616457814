import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FLOOR_DATA } from '../business/floor-data.model';
import { BUILDING } from '../business/building.model';
import { FLOOR } from '../business/floor.model';
import { Color, ColorUtils } from '../../core/utils/color';
import { ROOM_LAYOUT_TYPE } from '../business/room-layout-type.model';

export const FLOOR_LAYOUT_VIEW = {
  databaseTableName: 'FloorLayoutView',
  siteId: BUILDING.siteId,
  buildingId: FLOOR.buildingId,
  floorId: FLOOR.id,
  taskId: FLOOR_DATA.taskId,
  roomLayoutTypeId: ROOM_LAYOUT_TYPE.id,
  roomLayoutTypeCode: ROOM_LAYOUT_TYPE.code,
  roomLayoutTypeName: ROOM_LAYOUT_TYPE.name,
  roomLayoutTypeColor: ROOM_LAYOUT_TYPE.color,
  roomLayoutTypeIsNUA: ROOM_LAYOUT_TYPE.isNUA,
  roomLayoutTypeParentId: ROOM_LAYOUT_TYPE.parentId,
  area: 'FlLaVi_Area',
  roundedArea: 'FlLaVi_RoundedArea',
  layoutTypeTree: 'FlLaVi_LayoutTypeTree',
};

export class FloorLayoutView {
  constructor(
    public databaseTableName: string,
    public siteId: number,
    public buildingId: number,
    public floorId: number,
    public taskId: number,
    public roomLayoutTypeId: number,
    public roomLayoutTypeCode: string,
    public roomLayoutTypeName: string,
    public roomLayoutTypeColor: Color,
    public roomLayoutTypeIsNUA: number,
    public roomLayoutTypeParentId: number,
    public area: number,
    public roundedArea: number,
    public layoutTypeTree: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class FloorLayoutViewAdapter implements Adapter<FloorLayoutView> {
  adapt(item: any): FloorLayoutView {
    return new FloorLayoutView(
      FLOOR_LAYOUT_VIEW.databaseTableName,
      item[FLOOR_LAYOUT_VIEW.siteId],
      item[FLOOR_LAYOUT_VIEW.buildingId],
      item[FLOOR_LAYOUT_VIEW.floorId],
      item[FLOOR_LAYOUT_VIEW.taskId],
      item[FLOOR_LAYOUT_VIEW.roomLayoutTypeId],
      item[FLOOR_LAYOUT_VIEW.roomLayoutTypeCode],
      item[FLOOR_LAYOUT_VIEW.roomLayoutTypeName],
      ColorUtils.colorFromHexARGB(item[FLOOR_LAYOUT_VIEW.roomLayoutTypeColor]),
      item[FLOOR_LAYOUT_VIEW.roomLayoutTypeIsNUA],
      item[FLOOR_LAYOUT_VIEW.roomLayoutTypeParentId],
      item[FLOOR_LAYOUT_VIEW.area],
      item[FLOOR_LAYOUT_VIEW.roundedArea],
      item[FLOOR_LAYOUT_VIEW.layoutTypeTree],
    );
  }
}
