import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UploadDialogData } from '../../models/upload-dialog-data.model';

@Component({
  selector: 'echo-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {

  progress;
  canBeClosed = true;
  primaryButtonText = 'Téléverser';
  showCancelButton = true;
  uploadListEmpty = true;

  beginUpload = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<UploadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: UploadDialogData) {}

  successfulUpload(completeEvent: any) {
    this.dialogRef.close(completeEvent);
    this.dialogRef.disableClose = false;
    this.showCancelButton = true;
    this.canBeClosed = true;
  }

  startUpload() {
    this.showCancelButton = false;
    this.beginUpload.next(true);
    //
    // // start the upload and save the progress map
    // this.progress = this.uploadService.upload(this.files, this.dialogData.uploadUrl, this.dialogData?.fields, this.dialogData?.httpParams);
    //
    // // The OK-button should have the text "Finish" now
    // this.primaryButtonText = 'Terminé';
    //
    // // The upload-dialog should not be closed while uploading
    // this.canBeClosed = false;
    // this.dialogRef.disableClose = true;
    //
    // // Hide the cancel-button
    // this.showCancelButton = false;
    //
    // // When all progress-observables are completed...
    // forkJoin(allProgressObservables).subscribe(end => {
    //   // ... the upload-dialog can be closed again...
    //   this.canBeClosed = true;
    //   this.dialogRef.disableClose = false;
    //
    //   // ... the upload was successful...
    //   this.uploadSuccessful = true;
    //
    //   // ... and the component is no longer uploading
    //   this.uploading = false;
    //
    //   // ... close dialog when upload is complete
    //   if (this.dialogData.closeOnComplete) {
    //     this.successfulUpload();
    //   }
    // });
  }

  onFileAdded(count: number): void {
    // console.log(count);
    this.uploadListEmpty = count < 1;
  }

  // closeDialog() {
  //   // if everything was uploaded already, just close the upload-dialog
  //   if (this.uploadSuccessful) {
  //     return this.dialogRef.close();
  //   }
  //
  //   // set the component state to "uploading"
  //   this.uploading = true;
  //
  //   // start the upload and save the progress map
  //   this.progress = this.uploadService.upload(this.files, this.dialogData.uploadUrl, this.dialogData?.fields, this.dialogData?.httpParams);
  //
  //   // convert the progress map into an array
  //   const allProgressObservables = [];
  //   for (const key in this.progress) {
  //     allProgressObservables.push(this.progress[key].progress);
  //   }
  //
  //   // Adjust the state variables
  //
  //   // The OK-button should have the text "Finish" now
  //   this.primaryButtonText = 'Terminé';
  //
  //   // The upload-dialog should not be closed while uploading
  //   this.canBeClosed = false;
  //   this.dialogRef.disableClose = true;
  //
  //   // Hide the cancel-button
  //   this.showCancelButton = false;
  //
  //   // When all progress-observables are completed...
  //   forkJoin(allProgressObservables).subscribe(end => {
  //     // ... the upload-dialog can be closed again...
  //     this.canBeClosed = true;
  //     this.dialogRef.disableClose = false;
  //
  //     // ... the upload was successful...
  //     this.uploadSuccessful = true;
  //
  //     // ... and the component is no longer uploading
  //     this.uploading = false;
  //
  //     // ... close dialog when upload is complete
  //     if (this.dialogData.closeOnComplete) {
  //       this.closeDialog();
  //     }
  //   });
  // }

  onUploading() {
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;
  }
}
