import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'echo-loading-progress-bar',
  templateUrl: './loading-progress-bar.component.html',
  styleUrls: ['./loading-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingProgressBarComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  loading$: Observable<boolean>;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.httpProgress().pipe(
      takeUntil(this.unsubscribe$),
      delay(0), // fix Expression has changed after it was checked https://blog.angular-university.io/angular-debugging/
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
