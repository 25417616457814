import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const EQUIPMENT_PLANNING = {
  databaseTableName: 'EquipmentPlanning',
  floorDataId: 'EqPl_FloorDataId',
  equipmentItemId: 'EqPl_EquipmentItemId',
  planningStateId: 'EqPl_PlanningStateId',
  moveCaseTwinId: 'EqPl_MoveCaseTwinId',
  purchaseCasePurchaseId: 'EqPl_PurchaseCasePurchaseId',
  removeCaseDeletionCaseId: 'EqPl_RemoveCaseDeletionCaseId',
  storageCaseStorageAreaId: 'EqPl_StoreCaseStorageAreaId'
};

export class EquipmentPlanning {
  constructor(
    public databaseTableName: string,
    public floorDataId: number,
    public equipmentItemId: number,
    public planningStateId: number,
    public moveCaseTwinId: number,
    public purchaseCasePurchaseId: number,
    public removeCaseDeletionCaseId: number,
    public storageCaseStorageAreaId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class EquipmentPlanningAdapter implements Adapter<EquipmentPlanning> {
  adapt(item: any): EquipmentPlanning {
    return new EquipmentPlanning(
      EQUIPMENT_PLANNING.databaseTableName,
      item[EQUIPMENT_PLANNING.floorDataId],
      item[EQUIPMENT_PLANNING.equipmentItemId],
      item[EQUIPMENT_PLANNING.planningStateId],
      item[EQUIPMENT_PLANNING.moveCaseTwinId],
      item[EQUIPMENT_PLANNING.purchaseCasePurchaseId],
      item[EQUIPMENT_PLANNING.removeCaseDeletionCaseId],
      item[EQUIPMENT_PLANNING.storageCaseStorageAreaId]
    );
  }

  encode(item: EquipmentPlanning | Partial<EquipmentPlanning>): ISingleBackendObj {
    const encoded = {
      TableName: EQUIPMENT_PLANNING.databaseTableName,
      [EQUIPMENT_PLANNING.floorDataId]: item.floorDataId,
      [EQUIPMENT_PLANNING.equipmentItemId]: item.equipmentItemId,
      [EQUIPMENT_PLANNING.planningStateId]: item.planningStateId,
      [EQUIPMENT_PLANNING.moveCaseTwinId]: item.moveCaseTwinId,
      [EQUIPMENT_PLANNING.purchaseCasePurchaseId]: item.purchaseCasePurchaseId,
      [EQUIPMENT_PLANNING.removeCaseDeletionCaseId]: item.removeCaseDeletionCaseId,
      [EQUIPMENT_PLANNING.storageCaseStorageAreaId]: item.storageCaseStorageAreaId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
