import { DIRECTORY, Directory } from '../business/directory.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

const CONTRACTOR_USER_ACCOUNT = {
  databaseTableName: 'TableName',
  hasAccount: 'HasAccount',
};

export class ApiContractorUserAccount extends Directory {
  constructor(
    databaseTableName: string,
    id: number,
    code: string,
    title: string,
    firstName: string,
    lastName: string,
    businessUnitId: number,
    occupancyRatio: number,
    isInternal: boolean,
    public hasAccount: boolean,
  ) {
    super(databaseTableName, id, code, title, firstName, lastName, businessUnitId, occupancyRatio, isInternal);
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiContractorUserAccountAdapter implements Adapter<ApiContractorUserAccount> {
  adapt(item: any): ApiContractorUserAccount {
    return new ApiContractorUserAccount(
      DIRECTORY.databaseTableName,
      item[DIRECTORY.id],
      item[DIRECTORY.code],
      item[DIRECTORY.title],
      item[DIRECTORY.firstName],
      item[DIRECTORY.lastName],
      item[DIRECTORY.businessUnitId],
      item[DIRECTORY.occupancyRatio],
      item[DIRECTORY.isInternal],
      item[CONTRACTOR_USER_ACCOUNT.hasAccount],
    );
  }
}
