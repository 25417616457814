import { Color, ColorUtils } from '../../core/utils/color';
import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const BUSINESS_UNIT = {
  databaseTableName: 'business_unit',
  id: 'BuUn_Id',
  code: 'BuUn_Code',
  name: 'BuUn_Name',
  depth: 'BuUn_Depth',
  parentId: 'BuUn_ParentId',
  color: 'BuUn_Color',
  isActive: 'BuUn_IsActive',
  isFictive: 'BuUn_IsFictive',
  vacancyRatio: 'BuUn_VacancyRatio',
  flexibilityRatio: 'BuUn_FlexibilityRatio',
  occupancyRatio: 'BuUn_OccupancyRatio',
  anatomyId: 'BuUn_AnatomyId',
  // canHaveChildren: 'BuUn_CanHaveChildren',
  readGrantId: 'BuUn_ReadGrantId'
};

export class BusinessUnit {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public name: string,
    public depth: number,
    public parentId: number,
    public color: Color,
    public isActive: boolean,
    public isFictive: boolean,
    public vacancyRatio: number,
    public flexibilityRatio: number,
    public occupancyRatio: number,
    public anatomyId: number,
    // public canHaveChildren: boolean,
    public readGrantId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class BusinessUnitAdapter implements Adapter<BusinessUnit> {
  adapt(item: any): BusinessUnit {
    return new BusinessUnit (
      BUSINESS_UNIT.databaseTableName,
      item[BUSINESS_UNIT.id],
      item[BUSINESS_UNIT.code],
      item[BUSINESS_UNIT.name],
      item[BUSINESS_UNIT.depth],
      item[BUSINESS_UNIT.parentId],
      ColorUtils.colorFromHexARGB(item[BUSINESS_UNIT.color]),
      item[BUSINESS_UNIT.isActive],
      item[BUSINESS_UNIT.isFictive],
      item[BUSINESS_UNIT.vacancyRatio],
      item[BUSINESS_UNIT.flexibilityRatio],
      item[BUSINESS_UNIT.occupancyRatio],
      item[BUSINESS_UNIT.anatomyId],
      // item[BUSINESS_UNIT.canHaveChildren],
      item[BUSINESS_UNIT.readGrantId]
    );
  }
  encode(item: BusinessUnit): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [BUSINESS_UNIT.id]: item.id,
      [BUSINESS_UNIT.code]: item.code,
      [BUSINESS_UNIT.name]: item.name,
      [BUSINESS_UNIT.depth]: item.depth,
      [BUSINESS_UNIT.parentId]: item.parentId,
      [BUSINESS_UNIT.color]: item.color.toHexArgb(),
      [BUSINESS_UNIT.isActive]: item.isActive,
      [BUSINESS_UNIT.isFictive]: item.isFictive,
      [BUSINESS_UNIT.vacancyRatio]: item.vacancyRatio,
      [BUSINESS_UNIT.flexibilityRatio]: item.flexibilityRatio,
      [BUSINESS_UNIT.occupancyRatio]: item.occupancyRatio,
      [BUSINESS_UNIT.anatomyId]: item.anatomyId,
      // [BUSINESS_UNIT.canHaveChildren]: item.canHaveChildren,
      [BUSINESS_UNIT.readGrantId]: item.readGrantId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
