import { createAction, props } from '@ngrx/store';
import { DisplayThemeMockup } from '../../../features/floor/models/display-theme-mockup.model';
import { ThemeItemMockup } from '../../../features/floor/models/theme-item-mockup.model';
import { Update } from '@ngrx/entity';
import { DisplayThemeCategory } from '../../../domain-models/business/display-theme-category.model';

export const addDisplayThemes = createAction(
  '[API] - Add DisplayTheme',
  props<{themes: DisplayThemeMockup[]}>()
);

export const addDisplayThemeCategories = createAction(
  '[API] - Add DisplayTheme Categories',
  props<{themeCategories: DisplayThemeCategory[]}>()
);

export const updateDisplayThemeId = createAction(
  '[API] - Select DisplayTheme Id',
  props<{id: number}>()
);

export const updateDisplayThemeItem = createAction(
  '[API] - Update DisplayThemes',
  props<{displayThemeItem: Update<DisplayThemeMockup>}>()
);

export const updateDisplayThemeItems = createAction(
  '[API] - Update DisplayThemeItems',
  props<{displayThemeItems: Update<DisplayThemeMockup>[]}>()
);

export const addDisplayThemeItems = createAction(
  '[API] - Add DisplayThemeItems',
  props<{ displayThemeItems: ThemeItemMockup[] }>()
);

export const buildDisplayThemes = createAction(
  '[API] - Build DisplayThemes'
);

export const buildDisplayThemesSuccess = createAction(
  '[API] - Succeed Build DisplayThemes'
);

export const setDisplayThemeLegendContext = createAction(
  '[API] - Set display theme legend context',
  props<{ id: number }>()
);

export const applyThemeItem = createAction(
  '[FloorLegendComponent] - Apply theme item',
  props<{ resId: string }>()
);

export const setHoveredThemeItemId = createAction(
  '[API] - Set hovered theme item id',
  props<{ id: string }>()
);

export const isolateThemeItem = createAction(
  '[API] - Isolate selected ThemeItem',
  props<{id: string}>()
);

export const showAllThemeItems = createAction(
  '[API] - Show all current DisplayTheme theme items'
);
