import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as RoomLayoutTypeActions from '../actions/room-layout-type.actions';
import { RoomLayoutType } from '../../../domain-models/business/room-layout-type.model';

export const roomLayoutTypesFeatureKey = 'roomLayoutTypes';

export interface State extends EntityState<RoomLayoutType> {
  allRoomLayoutTypesLoaded: boolean;
}

export function sortByName(a: RoomLayoutType, b: RoomLayoutType): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<RoomLayoutType> = createEntityAdapter<RoomLayoutType>({
  sortComparer: sortByName
});

export const initialState: State = adapter.getInitialState({
  allRoomLayoutTypesLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(RoomLayoutTypeActions.successGetAllRoomLayoutTypes, (state, {roomLayoutTypes}) => {
    state = {...state, allRoomLayoutTypesLoaded: true};
    return adapter.addMany(roomLayoutTypes, state);
  }),
  on(RoomLayoutTypeActions.addRoomLayoutType, (state, roomLayoutType) => {
    return adapter.addOne(roomLayoutType, state);
  })
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoomLayoutTypeIds = selectIds;
export const selectRoomLayoutTypeEntities = selectEntities;
export const selectAllRoomLayoutTypes = selectAll;
export const selectRoomLayoutTypeTotal = selectTotal;
