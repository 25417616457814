import { createAction, props } from '@ngrx/store';
import { Room } from '../../../domain-models/business/room.model';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { RoomAllocationPartial } from '../../../features/floor/models/room-allocation-partial.model';

export const beginGetRoomsByTaskId = createAction(
  '[Room/API] - Begin All Rooms By taskId',
  props<{ taskId: number }>()
);

/**
 * CRUD Actions
 */

export const loadRooms = createAction('[Room/API] Load Rooms', props<{ rooms: Room[] }>());
export const addRoom = createAction('[Room/API] Add Room', props<{ room: Room }>());
export const setRoom = createAction('[Room/API] Set Room', props<{ room: Room }>());
export const upsertRoom = createAction('[Room/API] Upsert Room', props<{ room: Room }>());
export const addRooms = createAction('[Room/API] Add Rooms', props<{ rooms: Room[] }>());
export const upsertRooms = createAction('[Room/API] Upsert Rooms', props<{ rooms: Room[] }>());
export const updateRoom = createAction('[Room/API] Update Room', props<{ update: Update<Room> }>());
export const updateRooms = createAction('[Room/API] Update Rooms', props<{ updates: Update<Room>[] }>());
export const mapRooms = createAction('[Room/API] Map Rooms', props<{ entityMap: EntityMap<Room> }>());
export const deleteRoom = createAction('[Room/API] Delete Room', props<{ id: string }>());
export const deleteRooms = createAction('[Room/API] Delete Rooms', props<{ ids: string[] }>());
export const deleteRoomsByPredicate = createAction('[Room/API] Delete Rooms By Predicate', props<{ predicate: Predicate<Room> }>());
export const clearRooms = createAction('[Room/API] Clear Rooms');

/** END of CRUD **/

/**
 * Custom API related actions
 */

export const updateRoomLabel = createAction(
  '[API/Room] - Update room label',
  props<{ id: number, code: string }>(),
);

export const updateRoomLayoutType = createAction(
  '[API/Room] - Update room layout type',
  props<{ id: number, roomLayoutTypeId: number }>(),
);

export const updateRoomsLayoutType = createAction(
  '[API/Room] - Update rooms layout type',
  props<{ ids: number[], roomLayoutTypeId: number }>(),
);

export const discardRoom = createAction(
  '[API/Room] - Discard room',
  props<{ id: number, activityStatusTypeId: number }>(),
);

export const discardRooms = createAction(
  '[API/Room] - Discard rooms',
  props<{ ids: number[], activityStatusTypeId: number }>(),
);

export const wipeRoom = createAction(
  '[API/Room] - Wipe room',
  props<{ id: number }>(),
);

export const wipeRooms = createAction(
  '[API/Room] - Wipe rooms',
  props<{ ids: number[] }>(),
);

export const deleteRoomFromSvg = createAction(
  '[Floor/FloorRoomForm] - Delete room',
  props<{ id: number }>(),
);

export const deleteRoomsFromSvg = createAction(
  '[Floor/FloorRoomForm] - Delete rooms',
  props<{ ids: number[] }>(),
);

export const updateRoomSharing = createAction( // NOT A SPECIFIC ROOM ACTION, WRONG PLACE, RELATED TO ROOMSHARING but store doesn't exists yet
  '[API/Room] - Update room sharing',
  props<{ id: number, perimeterId: number }>(),
);

export const updateRoomsSharing = createAction( // NOT A SPECIFIC ROOM ACTION, WRONG PLACE, RELATED TO ROOMSHARING but store doesn't exists yet
  '[API/Room] - Update rooms sharing',
  props<{ ids: number[], perimeterId: number }>(),
);

export const updateRoomAllocations = createAction( // NOT A SPECIFIC ROOM ACTION, WRONG PLACE, RELATED TO ROOMSHARING but store doesn't exists yet
  '[API/Room] - Update room allocations',
  props<{ id: number, roomAllocations: RoomAllocationPartial[] }>(),
);

export const updateRoomsAllocations = createAction( // NOT A SPECIFIC ROOM ACTION, WRONG PLACE, RELATED TO ROOMSHARING but store doesn't exists yet
  '[API/Room] - Update rooms allocations',
  props<{ ids: number[], roomAllocations: RoomAllocationPartial[] }>(),
);

export const beginGetCurrentFloorTaskRooms = createAction('[API] - Get Current Floor Rooms by taskId');
export const beginGetFloorTaskRoomsByTaskId = createAction('[API] - Get Rooms by taskId', props<{ taskId: number }>());

export const errorGetAllRooms = createAction('[API] - Error Get All Floors', props<Error>());

/**
 * Selection related
 */

export const updateSelectedRoomId = createAction(
  '[Room/API] - Save Selected Room Id',
  props<{ id: number }>()
);

export const addToSelection = createAction(
  '[Room] - Add Room Id to selection',
  props<{ id: number, clearSelection?: boolean }>()
);

export const removeFromSelection = createAction(
  '[Room] - Remove Room Id to selection',
  props<{ id: number }>()
);

export const updateSelection = createAction(
  '[Room] - Update Room Selection Ids',
  props<{ addedId: number, removedId: number }>()
);

export const clearSelection = createAction('[Room/API] - Clear Selected Room Id');
