import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { EquipmentPlanning } from '../../../domain-models/business/equipment-planning.model';
import { EquipmentReconcileDialogData } from '../../../features/floor/models/equipment-reconcile-dialog-data.model';

/**
 * CRUD Actions
 */

export const loadEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Load EquipmentPlanningItems', props<{ equipmentPlanningItems: EquipmentPlanning[] }>());
export const addEquipmentPlanning = createAction('[EquipmentPlanning/API] Add EquipmentPlanning', props<{ equipmentPlanning: EquipmentPlanning }>());
export const setEquipmentPlanning = createAction('[EquipmentPlanning/API] Set EquipmentPlanning', props<{ equipmentPlanning: EquipmentPlanning }>());
export const upsertEquipmentPlanning = createAction('[EquipmentPlanning/API] Upsert EquipmentPlanning', props<{ equipmentPlanning: EquipmentPlanning }>());
export const addEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Add EquipmentPlanningItems', props<{ equipmentPlanningItems: EquipmentPlanning[] }>());
export const upsertEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Upsert EquipmentPlanningItems', props<{ equipmentPlanningItems: EquipmentPlanning[] }>());
export const updateEquipmentPlanning = createAction('[EquipmentPlanning/API] Update EquipmentPlanning', props<{ update: Update<EquipmentPlanning> }>());
export const updateEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Update EquipmentPlanningItems', props<{ updates: Update<EquipmentPlanning>[] }>());
export const mapEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Map EquipmentPlanningItems', props<{ entityMap: EntityMap<EquipmentPlanning> }>());
export const deleteEquipmentPlanning = createAction('[EquipmentPlanning/API] Delete EquipmentPlanning', props<{ id: string }>());
export const deleteEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Delete EquipmentPlanningItems', props<{ ids: string[] }>());
export const deleteEquipmentPlanningItemsByPredicate = createAction('[EquipmentPlanning/API] Delete EquipmentPlanningItems By Predicate', props<{ predicate: Predicate<EquipmentPlanning> }>());
export const clearEquipmentPlanningItems = createAction('[EquipmentPlanning/API] Clear EquipmentPlanningItems');

/** END of CRUD **/

export const beginGetEquipmentPlanningItemsByTaskId = createAction(
  '[API] - Begin All EquipmentPlanningItems By taskId',
  props<{ id: number }>()
);

export const updateEquipmentPlanningState = createAction(
  '[API] - SvgUse Update Equipment Planning State',
  props<{dialogResult: EquipmentReconcileDialogData}>()
);

export const beginGetCurrentFloorEquipmentPlanningItems = createAction('[API] - Get Current Floor EquipmentPlanningItems');

export const errorGetAllEquipmentPlanningItems = createAction('[API] - Error Get All EquipmentPlanningItems', props<Error>());
