import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum DisplayThemableEnum {
  LayoutType = 1,
  Allocated = 2,
  MultiAllocated = 3,
  NotAllocated = 4,
  Shared = 5,
  Exploitation = 6,
  Used = 7,
  UnderConstruction = 8,
  Empty = 9,
  None,
  StandingBy,
  Identified,
  Unchanged,
  ProposedAdded,
  ProposedDeleted
}

export const DISPLAY_THEMABLE = {
  databaseTableName: 'DisplayThemable',
  id: 'DiTb_Id',
  name: 'DiTb_Name',
  displayNameId: 'DiTb_DisplayNameId',
  color: 'DiTb_Color',
  svgPatternId: 'DiTb_SvgPatternId',
};

export class DisplayThemable {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color | null,
    public svgPatternId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DisplayThemableAdapter implements Adapter<DisplayThemable> {
  adapt(item: any): DisplayThemable {
    return new DisplayThemable(
      DISPLAY_THEMABLE.databaseTableName,
      item[DISPLAY_THEMABLE.id],
      item[DISPLAY_THEMABLE.name],
      item[DISPLAY_THEMABLE.displayNameId],
      ColorUtils.colorFromHexARGB(item[DISPLAY_THEMABLE.color]),
      item[DISPLAY_THEMABLE.svgPatternId]
    );
  }

  encode(item: DisplayThemable): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DISPLAY_THEMABLE.id]: item.id,
      [DISPLAY_THEMABLE.name]: item.name,
      [DISPLAY_THEMABLE.displayNameId]: item.displayNameId,
      [DISPLAY_THEMABLE.color]: item.color.toHexRgb(),
      [DISPLAY_THEMABLE.svgPatternId]: item.svgPatternId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
