import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromShared from '../shared-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { SharedEffects } from './effects/shared.effects';
import { DisplayThemeItemEffects } from './effects/display-theme-item.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromShared.sharedFeatureKey, fromShared.reducers),
    EffectsModule.forFeature([SharedEffects, DisplayThemeItemEffects]),
  ]
})
export class SharedStoreModule { }
