import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(array: T[], key: string, condition: any): T[] {
    return array.filter(e => e[key] === condition);
  }

}
