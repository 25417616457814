import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export const COUNTRY = {
  databaseTableName: 'Country',
  id: 'Cn_Id',
  isoCode: 'Cn_IsoCode',
  name: 'Cn_Name',
  displayNameId: 'Cn_DisplayNameId',
  continentId: 'Cn_ContinentId'
};

export class Country {
  constructor(
    public databaseTableName: string,
    public id: number,
    public isoCode: string,
    public name: string,
    public displayNameId: number,
    public continentId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class CountryAdapter implements Adapter<Country> {
  adapt(item: any): Country {
    return new Country(
      COUNTRY.databaseTableName,
      item[COUNTRY.id],
      item[COUNTRY.isoCode],
      item[COUNTRY.name],
      item[COUNTRY.displayNameId],
      item[COUNTRY.continentId]
    );
  }
}
