import { Injectable } from '@angular/core';
import { Contributor } from '../models/contributor.model';
import { GanttTask } from '../models/gantt-task.model';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { map } from 'rxjs/operators';
import { ApiData } from '../../../core/models/api-data.model';
import { GanttTaskLink } from '../models/gantt-task-link.model';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Department } from '../models/department.model';
import { ProjectManagementService } from './project-management.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GanttService {

  config = {
    defaultZoomLevel: new BehaviorSubject<number>(1)
  };

  //tasks$ = new ReplaySubject<GanttTask[]>(1);
  ganttTasks$: Observable<GanttTask[]>;
  ganttTasks: GanttTask[];
  links$ = new ReplaySubject<GanttTaskLink[]>(1);
  contributors$ = new ReplaySubject<Contributor[]>(1);
  departments$ = new ReplaySubject<Department[]>(1);

  public groupByResources$ = new Subject<boolean>();
  public zoomLevel$ = new Subject<number>();

  constructor(private projectManagementService: ProjectManagementService) {
  }

  get(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {

      const params = new HttpParams()
        .set('isActive', 'true');

      this.projectManagementService.get(API_ENDPOINTS.tasks, null, params)
        .pipe(
          map((data: ApiData) => {
            console.log(data);

            const tasks: GanttTask[] = [];
            const links: GanttTaskLink[] = [];
            const contributors: Contributor[] = [];
            const departments: Department[] = [];

            this.links$.next(links);

            // data.payload['task_contributor_directory_view'].forEach(contributor => {
            //   const tmpContributor = {} as Contributor;
            //   tmpContributor.key = contributor[TASK_CONTRIBUTOR_DIRECTORY.id];
            //   tmpContributor.label = contributor[TASK_CONTRIBUTOR_DIRECTORY.firstName] + ' ' + contributor[TASK_CONTRIBUTOR_DIRECTORY.lastName];
            //   tmpContributor.departmentId = contributor[DIRECTORY.businessUnitId];
            //   contributors.push(tmpContributor);
            // });
            this.contributors$.next(contributors);

            // data.payload['business_unit'].forEach((department, index) => {
            //   const tmpDepartment = {} as Department;
            //   tmpDepartment.key = department[BUSINESS_UNIT.id];
            //   tmpDepartment.label = department[BUSINESS_UNIT.name];
            //   departments.push(tmpDepartment);
            // });
            this.departments$.next(departments);

            resolve(true);
          }),
        )
        .subscribe();
    });
    return promise;
  }

}
