import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../floor-store/reducers';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { exhaustMap, map } from 'rxjs/operators';
import { TaskStatusActions } from '../actions';


@Injectable()
export class TaskStatusEffects {

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   )
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskTypes = createEffect(() => this.actions$.pipe(
    ofType(TaskStatusActions.beginGetAllTaskStatusItems),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskStatusItems().pipe(
        map(taskStatus =>
          TaskStatusActions.addTaskStatusItems({taskStatusItems: taskStatus })
        ),
    )
  )));
}
