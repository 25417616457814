import { createAction, props } from '@ngrx/store';

export const clearSvgState = createAction('[Building] - Clear SVG State');

export const beginGetGraphicsBuildingFromRouterState = createAction(
  '[Building/API] - Begin Get Graphic Building By Id From Router State',
);
export const beginGetGraphicsBuildingById = createAction(
  '[Building/API] - Begin Get Graphic Building By Bu_Id',
  props<{ id: number }>()
);

export const beginGetGraphicsBuildingFloorByTaskId = createAction(
  '[Building/API] - Begin Get Graphic Building Floor By Ta_Id',
  props<{ id: number }>()
);

export const successGetGraphicsBuildingFloorByTaskId = createAction('[Building/API] - Success Get Graphic Building By Bu_Id', props<{ taskId: number }>());

export const successGetGraphicsBuildingById = createAction('[Building/API] - Success Get Graphic Building By Bu_Id');
export const errorGetGraphicsBuildingById = createAction('[API] - Error Get Graphic Building By Bu_Id', props<Error>());
