import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, ROOT_REDUCERS } from './reducers';
import { RootEffects } from './effects/root.effects';

registerLocaleData(localeFr, 'fr-FR');
// registerLocaleData(localeEn, 'en');

// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     'rotate': {
//       direction: Hammer.DIRECTION_ALL,
//       enable: true
//     }, // override default settings
//     'pinch': {
//       direction: Hammer.DIRECTION_ALL,
//       enable: true,
//       recognizeWidth: 'rotate'
//     },
//     'pan': {
//       direction: Hammer.DIRECTION_ALL,
//       requireFailure: ['rotate, pinch']
//     }
//   };
// }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    CoreModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),

    // /**
    //  * @ngrx/router-store-store keeps router-store state up-to-date in the store.
    //  */
    // StoreRouterConnectingModule.forRoot({
    //   routerState: RouterState.Minimal,
    // }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'ecsy App',

      // In a production build you would want to disable the Store Devtools
      maxAge: 50, // Retains last 5 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([RootEffects]),
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    //{provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
  ],
})
export class AppModule {
}
