import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FACILITY_COMPANY_TASK } from '../business/facility-company-task.model';
import { FACILITY_COMPANY } from '../business/facility-company.model';
import { FACILITY_JOB_TASK } from '../business/facility-job-task.model';
import { UNIT_OF_WORK } from '../business/unit-of-work.model';
import { FACILITY_JOB } from '../business/facility-job.model';

export const FACILITY_COMPANY_TASK_VIEW = {
  databaseTableName: 'facility_company_task_dynview',
  facilityCompanyTaskCompanyId: FACILITY_COMPANY_TASK.company,
  facilityCompanyTaskId: FACILITY_COMPANY_TASK.id,
  facilityCompanyTaskIsActive: FACILITY_COMPANY_TASK.isActive,
  facilityCompanyTaskJobTask: FACILITY_COMPANY_TASK.jobTask,
  facilityCompanyTaskUnitPrice: FACILITY_COMPANY_TASK.unitPrice,
  facilityCompanyName: FACILITY_COMPANY.name,
  facilityJobId: FACILITY_JOB.id,
  facilityJobTaskId: FACILITY_JOB_TASK.id,
  facilityJobTaskName: FACILITY_JOB_TASK.name,
  unitOfWorkName: UNIT_OF_WORK.name,
};

export class FacilityCompanyTaskView {
  constructor(
    public databaseTableName: string,
    public facilityCompanyTaskCompanyId: number,
    public facilityCompanyTaskId: number,
    public facilityCompanyTaskIsActive: boolean,
    public facilityCompanyTaskJobTask: number,
    public facilityCompanyTaskUnitPrice: number,
    public facilityCompanyName: string,
    public facilityJobId: number,
    public facilityJobTaskId: number,
    public facilityJobTaskName: string,
    public unitOfWorkName: string) {
  }

}

@Injectable({
  providedIn: 'root'
})

export class FacilityCompanyTaskViewAdapter implements Adapter<FacilityCompanyTaskView> {
  adapt(item: any): FacilityCompanyTaskView {
    return new FacilityCompanyTaskView(
      FACILITY_COMPANY_TASK_VIEW.databaseTableName,
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyTaskCompanyId],
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyTaskId],
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyTaskIsActive],
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyTaskJobTask],
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyTaskUnitPrice],
      item[FACILITY_COMPANY_TASK_VIEW.facilityCompanyName],
      item[FACILITY_COMPANY_TASK_VIEW.facilityJobId],
      item[FACILITY_COMPANY_TASK_VIEW.facilityJobTaskId],
      item[FACILITY_COMPANY_TASK_VIEW.facilityJobTaskName],
      item[FACILITY_COMPANY_TASK_VIEW.unitOfWorkName],
    );
  }
}
