import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { CoreRoutingModule } from './core-routing.module';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { authModuleConfig } from './core-module.config';
import { UserModule } from '../features/user/user.module';
import { SharedModule } from '../shared/shared.module';
import { EchoCompanyInterceptor } from './http-interceptors/echo-company.interceptor';
import { ClientsComponent } from './components/clients/clients.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { FloorStoreModule } from '../store/floor-store/floor-store.module';
import { SvgStoreModule } from '../store/svg-store/svg-store.module';
import { SharedStoreModule } from '../store/shared-store/shared-store.module';
import { authConfig } from './auth.config';
import { ProjectManagementStoreModule } from '../store/project-management/project-management-store.module';
import { BuildingStoreModule } from '../store/building-store/building-store.module';
import { CoreStoreModule } from '../store/core-store/core-store.module';
import { LoaderInterceptor } from './http-interceptors/loader.interceptor';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { ErrorInterceptor } from './http-interceptors/error.interceptor';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { getSaver, SAVER } from './saver.provider';
import { CallerRequestIdInterceptor } from './http-interceptors/caller-request-id.interceptor';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     'swipe': {direction: Hammer.DIRECTION_ALL}, // override default settings
//     'pan': {direction: Hammer.DIRECTION_ALL}
//   };
// }


@NgModule({
  declarations: [
    NotFoundComponent,
    HeaderComponent,
    LayoutComponent,
    ClientsComponent,
    MobileMenuComponent,
    LoadingProgressBarComponent,
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule, // Only import once otherwise HttpInterceptors won't work
    CoreRoutingModule,
    OAuthModule.forRoot(),
    CoreStoreModule,
    BuildingStoreModule,
    FloorStoreModule,
    SvgStoreModule,
    SharedStoreModule,
    ProjectManagementStoreModule,
    SharedModule,
    UserModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: EchoCompanyInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CallerRequestIdInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: SAVER, useFactory: getSaver},
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: AuthConfig, useValue: authConfig},
        {provide: OAuthModuleConfig, useValue: authModuleConfig},
        {provide: OAuthStorage, useFactory: storageFactory},
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
