import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ROOM_LAYOUT_TYPE, RoomLayoutType, RoomLayoutTypeAdapter } from '../../domain-models/business/room-layout-type.model';
import { Observable } from 'rxjs';
import { API_ENDPOINTS, API_URL } from '../api-endpoints';
import { map, publishReplay, refCount, shareReplay } from 'rxjs/operators';
import { ApiData } from '../../core/models/api-data.model';
import {
  ROOM_ACTIVITY_STATUS_TYPE,
  RoomActivityStatusType,
  RoomActivityStatusTypeAdapter
} from '../../domain-models/business/room-activity-status-type.model';
import {
  ROOM_ATTRIBUTION_TYPE,
  RoomAttributionType,
  RoomAttributionTypeAdapter
} from '../../domain-models/business/room-attribution-type.model';
import { PERIMETER, Perimeter, PerimeterAdapter } from '../../domain-models/business/perimeter.model';
import { BUSINESS_UNIT, BusinessUnit, BusinessUnitAdapter } from '../../domain-models/business/business-unit.model';
import { LanguageItem, LanguageItemAdapter } from '../../domain-models/core/z-language-item.model';
import { DbColumn, DbColumnAdapter } from '../../domain-models/core/z-db-column.model';
import { FLOOR_MODEL, FloorModel, FloorModelAdapter } from '../../domain-models/business/floor-model.model';
import { FLOOR_CATALOG, FloorCatalog, FloorCatalogAdapter } from '../../domain-models/business/floor-catalog.model';
import { FLOOR_DOOR_STYLE, FloorDoorStyle, FloorDoorStyleAdapter } from '../../domain-models/business/floor-door-style.model';
import { FLOOR_WALL_STYLE, FloorWallStyle, FloorWallStyleAdapter } from '../../domain-models/business/floor-wall-style.model';
import { LoggerService } from '../../core/services/logger.service';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { LoaderService } from '../../core/services/loader.service';
import { WORKPLACE_TYPE, WorkplaceType, WorkplaceTypeAdapter } from '../../domain-models/business/workplace-type.model';
import {
  EQUIPMENT_PLANNING_STATE,
  EquipmentPlanningState,
  EquipmentPlanningStateAdapter
} from '../../domain-models/business/equipment-planning-state.model';
import { FLOOR_DATA_STATE, FloorDataState, FloorDataStateAdapter } from '../../domain-models/business/floor-data-state.model';
import { ApiAppUserCreate } from '../../domain-models/endpoint-specific/appusers.create.model';
import { Site, SITE, SiteAdapter } from '../../domain-models/business/site.model';
import { ApiContractor } from '../../domain-models/endpoint-specific/contractors.model';
import {
  ApiContractorGrantRequest,
  ApiContractorGrantRequestAdapter
} from '../../domain-models/endpoint-specific/contractors-grant-request.model';
import { UsageContextIdEnum } from '../models/usage-context-id.enum';
import { DIRECTORY, Directory, DirectoryAdapter } from '../../domain-models/business/directory.model';
import {
  ApiContractorUserAccount,
  ApiContractorUserAccountAdapter
} from '../../domain-models/endpoint-specific/contractor-user-account.model';
import { CONTINENT, Continent, ContinentAdapter } from '../../domain-models/business/continent.model';
import { COUNTRY, Country, CountryAdapter } from '../../domain-models/business/country.model';
import { CITY, City, CityAdapter } from '../../domain-models/business/city.model';
import { PERIMETER_DETAIL, PerimeterDetailAdapter } from '../../domain-models/business/perimeter-detail.model';
import {
  PERIMETER_DETAIL_VIEW,
  PerimeterDetailView,
  PerimeterDetailViewAdapter
} from '../../domain-models/views/perimeter-detail-view.model';
import { FACILITY_JOB, FacilityJob, FacilityJobAdapter } from '../../domain-models/business/facility-job.model';
import { FACILITY_JOB_TASK, FacilityJobTask, FacilityJobTaskAdapter } from '../../domain-models/business/facility-job-task.model';
import {
  FACILITY_COMPANY_TASK_VIEW,
  FacilityCompanyTaskView,
  FacilityCompanyTaskViewAdapter
} from '../../domain-models/views/facility-company-task-view.model';
import { GrantedTableView, GrantedTableViewAdapter, Z_GRANTED_TABLE_VIEW } from '../../domain-models/views/z-granted-table-view.model';
import { GrantedColumnView, GrantedColumnViewAdapter, Z_GRANTED_COLUMN_VIEW } from '../../domain-models/views/z-granted-column-view.model';
import { DbTable, DbTableAdapter, Z_DB_TABLE } from '../../domain-models/core/z-db-table.model';
import { ApiContractorUserAccountsDEPRECATED } from '../../domain-models/endpoint-specific/contractors-user-accounts.model';
import { ClientContractor } from '../../features/account/models/client-contractor.interface';
import { EcsyFileGrantRequest } from '../../domain-models/models/ecsy-file-grant-request.model';
import { EcSyFile } from '../../domain-models/models/EcSyFile.model';
import { APP_GRANT, AppGrant, AppGrantAdapter } from '../../domain-models/business/app-grant.model';
import { Dictionary } from '../../core/models/dictionary.model';
import {
  EQUIPMENT_STORAGE_AREA,
  EquipmentStorageArea,
  EquipmentStorageAreaAdapter
} from '../../domain-models/business/equipment-storage-area.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends ApiService {

  equipmentPlanningStates$: Observable<EquipmentPlanningState[]>;
  workplaceTypes$: Observable<WorkplaceType[]>;
  floorDataStates$: Observable<FloorDataState[]>;

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private roomLayoutTypeAdapter: RoomLayoutTypeAdapter,
              private roomActivityStatusTypeAdapter: RoomActivityStatusTypeAdapter,
              private roomAttributionTypeAdapter: RoomAttributionTypeAdapter,
              private perimeterAdapter: PerimeterAdapter,
              private businessUnitAdapter: BusinessUnitAdapter,
              private languageItemAdapter: LanguageItemAdapter,
              private dbColumnAdapter: DbColumnAdapter,
              private floorModelAdapter: FloorModelAdapter,
              private floorCatalogAdapter: FloorCatalogAdapter,
              private floorDoorStyleAdapter: FloorDoorStyleAdapter,
              private workplaceTypeAdapter: WorkplaceTypeAdapter,
              private floorDataStateAdapter: FloorDataStateAdapter,
              private equipmentPlanningStateAdapter: EquipmentPlanningStateAdapter,
              private floorWallStyleAdapter: FloorWallStyleAdapter,
              private siteAdapter: SiteAdapter,
              private apiContractorGrantRequestAdapter: ApiContractorGrantRequestAdapter,
              private apiContractorUserAccountAdapter: ApiContractorUserAccountAdapter,
              private continentAdapter: ContinentAdapter,
              private countryAdapter: CountryAdapter,
              private cityAdapter: CityAdapter,
              private perimeterDetail: PerimeterDetailAdapter,
              private perimeterDetailViewAdapter: PerimeterDetailViewAdapter,
              private directoryAdapter: DirectoryAdapter,
              private facilityJobAdapter: FacilityJobAdapter,
              private facilityJobTaskAdapter: FacilityJobTaskAdapter,
              private facilityCompanyTaskViewAdapter: FacilityCompanyTaskViewAdapter,
              private grantedTableViewAdapter: GrantedTableViewAdapter,
              private grantedColumnViewAdapter: GrantedColumnViewAdapter,
              private dbTableAdapter: DbTableAdapter,
              private appGrantAdapter: AppGrantAdapter,
              private equipmentStorageAreaAdapter: EquipmentStorageAreaAdapter,
  ) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  clearCache() {
    /**https://indepth.dev/posts/1248/fastest-way-to-cache-for-lazy-developers-angular-with-rxjs**/
    this.equipmentPlanningStates$ = null;
    this.workplaceTypes$ = null;
    this.floorDataStates$ = null;
  }

  getDirectory(): Observable<Directory[]> {
    return this.get(`${API_ENDPOINTS.dynT}${DIRECTORY.databaseTableName}`).pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.directoryAdapter.adapt(item));
      }),
      shareReplay(1), // https://dev.to/angular/how-to-cache-http-requests-in-angular-5c8i
      /** WARNING share replay avoids multiple HTTP Calls, therefore it may be not up to date**/
    );
  }

  getAllFloorDataStates(): Observable<FloorDataState[]> {
    if (!this.floorDataStates$) {
      this.floorDataStates$ = this.get(`${API_ENDPOINTS.dynT}/${FLOOR_DATA_STATE.databaseTableName}`).pipe(
        map(data => data.payload.map(item => this.floorDataStateAdapter.adapt(item))),
        publishReplay(1),
        refCount(),
      );
    }
    return this.floorDataStates$;
  }

  getAllWorkplaceTypes(): Observable<WorkplaceType[]> {
    if (!this.workplaceTypes$) {
      this.workplaceTypes$ = this.get(`${API_ENDPOINTS.dynT}/${WORKPLACE_TYPE.databaseTableName}`).pipe(
        map(data => data.payload.map(item => this.workplaceTypeAdapter.adapt(item))),
        publishReplay(1),
        refCount(),
      );
    }
    return this.workplaceTypes$;
  }

  getAllEquipmentPlanningStates(): Observable<EquipmentPlanningState[]> {
    // Cache it once if configs value is false
    if (!this.equipmentPlanningStates$) {
      this.equipmentPlanningStates$ = this.get(`${API_ENDPOINTS.dynT}/${EQUIPMENT_PLANNING_STATE.databaseTableName}`).pipe(
        map(data => data.payload.map(item => this.equipmentPlanningStateAdapter.adapt(item))),
        publishReplay(1),
        refCount(),
      );
    }

    return this.equipmentPlanningStates$;
    /*    return this.get(`${API_ENDPOINTS.dynT}/${EQUIPMENT_PLANNING_STATE.databaseTableName}`).pipe(
          map(data => data.payload.map(item => this.equipmentPlanningStateAdapter.adapt(item)))
        );*/
  }

  getAllRoomLayoutTypes(): Observable<RoomLayoutType[]> {
    return this.get(API_ENDPOINTS.dynT + `RoomLayoutType`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomLayoutTypeAdapter.adapt(item));
        })
      );
  }

  getAllRoomLayoutTypesLastItems(): Observable<RoomLayoutType[]> {
    return this.get(`${API_ENDPOINTS.dynH}${ROOM_LAYOUT_TYPE.databaseTableName}/lastitems`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomLayoutTypeAdapter.adapt(item));
        })
      );
  }

  getAllRoomActivityStatusTypes(): Observable<RoomActivityStatusType[]> {
    return this.get(`${API_ENDPOINTS.dynT}${ROOM_ACTIVITY_STATUS_TYPE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomActivityStatusTypeAdapter.adapt(item));
        })
      );
  }

  getAllFloorDoorStyles(): Observable<FloorDoorStyle[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_DOOR_STYLE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorDoorStyleAdapter.adapt(item));
        })
      );
  }

  getAllFloorWallStyles(): Observable<FloorWallStyle[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_WALL_STYLE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorWallStyleAdapter.adapt(item));
        })
      );
  }

  getAllRoomAttributionTypes(): Observable<RoomAttributionType[]> {
    return this.get(`${API_ENDPOINTS.dynT}${ROOM_ATTRIBUTION_TYPE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAttributionTypeAdapter.adapt(item));
        })
      );
  }

  getAllPerimeters(): Observable<Perimeter[]> {
    return this.get(`${API_ENDPOINTS.dynT}${PERIMETER.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.perimeterAdapter.adapt(item));
        })
      );
  }

  getPerimetersByType(typeId: number): Observable<Perimeter[]> {
    return this.getAllPerimeters().pipe(
      map(perimeters => perimeters.filter(p => p.typeId === typeId))
    );
  }

  getCustomPerimeterFloorIds(perimeterId: number): Observable<number[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('primaryColumnName', PERIMETER_DETAIL.perimeterId);
    httpParams = httpParams.append('primaryFilterId', perimeterId);
    return this.get(`${API_ENDPOINTS.dynT}/${PERIMETER_DETAIL.databaseTableName}`, UsageContextIdEnum.none, httpParams)
      .pipe(
        map(data => data.payload.map(r => this.perimeterDetail.adapt(r)).map(p => p.floorId))
      );
  }

  getCustomPerimeterDetailItems(perimeterId: number): Observable<PerimeterDetailView[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('primaryColumnName', PERIMETER.id);
    httpParams = httpParams.append('primaryFilterId', perimeterId);
    return this.get(`${API_ENDPOINTS.dynT}${PERIMETER_DETAIL_VIEW.databaseTableName}`, UsageContextIdEnum.none, httpParams)
      .pipe(
        map(data => data.payload.map(r => this.perimeterDetailViewAdapter.adapt(r)))
      );
  }

  getAllBusinessUnits(): Observable<BusinessUnit[]> {
    return this.get(API_ENDPOINTS.dynT + `business_unit`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.businessUnitAdapter.adapt(item));
        })
      );
  }

  getAllSites(): Observable<Site[]> {
    return this.get(`${API_ENDPOINTS.dynT}${SITE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.siteAdapter.adapt(item));
        })
      );
  }

  getAllLanguageItems(): Observable<LanguageItem[]> {
    return this.get(API_ENDPOINTS.dynT + `z_language_item_view`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.languageItemAdapter.adapt(item));
        })
      );
  }

  getAllFloorModels(): Observable<FloorModel[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_MODEL.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorModelAdapter.adapt(item));
        })
      );
  }

  getFloorModelsLastItemsByFloorModelId(floorModelId: number, withGrants?: boolean): Observable<FloorModel[]> {
    let httpParams = new HttpParams();
    if (withGrants === false) {
      httpParams = httpParams.set('granted', false);
    }
    return this.get(`${API_ENDPOINTS.dynH}${FLOOR_MODEL.databaseTableName}/LastItems/${floorModelId}`, UsageContextIdEnum.none, httpParams)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorModelAdapter.adapt(item));
        })
      );
  }

  getBusinessUnitsLastItemsByBusinessUnitId(businessUnitId: number): Observable<BusinessUnit[]> {
    return this.get(`${API_ENDPOINTS.dynH}${BUSINESS_UNIT.databaseTableName}/LastItems/${businessUnitId}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.businessUnitAdapter.adapt(item));
        })
      );
  }

  getFloorCatalogItems(): Observable<FloorCatalog[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_CATALOG.databaseTableName}`).pipe(
      map((data: ApiData) => data.payload.map(item => this.floorCatalogAdapter.adapt(item)))
    );
  }

  getAllDbColumns(): Observable<DbColumn[]> {
    return this.get(API_ENDPOINTS.dynT + `z_db_column`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.dbColumnAdapter.adapt(item));
        })
      );
  }

  getAllDbTables(): Observable<DbTable[]> {
    return this.get(`${API_ENDPOINTS.dynT}${Z_DB_TABLE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.dbTableAdapter.adapt(item));
        })
      );
  }

  createAppUser(appUserCreateDto: ApiAppUserCreate): Observable<ApiData> {
    return this.post(`${API_URL}AppUsers`, appUserCreateDto);
  }

  getAllContractors(): Observable<ApiContractor[]> {
    return this.get(`${API_ENDPOINTS.contractors}`).pipe(
      map(data => data.payload as ApiContractor[])
    );
  }

  createContractorUserAccount(companyId: string, firstName: string, lastName: string, email: string, isContractorAdmin?: boolean): Observable<ApiData> {
    const dto = {
      CompanyId: companyId,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      IsContractorAdmin: isContractorAdmin,
    };
    return this.post(`${API_ENDPOINTS.contractorsUser}`, dto);
  }

  createContractorGrantRequest(userPolicyId: string, companyId: string): Observable<EcsyFileGrantRequest> {
    const dto = {
      UserPolicyId: userPolicyId,
      CompanyId: companyId
    };
    return this.post(`${API_ENDPOINTS.contractorsUsersGrantRequest}`, dto).pipe(
      map(data => data.payload as EcsyFileGrantRequest)
    );
  }

  deleteContractorGrant(userPolicyId: string, companyId: string): Observable<EcSyFile[]> {
    let params = new HttpParams();
    params = params.append('userPolicyId', userPolicyId);
    params = params.append('companyId', companyId);
    return this.delete(`${API_ENDPOINTS.contractorsUserGrant}`, params).pipe(
      map(data => data.payload as EcSyFile[])
    );
  }

  deleteContractorGrantRequest(grantRequestId: number): Observable<EcsyFileGrantRequest[]> {
    let params = new HttpParams();
    params = params.append('grantRequestId', grantRequestId);
    return this.delete(`${API_ENDPOINTS.contractorsCancelGrantRequest}`, params).pipe(
      map(data => data.payload as EcsyFileGrantRequest[])
    );
  }

  deleteContractorUserAccount(userPolicyId: string): Observable<ApiData> {
    let params = new HttpParams();
    params = params.append('userPolicyId', userPolicyId);
    return this.delete(`${API_ENDPOINTS.contractorsUserAccount}`, params).pipe();
  }

  getContractorGrantRequests(): Observable<ApiContractorGrantRequest[]> {
    return this.get(`${API_ENDPOINTS.contractorsGrantRequests}`).pipe(
      map(data => {
        if (data.payload) {
          return data.payload.map(r => this.apiContractorGrantRequestAdapter.adapt(r));
        } else {
          return [];
        }
      })
    );
  }

  getContractorsUserAccount(contractorGrantRequest: ApiContractorGrantRequest): Observable<ApiContractorUserAccount[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('policyId', contractorGrantRequest.userId);
    httpParams = httpParams.set('firstName', contractorGrantRequest.firstName);
    httpParams = httpParams.set('lastName', contractorGrantRequest.lastName);
    return this.get(`${API_ENDPOINTS.contractorsUserAccount}`, UsageContextIdEnum.none, httpParams).pipe(
      map(data => data.payload.map(r => this.apiContractorUserAccountAdapter.adapt(r)))
    );
  }

  getContractorsUserAccounts(): Observable<ApiContractorUserAccountsDEPRECATED[]> {
    return this.get(`${API_ENDPOINTS.contractorsUserAccounts}`, UsageContextIdEnum.none).pipe(
      map(data => data.payload)
    );
  }

  getContractorsClients(companyId: string): Observable<ClientContractor[]> {
    return this.get(`${API_ENDPOINTS.contractorsClients(companyId)}`, UsageContextIdEnum.none).pipe(
      map(data => data.payload)
    );
  }

  grantContractor(userPolicyId: string, directoryId: number, isActive: boolean, roleId?: number): Observable<ApiData> {
    const dto = {
      UserPolicyId: userPolicyId,
      DirectoryId: directoryId,
      IsActive: isActive,
      RoleId: roleId ? roleId : null
    };
    return this.post(`${API_ENDPOINTS.contractorsGrantRequest}`, dto);
  }

  getContinents(): Observable<Continent[]> {
    return this.get(`${API_ENDPOINTS.dynT}${CONTINENT.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.continentAdapter.adapt(r)))
    );
  }

  getCountries(continent?: Continent): Observable<Country[]> {
    const endpoint = continent ? `${API_ENDPOINTS.addressCountries}/${continent.id}` : `${API_ENDPOINTS.dynT}${COUNTRY.databaseTableName}`;
    return this.get(endpoint).pipe(
      map(data => data.payload.map(r => this.countryAdapter.adapt(r)))
    );
  }

  getCities(country?: Country): Observable<City[]> {
    const endpoint = country ? `${API_ENDPOINTS.policyCitiesReferences}/${country.id}` : `${API_ENDPOINTS.dynT}${CITY.databaseTableName}`;
    return this.get(endpoint).pipe(
      map(data => {
        if (endpoint === `${API_ENDPOINTS.policyCitiesReferences}/${country.id}`) {
          return data.payload.map(r => {
            return new City(CITY.databaseTableName, r['Id'], r['Name'], `${r['Latitude']} ${r['Longitude']}`, r['CountryId']);
          });
        } else {
          return data.payload.map(r => this.cityAdapter.adapt(r));
        }
      })
    );
  }

  getFacilityJobs(): Observable<FacilityJob[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FACILITY_JOB.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.facilityJobAdapter.adapt(r)))
    );
  }

  getFacilityJobTasks(): Observable<FacilityJobTask[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FACILITY_JOB_TASK.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.facilityJobTaskAdapter.adapt(r)))
    );
  }

  getFacilityCompanyTasks(): Observable<FacilityCompanyTaskView[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FACILITY_COMPANY_TASK_VIEW.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.facilityCompanyTaskViewAdapter.adapt(r)))
    );
  }

  getGrantedTableViewItems(): Observable<GrantedTableView[]> {
    return this.get(`${API_ENDPOINTS.dynT}${Z_GRANTED_TABLE_VIEW.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.grantedTableViewAdapter.adapt(r)))
    );
  }

  getGrantedColumnViewItems(): Observable<GrantedColumnView[]> {
    return this.get(`${API_ENDPOINTS.dynT}${Z_GRANTED_COLUMN_VIEW.databaseTableName}`).pipe(
      map(data => data.payload.map(r => this.grantedColumnViewAdapter.adapt(r)))
    );
  }

  getFloorMapGrants(): Observable<AppGrant[]> {
    //dynt/app_grant_view?primaryColumnName=ApGr_GroupId&primaryFilterId=20
    const params = new HttpParams()
      .set('primaryColumnName', APP_GRANT.groupId)
      .set('primaryFilterId', 20);
    return this.get(`${API_ENDPOINTS.dynT}${APP_GRANT.databaseTableName}`, UsageContextIdEnum.none, params).pipe(
      map(data => data.payload.map(r => this.appGrantAdapter.adapt(r)))
    );
  }

  getEquipmentPurchaseDetail(equipmentPurchaseId: number): Observable<ApiData> {
    return this.get(`${API_ENDPOINTS.purchaseDetail}/${equipmentPurchaseId}`, UsageContextIdEnum.none).pipe(
      // map(data => data.payload.map(r => this.appGrantAdapter.adapt(r)))
    );
  }

  getEquipmentPurchaseHasReceiptTask(ids: number[]): Observable<Dictionary<boolean>> {
    let params = new HttpParams();
    ids.forEach(id => params = params.append('purchaseId', id));
    return this.get(`${API_ENDPOINTS.purchaseHasReceiptTask}`, UsageContextIdEnum.none, params).pipe(
      map(data => data.payload)
    );
  }

  getEquipmentStorageAreas(): Observable<EquipmentStorageArea[]> {
    return this.get(`${API_ENDPOINTS.dynT}${EQUIPMENT_STORAGE_AREA.databaseTableName}`).pipe(
      map(data => data.payload.map(a => this.equipmentStorageAreaAdapter.adapt(a)))
    );
  }

  postEquipmentPurchaseReceipt(dto: any): Observable<ApiData> {
    return this.post(`${API_ENDPOINTS.purchaseReceipt}`, dto).pipe(
      // map(data => data.payload.map(a => this.equipmentStorageAreaAdapter.adapt(a)))
    );
  }


}
