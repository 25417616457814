import { createAction, props } from '@ngrx/store';
import { FloorWallStyle } from '../../../domain-models/business/floor-wall-style.model';

export const beginGetAllFloorWallStyles = createAction('[Shared/API] - Begin Get All FloorWallStyles');

export const addFloorWallStyles = createAction(
  '[API] - Add FloorWallStyles',
  props<{ floorWallStyles: FloorWallStyle[] }>()
);

export const successGetAllFloorWallStyles = createAction('[API] - Success Get All FloorWallStyles');
export const errorGetAllFloorWallStyles = createAction('[Shared/API] - Error Get All FloorWallStyles', props<Error>());
