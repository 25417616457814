import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { WorkplaceAllocation } from '../../../domain-models/business/workplace-allocation.model';

/**
 * CRUD Actions
 */

export const loadWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Load WorkplaceAllocations', props<{ workplaceAllocations: WorkplaceAllocation[] }>());
export const addWorkplaceAllocation = createAction('[WorkplaceAllocation/API] Add WorkplaceAllocation', props<{ workplaceAllocation: WorkplaceAllocation }>());
export const setWorkplaceAllocation = createAction('[WorkplaceAllocation/API] Set WorkplaceAllocation', props<{ workplaceAllocation: WorkplaceAllocation }>());
export const upsertWorkplaceAllocation = createAction('[WorkplaceAllocation/API] Upsert WorkplaceAllocation', props<{ workplaceAllocation: WorkplaceAllocation }>());
export const addWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Add WorkplaceAllocations', props<{ workplaceAllocations: WorkplaceAllocation[] }>());
export const upsertWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Upsert WorkplaceAllocations', props<{ workplaceAllocations: WorkplaceAllocation[] }>());
export const updateWorkplaceAllocation = createAction('[WorkplaceAllocation/API] Update WorkplaceAllocation', props<{ update: Update<WorkplaceAllocation> }>());
export const updateWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Update WorkplaceAllocations', props<{ updates: Update<WorkplaceAllocation>[] }>());
export const mapWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Map WorkplaceAllocations', props<{ entityMap: EntityMap<WorkplaceAllocation> }>());
export const deleteWorkplaceAllocation = createAction('[WorkplaceAllocation/API] Delete WorkplaceAllocation', props<{ id: string }>());
export const deleteWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Delete WorkplaceAllocations', props<{ ids: string[] }>());
export const deleteWorkplaceAllocationsByPredicate = createAction('[WorkplaceAllocation/API] Delete WorkplaceAllocations By Predicate', props<{ predicate: Predicate<WorkplaceAllocation> }>());
export const clearWorkplaceAllocations = createAction('[WorkplaceAllocation/API] Clear WorkplaceAllocations');

/** END of CRUD **/

export const beginGetWorkplaceAllocationsByTaskId = createAction(
  '[Room/API] - Begin All WorkplaceAllocations By taskId',
  props<{ taskId: number }>()
);

export const beginGetCurrentTaskWorkplaceAllocations = createAction('[API] - Get Current Floor People Allocations');

export const errorGetAllWorkplaceAllocations = createAction('[API] - Error Get All People Allocations', props<Error>());

export const updateSelectedWorkplaceAllocationId = createAction(
  '[SvgRenderer] - Save Selected WorkplaceAllocation Id',
  props<{ id: number }>()
);

export const updateSelection = createAction(
  '[SvgRenderer] - Update WorkplaceAllocation Selection Ids',
  props<{ addedId: number, removedId: number }>()
);

export const clearSelection = createAction('[SvgRenderer] - Clear Selected WorkplaceAllocation Ids');
