import { Action, combineReducers, createAction, createFeatureSelector, INIT } from '@ngrx/store';
import * as fromFloor from '../reducers/floor.reducer';
import * as fromRoom from '../reducers/room.reducer';
import * as fromRoomAllocation from '../reducers/room-allocation.reducer';
import * as fromWorkplace from './workplace.reducer';
import * as fromWorkplaceAllocation from './workplace-allocation.reducer';
import * as fromEquipmentPlanning from './equipment-planning.reducer';


import * as fromUiContext from './ui-context.reducer';
import * as fromRoot from '../../../reducers';

export const floorFeatureKey = 'floor';

export const resetFloorFeatureState = createAction('[FloorFeatureState] - Reset FloorFeatureState');

export interface FloorState {
  [fromFloor.floorFeatureKey]: fromFloor.State;
  [fromRoom.roomFeatureKey]: fromRoom.State;
  [fromRoomAllocation.roomAllocationsFeatureKey]: fromRoomAllocation.State;
  [fromUiContext.uiContextFeatureKey]: fromUiContext.State;
  [fromWorkplace.workplaceFeatureKey]: fromWorkplace.State;
  [fromWorkplaceAllocation.workplaceAllocationsFeatureKey]: fromWorkplaceAllocation.State;
  [fromEquipmentPlanning.equipmentPlanningFeatureKey]: fromEquipmentPlanning.State;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: FloorState | undefined, action: Action) {
  if (action != null && action.type === resetFloorFeatureState.type) {
    return reducers(undefined, {type: INIT});
  }
  return combineReducers({
    [fromFloor.floorFeatureKey]: fromFloor.reducer,
    [fromRoom.roomFeatureKey]: fromRoom.reducer,
    [fromRoomAllocation.roomAllocationsFeatureKey]: fromRoomAllocation.reducer,
    [fromUiContext.uiContextFeatureKey]: fromUiContext.reducer,
    [fromWorkplace.workplaceFeatureKey]: fromWorkplace.reducer,
    [fromWorkplaceAllocation.workplaceAllocationsFeatureKey]: fromWorkplaceAllocation.reducer,
    [fromEquipmentPlanning.equipmentPlanningFeatureKey]: fromEquipmentPlanning.reducer,
  })(state, action);
}

export interface State extends fromRoot.State {
  [floorFeatureKey]: FloorState;
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectFloorsState = createFeatureSelector<FloorState>(
  floorFeatureKey
);
