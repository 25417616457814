import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export const CONTINENT = {
  databaseTableName: 'Continent',
  id: 'Co_Id',
  isoCode: 'Co_IsoCode',
  name: 'Co_Name',
  displayNameId: 'Co_DisplayNameId'
};

export class Continent {
  constructor(
    public databaseTableName: string,
    public id: number,
    public isoCode: string,
    public name: string,
    public displayNameId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class ContinentAdapter implements Adapter<Continent> {
  adapt(item: any): Continent {
    return new Continent(
      CONTINENT.databaseTableName,
      item[CONTINENT.id],
      item[CONTINENT.isoCode],
      item[CONTINENT.name],
      item[CONTINENT.displayNameId]
    );
  }
}
