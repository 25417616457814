import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoomAllocation } from '../../../domain-models/business/room-allocation.model';
import { RoomAllocationActions } from '../actions';

export const roomAllocationsFeatureKey = 'roomAllocations';

export interface State extends EntityState<RoomAllocation> {
  selectedRoomAllocationId: number | null;
}

export function generateId(a: RoomAllocation): string {
  return a.floorDataId + '-' + a.businessUnitId;
}

export const adapter: EntityAdapter<RoomAllocation> = createEntityAdapter<RoomAllocation>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  selectedRoomAllocationId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(RoomAllocationActions.addRoomAllocation, (state, {roomAllocation}) => {
    return adapter.addOne(roomAllocation, state);
  }),
  on(RoomAllocationActions.setRoomAllocation, (state, {roomAllocation}) => {
    return adapter.setOne(roomAllocation, state);
  }),
  on(RoomAllocationActions.upsertRoomAllocation, (state, {roomAllocation}) => {
    return adapter.upsertOne(roomAllocation, state);
  }),
  on(RoomAllocationActions.addRoomAllocations, (state, {roomAllocations}) => {
    return adapter.addMany(roomAllocations, state);
  }),
  on(RoomAllocationActions.upsertRoomAllocations, (state, {roomAllocations}) => {
    return adapter.upsertMany(roomAllocations, state);
  }),
  on(RoomAllocationActions.updateRoomAllocation, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(RoomAllocationActions.updateRoomAllocations, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(RoomAllocationActions.mapRoomAllocations, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(RoomAllocationActions.deleteRoomAllocation, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(RoomAllocationActions.deleteRoomAllocations, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(RoomAllocationActions.deleteRoomAllocationsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(RoomAllocationActions.loadRoomAllocations, (state, {roomAllocations}) => {
    return adapter.setAll(roomAllocations, state);
  }),
  on(RoomAllocationActions.clearRoomAllocations, state => {
    return adapter.removeAll({...state, selectedRoomAllocationId: null});
  }),
  /** END OF CRUD **/
);

export const getSelectedRoomAllocationId = (state: State) => state.selectedRoomAllocationId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoomAllocationIds = selectIds;
export const selectRoomAllocationEntities = selectEntities;
export const selectAllRoomAllocations = selectAll;
export const selectRoomAllocationTotal = selectTotal;
