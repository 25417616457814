import { Action, combineReducers, createAction, createFeatureSelector, INIT } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as fromGroupDefinitions from './floor-catalog.reducer';
import * as fromUses from './uses.reducer';
import * as fromFloorData from './floor-data.reducer';
import * as fromFloorModels from './task-floor-models.reducer';
import * as fromRenderer from './renderer.reducer';

export const svgFeatureKey = 'svg';

export const resetSvgFeatureState = createAction('[SvgFeatureState] - Reset SvgFeatureState');

export interface SvgState {
  [fromGroupDefinitions.floorCatalogFeatureKey]: fromGroupDefinitions.State;
  [fromUses.usesFeatureKey]: fromUses.State;
  [fromFloorData.floorDataFeatureKey]: fromFloorData.State;
  [fromFloorModels.taskFloorModelsFeatureKey]: fromFloorModels.State;
  [fromRenderer.rendererFeatureKey]: fromRenderer.State;
}

export interface State extends fromRoot.State {
  [svgFeatureKey]: SvgState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: SvgState | undefined, action: Action) {
  if (action != null && action.type === resetSvgFeatureState.type) {
    return reducers(undefined, {type: INIT});
  }
  return combineReducers({
    [fromGroupDefinitions.floorCatalogFeatureKey]: fromGroupDefinitions.reducer,
    [fromUses.usesFeatureKey]: fromUses.reducer,
    [fromFloorData.floorDataFeatureKey]: fromFloorData.reducer,
    [fromFloorModels.taskFloorModelsFeatureKey]: fromFloorModels.reducer,
    [fromRenderer.rendererFeatureKey]: fromRenderer.reducer,
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectSvgState = createFeatureSelector<SvgState>(
  svgFeatureKey
);
