import { createSelector } from '@ngrx/store';
import * as fromTaskValidation from '../reducers/task-validation.reducer';
import { selectProjectManagementState } from '../reducers';
import { selectCurrentTaskId } from './task.selectors';

/** Select store slice **/

export const selectTaskValidations = createSelector(
  selectProjectManagementState,
  state => state[fromTaskValidation.taskValidationFeatureKey]
);

/** Basics selectors **/

export const selectTaskValidationIds = createSelector(
  selectTaskValidations,
  fromTaskValidation.selectTaskValidationIds
);
export const selectTaskValidationEntities = createSelector(
  selectTaskValidations,
  fromTaskValidation.selectTaskValidationEntities
);
export const selectAllTaskValidations = createSelector(
  selectTaskValidations,
  fromTaskValidation.selectAllTaskValidations
);
export const selectTaskValidationTotal = createSelector(
  selectTaskValidations,
  fromTaskValidation.selectTaskValidationTotal
);
export const selectCurrentTaskValidationId = createSelector(
  selectTaskValidations,
  fromTaskValidation.getSelectedTaskValidationId
);

export const selectCurrentTaskValidation = createSelector(
  selectTaskValidationEntities,
  selectCurrentTaskValidationId,
  (taskValidationEntities, taskValidationId) => taskValidationEntities[taskValidationId]
);

/** Advanced selectors **/

export const selectTaskValidationById = (id: number) => createSelector(
  selectTaskValidationEntities,
  (entities) => entities[id]
);

export const selectCurrentTaskTaskValidations = createSelector(
  selectAllTaskValidations,
  selectCurrentTaskId,
  (taskValidations, currentTaskId) => taskValidations.filter(t => t.taskId === currentTaskId)
);
