import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FloorActions } from '../actions';
import { Floor } from '../../../domain-models/business/floor.model';

export const floorFeatureKey = 'floors';

export interface State extends EntityState<Floor> {
  // additional entities state properties
  selectedFloorId: number | null;
  selectedTaskId: number | null;
}

export function selectFloorId(a: Floor): number {
  //In this case this would be optional since primary key is id
  return a.id;
}

export const adapter: EntityAdapter<Floor> = createEntityAdapter<Floor>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedFloorId: null,
  selectedTaskId: null
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(FloorActions.addFloor, (state, {floor}) => {
    return adapter.addOne(floor, state);
  }),
  on(FloorActions.setFloor, (state, {floor}) => {
    return adapter.setOne(floor, state);
  }),
  on(FloorActions.upsertFloor, (state, {floor}) => {
    return adapter.upsertOne(floor, state);
  }),
  on(FloorActions.addFloors, (state, {floors}) => {
    return adapter.addMany(floors, state);
  }),
  on(FloorActions.upsertFloors, (state, {floors}) => {
    return adapter.upsertMany(floors, state);
  }),
  on(FloorActions.updateFloor, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(FloorActions.updateFloors, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(FloorActions.mapFloors, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(FloorActions.deleteFloor, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(FloorActions.deleteFloors, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(FloorActions.deleteFloorsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(FloorActions.loadFloors, (state, {floors}) => {
    return adapter.setAll(floors, state);
  }),
  on(FloorActions.clearFloors, state => {
    return adapter.removeAll({...state, selectedFloorId: null});
  }),
  /** END OF CRUD **/
  on(FloorActions.updateSelectedFloorId, (state, {floorId}) => {
    return ({...state, selectedFloorId: floorId});
  }),
);

export const getSelectedFloorId = (state: State) => state.selectedFloorId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of floor ids
export const selectFloorIds = selectIds;

// select the dictionary of floor entities
export const selectFloorEntities = selectEntities;

// select the array of floors
export const selectAllFloors = selectAll;

// select the total floor count
export const selectFloorTotal = selectTotal;
