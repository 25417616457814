import { createSelector } from '@ngrx/store';
import * as fromFloorCatalog from '../reducers/floor-catalog.reducer';
import { selectSharedState } from '../reducers';

export const selectFloorCatalog = createSelector(
  selectSharedState,
  state => state[fromFloorCatalog.floorCatalogsFeatureKey]
);

export const selectFloorCatalogsIds = createSelector(
  selectFloorCatalog,
  fromFloorCatalog.selectFloorCatalogsIds
);
export const selectFloorCatalogEntities = createSelector(
  selectFloorCatalog,
  fromFloorCatalog.selectFloorCatalogEntities
);
export const selectAllFloorCatalogItems = createSelector(
  selectFloorCatalog,
  fromFloorCatalog.selectAllFloorCatalogItems
);
export const selectFloorCatalogsTotal = createSelector(
  selectFloorCatalog,
  fromFloorCatalog.selectFloorCatalogItemsTotal
);

export const selectFloorCatalogById = (id: number) => createSelector(
  selectFloorCatalogEntities,
  entities => entities[id]
);

export const selectFloorCatalogItemsByIds = (ids: number[]) => createSelector(
  selectFloorCatalogEntities,
  entities => ids.map(id => entities[id])
);
