import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export enum ClientGrantStatusEnum {
  None = 1,
  Requested,
  Allowed,
  Rejected
}

const CONTRACTOR_GRANT_REQUEST = {
  companyId: 'CompanyId',
  firstName: 'FirstName',
  grantRequestTimeStamp: 'GrantRequestTimeStamp',
  id: 'Id',
  lastName: 'LastName',
  response: 'Response',
  responseTimeStamp: 'ResponseTimeStamp',
  statusName: 'StatusName',
  tableName: 'TableName',
  userEmployerId: 'UserEmployerId',
  userId: 'UserId',
  employerName: 'EmployerName',
};

export class ApiContractorGrantRequest {
  constructor(
    public companyId: string,
    public firstName: string,
    public grantRequestTimeStamp: Date,
    public id: number,
    public lastName: string,
    public response: number,
    public responseTimeStamp: Date,
    public statusName: string,
    public tableName: string,
    public userEmployerId: string,
    public userId: string,
    public employerName: string,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiContractorGrantRequestAdapter implements Adapter<ApiContractorGrantRequest> {
  adapt(item: any): ApiContractorGrantRequest {
    return new ApiContractorGrantRequest(
      item[CONTRACTOR_GRANT_REQUEST.companyId],
      item[CONTRACTOR_GRANT_REQUEST.firstName],
      item[CONTRACTOR_GRANT_REQUEST.grantRequestTimeStamp] !== null ? new Date(item[CONTRACTOR_GRANT_REQUEST.grantRequestTimeStamp]) : null,
      item[CONTRACTOR_GRANT_REQUEST.id],
      item[CONTRACTOR_GRANT_REQUEST.lastName],
      item[CONTRACTOR_GRANT_REQUEST.response],
      item[CONTRACTOR_GRANT_REQUEST.responseTimeStamp] !== null ? new Date(item[CONTRACTOR_GRANT_REQUEST.responseTimeStamp]) : null,
      item[CONTRACTOR_GRANT_REQUEST.statusName],
      item[CONTRACTOR_GRANT_REQUEST.tableName],
      item[CONTRACTOR_GRANT_REQUEST.userEmployerId],
      item[CONTRACTOR_GRANT_REQUEST.userId],
      item[CONTRACTOR_GRANT_REQUEST.employerName]
    );
  }
}
