import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import * as FloorDataActions from '../actions/floor-data.actions';

import { FloorService } from '../../../features/floor/services/floor.service';
import { Store } from '@ngrx/store';
import { FloorDataSelectors } from '../selectors';
import { of } from 'rxjs';
import { FloorCatalogActions, GraphicsFloorActions, RendererActions, TaskFloorModelActions, UsesActions } from '../actions';
import { State } from '../../../reducers';
import { TaskFloorModelAdapter } from '../../../domain-models/business-extended/task-floor-model.model';

@Injectable()
export class FloorDataEffects {


  /** Add FlMo to Ta if not existing when adding new FlDa **/


  /** Cascade delete floorData items **/

  // removeCascadingFloorData$ = createEffect(() => this.actions$.pipe(
  //   ofType(FloorDataActions.removeFloorDataItem),
  //   switchMap(action => this.store$.select(FloorDataSelectors.selectFloorDataChildren(action.id))),
  //   // Flatten children array inside the stream
  //   flatMap(children => of(...children)),
  //   map(child => FloorDataActions.removeFloorDataItem({id: child.id}))
  //   )
  // );

  // removeFloorData$ = createEffect(() => this.actions$.pipe(
  //   ofType(FloorDataActions.removeFloorDataItem),
  //   map(action => FloorDataActions.removeFromSelection({id: action.id}))
  //   )
  // );

  /** Add existing FlDa to Ta **/

  addFloorDataToTask$ = createEffect(
    () => this.actions$.pipe(
      ofType(FloorDataActions.addFloorDataToTask),
      switchMap(action => this.floorService.addFloorDataToTask(action.floorDataId, action.taskId).pipe(
        switchMap(cadProjectsAddRoom => [
          TaskFloorModelActions.addTaskFloorModels({taskFloorModels: cadProjectsAddRoom.floorModel}),
          FloorCatalogActions.addFloorCatalogItems({svgGroups: cadProjectsAddRoom.floorCatalog}),
          FloorDataActions.addFloorDataItems({svgGroups: cadProjectsAddRoom.svgDTO.SvgGroupDTO}),
          UsesActions.addUses({svgUses: cadProjectsAddRoom.svgDTO.SvgUseDTO}),
          GraphicsFloorActions.successGetGraphicsFloorByTaskId(), /** IMPORTANT FOR DISPLAYTHEMEITEMS PROPER REFRESH **/
        ])
      )))
  );

  /** Delete floorData items by taskId **/

  removeFloorDataItemsByTaskIds$ = createEffect(
    () => this.actions$.pipe(
      ofType(FloorDataActions.removeFloorDataItemsByTaskIds),
      /** Don't use parametrized selector here (causes remove action fired twice..... -_-) **/
      withLatestFrom(this.store$.select(FloorDataSelectors.selectAllFloorData)),
      switchMap(([action, floorDataItems]) => {
        let floorDataIds = [];
        action.taskIds.forEach(id => {
          floorDataIds = [...floorDataIds, ...floorDataItems.filter(e => e.taskId === id).map(e => e.id)];
        });
        return of(FloorDataActions.removeFloorDataItems({ids: floorDataIds}));
      })
    )
  );

  translateLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorDataActions.translateLabel),
      switchMap((action) =>
        this.floorService.translateLabel(action.id, action.translationVector)
          .pipe(
            switchMap((data) => [
              FloorDataActions.upsertFloorDataItems({
                svgGroups: data.svgGroups,
              }),
            ]),
            catchError((err) => of(FloorDataActions.floorDataTransformUpdateError()))
          )
      )
    )
  );

  floorDataTransformUpdateError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorDataActions.floorDataTransformUpdateError),
      switchMap(error => [RendererActions.resetSelection()]),
    )
  );

  /** DEBUG PURPOSES **/
  // upsert$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(FloorDataActions.upsertFloorDataItems),
  //     tap(a => console.log(a)),
  //   ), {dispatch: false}
  // );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store$: Store<State>,
    private taskFloorModelAdapter: TaskFloorModelAdapter
  ) {
  }
}
