import { TaskValidation, TaskValidationAdapter } from '../business/task-validation.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

const TASKS_VALIDATIONS = {
  taskValidation: 'TaskValidation',
  isLastValidation: 'IsLastValidation'
};

export class ApiTasksValidations {
  constructor(
    public taskValidation: TaskValidation,
    public isLastValidation: boolean) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiTasksValidationsAdapter implements Adapter<ApiTasksValidations> {
  adapt(item: any): ApiTasksValidations {
    return new ApiTasksValidations(
      new TaskValidationAdapter().adapt(item[TASKS_VALIDATIONS.taskValidation]),
      item[TASKS_VALIDATIONS.isLastValidation]
    );
  }
}
