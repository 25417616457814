import { createAction, props } from '@ngrx/store';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { TaskStatus } from '../../../domain-models/business/task-status.model';

/**
 * CRUD Actions
 */

export const loadTaskStatusItems = createAction('[TaskStatus/API] Load TaskStatusItems', props<{ taskStatusItems: TaskStatus[] }>());
export const addTaskStatus = createAction('[TaskStatus/API] Add TaskStatus', props<{ taskStatus: TaskStatus }>());
export const setTaskStatus = createAction('[TaskStatus/API] Set TaskStatus', props<{ taskStatus: TaskStatus }>());
export const upsertTaskStatus = createAction('[TaskStatus/API] Upsert TaskStatus', props<{ taskStatus: TaskStatus }>());
export const addTaskStatusItems = createAction('[TaskStatus/API] Add TaskStatusItems', props<{ taskStatusItems: TaskStatus[] }>());
export const upsertTaskStatusItems = createAction('[TaskStatus/API] Upsert TaskStatusItems', props<{ taskStatusItems: TaskStatus[] }>());
export const updateTaskStatus = createAction('[TaskStatus/API] Update TaskStatus', props<{ update: Update<TaskStatus> }>());
export const updateTaskStatusItems = createAction('[TaskStatus/API] Update TaskStatusItems', props<{ updates: Update<TaskStatus>[] }>());
export const mapTaskStatusItems = createAction('[TaskStatus/API] Map TaskStatusItems', props<{ entityMap: EntityMap<TaskStatus> }>());
export const deleteTaskStatus = createAction('[TaskStatus/API] Delete TaskStatus', props<{ id: string }>());
export const deleteTaskStatusItems = createAction('[TaskStatus/API] Delete TaskStatusItems', props<{ ids: string[] }>());
export const deleteTaskStatusItemsByPredicate = createAction('[TaskStatus/API] Delete TaskStatusItems By Predicate', props<{ predicate: Predicate<TaskStatus> }>());
export const clearTaskStatusItems = createAction('[TaskStatus/API] Clear TaskStatusItems');

/** END of CRUD **/

export const beginGetAllTaskStatusItems = createAction('[TaskStatus/API] - Begin Get All TaskStatusItems');
export const errorGetAllTaskStatusItems = createAction('[API] - Error Get All TaskStatusItems', props<Error>());

export const updateSelectedTaskStatusId = createAction(
  '[TaskStatus/API] - Save Selected TaskStatus Id',
  props<{ taskStatusId: number }>()
);
