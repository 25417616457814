import { createSelector } from '@ngrx/store';
import * as fromTask from '../reducers/task.reducer';
import { selectProjectManagementState } from '../reducers';
import { selectAllTaskLinks } from './task-link.selectors';
import { TaskLinkTypeEnum } from '../../../domain-models/business/task-link-type.model';
import { TaskTypeEnum } from '../../../domain-models/business/task-type.model';

/** Select store slice **/

export const selectTasks = createSelector(
  selectProjectManagementState,
  state => state[fromTask.taskFeatureKey]
);

/** Basics selectors **/

export const selectTaskIds = createSelector(
  selectTasks,
  fromTask.selectTaskIds
);
export const selectTaskEntities = createSelector(
  selectTasks,
  fromTask.selectTaskEntities
);
export const selectAllTasks = createSelector(
  selectTasks,
  fromTask.selectAllTasks
);
export const selectTaskTotal = createSelector(
  selectTasks,
  fromTask.selectTaskTotal
);
export const selectCurrentTaskId = createSelector(
  selectTasks,
  fromTask.getSelectedTaskId
);

export const selectCurrentTask = createSelector(
  selectTaskEntities,
  selectCurrentTaskId,
  (taskEntities, taskId) => taskEntities[taskId]
);

/** Advanced selectors **/

export const selectTaskById = (id: number) => createSelector(
  selectTaskEntities,
  (entities) => {
    return entities[id];
  }
);

export const selectTaskTemplates = createSelector(
  selectAllTasks,
  (tasks) => {
    return tasks.filter(t => t.isTemplate);
  }
);

export const selectTaskTemplatesOnlyTasks = createSelector(
  selectAllTasks,
  (tasks) => {
    return tasks.filter(t => t.isTemplate && t.typeId === TaskTypeEnum.Task);
  }
);

export const selectTaskProjectTemplates = createSelector(
  selectTaskTemplates,
  (taskTemplates) => taskTemplates.filter(t => t.typeId === TaskTypeEnum.Project)
);

export const selectTasksByIds = (ids: number[]) => createSelector(
  selectTaskEntities,
  (entities) => ids.map(id => entities[id])
);

export const selectCurrentTaskReconcileTaskIds = createSelector(
  selectTasks,
  (state) => state.selectedTaskReconcileTaskIds
);

export const selectCurrentTaskReconcileTasks = createSelector(
  selectCurrentTaskReconcileTaskIds,
  selectTaskEntities,
  (currentTaskReconcileTaskIds, entities) => currentTaskReconcileTaskIds.map(id => entities[id])
);

export const currentTaskHasReconcileTasks = createSelector(
  selectCurrentTaskReconcileTaskIds,
  (currentTaskReconcileTaskIds) => !!currentTaskReconcileTaskIds.length
);

export const selectSpacePlanningImportTaskByTaskId = (id: number) => createSelector(
  selectTaskEntities,
  selectAllTaskLinks,
  (entities, taskLinks) => {
    const taskLink = taskLinks.find(l => l.sourceTaskId === id && l.linkType === TaskLinkTypeEnum.StartToStart);
    if (taskLink) {
      return entities[taskLink.targetTaskId];
    }
  }
);

export const selectSpacePlanningImportTaskSourceTaskByTaskId = (id: number) => createSelector(
  selectTaskEntities,
  selectAllTaskLinks,
  (entities, taskLinks) => {
    const taskLink = taskLinks.find(l => l.targetTaskId === id && l.linkType === TaskLinkTypeEnum.StartToStart);
    if (taskLink) {
      return entities[taskLink.sourceTaskId];
    }
  }
);
