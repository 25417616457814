import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import { SharedModule } from '../../shared/shared.module';
import { NotificationsPanelComponent } from './components/notifications-panel/notifications-panel.component';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  declarations: [
    UserIconComponent,
    ProfileComponent,
    UserPanelComponent,
    NotificationsPanelComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    //FormsModule,
    //MaterialModule,
    //FlexLayoutModule,
    //SharedModule
    MaterialModule,
    SharedModule
  ],
  exports: [
    ProfileComponent,
    UserIconComponent,
    UserPanelComponent
  ],
  providers: []
})
export class UserModule {
}
