import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as RendererActions from '../actions/renderer.actions';

import { FloorService } from '../../../features/floor/services/floor.service';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { FloorDataActions, UsesActions } from '../actions';
import { RoomActions, WorkplaceActions, WorkplaceAllocationActions } from '../../floor-store/actions';
import { RoomContourProps } from '../../../features/floor/models/room-contour-props.model';
import { SvgRendererService } from '../../../features/svg-factory/services/svg-renderer.service';

@Injectable()
export class RendererEffects {

  getRoomContour$ = createEffect(
    () => this.actions$.pipe(
      ofType(RendererActions.beginGetRoomContour),
      switchMap(action => {
        let isUpdate = false;
        /** Check if isUpdate is defined in action props **/
        if (action.hasOwnProperty('isUpdate')) {
          if (action.isUpdate) {
            isUpdate = true;
          }
        }
        return this.floorService.getSvgContour(action.taskId, action.x, action.y, isUpdate).pipe(
          switchMap((data) => {
            return [
              RendererActions.successGetRoomContour({
                props: {
                  area: data.svgPathDTO.area,
                  svgStatement: data.svgPathDTO.data,
                  x: action.x,
                  y: action.y,
                  wallIds: data.wallIds,
                  svgEntity: data.svgPathDTO,
                } as RoomContourProps
              })
            ];
          }),
          catchError((error: Error) => {
            return of(RendererActions.errorGetRoomContour(error));
          })
        );
      }))
  );

  // errorGetRoomContour$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(RendererActions.errorGetRoomContour),
  //     switchMap(action => [
  //       UiContextActions.cancelRoomRecalculateArea(),
  //       UiContextActions.cancelRoomCreation()
  //     ]),
  //   )
  // );

  /**
   * WARNING WARNING CHANGE 10 DECEMBRE !!!!
   */
  // setEditedFloorModelIdOnRendererSelectionChange$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(RendererActions.setSelection),
  //     switchMap(action => [UiContextActions.isEditingFloorModel({id: action.selection.floorModelId})]),
  //   )
  // );

  // selectionChanges$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(RendererActions.setSelection),
  //     tap(a => console.log(a)),
  //     filter(a => !a.selection.multiple),
  //     switchMap((action) => {
  //       return [
  //         FloorDataActions.clearSelection(),
  //         UsesActions.clearSelection(),
  //         RoomActions.clearSelection(),
  //         WorkplaceActions.clearSelection(),
  //       ];
  //       // if(action.selection.floorModelId === FloorModelEnum.Rooms) {
  //       //   return [
  //       //     UsesActions.clearSelection(),
  //       //     WorkplaceActions.clearSelection(),
  //       //     FloorDataActions.clearSelection(),
  //       //   ];
  //       // } else if(action.selection.floorModelId === FloorModelEnum.Equipment) {
  //       //   return [
  //       //     RoomActions.clearSelection(),
  //       //     WorkplaceActions.clearSelection(),
  //       //     FloorDataActions.clearSelection(),
  //       //   ];
  //       // } else if(action.selection.floorModelId === FloorModelEnum.WorkplaceLabels) {
  //       //   return [
  //       //     UsesActions.clearSelection(),
  //       //     RoomActions.clearSelection(),
  //       //     FloorDataActions.clearSelection(),
  //       //   ];
  //       // } else if(action.selection.floorModelId === FloorModelEnum.Doors) {
  //       //   return [
  //       //     UsesActions.clearSelection(),
  //       //     RoomActions.clearSelection(),
  //       //     WorkplaceActions.clearSelection()
  //       //   ];
  //       // } else {
  //       //   return EMPTY;
  //       // }
  //     }),
  //   )
  // );

  /**
   * SvgGroup, SvgUse single selection switch DEPRECATED
   */

  // selectionTypeSwitch$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(UsesActions.addToSelection, FloorDataActions.addToSelection),
  //     withLatestFrom(
  //       this.store.select(FloorDataSelectors.getSelectedFloorDataIds),
  //       this.store.select(UseSelectors.getSelectedUsesIds),
  //     ),
  //     switchMap(([action, selectedFloorDataIds, selectedUsesIds]) => {
  //       if (action.type === UsesActions.addToSelection.type && selectedFloorDataIds.length < 2) {
  //         return of(FloorDataActions.clearSelection());
  //       } else if (action.type === FloorDataActions.addToSelection.type && selectedUsesIds.length < 2) {
  //         return of(UsesActions.clearSelection());
  //       }
  //     }),
  //   )
  // );

  // rendererSetSelection$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(RendererActions.setSelection),
  //     switchMap((action) => {
  //       // if(!action.selection.multiple) {
  //       //   return of(RendererActions.resetSelection());
  //       // }
  //       if(action.type === UsesActions.addToSelection.type && selectedFloorDataIds.length < 2) {
  //         return of(FloorDataActions.clearSelection());
  //       } else if (action.type === FloorDataActions.addToSelection.type && selectedUsesIds.length < 2) {
  //         return of(UsesActions.clearSelection());
  //       }
  //     }),
  //   )
  // );

  clearSelection$ = createEffect(
    () => this.actions$.pipe(
      ofType(RendererActions.resetSelection),
      /// ** Also clear custom floor data drawn contours (eg. room labels) **/
      // tap(a => this.svgRendererService.drawFloorDataContours$.next([])),
      switchMap((action) => [
        FloorDataActions.clearSelection(),
        UsesActions.clearSelection(),
        RoomActions.clearSelection(),
        WorkplaceActions.clearSelection(),
        WorkplaceAllocationActions.clearSelection()
      ]),
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private svgRendererService: SvgRendererService,
    private store: Store<State>
  ) {
  }
}
