import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const SIMULATION_PHOTO = {
  databaseTableName: 'simulation_photo',
  id: 'SiPh_Id',
  simulation: 'SiPh_Simulation',
  photoDate: 'SiPh_PhotoDate',
  isActive: 'SiPh_IsActive',
  name: 'SiPh_Name',
  description: 'SiPh_Description'
};

export class SimulationPhoto {
  constructor(
    public databaseTableName: string,
    public id: number,
    public simulation: number,
    public photoDate: Date,
    public isActive: boolean,
    public name: string,
    public description: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class SimulationPhotoAdapter implements Adapter<SimulationPhoto> {
  adapt(item: any): SimulationPhoto {
    return new SimulationPhoto (
      SIMULATION_PHOTO.databaseTableName,
      item[SIMULATION_PHOTO.id],
      item[SIMULATION_PHOTO.simulation],
      new Date(item[SIMULATION_PHOTO.photoDate]),
      item[SIMULATION_PHOTO.isActive],
      item[SIMULATION_PHOTO.name],
      item[SIMULATION_PHOTO.description],
    );
  }
  encode(item: SimulationPhoto): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [SIMULATION_PHOTO.id]: item.id,
      [SIMULATION_PHOTO.simulation]: item.simulation,
      [SIMULATION_PHOTO.photoDate]: item.photoDate.toDateString(),
      [SIMULATION_PHOTO.isActive]: item.isActive,
      [SIMULATION_PHOTO.name]: item.name,
      [SIMULATION_PHOTO.description]: item.description,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
