import { AfterViewInit, Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { UpdateService } from './core/services/update.service';

@Component({
  selector: 'echo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {

  constructor(private updateService: UpdateService,
              private authService: AuthService) {
    this.authService.runInitialLoginSequence();
  }

  ngAfterViewInit(): void {
    setTimeout(() => { // check for updates after 10 seconds
      this.updateService.checkForUpdates();
    }, 10000);
  }
}
