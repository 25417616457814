import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../models/confirm-dialog-data.interface';

@Component({
  selector: 'echo-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  cancelBtnText = 'Annuler';
  submitBtnText = 'Ok';
  cancelBtnWithoutColor: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    console.log(this.data);
    if (this.data.cancelBtn) {
      this.cancelBtnText = this.data.cancelBtn.text;
    }
    if (this.data.submitBtn) {
      this.submitBtnText = this.data.submitBtn.text;
    }
    if (this.data?.cancelBtn?.color === null) {
      this.cancelBtnWithoutColor = true;
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

}
