import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const TASK_FACILITY = {
  databaseTableName: 'task_facility',
  taskId: 'TaFa_TaskId',
  companyTaskId: 'TaFa_CompanyTaskId',
  unitOfWorkAmount: 'TaFa_UnitOfWorkAmount'
};

export class TaskFacility {
  public databaseTableName = TASK_FACILITY.databaseTableName;
  constructor(
    // public databaseTableName: string,
    public taskId: number,
    public companyTaskId: number,
    public unitOfWorkAmount: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskFacilityAdapter implements Adapter<TaskFacility> {
  adapt(item: any): TaskFacility {
    return new TaskFacility(
      // TASK_FACILITY.databaseTableName,
      item[TASK_FACILITY.taskId],
      item[TASK_FACILITY.companyTaskId],
      item[TASK_FACILITY.unitOfWorkAmount],
    );
  }

  encode(item: TaskFacility): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_FACILITY.taskId]: item.taskId,
      [TASK_FACILITY.companyTaskId]: item.companyTaskId,
      [TASK_FACILITY.unitOfWorkAmount]: item.unitOfWorkAmount,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
