import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../../shared/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { concatMap, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { ApiData } from '../models/api-data.model';
import { APP_USER, AppUser, AppUserAdapter } from '../../domain-models/business/app-user.model';
import { IdentityClaims } from '../models/identity-claims.model';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { AuthService } from './auth.service';
import { CoreActions } from '../../store/core-store/actions';
import { UserIdentity } from '../../domain-models/business-extended/user.model';
import { LoggerService } from './logger.service';
import { SnackBarService } from './snack-bar.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})

export class UserService extends ApiService {

  //public user$ = new ReplaySubject<User>(1);
  private user: UserIdentity;
  private identityClaims: IdentityClaims;

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private authservice: AuthService,
              private store: Store<State>,
              private appUserAdapter: AppUserAdapter) {
    super(http, loggerService, snackBarService, httpLoaderService);
    this.authservice.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated),
      first(),
      distinctUntilChanged(),
      concatMap(isAuthenticated => this.setUserFromIdentity().pipe(
        tap(user => this.store.dispatch(CoreActions.setUser({user: user}))),
      )),
    ).subscribe();

    //this.identityClaims = this.authservice.identityClaims as IdentityClaims;
    //this.setUserFromIdentity().subscribe();
    //console.log(this.authService.identityClaims);
    // if (localStorage.getItem('id_token_claims_obj')) {
    //   this.setUserFromIdentity().then(() => {
    //     if (localStorage.getItem('client_file')) {
    //       this.setEcsyUser();
    //     }
    //   });
    // }
  }

  /** Set user based on ID token claims **/
  public setUserFromIdentity(): Observable<UserIdentity> {
    return new Observable((observer) => {
      const user = {} as UserIdentity;
      const idTokenClaims = JSON.parse(localStorage.getItem('id_token_claims_obj')) as IdentityClaims;

      if (idTokenClaims) {
        //this.user = user;
        //this.idTokenClaims = idTokenClaims;
        user.employer = idTokenClaims.employer;
        user.name = idTokenClaims.name;
        user.role = idTokenClaims.role;
        user.sub = idTokenClaims.sub;
        //user.admin = user.role === 'EchoAdmin';
        //this.store.dispatch(CoreActions.setUser({user: user}));
        observer.next(user);
      } else {
        observer.error('User is not available');
      }

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
        }
      };
    });
  }

  getEcsyUser(): Observable<AppUser> {
    return this.get(API_ENDPOINTS.appUsersMe)
      .pipe(
        map(data => {
          if (data.payload) {
            return this.appUserAdapter.adapt(data.payload);
          } else {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                this.snackBarService.displayError(data.message);
              }
            }
          }
        }),
        //catchError(this.handleError)
      );
  }

  getAppUsers(): Observable<AppUser[]> {
    return this.get(`${API_ENDPOINTS.dynT}${APP_USER.databaseTableName}`).pipe(
      map((data: ApiData) => data.payload.map(user => this.appUserAdapter.adapt(user)))
    );
  }
}
