import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { API_ENDPOINTS, API_URL } from '../../../shared/api-endpoints';
import { ProjectManagementService } from '../../../features/project-management/services/project-management.service';
import { GanttTaskAdapter } from '../../../features/project-management/models/gantt-task.model';
import { TASK, TaskAdapter } from '../../../domain-models/business/task.model';
import { EMPTY, of } from 'rxjs';
import { State } from '../../../reducers';
import { TaskSelectors } from '../selectors';
import { GanttTaskActions, TaskActions, TaskFloorActions } from '../actions';
import { TaskService } from '../../../features/project-management/services/task.service';
import { BroadcastService } from '../../../core/services/broadcast.service';


@Injectable()
export class TaskEffects {

  beginGetTaskById$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.beginGetTaskById),
    concatMap((action) => {
        /** Use ConcatMap instead of SwitchMap, avoid requests to be canceled **/
        return this.projectManagementService.get(`${API_ENDPOINTS.dynT}${TASK.databaseTableName}/${action.id}`).pipe(
          switchMap(apiResult => [
            // TaskLinkActions.beginGetTaskLinkByTaskId({id: action.id}),
            TaskActions.addTask({task: this.taskAdapter.adapt(apiResult.payload)})
          ]),
        );
      }
    )),
  );

  beginGetReconcileTasksById$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.beginGetReconcileTasksById),
    switchMap((action) => {
        return this.taskService.getReconcileTasks(action.id).pipe(
          switchMap(tasks => [TaskActions.setSelectedTaskReconcileTaskIds({reconcileTaskIds: tasks.map(t => t.id)})]),
        );
      }
    )),
  );

  updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
      ofType(TaskActions.updateSelectedTaskId),
      switchMap(action => this.store.select(TaskSelectors.selectTaskById(action.taskId)).pipe(
        switchMap(task => {
          this.broadcastService.listenToTaskChannel(action.taskId);
          if (task === undefined && action.taskId !== null) {
            /** If Task is not in store and selectedTaskId is not null**/
            return [
              TaskActions.beginGetTaskById({id: action.taskId}),
              TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
              TaskActions.beginGetReconcileTasksById({id: action.taskId}),
              // RendererActions.resetSelection()
            ];
          } else {
            if (action.taskId !== null) {
              return [
                TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
                TaskActions.beginGetReconcileTasksById({id: action.taskId}),
              ];
            } else {
              return [
                TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
              ];
            }
            // return [
            //   TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
            //   TaskActions.beginGetReconcileTasksById({id: action.taskId}),
            //   // RendererActions.resetSelection()
            // ];
          }
        })
      )),

    ),
  );

  updateTask$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.updateTask),
    //filter(action => !action.ignoreApiCall),
    switchMap((action) => {
        const backendObj = this.taskAdapter.encode(action.update.changes);
        backendObj[TASK.id] = action.update.id;
        if (!action.ignoreApiCall) {
          return this.projectManagementService.patch(`${API_URL}tasks`, backendObj).pipe(
            map(apiResult => {
              const ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(action.update.changes);
              return GanttTaskActions.updateGanttTask({update: {id: ganttTaskUpdate.id, changes: ganttTaskUpdate}});
            }),
          );
        } else {
          return EMPTY;
        }
      }
    )),
  );

  /** Call backend and if result is success, update Task in store **/

  beginUpdateTask$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.beginUpdateTask),
    concatMap((action) => {
        const backendObj = this.taskAdapter.encode(action.update.changes);
        backendObj[TASK.id] = action.update.id;
        return this.projectManagementService.patch(`${API_URL}tasks`, backendObj).pipe(
          map(apiResult => {
            return TaskActions.updateTask({update: action.update, ignoreApiCall: true, });
          }),
        );
      }
    )),
  );

  deleteTask$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.deleteTask),
    switchMap((action) => {
        return this.projectManagementService.delete(`${API_URL}tasks/${action.id}`).pipe(
          concatMap(apiResult => {
            return of(GanttTaskActions.deleteGanttTask({id: action.id}));
          }),
        );
      }
    )),
  );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private ganttTaskAdapter: GanttTaskAdapter,
    private taskAdapter: TaskAdapter,
    private taskService: TaskService,
    private broadcastService: BroadcastService,
    private store: Store<State>
  ) {
  }
}
