import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const APP_GRANT = {
  databaseTableName: 'app_grant',
  id: 'ApGr_Id',
  name: 'ApGr_Name',
  groupId: 'ApGr_GroupId',
  displayNameId: 'ApGr_DisplayNameId'
};

export class AppGrant {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public groupId: number,
    public displayNameId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class AppGrantAdapter implements Adapter<AppGrant> {
  adapt(item: any): AppGrant {
    return new AppGrant(
      APP_GRANT.databaseTableName,
      item[APP_GRANT.id],
      item[APP_GRANT.name],
      item[APP_GRANT.groupId],
      item[APP_GRANT.displayNameId],
    );
  }

  encode(item: AppGrant): ISingleBackendObj {
    const encoded = {
      TableName: APP_GRANT.databaseTableName,
      [APP_GRANT.id]: item.id,
      [APP_GRANT.name]: item.name,
      [APP_GRANT.groupId]: item.groupId,
      [APP_GRANT.displayNameId]: item.displayNameId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
