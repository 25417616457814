import { Action, ActionReducer, ActionReducerMap, createAction, createFeatureSelector, INIT, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import { InjectionToken } from '@angular/core';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  //[fromLayout.layoutFeatureKey]: fromLayout.State;
  router: fromRouter.RouterReducerState<any>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
  >('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}


// // A function to allow the developer to choose the instance of search reducer to target in their selector.
// export const chooseSearchInstance = (instance: string): ((state: State) => searchReducer.State) => {
//   switch(instance) {
//     case 'books': {
//       return getBookSearchState;
//     }
//     case 'magazines': {
//       return getMagazineSearchState;
//     }
//   }
// }

export function logout(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    if ( action != null && action.type === clearState.type) {
      return reducer( undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [/*logger,*/
    logout]
  : [];

/**
 * Layout Reducers
 */
// export const selectLayoutState = createFeatureSelector<State, fromLayout.State>(
//   fromLayout.layoutFeatureKey
// );
//
// export const selecthowSidenav = createSelector(
//   selectLayoutState,
//   fromLayout.selectShowSidenav
// );

export const selectRouter = createFeatureSelector<
  fromRouter.RouterReducerState<any>
  >('router');

const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRootState = (state: State) => state;

/** Actions **/

export const clearState = createAction(
  '[APP] Client change / User Logout / Clear State'
);
