import { createSelector } from '@ngrx/store';
import * as fromEquipmentPlanning from '../reducers/equipment-planning.reducer';
import { selectFloorsState } from '../reducers';

/** Select store slice **/

export const selectEquipmentPlannings = createSelector(
  selectFloorsState,
  state => state[fromEquipmentPlanning.equipmentPlanningFeatureKey]
);

/** Basics selectors **/

export const selectEquipmentPlanningIds = createSelector(
  selectEquipmentPlannings,
  fromEquipmentPlanning.selectEquipmentPlanningIds // shorthand for equipmentPlanningsState => fromEquipmentPlanning.selectEquipmentPlanningIds(equipmentPlanningsState)
);
export const selectEquipmentPlanningEntities = createSelector(
  selectEquipmentPlannings,
  fromEquipmentPlanning.selectEquipmentPlanningEntities
);
export const selectAllEquipmentPlannings = createSelector(
  selectEquipmentPlannings,
  fromEquipmentPlanning.selectAllEquipmentPlannings
);
export const selectEquipmentPlanningTotal = createSelector(
  selectEquipmentPlannings,
  fromEquipmentPlanning.selectEquipmentPlanningTotal
);

/** Advanced selectors **/

export const selectEquipmentPlanningsByFloorDataId = (id: number) => createSelector(
  selectAllEquipmentPlannings,
  (equipmentPlannings) => equipmentPlannings.filter(w => w.floorDataId === id)
);

export const selectEquipmentPlanningByFloorDataId = (id: number) => createSelector(
  selectEquipmentPlanningEntities,
  (entities) => entities[id]
);
