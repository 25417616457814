import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export const DOCUMENT = {
  databaseTableName: 'document',
  id: 'Do_Id',
  filename: 'Do_FileName',
  typeId: 'Do_TypeId',
  dataModelId: 'Do_DataModelId',
  dataModelItemId: 'Do_DataModelItemId',
  memo: 'Do_Memo'
};

export class Document {
  constructor(
    public databaseTableName: string,
    public id: number,
    public filename: string,
    public typeId: number,
    public dataModelId: number,
    public dataModelItemId: number,
    public memo: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class DocumentAdapter implements Adapter<Document> {
  adapt(item: any): Document {
    return new Document (
      DOCUMENT.databaseTableName,
      item[DOCUMENT.id],
      item[DOCUMENT.filename],
      item[DOCUMENT.typeId],
      item[DOCUMENT.dataModelId],
      item[DOCUMENT.dataModelItemId],
      item[DOCUMENT.memo],
    );
  }
  encode(item: Document): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DOCUMENT.id]: item.id,
      [DOCUMENT.filename]: item.filename,
      [DOCUMENT.typeId]: item.typeId,
      [DOCUMENT.dataModelId]: item.dataModelId,
      [DOCUMENT.dataModelItemId]: item.dataModelItemId,
      [DOCUMENT.memo]: item.memo
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
