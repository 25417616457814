import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { FloorLayoutView, FloorLayoutViewAdapter } from '../views/floor-layout-view.model';
import { FloorAttributionView, FloorAttributionViewAdapter } from '../views/floor-attribution-view.model';
import { FloorAllocationView, FloorAllocationViewAdapter } from '../views/floor-allocation-view.model';
import { FloorActivityStatusView, FloorActivityStatusViewAdapter } from '../views/floor-activity-status-view.model';

const BUILDINGS_PLANNING_DATA = {
  floorActivityStatusView: 'FloorActivityStatusView',
  floorAllocationView: 'FloorAllocationView',
  floorAttributionView: 'FloorAttributionView',
  floorLayoutView: 'FloorLayoutView'
};

export class ApiBuildingsPlanningData {
  constructor(
    public floorActivityStatusView: FloorActivityStatusView[],
    public floorAllocationView: FloorAllocationView[],
    public floorAttributionView: FloorAttributionView[],
    public floorLayoutView: FloorLayoutView[]
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiBuildingsPlanningDataAdapter implements Adapter<ApiBuildingsPlanningData> {
  adapt(item: any): ApiBuildingsPlanningData {
    const floorAllocationViewAdapter = new FloorAllocationViewAdapter();
    const floorActivityStatusViewAdapter = new FloorActivityStatusViewAdapter();
    const floorAttributionViewAdapter = new FloorAttributionViewAdapter();
    const floorLayoutViewAdapter = new FloorLayoutViewAdapter();
    return new ApiBuildingsPlanningData(
      item[BUILDINGS_PLANNING_DATA.floorActivityStatusView].map(e => floorActivityStatusViewAdapter.adapt(e)),
      item[BUILDINGS_PLANNING_DATA.floorAllocationView].map(e => floorAllocationViewAdapter.adapt(e)),
      item[BUILDINGS_PLANNING_DATA.floorAttributionView].map(e => floorAttributionViewAdapter.adapt(e)),
      item[BUILDINGS_PLANNING_DATA.floorLayoutView].map(e => floorLayoutViewAdapter.adapt(e)),
    );
  }
}
