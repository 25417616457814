import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadService } from '../../upload.service';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'echo-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Input() multi: boolean;
  @Input() acceptedFiles: string;
  @Input() uploadUrl: string;
  @Input() uploadMetadata: string;
  @Input() beginUpload = new Subject<boolean>();
  @Input() simpleIcon: boolean;

  @Output() successfulUpload = new EventEmitter<any>();
  @Output() uploading = new EventEmitter<boolean>();
  @Output() filesCount = new EventEmitter<number>();
  @ViewChild('file') file: ElementRef;

  progress;
  canBeClosed = true;
  primaryButtonText = 'Téléverser';
  // showCancelButton = true;
  // uploading = false;
  uploadSuccessful = false;
  progressIcon = 'cloud';

  public files: Set<File> = new Set();

  constructor(public uploadService: UploadService) {
    if (!this.acceptedFiles) {
      this.acceptedFiles = '*';
    }
  }

  ngOnInit() {
    this.beginUpload.subscribe(() => this.uploadFiles());
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      // tslint:disable-next-line:radix
      if (!isNaN(parseInt(key))) {
        if (this.files.size === 0 || this.multi) {
          this.files.add(files[key]);
        } else {
          this.files.clear();
          this.files.add(files[key]);
        }
      }
    }
    this.filesCount.next(this.files.size);
  }

  uploadFiles() {
    // if everything was uploaded already, just close the upload-dialog
    // if (this.uploadSuccessful) {
    //   return this.dialogRef.close();
    // }

    // set the component state to "uploading"
    //this.uploading = true;
    this.uploading.next(true);

    // start the upload and save the progress map
    this.progress = this.uploadService.upload(this.files, this.uploadUrl, this.uploadMetadata);

    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in this.progress) {
      // allProgressObservables.push(this.progress[key].progress);
      allProgressObservables.push(this.progress[key].response);
    }

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      this.successfulUpload.next(end);

      // ... the upload was successful...
      this.uploadSuccessful = true;

      // ... and the component is no longer uploading
      this.uploading.next(false);
    });
  }


}
