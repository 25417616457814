import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { CoreActions } from '../actions';
import { UserService } from '../../../core/services/user.service';
import { MenuService } from '../../../core/services/menu.service';
import { Router } from '@angular/router';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { AppService } from '../../../core/services/app.service';

@Injectable()
export class CoreEffects {

  // setClientFile$ = createEffect(() => this.actions$.pipe(
  //   ofType(CoreActions.setClientFile),
  //   switchMap(action => this.userService.setUserFromIdentity().pipe(
  //     switchMap(user => this.userService.getEcsyUser().pipe(
  //       map(appUser => {
  //         user.setAppUser(appUser);
  //         console.log(user);
  //         //console.log(appUser);
  //         //console.log(action);
  //         sessionStorage.setItem('client_file', JSON.stringify(action.client));
  //         this.menuService.loadMenu();
  //         if (action.navigateToIndex) {
  //           this.router.navigate(['']);
  //         }
  //       }),
  //     )),
  //   )),
  // ), {dispatch: false});

  setClientFile$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.setClient),
    switchMap(action => this.userService.setUserFromIdentity().pipe(
      switchMap(user => this.userService.getEcsyUser().pipe(
        map(appUser => {
          this.store.dispatch(CoreActions.setAppUser({appUser: appUser}));
          sessionStorage.setItem('client_file', JSON.stringify(action.client));
          this.menuService.loadMenu();
          this.appService.loadAppParams();
          if (action.navigateToIndex) {
            this.router.navigate(['']);
          }
        }),
      )),
    )),
  ), {dispatch: false});

  clearClientFile = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.clearClient),
    tap(_ => {
      this.store.dispatch(CoreActions.setAppUser({appUser: null}));
      sessionStorage.removeItem('client_file');
      //this.appService.loadAppParams();
      this.menuService.loadMenu();
    }),
  ), {dispatch: false});

  // setClientFile$ = createEffect(() => this.actions$.pipe(
  //   ofType(CoreActions.setClientFile),
  //   switchMap(action => this.ticketService.releaseTicket().pipe(
  //     concatMap(_ => this.userService.setUserFromIdentity().pipe(
  //       map(user => {
  //         console.log(action);
  //         sessionStorage.setItem('client_file', JSON.stringify(action.client));
  //         this.menuService.loadMenu();
  //         if(action.navigateToIndex) {
  //           this.router.navigate(['']);
  //         }
  //       }),
  //     )),
  //   ))
  // ), {dispatch: false});

  // releaseTicket$ = createEffect(() => this.actions$.pipe(
  //   ofType(CoreActions.releaseTicket),
  //   switchMap(action => this.ticketService.releaseTicket())
  // ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    // private ticketService: TicketService,
    private appService: AppService,
    private userService: UserService,
    private menuService: MenuService,
    private router: Router
  ) {
  }
}
