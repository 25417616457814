import { createSelector } from '@ngrx/store';
import * as fromFloorData from '../reducers/floor-data.reducer';
import { selectSvgState } from '../reducers';
import { Dictionary } from '@ngrx/entity';
import { SvgGroup } from '../../../features/svg-factory/models/svg-group.model';
import { selectFloorsState } from '../../floor-store/reducers';
import { selectFloorsByBuildingId } from '../../floor-store/selectors/floor.selectors';
import { selectFloorModelsIds } from './task-floor-model.selectors';
import { selectCurrentTaskId, selectTasks } from '../../project-management/selectors/task.selectors';
import { FloorDataStateEnum } from '../../../domain-models/business/floor-data-state.model';

export const selectFloorData = createSelector(
  selectSvgState,
  state => state[fromFloorData.floorDataFeatureKey] as fromFloorData.State
);

export const selectFloorDataIds = createSelector(
  selectFloorData,
  fromFloorData.selectFloorDataIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectFloorDataEntities = createSelector(
  selectFloorData,
  fromFloorData.selectFloorDataEntities
);
export const selectAllFloorData = createSelector(
  selectFloorData,
  fromFloorData.selectAllFloorDataItems
);
export const selectFloorDataTotal = createSelector(
  selectFloorData,
  fromFloorData.selectFloorDataTotal
);

export const selectFloorDataChildren = (id: number) => createSelector(
  selectAllFloorData,
  floorDataItems => floorDataItems.filter(item => item.parentId === id || item.parentId)
);

export const selectFloorDataById = (id: number) => createSelector(
  selectFloorDataEntities,
  entities => entities[id]
);
//  floorDataItems => floorDataItems.find(item => item.id === id)

export const selectFloorDataByIds = (ids: number[]) => createSelector(
  selectFloorDataEntities,
  (entities) => ids.map(id => entities[id])
);

export const getSelectedFloorDataIds = createSelector(
  selectFloorData,
  state => state.selectedIds
);

export const getSelectedFloorDataItems = createSelector(
  selectAllFloorData,
  getSelectedFloorDataIds,
  (floorData, ids) =>
    floorData.filter(d => d.id === ids.find(e => e === d.id))
);

export const selectFloorDataByTaskId = (taskId: number) => createSelector(
  selectAllFloorData,
  floorDataItems => floorDataItems.filter(item => item.taskId === taskId)
);

export const selectFloorDataIdsByTaskId = (taskId: number) => createSelector(
  selectFloorDataByTaskId(taskId),
  taskFloorDataItems => taskFloorDataItems.filter(d => d.taskId === taskId).map(d => d.id)
);

export const selectFloorDataByBuildingId = (buildingId: number) => createSelector(
  selectSvgState,
  selectFloorsState,
  selectAllFloorData,
  selectFloorsByBuildingId(buildingId),
  (svgState, floorState, floorDataItems, floors) => {
    const buildingTaskIds = floors.filter(f => f.buildingId === buildingId).map(f => f.taskId);
    return floorDataItems.filter(item => buildingTaskIds.find(taskId => item.taskId === taskId));
  }
);

export const selectFloorDataIdsByBuildingId = (buildingId: number) => createSelector(
  selectFloorDataByBuildingId(buildingId),
  buildingFloorDataItems => buildingFloorDataItems.map(d => d.id)
);

export const selectFloorDataItemsByFloorModelEntities = createSelector(
  selectAllFloorData,
  selectFloorModelsIds,
  (floorDataItems, taskFloorModelIds) => {
    const dict = {} as Dictionary<SvgGroup[]>;
    // Initialize dictionary keys with FloorModels Ids and empty []
    taskFloorModelIds.forEach(id => dict[id] = []);
    // Go through dict keys
    Object.keys(dict).forEach(id => {
      // Filter floorDataItems by concatenation of FlMo_Id & Ta_Id
      dict[id] = floorDataItems.filter(g => `${g.floorModelId}-${g.taskId}` === id);
    });
    //console.log(dict);
    return dict;
  }
);

/** FloorDataState selectors **/

export const selectCurrentTaskAddedFloorData = createSelector(
  selectTasks,
  selectAllFloorData,
  selectCurrentTaskId,
  (tasksState, floorDataItems, currentTaskId) =>
    floorDataItems.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Added)
);

export const selectCurrentTaskDeletedFloorData = createSelector(
  selectTasks,
  selectAllFloorData,
  selectCurrentTaskId,
  (tasksState, floorDataItems, currentTaskId) =>
    floorDataItems.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Deleted)
);

export const selectCurrentTaskUpdatedFloorData = createSelector(
  selectTasks,
  selectAllFloorData,
  selectCurrentTaskId,
  (tasksState, floorDataItems, currentTaskId) =>
    floorDataItems.filter(f => f.taskId === currentTaskId && f.dataStateId === FloorDataStateEnum.Updated)
);
