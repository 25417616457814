import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';

export enum WorkplaceTypeEnum {
  NR = 1,
  Standard,
  Flex,
  Meeting,
  Neutralized
}

export const WORKPLACE_TYPE = {
  databaseTableName: 'WorkplaceType',
  id: 'WoTy_Id',
  name: 'WoTy_Name',
  color: 'WoTy_Color',
  scope: 'WoTy_Scope',
  isActive: 'WoTy_IsActive',
  isAllocatable: 'WoTy_IsAllocatable',
  displayNameId: 'WoTy_DisplayNameId'
};

export class WorkplaceType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public color: string,
    public scope: number,
    public isActive: boolean,
    public isAllocatable: boolean,
    public displayNameId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class WorkplaceTypeAdapter implements Adapter<WorkplaceType> {
  adapt(item: any): WorkplaceType {
    return new WorkplaceType(
      WORKPLACE_TYPE.databaseTableName,
      item[WORKPLACE_TYPE.id],
      item[WORKPLACE_TYPE.name],
      item[WORKPLACE_TYPE.color],
      item[WORKPLACE_TYPE.scope],
      item[WORKPLACE_TYPE.isActive],
      item[WORKPLACE_TYPE.isAllocatable],
      item[WORKPLACE_TYPE.displayNameId],
    );
  }

  encode(item: WorkplaceType): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [WORKPLACE_TYPE.id]: item.id,
      [WORKPLACE_TYPE.name]: item.name,
      [WORKPLACE_TYPE.name]: item.color,
      [WORKPLACE_TYPE.name]: item.scope,
      [WORKPLACE_TYPE.name]: item.isActive,
      [WORKPLACE_TYPE.isAllocatable]: item.isAllocatable,
      [WORKPLACE_TYPE.displayNameId]: item.displayNameId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
