import { createAction, props } from '@ngrx/store';

export const clearBuildingUiContextState = createAction('[Building] - Clear Building Ui Context State');

/** Building related **/
export const setCurrentBuildingRoomLayoutTypeIds = createAction('[API/Buildings] - Set Current Building RoomLayoutTypes Ids', props<{ids: number[]}>());
export const setCurrentBuildingActivityStatusTypeIds = createAction('[API/Buildings] - Set Current Building ActivityStatusTYpe Ids', props<{ids: number[]}>());
export const setCurrentBuildingAttributionTypeIds = createAction('[API/Buildings] - Set Current Building AttributionType Ids', props<{ids: number[]}>());
export const setCurrentBuildingBusinessUnitIds = createAction('[API/Buildings] - Set Current Building BusinessUnit Ids', props<{ids: number[]}>());

// export const updateSelectedTaskId = createAction(
//   '[Building] - Save Selected Floor Ta_Id',
//   props<{ taskId: number }>()
// );
