import { createSelector } from '@ngrx/store';
import * as fromTaskLink from '../reducers/task-link.reducer';
import { selectProjectManagementState } from '../reducers';

/** Select store slice **/

export const selectTaskLinks = createSelector(
  selectProjectManagementState,
  state => state[fromTaskLink.taskLinkFeatureKey]
);

/** Basics selectors **/

export const selectTaskLinkIds = createSelector(
  selectTaskLinks,
  fromTaskLink.selectTaskLinkIds
);
export const selectTaskLinkEntities = createSelector(
  selectTaskLinks,
  fromTaskLink.selectTaskLinkEntities
);
export const selectAllTaskLinks = createSelector(
  selectTaskLinks,
  fromTaskLink.selectAllTaskLinks
);
export const selectTaskLinkTotal = createSelector(
  selectTaskLinks,
  fromTaskLink.selectTaskLinkTotal
);
export const selectCurrentTaskLinkId = createSelector(
  selectTaskLinks,
  fromTaskLink.getSelectedTaskLinkId
);

export const selectCurrentTaskLink = createSelector(
  selectTaskLinkEntities,
  selectCurrentTaskLinkId,
  (taskLinkEntities, taskLinkId) => taskLinkEntities[taskLinkId]
);

/** Advanced selectors **/

export const selectTaskLinkById = (id: number) => createSelector(
  selectTaskLinkEntities,
  (entities) => entities[id]
);
