import * as signalR from '@microsoft/signalr';

export class SignalRHttpClient extends signalR.HttpClient {
  private logger: signalR.ILogger;
  constructor(private echoCompanyId: string) {
    super();
  }

  public send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
    request.headers = { ...request.headers, 'Echo-Company-Id': this.echoCompanyId };
    // Check that abort was not signaled before calling send
    if (request.abortSignal && request.abortSignal.aborted) {
      return Promise.reject(new signalR.AbortError());
    }

    if (!request.method) {
      return Promise.reject(new Error('No method defined.'));
    }
    if (!request.url) {
      return Promise.reject(new Error('No url defined.'));
    }

    return new Promise<signalR.HttpResponse>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // tslint:disable-next-line:no-non-null-assertion
      xhr.open(request.method!, request.url!, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      // Explicitly setting the Content-Type header for React Native on Android platform.
      xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');

      const headers = request.headers;
      if (headers) {
        Object.keys(headers)
          .forEach((header) => {
            xhr.setRequestHeader(header, headers[header]);
          });
      }

      if (request.responseType) {
        xhr.responseType = request.responseType;
      }

      if (request.abortSignal) {
        request.abortSignal.onabort = () => {
          xhr.abort();
          reject(new signalR.AbortError());
        };
      }

      if (request.timeout) {
        xhr.timeout = request.timeout;
      }

      xhr.onload = () => {
        if (request.abortSignal) {
          request.abortSignal.onabort = null;
        }

        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(new signalR.HttpResponse(xhr.status, xhr.statusText, xhr.response || xhr.responseText));
        } else {
          reject(new signalR.HttpError(xhr.statusText, xhr.status));
        }
      };

      xhr.onerror = () => {
        this.logger.log(signalR.LogLevel.Warning, `Error from HTTP request. ${xhr.status}: ${xhr.statusText}.`);
        reject(new signalR.HttpError(xhr.statusText, xhr.status));
      };

      xhr.ontimeout = () => {
        this.logger.log(signalR.LogLevel.Warning, `Timeout from HTTP request.`);
        reject(new signalR.TimeoutError());
      };

      xhr.send(request.content || '');
    });
  }
}
