import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';
import { PERIMETER } from '../business/perimeter.model';
import { PERIMETER_DETAIL } from '../business/perimeter-detail.model';
import { FLOOR } from '../business/floor.model';

export const PERIMETER_DETAIL_VIEW = {
  databaseTableName: 'PerimeterDetailView',
  perimeterId: PERIMETER.id,
  perimeterName: PERIMETER.name,
  perimeterDescription: PERIMETER.description,
  perimeterTypeId: PERIMETER.typeId,
  perimeterIsSharingArea: PERIMETER.isSharingArea,
  perimeterReadGrantId: PERIMETER.readGrantId,
  perimeterDetailId: PERIMETER_DETAIL.id,
  perimeterDetailPerimeterId: PERIMETER_DETAIL.perimeterId,
  perimeterDetailFloorId: PERIMETER_DETAIL.floorId,
  floorId: FLOOR.id,
  floorBuildingId: FLOOR.buildingId,
  floorCode: FLOOR.code,
  floorName: FLOOR.name,
  floorIsActive: FLOOR.isActive,
  floorIsFictive: FLOOR.isFictive,
  floorLocation: FLOOR.location,
  floorArea: FLOOR.area,
  floorElevation: FLOOR.elevation,
  floorTaskId: FLOOR.taskId,
  floorDefaultPerimeterId: FLOOR.defaultPerimeterId
};

export class PerimeterDetailView {
  constructor(
    public databaseTableName: string,
    public perimeterId: number,
    public perimeterName: string,
    public perimeterDescription: string,
    public perimeterTypeId: number,
    public perimeterIsSharingArea: boolean,
    public perimeterReadGrantId: number,
    public perimeterDetailId: number,
    public perimeterDetailPerimeterId: number,
    public perimeterDetailFloorId: number,
    public floorId: number,
    public floorBuildingId: number,
    public floorCode: string,
    public floorName: string,
    public floorIsActive: boolean,
    public floorIsFictive: boolean,
    public floorLocation: string,
    public floorArea: number,
    public floorElevation: number,
    public floorTaskId: number,
    public floorDefaultPerimeterId: number) {}
}

@Injectable({
  providedIn: 'root'
})

export class PerimeterDetailViewAdapter implements Adapter<PerimeterDetailView> {
  adapt(item: any): PerimeterDetailView {
    return new PerimeterDetailView(
      PERIMETER_DETAIL_VIEW.databaseTableName,
      item[PERIMETER_DETAIL_VIEW.perimeterId],
      item[PERIMETER_DETAIL_VIEW.perimeterName],
      item[PERIMETER_DETAIL_VIEW.perimeterDescription],
      item[PERIMETER_DETAIL_VIEW.perimeterTypeId],
      item[PERIMETER_DETAIL_VIEW.perimeterIsSharingArea],
      item[PERIMETER_DETAIL_VIEW.perimeterReadGrantId],
      item[PERIMETER_DETAIL_VIEW.perimeterDetailId],
      item[PERIMETER_DETAIL_VIEW.perimeterDetailPerimeterId],
      item[PERIMETER_DETAIL_VIEW.perimeterDetailFloorId],
      item[PERIMETER_DETAIL_VIEW.floorId],
      item[PERIMETER_DETAIL_VIEW.floorBuildingId],
      item[PERIMETER_DETAIL_VIEW.floorCode],
      item[PERIMETER_DETAIL_VIEW.floorName],
      item[PERIMETER_DETAIL_VIEW.floorIsActive],
      item[PERIMETER_DETAIL_VIEW.floorIsFictive],
      item[PERIMETER_DETAIL_VIEW.floorLocation],
      item[PERIMETER_DETAIL_VIEW.floorArea],
      item[PERIMETER_DETAIL_VIEW.floorElevation],
      item[PERIMETER_DETAIL_VIEW.floorTaskId],
      item[PERIMETER_DETAIL_VIEW.floorDefaultPerimeterId]
    );
  }
}
