import { Action, combineReducers, createAction, createFeatureSelector, INIT } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as fromTaskFloor from '../reducers/task-floor.reducer';
import * as fromTaskStatus from './task-status.reducer';
import * as fromTaskBusinessType from './task-business-type.reducer';
import * as fromTaskType from './task-type.reducer';
import * as fromTask from './task.reducer';
import * as fromTaskContributor from './task-contributor.reducer';
import * as fromTaskContributorDirectory from './task-contributor-directory.reducer';
import * as fromGanttTask from './gantt-task.reducer';
import * as fromTaskLink from './task-link.reducer';
import * as fromTaskFunction from './task-function.reducer';
import * as fromProjectManagementUi from './project-management-ui.reducer';
import * as fromTaskValidation from './task-validation.reducer';

export const projectManagementFeatureKey = 'projectManagement';

export const resetProjectManagementState = createAction('[ProjectManagementState] - Reset ProjectManagementState');

export interface ProjectManagementState {
  [fromTask.taskFeatureKey]: fromTask.State;
  [fromTaskFloor.taskTaskFloorFeatureKey]: fromTaskFloor.State;
  [fromTaskStatus.taskStatusFeatureKey]: fromTaskStatus.State;
  [fromTaskBusinessType.taskBusinessTypeFeatureKey]: fromTaskBusinessType.State;
  [fromTaskType.taskTypeFeatureKey]: fromTaskType.State;
  [fromTaskContributor.taskContributorFeatureKey]: fromTaskContributor.State;
  [fromTaskContributorDirectory.taskContributorDirectoryFeatureKey]: fromTaskContributorDirectory.State;
  [fromGanttTask.ganttTaskFeatureKey]: fromGanttTask.State;
  [fromTaskLink.taskLinkFeatureKey]: fromTaskLink.State;
  [fromTaskFunction.taskFunctionFeatureKey]: fromTaskFunction.State;
  [fromProjectManagementUi.projectManagementFeatureKey]: fromProjectManagementUi.State;
  [fromTaskValidation.taskValidationFeatureKey]: fromTaskValidation.State;
}

export interface State extends fromRoot.State {
  [projectManagementFeatureKey]: ProjectManagementState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: ProjectManagementState | undefined, action: Action) {
  if ( action != null && action.type === resetProjectManagementState.type) {
    console.log(action);
    return reducers( undefined, {type: INIT});
  }
  return combineReducers({
    [fromTask.taskFeatureKey]: fromTask.reducer,
    [fromTaskFloor.taskTaskFloorFeatureKey]: fromTaskFloor.reducer,
    [fromTaskStatus.taskStatusFeatureKey]: fromTaskStatus.reducer,
    [fromTaskBusinessType.taskBusinessTypeFeatureKey]: fromTaskBusinessType.reducer,
    [fromTaskType.taskTypeFeatureKey]: fromTaskType.reducer,
    [fromTaskContributor.taskContributorFeatureKey]: fromTaskContributor.reducer,
    [fromTaskContributorDirectory.taskContributorDirectoryFeatureKey]: fromTaskContributorDirectory.reducer,
    [fromGanttTask.ganttTaskFeatureKey]: fromGanttTask.reducer,
    [fromTaskLink.taskLinkFeatureKey]: fromTaskLink.reducer,
    [fromTaskFunction.taskFunctionFeatureKey]: fromTaskFunction.reducer,
    [fromProjectManagementUi.projectManagementFeatureKey]: fromProjectManagementUi.reducer,
    [fromTaskValidation.taskValidationFeatureKey]: fromTaskValidation.reducer,
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectProjectManagementState = createFeatureSelector<ProjectManagementState>(
  projectManagementFeatureKey
);
