import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export const Z_COLUMN_CONSTRAINT = {
  databaseTableName:  'z_column_constraint',
  id: 'CoCo_Id',
  tableName: 'CoCo_TableName',
  columnName: 'CoCo_ColumnName',
  constraintType: 'CoCo_ConstraintType',
  constraintMode: 'CoCo_ConstraintMode',
  value: 'CoCo_Value',
  index: 'CoCo_Index',
  triggeredId: 'CoCo_TriggeredId',
  messageId: 'CoCo_MessageId'
};

export class ZColumnConstraint {
  constructor(
    public databaseTableName: string,
    public id: number,
    public tableName: string,
    public columnName: string,
    public constraintType: number,
    public constraintMode: number,
    public value: number,
    public index: number,
    public triggeredId: number,
    public messageId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})


export class ZColumnConstraintAdapter implements Adapter<ZColumnConstraint> {
  adapt(item: any): ZColumnConstraint {
    return new ZColumnConstraint(
      Z_COLUMN_CONSTRAINT.databaseTableName,
      item[Z_COLUMN_CONSTRAINT.id],
      item[Z_COLUMN_CONSTRAINT.tableName],
      item[Z_COLUMN_CONSTRAINT.columnName],
      item[Z_COLUMN_CONSTRAINT.constraintType],
      item[Z_COLUMN_CONSTRAINT.constraintMode],
      item[Z_COLUMN_CONSTRAINT.value],
      item[Z_COLUMN_CONSTRAINT.index],
      item[Z_COLUMN_CONSTRAINT.triggeredId],
      item[Z_COLUMN_CONSTRAINT.messageId]
    );
  }
}
