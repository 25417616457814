import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BuildingActions } from '../actions';
import { Building } from '../../../domain-models/business/building.model';

export const buildingFeatureKey = 'buildings';

export interface State extends EntityState<Building> {
  // additional entities state properties
  selectedBuildingId: number | null;
}

export function selectBuildingId(a: Building): number {
  //In this case this would be optional since primary key is id
  return a.id;
}

export const adapter: EntityAdapter<Building> = createEntityAdapter<Building>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedBuildingId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(BuildingActions.addBuilding, (state, { building }) => {
    return adapter.addOne(building, state);
  }),
  on(BuildingActions.setBuilding, (state, { building }) => {
    return adapter.setOne(building, state);
  }),
  on(BuildingActions.upsertBuilding, (state, { building }) => {
    return adapter.upsertOne(building, state);
  }),
  on(BuildingActions.addBuildings, (state, { buildings }) => {
    return adapter.addMany(buildings, state);
  }),
  on(BuildingActions.upsertBuildings, (state, { buildings }) => {
    return adapter.upsertMany(buildings, state);
  }),
  on(BuildingActions.updateBuilding, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(BuildingActions.updateBuildings, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(BuildingActions.mapBuildings, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(BuildingActions.deleteBuilding, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(BuildingActions.deleteBuildings, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(BuildingActions.deleteBuildingsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(BuildingActions.loadBuildings, (state, { buildings }) => {
    return adapter.setAll(buildings, state);
  }),
  on(BuildingActions.clearBuildings, state => {
    return adapter.removeAll({ ...state, selectedBuildingId: null });
  }),
  /** END OF CRUD **/
  on(BuildingActions.updateSelectedBuildingId, (state, {buildingId}) => {
    return ({...state, selectedBuildingId: buildingId});
  }),
);

export const getSelectedBuildingId = (state: State) => state.selectedBuildingId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of building ids
export const selectBuildingIds = selectIds;

// select the dictionary of building entities
export const selectBuildingEntities = selectEntities;

// select the array of buildings
export const selectAllBuildings = selectAll;

// select the total building count
export const selectBuildingTotal = selectTotal;
