import { Injectable } from '@angular/core';
import { Adapter, deleteUndefinedFields, ISingleBackendObj } from '../adapter';
import { Color, ColorUtils } from '../../core/utils/color';

export enum TaskBusinessTypeStandardRootItemsEnum {
  Maintenance = 1,
  Works,
  Move,
  Blueprint,
}

export const TASK_BUSINESS_TYPE = {
  databaseTableName: 'TaskBusinessType',
  id: 'TaBuTy_Id',
  parentId: 'TaBuTy_ParentId',
  depth: 'TaBuTy_Depth',
  name: 'TaBuTy_Name',
  displayNameId: 'TaBuTy_DisplayNameId',
  scope: 'TaBuTy_Scope',
  isActive: 'TaBuTy_IsActive',
  color: 'TaBuTy_Color',
  canHaveChildren: 'TaBuTy_CanHaveChildren',
};

export class TaskBusinessType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public parentId: number,
    public depth: number,
    public name: string,
    public displayNameId: number,
    public scope: number,
    public isActive: boolean,
    public color: Color,
    public canHaveChildren: boolean,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class TaskBusinessTypeAdapter implements Adapter<TaskBusinessType> {
  adapt(item: any): TaskBusinessType {
    return new TaskBusinessType(
      TASK_BUSINESS_TYPE.databaseTableName,
      item[TASK_BUSINESS_TYPE.id],
      item[TASK_BUSINESS_TYPE.parentId],
      item[TASK_BUSINESS_TYPE.depth],
      item[TASK_BUSINESS_TYPE.name],
      item[TASK_BUSINESS_TYPE.displayNameId],
      item[TASK_BUSINESS_TYPE.scope],
      item[TASK_BUSINESS_TYPE.isActive],
      ColorUtils.colorFromHexARGB(item[TASK_BUSINESS_TYPE.color]),
      item[TASK_BUSINESS_TYPE.canHaveChildren],
    );
  }

  encode(item: TaskBusinessType): ISingleBackendObj {
    const encoded = {
      TableName: TASK_BUSINESS_TYPE.databaseTableName,
      [TASK_BUSINESS_TYPE.id]: item.id,
      [TASK_BUSINESS_TYPE.parentId]: item.parentId,
      [TASK_BUSINESS_TYPE.depth]: item.depth,
      [TASK_BUSINESS_TYPE.name]: item.name,
      [TASK_BUSINESS_TYPE.displayNameId]: item.displayNameId,
      [TASK_BUSINESS_TYPE.scope]: item.scope,
      [TASK_BUSINESS_TYPE.isActive]: item.isActive,
      [TASK_BUSINESS_TYPE.color]: item.color.toHexArgb(),
      [TASK_BUSINESS_TYPE.canHaveChildren]: item.canHaveChildren,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
