import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from '../../../core/services/logger.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { LoaderService } from '../../../core/services/loader.service';
import { API_ENDPOINTS } from '../../../shared/api-endpoints';
import { first, map } from 'rxjs/operators';
import { FieldConfig, FieldDependency, FieldOption } from '../models/field.model';
import { ZDbTypeEnum } from '../../../domain-models/core/z-db-type.model';
import { ZConstraintTypeEnum } from '../../../domain-models/core/z-constraint-type.model';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { ZColumnView, ZColumnViewAdapter } from '../../../domain-models/core/views/z-column-view.model';
import { ZColumnConstraintView, ZColumnConstraintViewAdapter } from '../../../domain-models/core/views/z-column-constraint-view.model';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormsService extends ApiService {

  formSubmit$ = new Subject<boolean>();
  onSubmit$ = new EventEmitter();

  constructor(http: HttpClient,
              loggerService: LoggerService,
              snackBarService: SnackBarService,
              httpLoaderService: LoaderService,
              private zColumnViewAdapter: ZColumnViewAdapter,
              private zColumnConstraintViewAdapter: ZColumnConstraintViewAdapter) {
    super(http, loggerService, snackBarService, httpLoaderService);
  }

  getDependencyValues(constraintId: number, sourceColumnName: string, sourceColumnValue: string | number): Observable<FieldOption[]> {
    let params = new HttpParams();
    params = params.append('constraintId', String(constraintId));
    params = params.append('sourceColumnName', String(sourceColumnName));
    params = params.append('sourceColumnValue', String(sourceColumnValue));
    return this.get(`${API_ENDPOINTS.constraints}/DependencyValues`, 1, params).pipe(
      map(data => data.payload.map(v => {
          return {
            Id: v['Id'],
            Value: v['Value'],
            FileUrl: v['FileUrl']
          } as FieldOption;
        })
      )
    );
  }

  checkIfValueExists(tableName: string, sourceColumnName: string, sourceColumnValue: string | number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('tableName', String(tableName));
    params = params.append('columnName', String(sourceColumnName));
    params = params.append('value', String(sourceColumnValue));
    return this.get(`${API_ENDPOINTS.constraints}/ValueExists`, 1, params).pipe(
      map(data => data.payload)
    );
  }

  getConstraints(contextUITableUserId: number): Observable<{columns: ZColumnView[], constraints: ZColumnConstraintView[]}> {
    return this.get(`${API_ENDPOINTS.constraints}/${contextUITableUserId}`).pipe(
      map(data => {
          return {
            columns: data.contextUserColumns.map(c => this.zColumnViewAdapter.adapt(c)),
            constraints: data.payload.map(c => this.zColumnConstraintViewAdapter.adapt(c))
          };
        }
      )
    );
  }

  createFormFieldsFromTableName(tableName: string, contextUiTableUserId: number, data?: any): Observable<FieldConfig[]> {
    const regConfig = [] as FieldConfig[];

    /**
     * Load all constraints
     */

    return this.getConstraints(contextUiTableUserId)
      .pipe(
        // tap(constraintsPayload => console.log(constraintsPayload.constraints)),
        map(constraintsPayload => {

          tableName = constraintsPayload.columns[0].dbColumnTableName;
          /** UPDATE CASE **/
          // if (data) {
          //   this.updateForm.tableName = constraintsPayload.columns.find(c => c.dbColumnIsPrimaryKey).dbColumnTableName;
          //   this.updateForm.primaryColumnName = constraintsPayload.columns.find(c => c.dbColumnIsPrimaryKey).dbColumnColumnName;
          //   this.updateForm.primaryColumnIndex = this.data.row[this.updateForm.primaryColumnName];
          // }

          /** sort columns **/
          constraintsPayload.columns.sort((a, b) => a.dbColumndisplayOrder - b.dbColumndisplayOrder);

          constraintsPayload.columns.forEach(col => {
            let inputType = null;
            if (!col.dbColumnIsReadOnly) {
              if (col.dbTypeId === ZDbTypeEnum.Int32Int
                || col.dbTypeId === ZDbTypeEnum.Int64Int
                || col.dbTypeId === ZDbTypeEnum.DoubleDouble
                || col.dbTypeId === ZDbTypeEnum.DecimalDecimal) {
                inputType = 'number';
              }
              regConfig.push({
                type: col.dbTypeSystemTypeName,
                label: col.columnViewColumnDisplayName,
                name: col.dbColumnColumnName,
                inputType: inputType ? inputType : null,
              } as FieldConfig);
            } else if (col.dbColumnIsPrimaryKey) {
              regConfig.push({
                label: col.columnViewColumnDisplayName,
                name: col.dbColumnColumnName
              } as FieldConfig);
              /** Manually add table name**/
              regConfig.push({
                label: 'TableName',
                name: 'TableName',
                protectedValue: true,
                value: tableName
              } as FieldConfig);
            }
          });

          regConfig.forEach(fieldConfig => {
            /** Update case **/
            if (data) {
              // console.log(fieldConfig);
              const foundKey = Object.keys(data).find(k => k === fieldConfig.name);
              if (foundKey && !fieldConfig.protectedValue) {
                //console.log(`${foundKey} ${data[foundKey]}`);
                fieldConfig.value = data[foundKey];
              }
            }
            // else {
            //   /**
            //    * Set default value for boolean checkboxes
            //    */
            //   if (fieldConfig.type === 'checkbox' || fieldConfig.type === 'Boolean') {
            //     fieldConfig.value = false;
            //   }
            // }

            const validations = [];
            const constraints = constraintsPayload.constraints.filter(constraint => constraint.columnConstraintColumnName === fieldConfig.name);
            constraints.forEach(c => {
              // console.log(c);
              switch (c.columnConstraintConstraintType) {
                case ZConstraintTypeEnum.Dependency: {
                  // console.log(fieldConfig);
                  fieldConfig.type = 'select';
                  fieldConfig.fieldDependency = {
                    constraintId: c.columnConstraintId,
                    sourceColumnName: c.dependencySourceColumnName,
                    sourceColumnValue: fieldConfig.value,
                  } as FieldDependency;
                  break;
                }
                case ZConstraintTypeEnum.List: {
                  fieldConfig.type = 'select';
                  fieldConfig.options = c.columnConstraintValue;
                  break;
                }
                case ZConstraintTypeEnum.Minimum: {
                  if (fieldConfig.inputType === 'number') {
                    validations.push({
                      name: 'min',
                      validator: Validators.min(Number(c.columnConstraintValue)),
                      message: c.languageItemText
                    });
                  } else {
                    validations.push({
                      name: 'minlength',
                      validator: Validators.minLength(Number(c.columnConstraintValue)),
                      message: c.languageItemText
                    });
                  }
                  break;
                }
                case ZConstraintTypeEnum.Maximum: {
                  if (fieldConfig.inputType === 'number') {
                    validations.push({
                      name: 'max',
                      validator: Validators.max(Number(c.columnConstraintValue)),
                      message: c.languageItemText
                    });
                  } else {
                    validations.push({
                      name: 'maxlength',
                      validator: Validators.maxLength(Number(c.columnConstraintValue)),
                      message: c.languageItemText
                    });
                  }
                  break;
                }
                case ZConstraintTypeEnum.AllowsDiacritics: {
                  if (c.columnConstraintValue) {
                    validations.push({
                      name: 'pattern',
                      validator: Validators.pattern('^[a-zA-Zéèàê0-9 .,_\-]+$'),
                      // validator: Validators.pattern('^[a-zA-Zéèàê0-9 ]+$'),
                      message: c.languageItemText
                    });
                  } else {
                    validations.push({
                      name: 'pattern',
                      validator: Validators.pattern('^[a-zA-Z0-9 ]+$'),
                      // validator: Validators.pattern('^[a-zA-Z0-9 ]+$')
                      message: c.languageItemText
                    });
                  }
                  break;
                }
                case ZConstraintTypeEnum.IsUnique: {
                  if (c.columnConstraintValue) {
                    validations.push({
                      name: 'isUnique',
                      async: true,
                      validator: this.existingValueValidator(tableName, fieldConfig.name, fieldConfig.value),
                      message: c.languageItemText
                    });
                  }
                  break;
                }
                case ZConstraintTypeEnum.AllowsNull: {
                  if (!c.columnConstraintValue) {
                    validations.push({
                      name: 'required',
                      validator: Validators.required,
                      message: c.languageItemText
                    });
                  }
                  break;
                }
                case ZConstraintTypeEnum.DefaultValue: {
                  if (fieldConfig.type === 'Boolean') {
                    if (fieldConfig.value === null || fieldConfig.value === undefined) {
                      fieldConfig.value = +c.columnConstraintValue; // Infers boolean type from string
                    }
                  } else if (!fieldConfig.value) {
                    fieldConfig.value = c.columnConstraintValue;
                  }
                  break;
                }
                case ZConstraintTypeEnum.IsReadOnlyAfterCreate: {
                  if (data) { // Means user is updating a row
                    fieldConfig.disabled = true;
                  }
                  break;
                }
                case ZConstraintTypeEnum.IsReadOnlyOnApplicationScope: {
                  if (data && data[c.columnConstraintValue] && Number(data[c.columnConstraintValue]) === 1) {
                    fieldConfig.disabled = true;
                  }
                  break;
                }
                default: {
                  break;
                }
              }
            });

            fieldConfig.validations = validations;

          });
          // console.log(regConfig);
          return regConfig as FieldConfig[];
        })
      );
  }

  existingValueValidator(tableName: string, columnName: string, originalValue?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // Do not send request if original value equals control value (update case) or contro is pristine (new case)
      if (originalValue === control.value || control.pristine) {
        return of(null).pipe(first());
      } else {
        return this.checkIfValueExists(tableName, columnName, control.value).pipe(
          map(res => {
            return res ? { isUnique: true } : null;
          }),
          first(),
        );
      }
    };
  }


}
